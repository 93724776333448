<template>
  <div>
    <!-- bulk upload modal start -->
    <b-modal
      title="Import Data"
      size="lg"
      scrollable
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="onHideModal"
      visible
    >
      <div class="text-center" v-if="!isUploadingSuccessful">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          @vdropzone-success="vsuccess"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="sendingEvent"
          :useCustomSlot="true"
          class="m-5 p-3"
          style="border: dashed 1px"
          duplicateCheck
          @vdropzone-duplicate-file="onDuplicateFileUpload"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Drag and drop to upload content!
            </h3>
            <div class="subtitle">
              ...or click to select a file from your computer
            </div>
          </div>
        </vue-dropzone>

        <!-- Sample Excel files -->
        <router-link :to="urls.LIST_OF_SAMPLE_EXCEL_FILES" target="_blank">
          List of available Sample Excel Files
        </router-link>
      </div>
      <div v-if="isUploadingSuccessful">
        <p class="my-2 text-center">
          Your import data has been uploaded successfully.
        </p>
      </div>
      <template v-slot:modal-footer>
        <div class="float-right">
          <b-button
            size="md"
            class="mr-3"
            variant="outline-danger"
            v-if="!uploading"
            @click="resetModal"
            >Close</b-button
          >
          <b-button
            size="md"
            variant="outline-success"
            v-if="!uploading && !isUploadingSuccessful"
            @click="uploadFile"
            >Upload</b-button
          >
        </div>
      </template>
    </b-modal>
    <!-- bulk upload modal end -->

    <!-- errorModal for displaying error comes while uploading start -->
    <b-modal size="lg" v-model="errorModal" title="Import Data">
      <p>
        <b>The following import will not run due to the following reasons</b>
      </p>
      <b-table-simple bordered>
        <b-thead>
          <b-tr>
            <b-th>Reason</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(errorMsg, errorMsgIndex) in errorToastMsg"
            :key="errorMsgIndex"
          >
            <b-td><p v-html="errorMsg"></p></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <template v-slot:modal-footer>
        <b-btn @click="closeErrorModal" size="sm" variant="outline-success">
          Close
        </b-btn>
      </template>
    </b-modal>
    <!-- errorModal  for displaying error comes while uploading end -->

    <!-- toast for success/error message start -->
    <b-toast
      :visible="errorToast"
      variant="danger"
      title="Import Data"
      solid
      append-toast
      auto-hide-delay="5000"
      v-for="(errorMsg, errorMsgIndex) in errorToastMsg"
      :key="errorMsgIndex"
    >
      <p v-html="errorMsg"></p>
    </b-toast>
    <!-- toast for success/error message end -->
  </div>
</template>

<script>
import vue2Dropzone from "@/components/Dropzone/VueDropzone";
import {
  toastPropSuccess,
  toastPropFailure,
  urls,
  modalEvents
} from "@/constants";
import { makeAuthorizationHeader } from "@/utils";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState } from "vuex";

export default {
  name: "BulkUploadModal",
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      urls,
      errorModal: false,
      errorToast: false,
      errorToastMsg: [],
      isUploadingSuccessful: false,
      uploading: false,
      bulkUploadFormData: {},
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}thirdparty/bulk/upload`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: makeAuthorizationHeader()
      }
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  methods: {
    closeErrorModal() {
      this.errorModal = false;
    },
    uploadFile() {
      if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        this.uploading = true;
        this.$refs.myVueDropzone.processQueue();
      }
    },
    resetModal() {
      this.isUploadingSuccessful = false;
      this.onHideModal();
    },
    vsuccess(file, response) {
      this.errorToastMsg = [];
      this.uploading = false;
      this.isUploadingSuccessful = true;

      if (response && response.success == 0) {
        this.errorToastMsg = response.errors;
        this.isUploadingSuccessful = false;
        this.errorModal = true;
      } else {
        let message =
          "Your import data upload file has been successfully uploaded and is now processed";
        if (response && response.successCount && response.successCount > 0) {
          message = `Your import data upload file has been successfully uploaded and is now processed. ${response.successCount} suppliers have been uploaded on the platform.`;
        }
        if (
          response?.task_data?.[0]?.task_id &&
          response?.task_data?.[0]?.task_user_id
        ) {
          let url = `/user-form-page/${response.task_data[0].task_id}/${response.task_data[0].task_user_id}`;
          window.open(url, "_self");
        }
        this.$bvToast.toast(message, toastPropSuccess);
      }
      this.$refs.myVueDropzone.removeAllFiles();
    },
    onDuplicateFileUpload() {
      let message = "Duplicate Files cannot be uploaded";
      this.$bvToast.toast(message, toastPropFailure);
    },
    sendingEvent(file, xhr, formData) {
      formData.append("user_id", this.userId);
      formData.append("company_id", this.companyId);
      formData.append("background", 1);
      for (const [key, value] of Object.entries(this.bulkUploadFormData)) {
        formData.append(`${key}`, `${value}`);
      }
    },
    onHideModal() {
      this.$emit(modalEvents.CLOSE_MODAL);
    }
  },
  props: {
    companyId: Number,
    fileFormats: Array
  }
};
</script>
