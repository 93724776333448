import { state as makeState } from "./product.state.store";

export const mutations = {
  setAvailableProductList(state, payload) {
    state.availableProductList = payload;
  },
  setPlatformProductList(state, payload) {
    state.platformProductList = payload;
  },
  resetProduct(state) {
    Object.assign(state, makeState());
  },
  setIsLoadingProductList(state, value) {
    state.isLoadingProductList = value;
  }
};
