import { timers } from "./timers.constant";

const modalVariants = {
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger"
};

const toastPropSuccess = {
  variant: modalVariants.SUCCESS,
  autoHideDelay: timers.MODERATE_READABLE,
  appendToast: false,
  noCloseButton: true
};

const toastPropSlowHideSuccess = {
  title: "Success",
  appendToast: false,
  variant: modalVariants.SUCCESS,
  autoHideDelay: timers.VERY_SLOW
};

const toastPropFailure = {
  variant: modalVariants.DANGER,
  autoHideDelay: timers.MODERATE_READABLE,
  appendToast: false,
  noCloseButton: true
};

const toastPropSlowHideFailure = {
  title: "Error",
  appendToast: false,
  variant: modalVariants.DANGER,
  autoHideDelay: timers.VERY_SLOW
};

export {
  toastPropFailure,
  toastPropSuccess,
  toastPropSlowHideSuccess,
  toastPropSlowHideFailure,
  modalVariants
};
