const routeKeys = {
  LOGIN: "LOGIN",
  THIRD_PARTY_PROFILE: "THIRD_PARTY_PROFILE",
  THIRD_PARTY_PROFILE_COMPANY: "THIRD_PARTY_PROFILE_COMPANY",
  THIRD_PARTY_CREATE: "THIRD_PARTY_CREATE",
  THIRD_PARTY_UPDATE: "THIRD_PARTY_UPDATE",
  HOME: "HOME",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  DASHBOARD: "DASHBOARD",
  ANALYTICS_VIEW: "ANALYTICS_VIEW",
  ANALYTICS_APRI: "ANALYTICS_APRI",
  CREATE_PROFILE: "CREATE_PROFILE",
  FAQS: "FAQS",
  MANAGE_THIRD_PARTY: "MANAGE_THIRD_PARTY",
  MANAGE_THIRD_PARTY_ID: "MANAGE_THIRD_PARTY_ID",
  PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  ESG_THIRD_PARTIES: "ESG_THIRD_PARTIES",
  EVENT_LOG: "EVENT_LOG",
  EVENT_LOG_ID: "EVENT_LOG_ID",
  USER_SETTINGS: "USER_SETTINGS",
  NOTIFICATION_SETTINGS: "NOTIFICATION_SETTINGS",
  BRANDING_AND_LINKS_SETTINGS: "BRANDING_AND_LINKS_SETTINGS",
  GENERAL_SETTINGS: "GENERAL_SETTINGS",
  CUSTOM_ATTRIBUTES: "CUSTOM_ATTRIBUTES",
  EMAIL_TEMPLATE: "EMAIL_TEMPLATE",
  PLATFORM_SWITCHER: "PLATFORM_SWITCHER",
  DDQ_ONBOARDING: "DDQ_ONBOARDING",
  ACTIONS_WRAPPER: "ACTIONS_WRAPPER",
  ACTIONS: "ACTIONS",
  ADD_ACTION: "ADD_ACTION",
  EDIT_ACTION: "EDIT_ACTION",
  EDIT_ACTION_ID: "EDIT_ACTION_ID",
  AUTOMATIONS: "AUTOMATIONS",
  AUTOMATIONS_WRAPPER: "AUTOMATIONS_WRAPPER",
  ADD_AUTOMATION: "ADD_AUTOMATION",
  EDIT_AUTOMATION: "EDIT_AUTOMATION",
  EDIT_AUTOMATION_ID: "EDIT_AUTOMATION_ID",
  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_EMAIL_ID: "SET_PASSWORD_EMAIL_ID",
  PRODUCT_VERSION: "PRODUCT_VERSION",
  ROLES_WRAPPER: "ROLES_WRAPPER",
  ROLES: "ROLES",
  ADD_ROLE: "ADD_ROLE",
  EDIT_ROLE: "EDIT_ROLE",
  EDIT_ROLE_ID: "EDIT_ROLE_ID",
  ANALYTICS_OVERVIEW: "ANALYTICS_OVERVIEW",
  QUESTIONS_REFERENCE: "QUESTIONS_REFERENCE",
  ANALYTICS_USER_VIEW_ID: "ANALYTICS_USER_VIEW_ID",
  GREENLITE_THIRD_PARTIES: "GREENLITE_THIRD_PARTIES",
  ULTIMATE_BENEFICIAL_OWNERS: "ULTIMATE_BENEFICIAL_OWNERS",
  ULTIMATE_BENEFICIAL_OWNERS_PROFILE: "ULTIMATE_BENEFICIAL_OWNERS_PROFILE",
  ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS:
    "ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS",
  ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE:
    "ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE",
  ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS:
    "ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS",
  ANALYTICS_QUESTIONNAIRES_ID: "ANALYTICS_QUESTIONNAIRES_ID",
  EDD_REPORT_TYPE: "EDD_REPORT_TYPE",
  LIST_OF_SAMPLE_EXCEL_FILES: "LIST_OF_SAMPLE_EXCEL_FILES",
  ANALYTICS_QUESTIONNAIRES_COMPARATIVE: "ANALYTICS_QUESTIONNAIRES_COMPARATIVE",
  USER_FORM_PAGE: "USER_FORM_PAGE",
  ONBOARDING_TEMPLATES: "ONBOARDING_TEMPLATES",
  ONBOARDING_FORM: "ONBOARDING_FORM",
  ONBOARDING_FORM_CONFIGURATION: "ONBOARDING_FORM_CONFIGURATION",
  ONBOARDING_FORM_INTRO: "ONBOARDING_FORM_INTRO",
  ONBOARDING_FORM_DETAILS: "ONBOARDING_FORM_DETAILS",
  ONBOARDING_FORM_BUILDER: "ONBOARDING_FORM_BUILDER",
  ONBOARDING_FORM_JSON: "ONBOARDING_FORM_JSON",
  ONBOARDING_CLIENTS: "ONBOARDING_CLIENTS",
  ONBOARDING_CLIENTS_CONFIGURATION_PROFILE:
    "ONBOARDING_CLIENTS_CONFIGURATION_PROFILE",
  ONBOARDING_CLIENTS_PLATFORM_SETTINGS: "ONBOARDING_CLIENTS_PLATFORM_SETTINGS",
  ONBOARDING_CLIENTS_SETTINGS: "ONBOARDING_CLIENTS_SETTINGS",
  ONBOARDING_CLIENTS_EMAIL_TEMPLATES: "ONBOARDING_CLIENTS_EMAIL_TEMPLATES",
  ONBOARDING_CLIENTS_ACTIIONS: "ONBOARDING_CLIENTS_ACTIIONS",
  ONBOARDING_CLIENTS_BULK_ACTIONS: "ONBOARDING_CLIENTS_BULK_ACTIONS",
  ONBOARDING_CLIENTS_COLUMNS: "ONBOARDING_CLIENTS_COLUMNS",
  ONBOARDING_CLIENTS_EDD_CONFIGURATION: "ONBOARDING_CLIENTS_EDD_CONFIGURATION",
  ONBOARDING_CLIENTS_ACTION_DETAILS: "ONBOARDING_CLIENTS_ACTION_DETAILS",
  ONBOARDING_CLIENTS_ACTION_SETTINGS: "ONBOARDING_CLIENTS_ACTION_SETTINGS",
  ONBOARDING_CLIENTS_ANALYTICS: "ONBOARDING_CLIENTS_ANALYTICS",
  ANALYTICS: "ANALYTICS",
  USER_VIEWS: "USER_VIEWS",
  PLATFORM_SETTINGS: "PLATFORM_SETTINGS",
  ONBOARDING: "ONBOARDING",
  DASHBOARD_THIRD_PARTY: "DASHBOARD_THIRD_PARTY",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  MY_TPP_QUESTIONNAIRES: "MY_TPP_QUESTIONNAIRES",
  TRAINING_TEMPLATE: "TRAINING_TEMPLATE",
  TRAINING: "TRAINING",
  TRAINING_DETAILS: "TRAINING_DETAILS",
  PREVIEW: "PREVIEW",
  USER_EMAIL_TEMPLATES: "USER_EMAIL_TEMPLATES",
  USER_EMAIL_TEMPLATE_WRAPPER: "USER_EMAIL_TEMPLATE_WRAPPER",
  ADD_USER_EMAIL_TEMPLATE: "ADD_USER_EMAIL_TEMPLATE",
  EDIT_USER_EMAIL_TEMPLATE: "EDIT_USER_EMAIL_TEMPLATE",
  EDIT_USER_EMAIL_TEMPLATE_ID: "EDIT_USER_EMAIL_TEMPLATE_ID",
  THIRD_PARTY_ONBOARDING_QUESTIONNAIRES:
    "THIRD_PARTY_ONBOARDING_QUESTIONNAIRES",
  USER_FORM: "USER_FORM",
  INVALID_DOCUMENT_FORMAT: "INVALID_DOCUMENT_FORMAT",
  ACCESS_RESTRICTED: "ACCESS_RESTRICTED",
  PERMISSIONS: "PERMISSIONS",
  PERMISSIONS_PLATFORM_FEATURES: "PERMISSIONS_PLATFORM_FEATURES",
  TASK_TEMPLATES: "TASK_TEMPLATES",
  TASK_TEMPLATE_WRAPPER: "TASK_TEMPLATE_WRAPPER",
  ADD_TASK_TEMPLATE: "ADD_TASK_TEMPLATE",
  EDIT_TASK_TEMPLATE: "EDIT_TASK_TEMPLATE",
  EDIT_TASK_TEMPLATE_ID: "EDIT_TASK_TEMPLATE_ID",
  BI_DASHBOARD: "BI_DASHBOARD",
  TASKS_WRAPPER: "TASKS_WRAPPER",
  TASKS: "TASKS",
  TASK: "TASK",
  TASK_ID: "TASK_ID",
  DEFAULT: "DEFAULT",
  BULK_UPDATE: "BULK_UPDATE",
  MANAGE_PRODUCT: "MANAGE_PRODUCT"
};

const urls = {
  [routeKeys.DASHBOARD]: "/dashboard",
  [routeKeys.TRAINING]: "/training",
  [routeKeys.ANALYTICS_VIEW]: "/analytics-view",
  [routeKeys.ANALYTICS_OVERVIEW]: "/analytics-overview",
  [routeKeys.ANALYTICS_APRI]: "/analytics-apri",
  [routeKeys.CREATE_PROFILE]: "/create-profile",
  [routeKeys.FORGOT_PASSWORD]: "/forgot-password",
  [routeKeys.LOGIN]: "/login",
  [routeKeys.HOME]: "/",
  [routeKeys.FAQS]: "/faqs",
  [routeKeys.THIRD_PARTY_PROFILE]: (id = ":id") => `/thirdparty/profile/${id}`,
  [routeKeys.THIRD_PARTY_PROFILE_COMPANY]: (id = ":id", company = ":compId") =>
    `/thirdparty/profile/${id}/${company}`,
  [routeKeys.THIRD_PARTY_CREATE]: "/thirdparty/create",
  [routeKeys.THIRD_PARTY_UPDATE]: ({
    id = ":id",
    companyId = ":companyId"
  } = {}) => `/thirdparty/update/${id}/${companyId}`,
  [routeKeys.MANAGE_THIRD_PARTY]: "/manage/third-parties",
  [routeKeys.MANAGE_THIRD_PARTY_ID]: (id = ":id") =>
    `/manage/third-parties/${id}`,
  [routeKeys.ESG_THIRD_PARTIES]: "/esg-explore-third-parties",
  [routeKeys.EVENT_LOG]: "/event-log",
  [routeKeys.BI_DASHBOARD]: (id = ":id") => `/bi-dashboard/${id}`,
  [routeKeys.EVENT_LOG_ID]: (id = ":id") => `/event-log/${id}`,
  [routeKeys.USER_SETTINGS]: "/user-settings",
  [routeKeys.NOTIFICATION_SETTINGS]: "/notification-settings",
  [routeKeys.BRANDING_AND_LINKS_SETTINGS]: "/branding-and-links-settings",
  [routeKeys.GENERAL_SETTINGS]: "/general-settings",
  [routeKeys.CUSTOM_ATTRIBUTES]: "/custom-attributes",
  [routeKeys.EMAIL_TEMPLATE]: "/email-template",
  [routeKeys.DDQ_ONBOARDING]: (id = ":taskId") => `/ddq-onboarding/${id}`,
  [routeKeys.ACTIONS]: "/actions",
  [routeKeys.ADD_ACTION]: "/actions/add-action",
  [routeKeys.EDIT_ACTION]: `/actions/edit-action`,
  [routeKeys.EDIT_ACTION_ID]: (id = ":id") => `/actions/edit-action/${id}`,
  [routeKeys.PLATFORM_SWITCHER]: "/platform-switcher",
  [routeKeys.AUTOMATIONS]: "/automations",
  [routeKeys.ADD_AUTOMATION]: "/automations/add-automation",
  [routeKeys.EDIT_AUTOMATION]: `/actions/edit-automation`,
  [routeKeys.EDIT_AUTOMATION_ID]: (id = ":id") =>
    `/automation/edit-automation/${id}`,
  [routeKeys.SET_PASSWORD]: "/set-password",
  [routeKeys.SET_PASSWORD_EMAIL_ID]: "/set-password/:emailId",
  [routeKeys.PRODUCT_VERSION]: "/product-version",
  [routeKeys.ROLES]: "/roles",
  [routeKeys.ADD_ROLE]: "/roles/add-role",
  [routeKeys.EDIT_ROLE]: "/roles/edit-role",
  [routeKeys.EDIT_ROLE_ID]: (id = ":id") => `/roles/edit-role/${id}`,
  [routeKeys.QUESTIONS_REFERENCE]: (id = ":id") => `/questions-reference/${id}`,
  [routeKeys.ONBOARDING_TEMPLATES]: "/onboarding/templates",
  [routeKeys.ANALYTICS_USER_VIEW_ID]: (id = ":id") =>
    `/analytics-user-view/${id}`,
  [routeKeys.GREENLITE_THIRD_PARTIES]: "/greenlite-third-parties",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS]: "/ultimate-beneficial-owners",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE]: (
    thirdPartyId = ":thirdPartyId",
    memberId = ":memberId"
  ) => `/ultimate-beneficial-owners/${thirdPartyId}/${memberId}`,
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS]: (
    thirdPartyId = ":thirdPartyId",
    memberId = ":memberId"
  ) => `/ultimate-beneficial-owners/${thirdPartyId}/${memberId}/details`,
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE]: (
    thirdPartyId = ":thirdPartyId",
    memberId = ":memberId"
  ) => `/ultimate-beneficial-owners/${thirdPartyId}/${memberId}/due-diligence`,
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS]: (
    thirdPartyId = ":thirdPartyId",
    memberId = ":memberId"
  ) =>
    `/ultimate-beneficial-owners/${thirdPartyId}/${memberId}/due-diligence/reports`,
  [routeKeys.ANALYTICS_QUESTIONNAIRES_ID]: (id = ":id") =>
    `/analytics-questionnaires/${id}`,
  [routeKeys.EDD_REPORT_TYPE]: "/edd-report-types",
  [routeKeys.GENERAL_SETTINGS]: "/general-settings",
  [routeKeys.NOTIFICATION_SETTINGS]: "/notification-settings",
  [routeKeys.BRANDING_AND_LINKS_SETTINGS]: "/branding-and-links-settings",
  [routeKeys.LIST_OF_SAMPLE_EXCEL_FILES]: "/list-of-sample-excel-files",
  [routeKeys.PAGE_NOT_FOUND]: "/page-not-found",
  [routeKeys.DEFAULT]: "/:pathMatch(.*)*",
  [routeKeys.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]: (id = ":id") =>
    `/analytics-questionnaires-comparative/${id}`,
  [routeKeys.USER_FORM_PAGE]: (taskId = ":taskId", userId = ":userId") =>
    `/user-form-page/${taskId}/${userId}`,
  [routeKeys.ONBOARDING_CLIENTS]: "/onboarding/clients-configuration",
  [routeKeys.ONBOARDING_CLIENTS_CONFIGURATION_PROFILE]: (id = ":id") =>
    `/client-configuration-profile/${id}`,
  [routeKeys.ONBOARDING_FORM_CONFIGURATION]: "/form/configuration",
  [routeKeys.ONBOARDING_FORM_INTRO]: "/form/intro",
  [routeKeys.ONBOARDING_FORM_DETAILS]: "/form/details",
  [routeKeys.ONBOARDING_FORM_BUILDER]: "/form/builder",
  [routeKeys.ONBOARDING_FORM_JSON]: "/form/json",
  [routeKeys.ONBOARDING_CLIENTS_PLATFORM_SETTINGS]:
    "/clients-configuration/platform-settings",
  [routeKeys.ONBOARDING_CLIENTS_SETTINGS]: "/clients-configuration/settings",
  [routeKeys.ONBOARDING_CLIENTS_EMAIL_TEMPLATES]:
    "/clients-configuration/email-templates",
  [routeKeys.ONBOARDING_CLIENTS_ACTIIONS]:
    "/clients-configuration/dynamic-actions",
  [routeKeys.ONBOARDING_CLIENTS_BULK_ACTIONS]:
    "/clients-configuration/dynamic-bulk-order-actions",
  [routeKeys.ONBOARDING_CLIENTS_COLUMNS]:
    "/clients-configuration/dynamic-columns",
  [routeKeys.ONBOARDING_CLIENTS_EDD_CONFIGURATION]:
    "/clients-configuration/dynamic-edd-configuration",
  [routeKeys.ONBOARDING_CLIENTS_ACTION_DETAILS]:
    "/clients-configuration/action-details",
  [routeKeys.ONBOARDING_CLIENTS_ACTION_SETTINGS]:
    "/clients-configuration/action-settings",
  [routeKeys.ONBOARDING_CLIENTS_ANALYTICS]:
    "/clients-configuration/dynamic-analytics",
  [routeKeys.USER_EMAIL_TEMPLATES]: "/user-email-templates",
  [routeKeys.ADD_USER_EMAIL_TEMPLATE]:
    "/user-email-templates/add-user-email-template",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE]:
    "/user-email-templates/edit-user-email-template",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE_ID]: (id = ":id") =>
    `/user-email-templates/edit-user-email-template/${id}`,
  [routeKeys.THIRD_PARTY_ONBOARDING_QUESTIONNAIRES]: "/my-questionnaires",
  [routeKeys.USER_FORM]: (taskId = ":taskId") => `/user-form/${taskId}`,
  [routeKeys.INVALID_DOCUMENT_FORMAT]: "/invalid-document-format",
  [routeKeys.ACCESS_RESTRICTED]: "/access-restricted",
  [routeKeys.PERMISSIONS]: "/permissions",
  [routeKeys.PERMISSIONS_PLATFORM_FEATURES]: "/permissions/platform-features",
  [routeKeys.TASK_TEMPLATES]: "/task-templates",
  [routeKeys.ADD_TASK_TEMPLATE]: "/task-templates/add-task-template",
  [routeKeys.EDIT_TASK_TEMPLATE]: "/task-templates/edit-task-template",
  [routeKeys.EDIT_TASK_TEMPLATE_ID]: (id = ":id") =>
    `/task-templates/edit-task-template/${id}`,
  [routeKeys.TASKS]: "/tasks",
  [routeKeys.TASK]: "/tasks/task",
  [routeKeys.TASK_ID]: (id = ":id") => `/tasks/task/${id}`,
  [routeKeys.TRAINING_DETAILS]: (id = ":id") => `/training/${id}`,
  [routeKeys.BULK_UPDATE]: "/bulk-update",
  [routeKeys.MANAGE_PRODUCT]: "manage-training-course"
};

const routeNames = {
  [routeKeys.LOGIN]: "Login",
  [routeKeys.THIRD_PARTY_PROFILE]: "ThirdPartyProfile",
  [routeKeys.THIRD_PARTY_PROFILE_COMPANY]: "ThirdPartyProfileCompany",
  [routeKeys.THIRD_PARTY_CREATE]: "ThirdPartyCreate",
  [routeKeys.THIRD_PARTY_UPDATE]: "ThirdPartyUpdate",
  [routeKeys.HOME]: "Home",
  [routeKeys.FORGOT_PASSWORD]: "ForgotPassword",
  [routeKeys.MANAGE_THIRD_PARTY]: "ManageThirdParty",
  [routeKeys.MANAGE_THIRD_PARTY_ID]: "ManageThirdPartyId",
  [routeKeys.PAGE_NOT_FOUND]: "PageNotFound",
  [routeKeys.ESG_THIRD_PARTIES]: "EsgThirdParties",
  [routeKeys.EVENT_LOG]: "EventLog",
  [routeKeys.EVENT_LOG_ID]: "EventLogId",
  [routeKeys.USER_SETTINGS]: "UserSettings",
  [routeKeys.NOTIFICATION_SETTINGS]: "NotificationSettings",
  [routeKeys.BRANDING_AND_LINKS_SETTINGS]: "BrandingAndLinksSettings",
  [routeKeys.GENERAL_SETTINGS]: "GeneralSettings",
  [routeKeys.CUSTOM_ATTRIBUTES]: "CustomAttributes",
  [routeKeys.EMAIL_TEMPLATE]: "EmailTemplate",
  [routeKeys.PLATFORM_SWITCHER]: "PlatformSwitcher",
  [routeKeys.DDQ_ONBOARDING]: "DDQOnboarding",
  [routeKeys.ACTIONS_WRAPPER]: "ActionsWrapper",
  [routeKeys.ACTIONS]: "Actions",
  [routeKeys.ADD_ACTION]: "AddAction",
  [routeKeys.EDIT_ACTION]: "EditAction",
  [routeKeys.EDIT_ACTION_ID]: "EditActionId",
  [routeKeys.AUTOMATIONS]: "Automations",
  [routeKeys.AUTOMATIONS_WRAPPER]: "AutomationsWrapper",
  [routeKeys.ADD_AUTOMATION]: "AddAutomation",
  [routeKeys.EDIT_AUTOMATION]: "EditAutomation",
  [routeKeys.EDIT_AUTOMATION_ID]: "EditAutomationId",
  [routeKeys.SET_PASSWORD]: "SetPassword",
  [routeKeys.SET_PASSWORD_EMAIL_ID]: "SetPasswordEmailId",
  [routeKeys.PRODUCT_VERSION]: "ProductVersion",
  [routeKeys.ROLES_WRAPPER]: "RolesWrapper",
  [routeKeys.ROLES]: "Roles",
  [routeKeys.ADD_ROLE]: "AddRoles",
  [routeKeys.EDIT_ROLE]: "EditRoles",
  [routeKeys.EDIT_ROLE_ID]: "EditRolesId",
  [routeKeys.ANALYTICS_OVERVIEW]: "AnalyticsOverview",
  [routeKeys.QUESTIONS_REFERENCE]: "DdqQuestions",
  [routeKeys.ANALYTICS_USER_VIEW_ID]: "DashboardViewPage",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS]: "UltimateBeneficialOwnersTemplate",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE]:
    "UltimateBeneficialOwnersProfile",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS]:
    "UltimateBeneficialOwnersProfileDetails",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE]:
    "UltimateBeneficialOwnersProfileDueDiligence",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS]:
    "UltimateBeneficialOwnersProfileDueDiligenceReports",
  [routeKeys.ANALYTICS_QUESTIONNAIRES_ID]: "AnalyticsQuestionaresId",
  [routeKeys.EDD_REPORT_TYPE]: "EddReportType",
  [routeKeys.NOTIFICATION_SETTINGS]: "NotificationSettings",
  [routeKeys.LIST_OF_SAMPLE_EXCEL_FILES]: "ListOfSampleExcelFiles",
  [routeKeys.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]:
    "AnalyticsQuestionnairesComparative",
  [routeKeys.GREENLITE_THIRD_PARTIES]: "GreenliteThirdParties",
  [routeKeys.USER_FORM]: "userForm",
  [routeKeys.USER_FORM_PAGE]: "userFormPage",
  [routeKeys.ONBOARDING_TEMPLATES]: "templates",
  [routeKeys.ONBOARDING_FORM]: "form",
  [routeKeys.ONBOARDING_FORM_CONFIGURATION]: "config",
  [routeKeys.ONBOARDING_FORM_INTRO]: "intro",
  [routeKeys.ONBOARDING_FORM_DETAILS]: "details",
  [routeKeys.ONBOARDING_FORM_BUILDER]: "builder",
  [routeKeys.ONBOARDING_FORM_JSON]: "json",
  [routeKeys.ONBOARDING_CLIENTS]: "ClientsConfigurationPage",
  [routeKeys.ONBOARDING_CLIENTS_CONFIGURATION_PROFILE]:
    "ClientsConfigurationProfile",
  [routeKeys.ONBOARDING_CLIENTS_PLATFORM_SETTINGS]: "PlatformSettings",
  [routeKeys.ONBOARDING_CLIENTS_SETTINGS]: "Settings",
  [routeKeys.ONBOARDING_CLIENTS_EMAIL_TEMPLATES]: "EmailTemplates",
  [routeKeys.ONBOARDING_CLIENTS_ACTIIONS]: "DynamicActions",
  [routeKeys.ONBOARDING_CLIENTS_BULK_ACTIONS]: "DynamicBulkOrderActions",
  [routeKeys.ONBOARDING_CLIENTS_COLUMNS]: "DynamicColumns",
  [routeKeys.ONBOARDING_CLIENTS_EDD_CONFIGURATION]: "DynamicEddConfiguration",
  [routeKeys.ONBOARDING_CLIENTS_ACTION_DETAILS]: "ActionDetails",
  [routeKeys.ONBOARDING_CLIENTS_ACTION_SETTINGS]: "ActionSettings",
  [routeKeys.ONBOARDING_CLIENTS_ANALYTICS]: "DynamicAnalytics",
  [routeKeys.ANALYTICS]: "Analytics",
  [routeKeys.USER_VIEWS]: "UserViews",
  [routeKeys.PLATFORM_SETTINGS]: "PlatformSettings",
  [routeKeys.ONBOARDING]: "onboarding",
  [routeKeys.DASHBOARD_THIRD_PARTY]: "dashboard-third-party",
  [routeKeys.CREATE_PROFILE]: "createProfile",
  [routeKeys.UPDATE_PROFILE]: "updateProfile",
  [routeKeys.MY_TPP_QUESTIONNAIRES]: "mytppquestionnaires",
  [routeKeys.PREVIEW]: "preview",
  [routeKeys.USER_EMAIL_TEMPLATE_WRAPPER]: "UserEmailTemplateWrapper",
  [routeKeys.USER_EMAIL_TEMPLATES]: "UserEmailTemplates",
  [routeKeys.ADD_USER_EMAIL_TEMPLATE]: "AddUserEmailTemplate",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE]: "EditUserEmailTemplate",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE_ID]: "EditUserEmailTemplateId",
  [routeKeys.INVALID_DOCUMENT_FORMAT]: "InvalidDocumentFormat",
  [routeKeys.ACCESS_RESTRICTED]: "AccessRestricted",
  [routeKeys.PERMISSIONS]: "Permissions",
  [routeKeys.PERMISSIONS_PLATFORM_FEATURES]: "PermissionsPlatformFeatures",
  [routeKeys.TASK_TEMPLATE_WRAPPER]: "TaskTemplateWrapper",
  [routeKeys.TASK_TEMPLATES]: "TaskTemplates",
  [routeKeys.ADD_TASK_TEMPLATE]: "AddTaskTemplate",
  [routeKeys.EDIT_TASK_TEMPLATE]: "EditTaskTemplate",
  [routeKeys.EDIT_TASK_TEMPLATE_ID]: "EditTaskTemplateId",
  [routeKeys.BI_DASHBOARD]: "BIDashboard",
  [routeKeys.TASKS_WRAPPER]: "TasksWrapper",
  [routeKeys.TASKS]: "Tasks",
  [routeKeys.TASK]: "Task",
  [routeKeys.TASK_ID]: "TaskId",
  [routeKeys.TRAINING_TEMPLATE]: "TrainingTemplate",
  [routeKeys.TRAINING]: "Training",
  [routeKeys.TRAINING_DETAILS]: "TrainingDetails",
  [routeKeys.BULK_UPDATE]: "BulkUpdate",
  [routeKeys.MANAGE_PRODUCT]: "ManageProduct"
};

const breadcrumbRouteNames = {
  [routeKeys.LOGIN]: "Login",
  [routeKeys.THIRD_PARTY_PROFILE]: "Third party profile",
  [routeKeys.THIRD_PARTY_CREATE]: "Create third party",
  [routeKeys.THIRD_PARTY_UPDATE]: "Update third party",
  [routeKeys.HOME]: "Home",
  [routeKeys.FORGOT_PASSWORD]: "Forgot password",
  [routeKeys.MANAGE_THIRD_PARTY]: "Manage third party",
  [routeKeys.MANAGE_THIRD_PARTY_ID]: "Third party user view",
  [routeKeys.PAGE_NOT_FOUND]: "Page not found",
  [routeKeys.ESG_THIRD_PARTIES]: "Esg explore third parties",
  [routeKeys.EVENT_LOG]: "Event log",
  [routeKeys.EVENT_LOG_ID]: "Event log user view",
  [routeKeys.USER_SETTINGS]: "Users settings",
  [routeKeys.NOTIFICATION_SETTINGS]: "Notification settings",
  [routeKeys.BRANDING_AND_LINKS_SETTINGS]: "Branding and links settings",
  [routeKeys.GENERAL_SETTINGS]: "General settings",
  [routeKeys.CUSTOM_ATTRIBUTES]: "Custom attributes",
  [routeKeys.EMAIL_TEMPLATE]: "Email template",
  [routeKeys.ACTIONS]: "Actions",
  [routeKeys.ADD_ACTION]: "Add action",
  [routeKeys.EDIT_ACTION]: "Edit action",
  [routeKeys.EDIT_ACTION_ID]: "Edit action id",
  [routeKeys.PLATFORM_SWITCHER]: "Platform switcher",
  [routeKeys.AUTOMATIONS]: "Automations",
  [routeKeys.ADD_AUTOMATION]: "Add automation",
  [routeKeys.EDIT_AUTOMATION]: "Edit automation",
  [routeKeys.EDIT_AUTOMATION_ID]: "Edit automation id",
  [routeKeys.PRODUCT_VERSION]: "Product version",
  [routeKeys.ROLES]: "Roles",
  [routeKeys.ADD_ROLE]: "Add role",
  [routeKeys.EDIT_ROLE]: "Edit role",
  [routeKeys.PRODUCT_VERSION]: "Product version",
  [routeKeys.QUESTIONS_REFERENCE]: "Questions reference",
  [routeKeys.ANALYTICS_USER_VIEW_ID]: "Analytics user view",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS]: "Ultimate beneficial owners",
  [routeKeys.DDQ_ONBOARDING]: "DDQ onboarding",
  [routeKeys.GREENLITE_THIRD_PARTIES]: "Greenlite third parties",
  [routeKeys.ANALYTICS_OVERVIEW]: "Analytics overview",
  [routeKeys.ANALYTICS_QUESTIONNAIRES_ID]: "Analytics questionares id",
  [routeKeys.EDD_REPORT_TYPE]: "Edd report type",
  [routeKeys.GENERAL_SETTINGS]: "General settings",
  [routeKeys.NOTIFICATION_SETTINGS]: "Notification settings",
  [routeKeys.BRANDING_AND_LINKS_SETTINGS]: "Branding and links settings",
  [routeKeys.CUSTOM_ATTRIBUTES]: "Custom attributes",
  [routeKeys.LIST_OF_SAMPLE_EXCEL_FILES]: "List of sample excel files",
  [routeKeys.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]:
    "Analytics questionnaires comparative",
  [routeKeys.USER_FORM_PAGE]: "User form page",
  [routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE]:
    "Ultimate beneficial owner profile",
  [routeKeys.USER_EMAIL_TEMPLATES]: "User Email Templates",
  [routeKeys.ADD_USER_EMAIL_TEMPLATE]: "Add user email template",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE]: "Edit user email template",
  [routeKeys.EDIT_USER_EMAIL_TEMPLATE_ID]: "Edit user email template id",
  [routeKeys.USER_FORM]: "User form",
  [routeKeys.PERMISSIONS]: "Permissions",
  [routeKeys.PERMISSIONS_PLATFORM_FEATURES]: "Platform features",
  [routeKeys.TASK_TEMPLATES]: "Task Templates",
  [routeKeys.ADD_TASK_TEMPLATE]: "Add task template",
  [routeKeys.EDIT_TASK_TEMPLATE]: "Edit task template",
  [routeKeys.EDIT_TASK_TEMPLATE_ID]: "Edit task template id",
  [routeKeys.BI_DASHBOARD]: "BI dashboard",
  [routeKeys.TASKS]: "Tasks",
  [routeKeys.TASK]: "Task",
  [routeKeys.TASK_ID]: "Task",
  [routeKeys.TRAINING]: "Training",
  [routeKeys.TRAINING_DETAILS]: "Training details",
  [routeKeys.BULK_UPDATE]: "Bulk update"
};

const thirdPartyTabHash = {
  ESG: "#esg",
  DUE_DILIGENCE: "#duediligence",
  QUESTIONNAIRE: "#questionnaire",
  DETAILS: "#details",
  UBO: "#ubo"
};

const externalUrls = {
  FAQS_ZENDESK: "https://ethixbase.zendesk.com/hc/en-us",
  EB2_PLATFORM_URL: "https://eb2.ethixbase.com/",
  GREENLITE: "https://ethixbase.com/greenlite-esg-supply-chain-risk-reporting"
};

export {
  breadcrumbRouteNames,
  thirdPartyTabHash,
  routeNames,
  externalUrls,
  urls,
  routeKeys
};
