<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading" rounded="sm">
          <b-card class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
            <b-navbar toggleable="lg">
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-navbar-brand href="#">
                <strong style="color: #3b3e66">Email Types</strong>
              </b-navbar-brand>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <!-- Add button -->
                  <b-button
                    @click="showAddEmailTypeModal = !showAddEmailTypeModal"
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>

                  <!-- Reload button -->
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="mr-2"
                    @click="reloadTable"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>

                  <!-- Search bar -->
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>

            <!-- Email types table start -->
            <b-table
              sticky-header="calc(100vh - 295px)"
              :busy.sync="isLoading"
              bordered
              ref="table"
              id="email-types-table"
              :fields="staticFields"
              :items="getEmailTypesData"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
            >
              <template v-slot:cell(created_at)="data">
                <p>{{ createdAtDate(data.item.created_at) }}</p>
              </template>
              <template v-slot:cell(is_enable_for_client)="data">
                <div>
                  <b-badge
                    class="mr-2"
                    :variant="
                      data.item.is_enable_for_client == 1 ? 'success' : 'danger'
                    "
                    >{{
                      data.item.is_enable_for_client == 1
                        ? "Active"
                        : "Deactive"
                    }}</b-badge
                  >
                  <b-btn
                    v-if="data.item.is_enable_for_client == 0"
                    @click="activeEmailType(data, 'client-enable')"
                    size="sm"
                    variant="outline-success"
                  >
                    Activate
                  </b-btn>
                  <b-btn
                    v-else
                    @click="deleteEmailType(data, 'client-enable')"
                    size="sm"
                    variant="outline-danger"
                  >
                    Deactivate
                  </b-btn>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="data.item.status == 1 ? 'success' : 'danger'"
                  >{{ data.item.status == 1 ? "Active" : "Deative" }}</b-badge
                >
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn
                  @click="editEmailType(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
                <b-btn
                  v-if="data.item.status == 1"
                  @click="deleteEmailType(data, 'action-type')"
                  class="mr-2"
                  size="sm"
                  variant="outline-danger"
                  >Deactivate</b-btn
                >
                <b-btn
                  v-if="data.item.status == 0"
                  @click="activeEmailType(data, 'action-type')"
                  class="mr-2"
                  size="sm"
                  variant="outline-success"
                  >Activate</b-btn
                >
              </template>
            </b-table>
            <!-- Email types table end -->
            <template v-slot:footer>
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="6">
                  <p class="text-center">
                    Showing Result From
                    <b>{{ fromRows }}</b> to
                    <b>{{ toRows }}</b>
                  </p>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="email-types-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modal for Add/Update Email type -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="isEditEmailType ? 'Update Email Type' : 'Add Email Type'"
      size="md"
      scrollable
      v-model="showAddEmailTypeModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col :cols="isEditEmailType ? '12' : '6'">
              <b-form-group>
                <label class="text-left">Title:</label>
                <span class="required-span">*</span>
                <b-form-input
                  v-model="v$.form.title.$model"
                  :state="v$.form.title.$dirty ? !v$.form.title.$error : null"
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="!isEditEmailType">
              <b-form-group style="margin-top: 30px">
                <Checkbox v-model="form.isActive" label="Is Active" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>
              {{
                isEditEmailType
                  ? "Your Email Type is being Updateing......"
                  : "Your Email Type is being Creating......"
              }}
            </p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isEditEmailType
                ? "Your Email Type has been updated successfully."
                : "Your Email Type has been added successfully."
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" @click="resetModal" variant="outline-danger"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          @click="checkValidation"
          variant="outline-success"
          >{{ isEditEmailType ? "Update" : "Save" }}</b-button
        >
      </template>
    </b-modal>

    <!-- overlay for deactivate email type -->
    <b-overlay :show="showDeleteEmailType" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Deactivating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetDeleteModal"
              >Cancel</b-button
            >
            <b-button variant="outline-danger" @click="deleteEmailTypeCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- overlay for activate email type -->
    <b-overlay :show="showActiveEmailType" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Activating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetActiveModal"
              >Cancel</b-button
            >
            <b-button variant="outline-danger" @click="activeEmailTypeCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import { Helper } from "@/helpers";
import { mapState } from "vuex";
import { nextTick } from "vue";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "EmailTypes",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Checkbox
  },
  data() {
    return {
      showActiveEmailType: false,
      isEditEmailType: false,
      showDeleteEmailType: false,
      submiting: false,
      isLoading: false,
      responseSuccess: false,
      showAddEmailTypeModal: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "created_at", label: "Created Date", sortable: true },
        { key: "title", label: "Title", sortable: true },
        { key: "status", label: "Status", sortable: true },
        {
          key: "is_enable_for_client",
          label: "Enable For Client",
          sortable: true
        },
        { key: "actions", label: "Action", sortable: false }
      ],
      form: {
        title: null,
        isActive: true
      },
      emailTypeId: null,
      processing: false,
      counter: 1,
      interval: null,
      clientEnable: false
    };
  },
  validations: {
    form: {
      title: { required }
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows;
    }
  },
  mounted() {},
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    onActivateSetting() {},
    onDectivateSetting() {},
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetModal();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    },
    createdAtDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "email-types-table");
    },
    async getEmailTypesData() {
      let formData = {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/types/get",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    resetForm() {
      this.form = {
        title: null,
        isActive: true
      };
      this.v$.$reset();
    },
    resetModal() {
      this.resetForm();
      this.emailTypeId = null;
      this.isEditEmailType = false;
      this.showAddEmailTypeModal = false;
      this.submiting = false;
      this.isLoading = false;
      this.responseSuccess = false;
    },
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else if (this.isEditEmailType) {
        this.updateEmailType().then(this.reloadTable);
      } else {
        this.saveEmailType().then(this.reloadTable);
      }
    },
    async saveEmailType() {
      let formData = {
        title: this.form.title,
        status: this.form.isActive ? 1 : 0,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/types/create",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", "Your Tag has been added successfully.");
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    async updateEmailType() {
      let formData = {
        title: this.form.title,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "put",
          `v1/types/update/${this.emailTypeId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", result.data.message);
        } else if (result && result.data && result.data.success == 0) {
          this.makeToster("danger", result.data.message);
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    editEmailType(data) {
      this.isEditEmailType = true;
      this.emailTypeId = data.item.id;
      this.form = {
        title: data.item.title,
        isActive: data.item.status == 1
      };
      this.showAddEmailTypeModal = true;
    },
    deleteEmailType(data, type) {
      if (type == "client-enable") {
        this.clientEnable = true;
      }
      this.emailTypeId = data.item.id;
      this.processing = false;
      this.showDeleteEmailType = true;
    },
    deleteEmailTypeCall() {
      this.counter = 1;
      this.processing = true;
      this.deleteEmailTypeApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 10) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showDeleteEmailType = this.processing = false;
          });
        }
      }, 200);
    },
    async deleteEmailTypeApiCall() {
      let formData;
      if (this.clientEnable) {
        formData = {
          is_enable_for_client: 0
        };
      } else {
        formData = {
          status: 1
        };
      }
      try {
        await HTTP(
          "put",
          `v1/types/update/${this.emailTypeId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.resetDeleteModal();
      } catch (err) {
        this.emailTypeId = null;
        return [err];
      }
    },
    resetDeleteModal() {
      this.emailTypeId = null;
      this.showDeleteEmailType = false;
      this.clientEnable = false;
    },
    activeEmailType(data, type) {
      if (type == "client-enable") {
        this.clientEnable = true;
      }
      this.emailTypeId = data.item.id;
      this.showActiveEmailType = true;
    },
    activeEmailTypeCall() {
      this.counter = 1;
      this.processing = true;
      this.activeEmailTypeApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 10) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showActiveEmailType = this.processing = false;
          });
        }
      }, 200);
    },
    async activeEmailTypeApiCall() {
      let formData;
      if (this.clientEnable) {
        formData = {
          is_enable_for_client: 1
        };
      } else {
        formData = {
          status: 1
        };
      }

      try {
        await HTTP(
          "put",
          `v1/types/update/${this.emailTypeId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.resetActiveModal();
      } catch (err) {
        this.emailTypeId = null;
        return [err];
      }
    },
    resetActiveModal() {
      this.emailTypeId = null;
      this.showActiveEmailType = false;
      this.clientEnable = false;
    }
  }
};
</script>
<style scoped>
.card-body {
  padding: 0px;
}
</style>
