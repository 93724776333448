<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Table" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-button class="mr-2 my-2" @click="onAddRowrModal" size="sm"
                  >Add Row</b-button
                >
                <b-button class="mr-2 my-2" @click="onAddHeaderModal" size="sm"
                  >Add Header</b-button
                >
                <b-table-simple bordered>
                  <b-thead>
                    <b-tr>
                      <b-th
                        v-for="(field, index) in fieldData.tableHeaders"
                        :key="index"
                        :id="field.id"
                      >
                        {{ field.heading[transalationLanguage] }}
                        <b-button
                          @click="onDeleteCol(index)"
                          variant="danger"
                          size="sm"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                        <b-button
                          @click="onEditCol(index, field)"
                          class="ml-1"
                          variant="danger"
                          size="sm"
                        >
                          <font-awesome-icon icon="pen" />
                        </b-button>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody
                    v-if="
                      fieldData.tableRowsItems.length &&
                      fieldData.tableHeaders.length
                    "
                  >
                    <b-tr
                      v-for="(itemRow, itemIndex) in fieldData.tableRowsItems"
                      :key="itemIndex"
                    >
                      <b-th>{{ itemRow.text[transalationLanguage] }}</b-th>
                      <b-td
                        v-for="(item, index) in fieldData.tableHeaders.length -
                        1"
                        :key="index"
                      >
                        <b-button
                          @click="addColModal(itemRow, itemIndex, index)"
                          >Add col</b-button
                        >
                      </b-td>
                      <b-td>
                        <b-button
                          @click="onDeleteRow(itemIndex)"
                          variant="danger"
                          size="sm"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="Configuration">
          <Checkbox
            v-model="fieldData.enableNumbers"
            label="Enable Serial Numbers"
          />
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal
      v-model="showAddColModal"
      @ok="
        isAddHeader
          ? addHeader()
          : isAddRow
          ? addRow()
          : isAddCol
          ? addCol()
          : null
      "
      title="Add New Column"
    >
      <b-form-group label="Select Type" v-if="isAddCol">
        <b-form-radio-group
          v-model="selectedColType"
          :options="optionsColsTypes"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="selectedColType == 'text'" label="Column Name">
        <b-form-input v-model="colHeading"></b-form-input>
      </b-form-group>

      <b-form v-if="selectedColType == 'radio'">
        <b-form-group label="Column Name">
          <b-form-input v-model="colHeading"></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import uid from "uid";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "AdvanceSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {
    Checkbox
  },
  data() {
    return {
      transalationLanguage: "english",
      text1: null,
      colHeading: null,
      items: [],
      showAddColModal: false,
      optionsLayout: [{ text: "Layout 1", value: "l1" }],
      fields: [],
      selectedColType: "text",
      optionsColsTypes: [
        { text: "Free Form Field", value: "text" },
        { text: "Radios", value: "radio" },
        { text: "Label", value: "label" },
        { text: "Date", value: "date" },
        { text: "Select", value: "select" }
      ],
      isEdit: false,
      isAddHeader: false,
      isAddRow: false,
      isAddCol: false,
      selectedRow: null,
      selectedRowIndex: null,
      selectedColIndex: null
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.onSaveAdvanceSettings();
    });
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  methods: {
    addRow() {
      let row = {
        id: uid(),
        value: null,
        key: null,
        noOfCols: [this.fieldData.tableHeaders.length],
        cols: [],
        text: {
          [this.transalationLanguage]: this.colHeading
        }
      };
      this.fieldData.tableRowsItems.push(row);
      this.colHeading = null;
      this.isEdit = false;
      (this.isAddRow = false), (this.selectedColIndex = null);
    },

    onAddHeaderModal() {
      this.isAddHeader = true;
      this.isAddCol = false;
      this.isAddRow = false;
      this.showAddColModal = !this.showAddColModal;
    },

    onAddRowrModal() {
      this.isAddRow = true;
      this.isAddCol = false;
      this.isAddHeader = false;
      this.showAddColModal = !this.showAddColModal;
    },

    addColModal(e, index, colIndex) {
      this.isAddCol = true;
      this.isAddRow = false;
      this.isAddHeader = false;
      this.showAddColModal = !this.showAddColModal;
      (this.selectedRow = e),
        (this.selectedRowIndex = index),
        (this.selectedColIndex = colIndex);
    },

    addHeader() {
      // tableHeaders
      if (this.isEdit) {
        this.fieldData.tableHeaders[this.selectedColIndex].heading[
          this.transalationLanguage
        ] = this.colHeading;
        this.fieldData.tableHeaders[this.selectedColIndex].type =
          this.selectedColType;
      } else {
        this.fieldData.tableHeaders.push({
          id: uid(),
          heading: {
            [this.transalationLanguage]: this.colHeading
          },
          type: this.selectedColType
        });
      }

      this.colHeading = null;
      this.isEdit = false;
      (this.isAddHeader = false), (this.selectedColIndex = null);
    },

    addCol() {
      if (
        this.selectedRowIndex >= 0 &&
        this.selectedColIndex >= 0 &&
        this.selectedColType
      ) {
        this.fieldData.tableRowsItems[this.selectedRowIndex].cols[
          this.selectedColIndex
        ] = {
          text: {
            [this.transalationLanguage]: this.colHeading
          },
          id: uid(),
          value: this.selectedRow.value,
          type: this.selectedColType
        };
        this.isAddCol = false;
      }
      this.colHeading = null;
      this.isEdit = false;
      this.selectedColIndex = null;
    },

    onDeleteRow(index) {
      this.fieldData.tableRowsItems.splice(index, 1);
    },

    onEditCol(index, field) {
      (this.isEdit = true), (this.selectedColIndex = index);
      (this.colHeading = field.heading[this.transalationLanguage]),
        (this.showAddColModal = true);
      this.selectedColType = field.type;
    },

    onDeleteCol(index) {
      this.fieldData.tableHeaders.splice(index, 1);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.text1 = this.fieldData.text1[this.transalationLanguage];
    },

    setFieldOptionText(value, index) {
      this.text1 = value;
      this.fieldData.tableRowsItems[index].text[this.transalationLanguage] =
        value;
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.text1[tempKey] = this.text1;
    },

    onSaveAdvanceSettings() {
      // let fields = this.fields;
      // let items = this.items;
      // Object.assign(this.fieldData, { fields, items });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
