<template>
  <div class="point-value">
    <BaseText
      data-test-id="point-value__value"
      :size="typographySize.EXTRA_LARGE_TEXT_BOLD"
      :text="value"
    />
    <BaseText
      v-if="secondaryValue"
      data-test-id="point-value__secondary-value"
      has-default-spacing-removed
      :text="secondaryValue"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";

export default {
  name: "PointValue",
  components: {
    BaseText
  },
  compatConfig: {
    MODE: 3
  },
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    secondaryValue: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {
      typographySize
    };
  }
};
</script>

<style lang="scss" scoped>
.point-value {
  text-align: center;
}
</style>
