<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClick(data)"
              v-for="(data, index) in form.formTranslations"
              :key="index"
              :title="data"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group
                  id="input-group-2"
                  label="File Title"
                  label-for="input-2"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    id="input-2"
                    v-model="langugeLabel"
                    type="text"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="text-area"
                  label="File Description"
                  label-for="text-area"
                >
                  <b-form-textarea
                    @blur="addLanguageToField(data)"
                    id="text-area"
                    v-model="langugeFileDescription"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- advacneee-------------------tab -->
        <b-tab title="Advanced" v-if="fieldData.inputType === 'file'">
          <b-row>
            <b-col cols="10">
              <Checkbox
                class="text-left mb-3"
                v-model="uploadTitleTextIsDisabled"
                checked-value="1"
                unchecked-value="0"
                label="Disable upload title?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="uploadDescriptionTextIsDisabled"
                checked-value="1"
                unchecked-value="0"
                label="Disable upload description?"
              />
            </b-col>
          </b-row>
          <div class="text-left">
            <b-col cols="6">
              <b-form-group>
                <Checkbox
                  class="mt-2"
                  v-model="isMultipleUpload"
                  label="Allow Multiple Uploads"
                />
              </b-form-group>
            </b-col>
            <b-row v-if="isMultipleUpload" class="ml-1">
              <b-col cols="4">
                <b-form-group label="Maximum number of files">
                  <b-form-input
                    type="number"
                    v-model="maxUploads"
                    placeholder="Number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-col>
              <b-form-group label="Supported Formats">
                <CheckboxGroup v-model="fileFormatOptions" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Maximun file size ( in Mbs)">
                <b-form-input
                  type="number"
                  v-model="maxfileSize"
                  placeholder="2"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </div>
        </b-tab>

        <!-- advance  date tab -->
        <b-tab title="Advanced" v-if="fieldData.inputType === 'date'">
          <div @submit.stop.prevent class="text-left mt-4"></div>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireCheck"
                checked-value="1"
                unchecked-value="0"
                label="Is required?"
              />
            </b-col>
          </div>
        </b-tab>

        <!-- -------------------------------- -->
        <b-tab title="Conditional">
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab title="Simple">
              <b-col cols="12" class="mr-0">
                <Checkbox
                  v-model="isConditional"
                  name="conditional"
                  checked-value="1"
                  unchecked-value="0"
                  label="Is Conditional?"
                />
                <b-row v-if="isConditional == 1">
                  <b-col cols="1"> <br />1 </b-col>
                  <b-col cols="6">
                    <label class="text-left">When Field</label>
                    <v-select
                      v-model="conditionalField"
                      class="mb-2"
                      :options="conditionalSectionElementLables"
                      lablel="label"
                      @update:modelValue="getSelectedElement"
                    ></v-select>
                  </b-col>
                  <b-col cols="5">
                    <label class="text-left">Has value</label>
                    <v-select
                      class="mb-2"
                      label="text"
                      v-model="conditionalFieldValue"
                      :options="selectedElementOption"
                    ></v-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-col>
    <!-- End of column -->
  </b-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
import uid from "uid";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import CheckboxGroup from "@/molecules/CheckboxGroup/CheckboxGroup.vue";

export default {
  name: "AdvanceSettings",
  components: {
    Checkbox,
    CheckboxGroup
  },
  data() {
    return {
      uploadTitleTextIsDisabled: false,
      uploadDescriptionTextIsDisabled: false,
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      fieldCSS: null,
      fieldJS: null,
      isConditional: this.fieldData.isConditional,

      condition: {},
      transalationLanguage: "english",

      langugeInput: undefined,
      langugeLabel: undefined,
      langugeFileDescription: undefined,
      langugeValidateMessage: undefined,

      isRequireCheck:
        this.fieldData.validation && this.fieldData.validation.required,

      // for file formats
      isMultipleUpload:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleUpload) ||
        false,
      maxUploads:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxUploads) ||
        1,
      maxfileSize:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxfileSize) ||
        1,

      selectedFormats:
        (this.fieldData.configuration &&
          this.fieldData.configuration.selectedFormats) ||
        [],
      fileFormats: [
        { text: "PDF", value: "application/pdf" },
        { text: "MS Word", value: "application/msword" },
        { text: "Excel 2012", value: "application/vnd.ms-excel" },
        { text: "Power point", value: "application/vnd.ms-powerpoint" },
        { text: "Text", value: "text/plain" },
        { text: "Jpeg", value: "image/jpeg" },
        { text: "PNG", value: "image/png" }
      ]
    };
  },
  mounted() {
    this.uploadTitleTextIsDisabled = this.fieldData.uploadTitleTextIsDisabled;
    this.uploadDescriptionTextIsDisabled =
      this.fieldData.uploadDescriptionTextIsDisabled;

    (this.langugeInput = this.fieldData.label[this.transalationLanguage]),
      (this.langugeLabel = this.fieldData.label[this.transalationLanguage]),
      (this.langugeFileDescription =
        this.fieldData.fileDescription[this.transalationLanguage]),
      this.getCurrentSectionList();
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.setAdvanceSettingsForFile();
      this.setFieldConditions();
      this.setValidation();
    });
  },

  computed: {
    ...mapGetters({ form: "form1" }),
    fileFormatOptions: {
      get() {
        return this.fileFormats.map(({ text, value }) => ({
          label: text,
          modelValue: this.selectedFormats
            ?.map((format) => format?.value)
            .includes(value)
            ? value
            : "",
          uncheckedValue: "",
          checkedValue: value
        }));
      },
      set(formats) {
        const formTranslations = formats
          .filter((value) => value.modelValue)
          .map((value) => value.modelValue);

        this.selectedFormats = formTranslations;
      }
    }
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    tabClick(key) {
      this.transalationLanguage = key.toLowerCase();
      this.langugeInput = this.fieldData.label[this.transalationLanguage];
      this.langugeLabel = this.fieldData.label[this.transalationLanguage];
      this.langugeFileDescription =
        this.fieldData.fileDescription[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.langugeInput;
      this.fieldData.label[tempKey] = this.langugeLabel;
      this.fieldData.fileDescription[tempKey] = this.langugeFileDescription;
    },

    onCmReady() {},

    onCmFocus() {},

    onCmCodeChange(newCode) {
      this.fieldCSS = newCode;
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };
        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
      }
    },

    setValidation() {
      let validation = {
        required: this.isRequireCheck
      };
      Object.assign(this.fieldData, { validation });
    },

    setAdvanceSettingsForFile() {
      this.fieldData.configuration = {
        isMultipleUpload: this.isMultipleUpload,
        selectedFormats: this.selectedFormats,
        maxUploads: this.maxUploads,
        maxfileSize: this.maxfileSize
      };
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].uploadTitleTextIsDisabled = this.uploadTitleTextIsDisabled;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].uploadDescriptionTextIsDisabled = this.uploadDescriptionTextIsDisabled;
      // Object.assign(this.fieldData, { advanceOption });
      // this.TOGGLE_LOADER(250);
    }
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
