import {
  companyFeatures,
  companyFeatureValues,
  featurePermissionsKeys,
  userPermissionKeysDTO
} from "@/constants";
import { hasPermission } from "@/utils";

export const getters = {
  isContactNameAndEmailRequired: (state) =>
    !!state.companyFeatures.find(
      (companyFeature) =>
        companyFeature.name ===
          companyFeatures.MANDATORY_CONTACT_NAME_AND_EMAIL &&
        companyFeature.value === companyFeatureValues.ENABLED
    ),

  isOpenCorporateDataEnabled: (state) =>
    !!state.companyFeatures.find(
      (companyFeature) =>
        companyFeature.name === companyFeatures.CORPORATE_DATA &&
        companyFeature.value === companyFeatures.OPEN_CORPORATE
    ),

  isOrbiaOnboardingFormEnabled: (state) =>
    !!state.companyFeatures.find(
      (companyFeature) =>
        companyFeature.name === companyFeatures.ENABLE_ORBIA_ONBOARDING_FORM &&
        companyFeature.value === companyFeatureValues.ENABLED
    ),

  isDNBFeatureEnabled: (state) =>
    !!state.companyFeatures.find(
      (companyFeature) =>
        companyFeature.name === companyFeatures.CORPORATE_DATA &&
        companyFeature.value === companyFeatures.DNB
    ),

  hasUBOFeature: (state) =>
    !!state.companyFeatures.find(
      (companyFeature) =>
        companyFeature.name === companyFeatures.UBO &&
        companyFeature.value === companyFeatureValues.ENABLED
    ),
  hasPremiumCorporateDataProducts: (state) =>
    state.companyFeatures.some(
      (companyFeature) =>
        companyFeature.name ===
          companyFeatures.PREMIUM_CORPORATE_DATA_PRODUCTS &&
        companyFeature.value === companyFeatureValues.ENABLED
    ),
  getCompanyViewsWithPermission(state) {
    return (
      state.companyViews?.filter((view = {}) =>
        hasPermission({
          key: featurePermissionsKeys.VIEW_USER_VIEW,
          contextId: view?.id || "",
          contextKey: userPermissionKeysDTO.USER_VIEW_ID
        })
      ) || []
    );
  }
};
