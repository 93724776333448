<template>
  <div class="dropdown-menu-list" data-test-id="dropdown-menu-list">
    <div
      v-for="(option, index) in menuOptions"
      :key="index"
      class="dropdown-menu-list__section"
      data-test-id="dropdown-menu-list__section"
    >
      <DropdownMenuHeading
        v-if="option.headingOptions"
        v-bind="option.headingOptions"
        data-test-id="dropdown-menu-list__section-heading"
      />
      <ComponentList
        :style="
          option.contentMaxHeight && {
            maxHeight: `${option.contentMaxHeight}px`
          }
        "
        class="dropdown-menu-list__section-content"
        :listOptions="option.listOptions"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import ComponentList from "@/molecules/ComponentList/ComponentList";
import DropdownMenuHeading from "@/molecules/DropdownMenuHeading/DropdownMenuHeading";
import { dropdownEvents } from "@/constants";

export default {
  name: "DropdownMenuList",
  components: { ComponentList, DropdownMenuHeading },
  props: {
    menuOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick() {
      this.$emit(dropdownEvents.MENU_ITEM_CLICK);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-list {
  background-color: white;
  border-radius: $spacing20;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 1px solid $grey;
  box-shadow: $box-shadow-dropdown;

  &__section {
    &-content {
      overflow: auto;
    }
  }
}
</style>
