<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Question">
                  <b-form-textarea
                    @blur="addLanguageToField(language)"
                    id="textarea"
                    v-model="questionText"
                    placeholder="Enter your question here...."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <h3 class="text-center">Advanced Options</h3>
              <b-form-group label="Time Limit *">
                <b-input-group append="Mins" class="mb-1">
                  <b-input
                    type="number"
                    v-model="questionTimeLimit"
                    name="questionTimeLimit"
                    placeholder="3"
                  ></b-input>
                </b-input-group>
              </b-form-group>

              <b-form-group label="score *">
                <b-input
                  type="number"
                  v-model="questionScore"
                  name="questionScore"
                ></b-input>
              </b-form-group>

              <b-form-group>
                <Checkbox
                  :checked-value="1"
                  :unchecked-value="0"
                  v-model="isCompulsory"
                  label="Is Compulsory?"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Anwsers">
          <b-row>
            <b-col cols="10">
              <h3 class="text-center">Answers Options</h3>

              <b-form-radio-group id="radio-group-2">
                <b-form-radio
                  name="questionOrderType"
                  v-model="questionAnswerOrderType"
                  value="1"
                  >Show in current order</b-form-radio
                >
                <b-form-radio
                  name="questionOrderType"
                  v-model="questionAnswerOrderType"
                  value="2"
                  >Shuffle answers</b-form-radio
                >
              </b-form-radio-group>

              <b-form-group label="Answer Input Type">
                <b-form-radio-group
                  v-model="selectedQuestionAnswerInputType"
                  :options="optionsQuestionAnswerInputType"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group label="Answer *">
                <b-form-textarea
                  id="textarea"
                  v-model="questionAnswer"
                  placeholder="Enter a possible answer here"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-invalid-feedback
                  >This is a required field and must be at least 5
                  characters.</b-form-invalid-feedback
                >
              </b-form-group>
              <Checkbox
                v-model="questionAnswerIsCorrect"
                label="Is Correct Answer?"
              />
              <b-form-row class="float-right">
                <b-button variant="outline-warning" class="mr-2" @click="reset"
                  >reset</b-button
                >
                <b-button
                  variant="outline-primary"
                  class="mr-2"
                  @click="addItemMultiChoice"
                  >{{ isEditingAnswer ? "Edit" : "Add" }}</b-button
                >
              </b-form-row>
              <div class="clearfix"></div>

              <br />
              <b-table-simple>
                <thead>
                  <tr>
                    <th>Answer</th>
                    <th>Is Correct</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tr v-for="(item, index) in answers" :key="index">
                  <td>{{ item.answer }}</td>
                  <td>{{ item.isCorrectAnswer }}</td>
                  <td>
                    <font-awesome-icon
                      v-b-tooltip.hover
                      title="Delete Answer"
                      icon="trash"
                      :class="'ml-2 text-danger'"
                      @click.native="removeRowMultiChoice(index)"
                    />
                    <font-awesome-icon
                      v-b-tooltip.hover
                      title="Edit Answer"
                      class="ml-2 text-primary"
                      icon="edit"
                      @click.native="editAnswer(item, index)"
                    />
                  </td>
                </tr>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Helper } from "@/helpers";
import { EventBus } from "@/event-bus.js";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "ExamQuestionSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {
    Checkbox
  },
  data() {
    return {
      isLoading: false,
      transalationLanguage: "english",
      label: null,
      questionText: null,
      isCompulsory: 0,
      questionAnswer: null,
      questionAnswerIsCorrect: false,
      questionAnswerOrderType: 1,
      selectedQuestionAnswerInputType: 1,
      selectedQuestionType: 1,
      optionsQuestionType: [
        {
          text: "Multiple Choice",
          value: 1
        },
        {
          text: "Fill in the gaps",
          value: 2
        },
        {
          text: "Free Text",
          value: 3
        }
      ],
      questionTimeLimit: 0,
      questionScore: 1,
      selectedQuestionLevel: 1,
      optionsQuestionLevel: [
        {
          value: 1,
          text: "Basic"
        },
        {
          value: 2,
          text: "Intermediate"
        },
        {
          value: 3,
          text: "Advanced"
        },
        {
          value: 4,
          text: "Professional"
        },
        {
          value: 5,
          text: "Expert"
        }
      ],
      optionsQuestionAnswerInputType: [
        {
          value: 1,
          text: "Radio"
        },
        {
          value: 2,
          text: "Checkbox"
        },
        {
          value: 3,
          text: "Button"
        }
      ],
      answers: [],
      isEditingAnswer: false,
      selectedAnswerIndex: null
    };
  },

  mounted() {
    this.questionText = this.fieldData.questionText[this.transalationLanguage];

    this.isCompulsory = this.fieldData.isCompulsory;
    this.questionTimeLimit = this.fieldData.questionTimeLimit;
    this.questionLevel = this.fieldData.questionLevel;
    this.questionScore = this.fieldData.questionScore;
    this.selectedQuestionType = this.fieldData.questionType;
    this.questionAnswerOrderType = this.fieldData.questionAnswerOrderType;
    this.answers = this.fieldData.answers;
    this.selectedQuestionAnswerInputType =
      this.fieldData.questionAnswerInputType;
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    });
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    tabClick(language) {
      this.transalationLanguage = language.toLowerCase();
    },

    updateAdvancedSettings() {
      this.fieldData.isCompulsory = this.isCompulsory;
      this.fieldData.questionTimeLimit = this.questionTimeLimit;
      this.fieldData.questionLevel = this.questionLevel;
      this.fieldData.questionScore = this.questionScore;
      this.fieldData.questionType = this.selectedQuestionType;
      this.fieldData.questionAnswerOrderType = this.questionAnswerOrderType;
      this.fieldData.answers = this.answers;
      this.fieldData.questionAnswerInputType =
        this.selectedQuestionAnswerInputType;
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.questionText[tempKey] = this.questionText;
    },

    addItemMultiChoice() {
      if (this.questionAnswer) {
        if (this.isEditingAnswer) {
          this.answers[this.selectedAnswerIndex].answer = this.questionAnswer;
          this.answers[this.selectedAnswerIndex].isCorrectAnswer = this
            .questionAnswerIsCorrect
            ? "Yes"
            : "No";
          this.isEditingAnswer = false;
        } else {
          let answer = {
            answer: this.questionAnswer,
            isCorrectAnswer: this.questionAnswerIsCorrect ? "Yes" : "No"
          };
          this.answers.push(answer);
        }
      }

      (this.questionAnswer = null), (this.questionAnswerIsCorrect = false);
    },

    removeRowMultiChoice(index) {
      this.answers.splice(index, 1);
    },

    editAnswer(item, index) {
      (this.isEditingAnswer = true),
        (this.questionAnswer = item.answer),
        (this.questionAnswerIsCorrect = item.isCorrectAnswer === "Yes"),
        (this.selectedAnswerIndex = index);
    },

    reset() {
      (this.questionAnswer = null), (this.questionAnswerIsCorrect = false);
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
