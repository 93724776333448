import { roles } from "./user.constant";
import { routeNames } from "./urls.constant";
import { featurePermissionsKeys } from "./keys-constant/keys.constant";

const routeAccessMapper = {
  // View Analytics sections start
  [routeNames.ANALYTICS]: featurePermissionsKeys.VIEW_ANALYTICS,
  [routeNames.ANALYTICS_OVERVIEW]: featurePermissionsKeys.VIEW_ANALYTICS,
  [routeNames.ANALYTICS_QUESTIONNAIRES_ID]:
    featurePermissionsKeys.VIEW_ANALYTICS,
  [routeNames.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]:
    featurePermissionsKeys.VIEW_ANALYTICS,
  // View analytics sections end
  [routeNames.MANAGE_THIRD_PARTY]:
    featurePermissionsKeys.VIEW_MANAGE_THIRD_PARTIES,
  [routeNames.ESG_THIRD_PARTIES]: featurePermissionsKeys.VIEW_ESG_THIRD_PARTIES,
  [routeNames.EVENT_LOG]: featurePermissionsKeys.VIEW_EVENT_LOG,
  [routeNames.THIRD_PARTY_CREATE]: featurePermissionsKeys.ADD_THIRD_PARTIES,
  [routeNames.THIRD_PARTY_UPDATE]: featurePermissionsKeys.EDIT_THIRD_PARTIES,
  [routeNames.BULK_UPDATE]: featurePermissionsKeys.BULK_UPDATE_THIRD_PARTIES
};

const routeAccessRules = {
  [routeNames.LOGIN]: [roles.BASIC, roles.ADMIN],
  [routeNames.FORGOT_PASSWORD]: [roles.BASIC, roles.ADMIN],
  [routeNames.HOME]: [roles.BASIC, roles.ADMIN],
  [routeNames.THIRD_PARTY_PROFILE]: [roles.BASIC, roles.ADMIN],
  [routeNames.THIRD_PARTY_PROFILE_COMPANY]: [roles.BASIC, roles.ADMIN],
  [routeNames.THIRD_PARTY_CREATE]: [roles.BASIC, roles.ADMIN],
  [routeNames.THIRD_PARTY_UPDATE]: [roles.BASIC, roles.ADMIN],
  [routeNames.MANAGE_THIRD_PARTY]: [roles.BASIC, roles.ADMIN],
  [routeNames.MANAGE_THIRD_PARTY_ID]: [roles.BASIC, roles.ADMIN],
  [routeNames.PAGE_NOT_FOUND]: [roles.BASIC, roles.ADMIN],
  [routeNames.ESG_THIRD_PARTIES]: [roles.BASIC, roles.ADMIN],
  [routeNames.EVENT_LOG]: [roles.BASIC, roles.ADMIN],
  [routeNames.EVENT_LOG_ID]: [roles.BASIC, roles.ADMIN],
  [routeNames.USER_SETTINGS]: [roles.ADMIN],
  [routeNames.NOTIFICATION_SETTINGS]: [roles.ADMIN],
  [routeNames.GENERAL_SETTINGS]: [roles.ADMIN],
  [routeNames.CUSTOM_ATTRIBUTES]: [roles.ADMIN],
  [routeNames.EMAIL_TEMPLATE]: [roles.ADMIN],
  [routeNames.DDQ_ONBOARDING]: [roles.BASIC, roles.ADMIN],
  [routeNames.SET_PASSWORD]: [roles.BASIC, roles.ADMIN],
  [routeNames.SET_PASSWORD_EMAIL_ID]: [roles.BASIC, roles.ADMIN],
  [routeNames.ROLES_WRAPPER]: [roles.BASIC],
  [routeNames.ROLES]: [roles.ADMIN],
  [routeNames.ADD_ROLE]: [roles.ADMIN],
  [routeNames.EDIT_ROLE]: [roles.ADMIN],
  [routeNames.EDIT_ROLE_ID]: [roles.ADMIN],
  [routeNames.ANALYTICS_OVERVIEW]: [roles.BASIC, roles.ADMIN],
  [routeNames.QUESTIONS_REFERENCE]: [roles.BASIC, roles.ADMIN],
  [routeNames.ANALYTICS_USER_VIEW_ID]: [roles.BASIC, roles.ADMIN],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS]: [roles.BASIC, roles.ADMIN],
  [routeNames.ANALYTICS_QUESTIONNAIRES_ID]: [roles.BASIC, roles.ADMIN],
  [routeNames.EDD_REPORT_TYPE]: [roles.BASIC, roles.ADMIN],
  [routeNames.NOTIFICATION_SETTINGS]: [roles.ADMIN],
  [routeNames.LIST_OF_SAMPLE_EXCEL_FILES]: [roles.BASIC, roles.ADMIN],
  [routeNames.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]: [roles.BASIC, roles.ADMIN],
  [routeNames.GREENLITE_THIRD_PARTIES]: [roles.BASIC, roles.ADMIN],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE]: [roles.BASIC, roles.ADMIN],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS]: [
    roles.BASIC,
    roles.ADMIN
  ],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE]: [
    roles.BASIC,
    roles.ADMIN
  ],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS]: [
    roles.BASIC,
    roles.ADMIN
  ],
  [routeNames.USER_FORM_PAGE]: [roles.ADMIN],
  [routeNames.ANALYTICS]: [roles.BASIC, roles.ADMIN],
  [routeNames.USER_VIEWS]: [roles.BASIC, roles.ADMIN],
  [routeNames.PLATFORM_SETTINGS]: [roles.ADMIN],
  [routeNames.USER_EMAIL_TEMPLATES]: [roles.ADMIN],
  [routeNames.ADD_USER_EMAIL_TEMPLATE]: [roles.ADMIN],
  [routeNames.EDIT_USER_EMAIL_TEMPLATE]: [roles.ADMIN],
  [routeNames.EDIT_USER_EMAIL_TEMPLATE_ID]: [roles.ADMIN],
  [routeNames.INVALID_DOCUMENT_FORMAT]: [roles.BASIC, roles.ADMIN],
  [routeNames.ACCESS_RESTRICTED]: [roles.BASIC, roles.ADMIN],
  [routeNames.PERMISSIONS]: [roles.ADMIN],
  [routeNames.PERMISSIONS_PLATFORM_FEATURES]: [roles.ADMIN],
  [routeNames.TASK_TEMPLATES]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ADD_TASK_TEMPLATE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_TASK_TEMPLATE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_TASK_TEMPLATE_ID]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.BI_DASHBOARD]: [roles.BASIC, roles.ADMIN],
  [routeNames.TASKS]: [roles.BASIC],
  [routeNames.TASK]: [roles.BASIC],
  [routeNames.TASK_ID]: [roles.BASIC]
};

export { routeAccessRules, routeAccessMapper };
