<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading" rounded="sm">
          <b-card class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
            <b-navbar toggleable="lg">
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-navbar-brand href="#">
                <strong style="color: #3b3e66">Email Languages</strong>
              </b-navbar-brand>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <!-- Add button -->
                  <b-button
                    @click="
                      showAddEmailLanguagesModal = !showAddEmailLanguagesModal
                    "
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>

                  <!-- Reload button -->
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="mr-2"
                    @click="reloadTable"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>

                  <!-- Search bar -->
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>

            <!-- Email languages table start -->
            <b-table
              sticky-header="calc(100vh - 295px)"
              :busy.sync="isLoading"
              bordered
              ref="table"
              id="email-languages-table"
              :fields="staticFields"
              :items="getEmailLanguagessData"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
            >
              <template v-slot:cell(created_at)="data">
                <p>{{ createdAtDate(data.item.created_at) }}</p>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="data.item.status == 1 ? 'success' : 'danger'"
                  >{{ data.item.status == 1 ? "Active" : "Deactive" }}</b-badge
                >
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn
                  @click="editEmailLanguages(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
                <b-btn
                  v-if="data.item.status == 1"
                  @click="deativeEmailLanguages(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-danger"
                  >Deactivate</b-btn
                >
                <b-btn
                  v-if="data.item.status == 0"
                  @click="activeEmailLanguages(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-success"
                  >Activate</b-btn
                >
              </template>
            </b-table>
            <!-- Email languages table end -->

            <template v-slot:footer>
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="6">
                  <p class="text-center">
                    Showing Result From
                    <b>{{ fromRows }}</b> to
                    <b>{{ toRows }}</b>
                  </p>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="email-languages-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modal for Add/Update Email Language -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="
        isEditEmailLanguages ? 'Update Email Language' : 'Add Email Language'
      "
      size="lg"
      scrollable
      v-model="showAddEmailLanguagesModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label class="text-left">Language Code:</label>
                <span class="required-span">*</span>
                <b-form-input
                  v-model="v$.form.languageCode.$model"
                  :state="
                    v$.form.languageCode.$dirty
                      ? !v$.form.languageCode.$error
                      : null
                  "
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="text-left">Language:</label>
                <span class="required-span">*</span>
                <b-form-input
                  v-model="v$.form.language.$model"
                  :state="
                    v$.form.language.$dirty ? !v$.form.language.$error : null
                  "
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="!isEditEmailLanguages">
              <b-form-group style="margin-top: 30px">
                <Checkbox v-model="form.isActive" label="Is Active" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>
              {{
                isEditEmailLanguages
                  ? "Your Email Language is being Updating......"
                  : "Your Email Language is being Creating......"
              }}
            </p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isEditEmailLanguages
                ? "Your Email Language has been updated successfully."
                : "Your Email Language has been added successfully."
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" @click="resetModal" variant="outline-danger"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          @click="checkValidation"
          variant="outline-success"
          >{{ isEditEmailLanguages ? "Update" : "Save" }}</b-button
        >
      </template>
    </b-modal>

    <!-- overlay for deactivate email language -->
    <b-overlay :show="showDeativeEmailLanguages" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Deactivating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetDeativeModal"
              >Cancel</b-button
            >
            <b-button
              variant="outline-danger"
              @click="deativeEmailLanguagesCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- overlay for activate email language -->
    <b-overlay :show="showActiveEmailLanguages" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Activating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetActiveModal"
              >Cancel</b-button
            >
            <b-button variant="outline-danger" @click="activeEmailLanguagesCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import { Helper } from "@/helpers";
import { mapState } from "vuex";
import { nextTick } from "vue";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "EmailLanguages",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Checkbox
  },
  data() {
    return {
      showActiveEmailLanguages: false,
      isEditEmailLanguages: false,
      showDeativeEmailLanguages: false,
      submiting: false,
      isLoading: false,
      responseSuccess: false,
      showAddEmailLanguagesModal: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "created_at", label: "Created Date", sortable: true },
        { key: "languages", label: "Language", sortable: true },
        { key: "language_code", label: "Language Code", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Action", sortable: false }
      ],
      form: {
        language: null,
        languageCode: null,
        isActive: true
      },
      emailLanguagesId: null,
      processing: false,
      counter: 1,
      interval: null
    };
  },
  validations: {
    form: {
      languageCode: { required },
      language: { required }
    }
  },
  computed: {
    rows() {
      return this.totalRows;
    },
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  mounted() {},
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetModal();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    },
    createdAtDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "email-languages-table");
    },
    async getEmailLanguagessData() {
      let formData = {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/languages/get",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    resetForm() {
      this.form = {
        language: null,
        languageCode: null,
        isActive: true
      };
      this.v$.$reset();
    },
    resetModal() {
      this.resetForm();
      this.emailLanguagesId = null;
      this.isEditEmailLanguages = false;
      this.showAddEmailLanguagesModal = false;
      this.submiting = false;
      this.isLoading = false;
      this.responseSuccess = false;
    },
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else if (this.isEditEmailLanguages) {
        this.updateEmailLanguages().then(this.reloadTable);
      } else {
        this.saveEmailLanguages().then(this.reloadTable);
      }
    },
    async saveEmailLanguages() {
      let formData = {
        languages: this.form.language,
        language_code: this.form.languageCode,
        status: this.form.isActive ? 1 : 0,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/languages/create",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", "Your Tag has been added successfully.");
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    async updateEmailLanguages() {
      let formData = {
        languages: this.form.language,
        language_code: this.form.languageCode,
        status: this.form.isActive ? 1 : 0,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "put",
          `v1/languages/update/${this.emailLanguagesId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", result.data.message);
        } else if (result && result.data && result.data.success == 0) {
          this.makeToster("danger", result.data.message);
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    editEmailLanguages(data) {
      this.isEditEmailLanguages = true;
      this.emailLanguagesId = data.item.id;
      this.form = {
        language: data.item.languages,
        languageCode: data.item.language_code,
        isActive: data.item.status == 1
      };
      this.showAddEmailLanguagesModal = true;
    },
    deativeEmailLanguages(data) {
      this.emailLanguagesId = data.item.id;
      this.processing = false;
      this.showDeativeEmailLanguages = true;
    },
    deativeEmailLanguagesCall() {
      this.counter = 1;
      this.processing = true;
      this.deativeEmailLanguagesApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showDeativeEmailLanguages = this.processing = false;
          });
        }
      }, 200);
    },
    async deativeEmailLanguagesApiCall() {
      let formData = {
        status: 0
      };
      try {
        await HTTP(
          "put",
          `v1/languages/update/${this.emailLanguagesId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.emailLanguagesId = null;
      } catch (err) {
        this.emailLanguagesId = null;
        return [err];
      }
    },
    resetDeativeModal() {
      this.emailLanguagesId = null;
      this.showDeativeEmailLanguages = false;
    },
    activeEmailLanguages(data) {
      this.emailLanguagesId = data.item.id;
      this.showActiveEmailLanguages = true;
    },
    activeEmailLanguagesCall() {
      this.counter = 1;
      this.processing = true;
      this.activeEmailLanguagesApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showActiveEmailLanguages = this.processing = false;
          });
        }
      }, 200);
    },
    async activeEmailLanguagesApiCall() {
      let formData = {
        status: 1
      };
      try {
        await HTTP(
          "put",
          `v1/languages/update/${this.emailLanguagesId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.emailLanguagesId = null;
      } catch (err) {
        this.emailLanguagesId = null;
        return [err];
      }
    },
    resetActiveModal() {
      this.emailLanguagesId = null;
      this.showActiveEmailLanguages = false;
    }
  }
};
</script>
<style scoped>
.card-body {
  padding: 0px;
}
</style>
