<template>
  <div
    v-bind="$attrs"
    class="search-select"
    data-test-id="search-select"
    v-click-outside="onHideDropDown"
  >
    <Label
      :id="id"
      :label="label"
      :is-required="isRequired"
      :is-label-hidden="isLabelHidden"
    >
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot" />
        </template>
      </template>
    </Label>
    <div
      :class="[
        'search-select__wrapper',
        isDropDownVisible && 'search-select__wrapper--active'
      ]"
      role="listbox"
    >
      <div class="search-select__input-wrapper">
        <input
          type="text"
          ref="select"
          data-test-id="search-select__input-field"
          class="search-select__input-field"
          :aria-label="label"
          :value="selectedValue"
          :placeholder="placeholder"
          @input="setSelectedValue($event.target.value)"
          @click="onToggleDropDown"
          @keyup.enter="onToggleDropDown"
        />
        <div
          @click="resetSearchSelect"
          data-test-id="search-select__clear-value"
          class="search-select__clear-value"
        >
          <BaseIcon
            v-if="selectedValue"
            icon="times"
            shape="circle"
            :size="10"
          />
        </div>
        <div
          class="search-select__toggle-options"
          data-test-id="search-select__toggle-options"
          aria-controls="menuList"
          :aria-expanded="isDropDownVisible"
          @click="onToggleDropDown"
        >
          <BaseIcon :icon="getIcon" :size="16" />
        </div>
        <MenuList
          id="menuList"
          data-test-id="search-select__drop-down"
          class="search-select__drop-down"
          :isMenuVisible="isDropDownVisible"
          :menuList="availableMenuOptions"
          @mousedown="onMenuOptionMouseDown"
        />
      </div>
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import Label from "@/atoms/Label/Label";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import MenuList from "@/atoms/MenuList/MenuList";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { icons } from "@/constants";
import vClickOutside from "v-click-outside";
import { getSelectedOption } from "@/utils";

export default {
  name: "SearchSelect",
  components: { Label, MenuList, BaseIcon, InputErrorMessage },
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: null
    },
    error: {
      default: "",
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    },
    searchValue: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      availableMenuOptions: [],
      selectedValue: "",
      isDropDownVisible: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    getIcon() {
      return this.isDropDownVisible ? icons.CARET_UP : icons.CARET_DOWN;
    }
  },
  watch: {
    options() {
      this.availableMenuOptions = this.options;
    },
    searchValue() {
      this.selectedValue = this.searchValue;
    }
  },
  created() {
    this.selectedValue =
      this.getSelectedOption(this.options)?.text || this.searchValue;
    this.setAvailableOptions(this.selectedValue);
  },
  methods: {
    getSelectedOption,
    onToggleDropDown() {
      this.isDropDownVisible = !this.isDropDownVisible;
      this.$refs.select.focus();
    },
    onHideDropDown() {
      this.isDropDownVisible = false;
    },
    resetSearchSelect() {
      this.selectedValue = "";
      this.setAvailableOptions();
      this.$emit("change", "");
    },
    setSelectedValue(text) {
      this.selectedValue = text;
      this.setAvailableOptions(text);
      this.emitData();
    },
    setAvailableOptions(searchText) {
      if (searchText) {
        this.availableMenuOptions = this.options.filter(({ text }) =>
          text?.toLowerCase?.()?.includes?.(searchText?.toLowerCase?.())
        );
      } else {
        this.availableMenuOptions = this.options;
      }
    },
    emitData() {
      const matchingOption = this.findAvailableOption(this.selectedValue);

      if (matchingOption) {
        this.$emit("change", matchingOption.value);
      } else if (this.selectedValue) {
        this.$emit("create", this.selectedValue);
      } else {
        this.$emit("reset", "");
      }
    },
    onMenuOptionMouseDown(value) {
      const option = this.findAvailableOption(value);
      this.setSelectedValue(option?.text);
      this.isUserOnDropDown = false;
      if (option?.text) {
        this.onHideDropDown();
      }
    },
    findAvailableOption(searchValue) {
      return this.availableMenuOptions.find(({ text, value }) => {
        const tempText = typeof text === "string" ? text.toLowerCase() : text;
        const tempSearchValue =
          typeof searchValue === "string"
            ? searchValue.toLowerCase()
            : searchValue;
        const tempValue =
          typeof value === "string" ? value.toLowerCase() : value;

        return tempText === tempSearchValue || tempValue === tempSearchValue;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-select {
  &__wrapper {
    position: relative;
    width: 100%;
    z-index: 0;

    &--active {
      border-color: $black;
      z-index: 1;
    }

    &:deep(.menu-list) {
      top: calc(100% + 5px);
      left: 0;
      width: 100%;
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: $element-border;
    border-radius: $border-radius8;
  }

  &__input-field {
    width: 100%;
    border: 0;
    padding: 6px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &:focus {
      outline: 0;
    }
  }

  &__clear-value {
    cursor: pointer;
  }

  &__toggle-options {
    padding-right: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
  }

  &:deep(.search-select__drop-down) {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__label--hidden {
    @include screen-reader-only;
  }
}
</style>
