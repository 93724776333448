<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Firstname label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="forenameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Lastname label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="lastnameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Contact label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="contactTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Email Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="emailTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Role/ Position Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="roleTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="LinkedIn Profile Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="linkedinTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Free Form Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for First Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="forenameHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text for Last Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="lastnameHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Contact</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="contactHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Email</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="emailHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Role/position</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="roleHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Linkedin</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="linkedinHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text for First Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="forenamePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Place Holder Text for Last Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="lastnamePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text for Contact</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="contactPlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder Text for Email</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="emailPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text for Role/position</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="rolePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder Text for Linkedin</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="linkedinPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for First Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="forenameValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message for Last Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="lastnameValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Contact</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="contactValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Email</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="emailValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Role/position</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="roleValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Linkedin</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="linkedinValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Firstname</label>
                <b-form-input v-model="forenamePostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Last Name</label>
                <b-form-input v-model="lastnamePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Contact</label>
                <b-form-input v-model="contactPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Email</label>
                <b-form-input v-model="emailPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Role/position</label>
                <b-form-input v-model="rolePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Linkedin</label>
                <b-form-input v-model="linkedinPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <Checkbox
                class="text-left mb-3"
                v-model="forenameDisabled"
                label="Disable First name?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="lastnameDisabled"
                label="Disable Last name?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="contactDisabled"
                label="Disable Contact?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="emailDisabled"
                label="Disable Email?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="roleDisabled"
                label="Disable Role/ Position?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="linkedinDisabled"
                label="Disable LinkedIn?"
              />
              <Checkbox
                class="text-left mb-3"
                v-model="freeFormDisabled"
                label="Disable Free Form?"
              />
              <Checkbox
                class="text-left mb-3"
                value="1"
                unchecked-value="0"
                v-model="fieldData.isNaFeature"
                label="Disable N/A Feature"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireForename"
                label="Is required First name?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireLastname"
                label="Is required Last name?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireContact"
                label="Is required Contact?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireEmail"
                label="Is required Email?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRole"
                label="Is required Role/ Position?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireLinkedin"
                label="Is required LinkedIn Profile?"
              />
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                label="Is required Free Form?"
              />
            </b-col>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "PrincipalContactSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: { Checkbox },

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      forenameTitle: null,
      lastnameTitle: null,
      contactTitle: null,
      emailTitle: null,
      roleTitle: null,
      linkedinTitle: null,
      freeFormTitle: null,

      // Helper Text
      forenameHelperText: null,
      lastnameHelperText: null,
      contactHelperText: null,
      emailHelperText: null,
      roleHelperText: null,
      linkedinHelperText: null,
      freeFormHelperText: null,

      // PlaceHolder Text
      forenamePlaceHolderText: null,
      lastnamePlaceHolderText: null,
      contactPlaceHolderText: null,
      emailPlaceHolderText: null,
      rolePlaceHolderText: null,
      linkedinPlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      forenameValidationMessage: null,
      lastnameValidationMessage: null,
      contactValidationMessage: null,
      emailValidationMessage: null,
      roleValidationMessage: null,
      linkedinValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      forenameDisabled: false,
      lastnameDisabled: false,
      contactDisabled: false,
      emailDisabled: false,
      roleDisabled: false,
      linkedinDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireForename: true,
      isRequireLastname: true,
      isRequireContact: true,
      isRequireEmail: true,
      isRequireRole: true,
      isRequireLinkedin: true,
      isRequireFreeForm: false,

      // attributes
      forenamePostName: null,
      lastnamePostName: null,
      contactPostName: null,
      emailPostName: null,
      rolePostName: null,
      linkedinPostName: null,
      freeFormPostName: null
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      // this.addLabelLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderTextLanguageToField(this.transalationLanguage);
      // this.addValidationMessageLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
    });
  },

  mounted() {
    this.setLabelFiledsText();
    this.setHelperFiledsText();
    this.setPlaceHolderFiledsText();
    this.setValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    setLabelFiledsText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.forenameTitle =
        this.fieldData.foreNameLabel[this.transalationLanguage];
      this.lastnameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];
      this.contactTitle =
        this.fieldData.contactNumberLabel[this.transalationLanguage];

      this.emailTitle =
        this.fieldData.emailAddressLabel[this.transalationLanguage];

      this.roleTitle =
        this.fieldData.rolePositionLabel[this.transalationLanguage];
      this.linkedinTitle =
        this.fieldData.linkedInProfileLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setHelperFiledsText() {
      // Helper text

      this.forenameHelperText =
        this.fieldData.foreNameHelperText[this.transalationLanguage];
      this.lastnameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];
      this.contactHelperText =
        this.fieldData.contactNumberHelperText[this.transalationLanguage];

      this.emailHelperText =
        this.fieldData.emailAddressHelperText[this.transalationLanguage];

      this.roleHelperText =
        this.fieldData.rolePositionHelperText[this.transalationLanguage];
      this.linkedinHelperText =
        this.fieldData.linkedInProfileHelperText[this.transalationLanguage];
      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setPlaceHolderFiledsText() {
      // PlaceHolder text
      this.forenamePlaceHolderText =
        this.fieldData.foreNamePlaceholderText[this.transalationLanguage];
      this.lastnamePlaceHolderText =
        this.fieldData.lastNamePlaceholderText[this.transalationLanguage];
      this.contactPlaceHolderText =
        this.fieldData.contactNumberPlaceholderText[this.transalationLanguage];

      this.emailPlaceHolderText =
        this.fieldData.emailAddressPlaceholderText[this.transalationLanguage];

      this.rolePlaceHolderText =
        this.fieldData.rolePositionPlaceholderText[this.transalationLanguage];
      this.linkedinPlaceHolderText =
        this.fieldData.linkedInProfilePlaceholderText[
          this.transalationLanguage
        ];
      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setValidationMessage() {
      // Validation Message

      this.forenameValidationMessage =
        this.fieldData.foreNameValidationMessage[this.transalationLanguage];
      this.lastnameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];
      this.contactValidationMessage =
        this.fieldData.contactNumberValidationMessage[
          this.transalationLanguage
        ];

      this.emailValidationMessage =
        this.fieldData.emailAddressValidationMessage[this.transalationLanguage];

      this.roleValidationMessage =
        this.fieldData.rolePositionValidationMessage[this.transalationLanguage];
      this.linkedinValidationMessage =
        this.fieldData.linkedInProfileValidationMessage[
          this.transalationLanguage
        ];
      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.forenamePostName =
        this.fieldData.attributes && this.fieldData.attributes.forenamePostName
          ? this.fieldData.attributes.forenamePostName
          : undefined),
        (this.lastnamePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.lastnamePostName
            ? this.fieldData.attributes.lastnamePostName
            : undefined),
        (this.contactPostName =
          this.fieldData.attributes && this.fieldData.attributes.contactPostName
            ? this.fieldData.attributes.contactPostName
            : undefined),
        (this.emailPostName =
          this.fieldData.attributes && this.fieldData.attributes.emailPostName
            ? this.fieldData.attributes.emailPostName
            : undefined),
        (this.rolePostName =
          this.fieldData.attributes && this.fieldData.attributes.rolePostName
            ? this.fieldData.attributes.rolePostName
            : undefined),
        (this.linkedinPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.linkedinPostName
            ? this.fieldData.attributes.linkedinPostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.forenameDisabled =
        this.fieldData.advance && this.fieldData.advance.forenameDisabled),
        (this.lastnameDisabled =
          this.fieldData.advance && this.fieldData.advance.lastnameDisabled),
        (this.contactDisabled =
          this.fieldData.advance && this.fieldData.advance.contactDisabled),
        (this.emailDisabled =
          this.fieldData.advance && this.fieldData.advance.emailDisabled),
        (this.roleDisabled =
          this.fieldData.advance && this.fieldData.advance.roleDisabled),
        (this.linkedinDisabled =
          this.fieldData.advance && this.fieldData.advance.linkedinDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireForename =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireForename),
        (this.isRequireLastname =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireLastname),
        (this.isRequireContact =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireContact),
        (this.isRequireEmail =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireEmail),
        (this.isRequireRole =
          this.fieldData.validation && this.fieldData.validation.isRequireRole),
        (this.isRequireLinkedin =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireLinkedin),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.forenameTitle =
        this.fieldData.foreNameLabel[this.transalationLanguage];
      this.lastnameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];
      this.contactTitle =
        this.fieldData.contactNumberLabel[this.transalationLanguage];
      this.emailTitle =
        this.fieldData.emailAddressLabel[this.transalationLanguage];
      this.roleTitle =
        this.fieldData.rolePositionLabel[this.transalationLanguage];
      this.linkedinTitle =
        this.fieldData.linkedInProfileLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];

      // this.addLabelLanguageToField(this.transalationLanguage);
    },

    tabHelperTextClick(language) {
      // Helper text

      this.transalationLanguage = language.toLowerCase();

      this.forenameHelperText =
        this.fieldData.foreNameHelperText[this.transalationLanguage];
      this.lastnameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];
      this.contactHelperText =
        this.fieldData.contactNumberHelperText[this.transalationLanguage];

      this.emailHelperText =
        this.fieldData.emailAddressHelperText[this.transalationLanguage];

      this.roleHelperText =
        this.fieldData.rolePositionHelperText[this.transalationLanguage];
      this.linkedinHelperText =
        this.fieldData.linkedInProfileHelperText[this.transalationLanguage];
      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderClick(language) {
      // PlaceHolder text

      this.transalationLanguage = language.toLowerCase();

      this.forenamePlaceHolderText =
        this.fieldData.foreNamePlaceholderText[this.transalationLanguage];
      this.lastnamePlaceHolderText =
        this.fieldData.lastNamePlaceholderText[this.transalationLanguage];
      this.contactPlaceHolderText =
        this.fieldData.contactNumberPlaceholderText[this.transalationLanguage];

      this.emailPlaceHolderText =
        this.fieldData.emailAddressPlaceholderText[this.transalationLanguage];

      this.rolePlaceHolderText =
        this.fieldData.rolePositionPlaceholderText[this.transalationLanguage];
      this.linkedinPlaceHolderText =
        this.fieldData.linkedInProfilePlaceholderText[
          this.transalationLanguage
        ];
      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationClick(language) {
      // Validation Message

      this.transalationLanguage = language.toLowerCase();

      this.forenameValidationMessage =
        this.fieldData.foreNameValidationMessage[this.transalationLanguage];
      this.lastnameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];
      this.contactValidationMessage =
        this.fieldData.contactNumberValidationMessage[
          this.transalationLanguage
        ];

      this.emailValidationMessage =
        this.fieldData.emailAddressValidationMessage[this.transalationLanguage];

      this.roleValidationMessage =
        this.fieldData.rolePositionValidationMessage[this.transalationLanguage];
      this.linkedinValidationMessage =
        this.fieldData.linkedInProfileValidationMessage[
          this.transalationLanguage
        ];
      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // let tempKey = language;
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.foreNameLabel[tempKey] = this.forenameTitle;
      this.fieldData.lastNameLabel[tempKey] = this.lastnameTitle;
      this.fieldData.contactNumberLabel[tempKey] = this.contactTitle;
      this.fieldData.emailAddressLabel[tempKey] = this.emailTitle;
      this.fieldData.rolePositionLabel[tempKey] = this.roleTitle;
      this.fieldData.linkedInProfileLabel[tempKey] = this.linkedinTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Helper Text

      this.fieldData.foreNameHelperText[tempKey] = this.forenameHelperText;
      this.fieldData.lastNameHelperText[tempKey] = this.lastnameHelperText;
      this.fieldData.contactNumberHelperText[tempKey] = this.contactHelperText;
      this.fieldData.emailAddressHelperText[tempKey] = this.emailHelperText;
      this.fieldData.rolePositionHelperText[tempKey] = this.roleHelperText;
      this.fieldData.linkedInProfileHelperText[tempKey] =
        this.linkedinHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderTextLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // PlaceHolder Text

      this.fieldData.foreNamePlaceholderText[tempKey] =
        this.forenamePlaceHolderText;
      this.fieldData.lastNamePlaceholderText[tempKey] =
        this.lastnamePlaceHolderText;
      this.fieldData.contactNumberPlaceholderText[tempKey] =
        this.contactPlaceHolderText;
      this.fieldData.emailAddressPlaceholderText[tempKey] =
        this.emailPlaceHolderText;
      this.fieldData.rolePositionPlaceholderText[tempKey] =
        this.rolePlaceHolderText;
      this.fieldData.linkedInProfilePlaceholderText[tempKey] =
        this.linkedinPlaceHolderText;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationMessageLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Validation Message

      this.fieldData.foreNameValidationMessage[tempKey] =
        this.forenameValidationMessage;
      this.fieldData.lastNameValidationMessage[tempKey] =
        this.lastnameValidationMessage;
      this.fieldData.contactNumberValidationMessage[tempKey] =
        this.contactValidationMessage;
      this.fieldData.emailAddressValidationMessage[tempKey] =
        this.emailValidationMessage;
      this.fieldData.rolePositionValidationMessage[tempKey] =
        this.roleValidationMessage;
      this.fieldData.linkedInProfileValidationMessage[tempKey] =
        this.linkedinValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireForename: this.forenameDisabled
          ? false
          : this.isRequireForename,
        isRequireLastname: this.lastnameDisabled
          ? false
          : this.isRequireLastname,
        isRequireContact: this.contactDisabled ? false : this.isRequireContact,
        isRequireEmail: this.emailDisabled ? false : this.isRequireEmail,
        isRequireRole: this.roleDisabled ? false : this.isRequireRole,
        isRequireLinkedin: this.linkedinDisabled
          ? false
          : this.isRequireLinkedin,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        forenameDisabled: this.forenameDisabled,
        lastnameDisabled: this.lastnameDisabled,
        contactDisabled: this.contactDisabled,
        emailDisabled: this.emailDisabled,
        roleDisabled: this.roleDisabled,
        linkedinDisabled: this.linkedinDisabled,
        freeFormDisabled: this.freeFormDisabled
      };

      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        forenamePostName: this.forenamePostName,
        lastnamePostName: this.lastnamePostName,
        contactPostName: this.contactPostName,
        emailPostName: this.emailPostName,
        rolePostName: this.rolePostName,
        linkedinPostName: this.linkedinPostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
