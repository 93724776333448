import { state as makeState } from "./onboardingClient.state.store";

export const mutations = {
  setClient(state, payload) {
    state.client = payload;
  },
  resetClient(state) {
    Object.assign(state, makeState());
  }
};
