export const mutations = {
  setUserTrainingList(state, payload) {
    state.userTrainingList = payload;
  },
  resetUserTrainingList(state) {
    state.userTrainingList = [];
  },
  setIsLoadingUserTrainingList(state, value) {
    state.isLoadingUserTrainingList = value;
  }
};
