<template>
  <div>
    <div>
      <div class="drop-down">
        <!-- EDD Report Type dropdown -->
        <b class="m-2">EDD Report Type: </b>
        <b-dropdown size="md" :text="selectedEdd">
          <div
            class="dropdown-item bg-white text-body"
            v-for="(item, index) in listOfEDD"
            :key="index"
          >
            <div style="display: flex">
              <b-dropdown-item
                v-if="parseInt(item.client_packages_id) > 0"
                @click="setSelectedEdd(item)"
                >{{ item.name }}</b-dropdown-item
              >
            </div>
          </div>
        </b-dropdown>

        <!-- Region Type dropdown -->
        <b class="m-2">Region Type: </b>
        <b-dropdown size="md" :text="selectedRegion">
          <div
            class="dropdown-item bg-white text-body"
            v-for="(item, index) in listOfRegions"
            :key="index"
          >
            <div style="display: flex">
              <b-dropdown-item @click="setSelectedRegion(item)">{{
                item.name
              }}</b-dropdown-item>
            </div>
          </div>
        </b-dropdown>

        <!-- Submit button for saving configuartion -->
        <div class="ml-auto my-2">
          <b-btn variant="outline-success" @click="submitConfiguration">
            Submit
          </b-btn>
        </div>
      </div>

      <b-overlay :show="isSubmiting">
        <div class="d-flex" v-if="selectedEdd && selectedRegion">
          <!-- TAT input field -->
          <b-form-group class="mr-4 ml-5" label="Turn around time (Days) :">
            <b-form-input
              type="number"
              v-model="v$.form.tat.$model"
              :state="v$.form.tat.$dirty ? !v$.form.tat.$error : null"
              placeholder="Enter Turn around time (Days)"
              id="form-tat"
            ></b-form-input>
            <b-form-invalid-feedback id="form-tat"
              >A Required field has no value.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- TAT MAX input field -->
          <b-form-group class="mr-4" label="Turn around time MAX (Days) :">
            <b-form-input
              type="number"
              v-model="v$.form.tat_max.$model"
              :state="v$.form.tat_max.$dirty ? !v$.form.tat_max.$error : null"
              placeholder="Enter Tat max (Days)"
              id="form-tatMax"
            ></b-form-input>
            <b-form-invalid-feedback id="form-tatMax"
              >A Required field has no value.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Cost input field -->
          <b-form-group label="Cost (USD) :">
            <b-form-input
              type="number"
              v-model="v$.form.cost.$model"
              :state="v$.form.cost.$dirty ? !v$.form.cost.$error : null"
              placeholder="Enter Cost (USD)"
              id="form-cost"
            ></b-form-input>
            <b-form-invalid-feedback id="form-cost"
              >A Required field has no value.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { HTTP, makeAuthorizationHeader } from "@/utils";

export default {
  name: "DynamicEDDConfiguration",
  setup() {
    return {
      v$: useVuelidate()
    };
  },

  data() {
    return {
      isSubmiting: false,
      selectedEdd: "",
      selectedEddArray: [],
      selectedRegion: "",
      selectedRegionArray: [],
      sortedListOfEDD: [],
      form: {
        tat: null,
        tat_max: null,
        cost: null
      }
    };
  },
  validations: {
    form: {
      tat: {
        required
      },
      tat_max: {
        required
      },
      cost: {
        required
      }
    }
  },
  mounted() {
    this.listOfEDD.map((data) => {
      if (parseInt(data.client_packages_id) > 0) {
        this.sortedListOfEDD.push(data);
      }
    });
  },
  methods: {
    checkValue() {
      if (this.selectedEdd && this.selectedRegion) {
        this.getTatCost();
      }
    },
    setSelectedEdd(val) {
      this.selectedEdd = val.name;
      this.selectedEddArray = val;
      this.checkValue();
    },
    setSelectedRegion(val) {
      this.selectedRegion = val.name;
      this.selectedRegionArray = val;
      this.checkValue();
    },

    // get tat cost vaules
    async getTatCost() {
      this.isSubmiting = true;
      let formData = {
        region_id: this.selectedRegionArray.id,
        client_id: this.$route.params.id,
        package_id: this.selectedEddArray.id
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/regions/get/package/price",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          if (result.data.data) {
            this.form.tat = result.data.data.tat;
            this.form.tat_max = result.data.data.tat_max;
            this.form.cost = result.data.data.price;
          } else {
            this.form.tat = null;
            this.form.tat_max = null;
            this.form.cost = null;
          }
          this.isSubmiting = false;
        } else if (result.data.status == 0) {
          this.isSubmiting = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        return [error];
      }
    },

    // save configuration API
    async submitConfiguration() {
      this.v$?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        this.isSubmiting = true;
        let formData = {
          region_id: this.selectedRegionArray.id,
          client_id: this.$route.params.id,
          package_id: this.selectedEddArray.id,
          tat: this.form.tat,
          tat_max: this.form.tat_max,
          cost: this.form.cost
        };
        try {
          let result = await HTTP(
            "post",
            "thirdparty/edd/regions/package/price",
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200 && result.data.status == 1) {
            this.$bvToast.toast("Configuration done successfully", {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.isSubmiting = false;
          } else if (result.data.status == 0) {
            this.$bvToast.toast(result.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.isSubmiting = false;
          }
        } catch (error) {
          this.isSubmiting = false;
          return [error];
        }
      }
    }
  },
  props: {
    listOfEDD: Array,
    listOfRegions: Array
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
.drop-down {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
}
.dropdownFieldsContainer {
  display: flex;
}
</style>
