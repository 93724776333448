<template>
  <div class="dropdown-menu-heading" data-test-id="dropdown-menu-heading">
    <BaseText
      v-bind="getCombinedTextOptions(textOptions)"
      hasDefaultSpacingRemoved
      class="dropdown-menu-heading__text"
      data-test-id="dropdown-menu-heading__text"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";

export default {
  name: "DropdownMenuHeading",
  components: { BaseText },
  props: {
    textOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getCombinedTextOptions(options = {}) {
      return {
        tag: "h3",
        size: typographySize.BODY_TEXT_SMALL_BOLD,
        ...options
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-heading {
  padding: $spacing12 $spacing20;
  background-color: $grey-light;
}
</style>
