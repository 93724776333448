<template id="template-input">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group :label="fieldData.label[selectedLanguage]">
            <b-row
              class="mt-1 mb-1"
              v-for="(option, index) in fieldData.options"
              :key="index"
            >
              <b-col class="col-10">
                <label class="text-left">{{
                  option.text[selectedLanguage]
                }}</label>
              </b-col>
              <b-col class="col-2">
                <b-form-input
                  onkeydown="return event.keyCode !== 69"
                  class="text-right"
                  onpaste="return false;"
                  type="number"
                  @input="onUpdateValue($event, index)"
                  @focus="$event.target.select()"
                  :value="option.value || 0"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-10">
          <label class="text-left">Total Sum</label>
        </b-col>
        <b-col class="col-2">
          <b-form-input
            disabled
            class="text-right"
            type="number"
            :value="fieldData.totalSum"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="lg"
        no-close-on-backdrop
        @hidden="onUpdateSettings"
        v-model="showSettings"
        scrollable
        title="Input Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import AdvanceSettings from "../advanceSumSettingsComponent";
import MenuButtons from "../menuButtons";
import _ from "lodash";
import { mapGetters, mapMutations, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "AutoSumGroup",
  components: {
    AdvanceSettings,
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      label: undefined,
      showSettings: false,
      collapse: true
    };
  },
  mounted() {
    this.getSumOfAllValues();
  },

  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {},
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    getSumOfAllValues() {
      let options = this.fieldData.options;
      let sumOfAllValues = _.sumBy(options, (o) => parseInt(o.value) || 0);
      this.fieldData.totalSum = sumOfAllValues;
    },
    onUpdateSettings() {
      this.TOGGLE_LOADER(50);
    },
    onUpdateValue(value, index) {
      this.fieldData.options[index].value = value;
      this.getSumOfAllValues();
    },
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    }
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
      } else {
        if (this.v$ && !this.v$.$invalid) {
          // alert("submit success");
        } else {
          this.v$.form?.$touch();
        }
      }
    });
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style lang="scss">
.required-span {
  font-size: 24px;
  margin-left: 3px;
  color: $spanish-red;
}
</style>
