export const getters = {
  getCountryCodeBasedOnCountryName:
    (state) =>
    (countryName = "") => {
      const country = state.countries.find(
        ({ country }) =>
          country?.toLowerCase?.() === countryName?.toLowerCase?.()
      );
      return country?.code;
    },

  getStateNameBasedOnStateCode:
    (state, getters, rootState) =>
    (stateCode = "", countryCode = "") =>
      rootState?.thirdParty?.OCStatesList.find(
        (option) =>
          option.countryCode?.toLowerCase() === countryCode?.toLowerCase() &&
          option.stateCode?.toLowerCase() === stateCode?.toLowerCase()
      )?.state || ""
};
