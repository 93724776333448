import { userTrainingService } from "@/services";
import {
  actionName,
  mutationName,
  infoMessages,
  customErrorMessages
} from "@/constants";

export const actions = {
  async addNewTrainingLearner({ rootState, dispatch }, learner) {
    const { tenantId } = rootState.company;
    const { thirdPartyId, id: courseId } = learner;

    await userTrainingService.addNewTrainingLearner({ tenantId, learner });

    await dispatch(
      actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_DETAILS,
      { thirdPartyId, courseId },
      { root: true }
    );

    dispatch(actionName.USER_TRAINING.FETCH_USER_TRAINING_LIST, null, {
      root: true
    });
  },
  async fetchUserTrainingList({ commit, rootState }) {
    const { tenantId } = rootState.company;
    if (tenantId) {
      const params = {
        tenantId
      };
      try {
        commit(
          mutationName.USER_TRAINING.SET_IS_LOADING_USER_TRAINING_LIST,
          true,
          {
            root: true
          }
        );
        const { data = [] } = await userTrainingService.fetchUserTrainingList(
          params
        );

        commit(mutationName.USER_TRAINING.SET_USER_TRAINING_LIST, data, {
          root: true
        });

        if (!data?.length) {
          return infoMessages.thirdParty.noTrainingListFeedback;
        }
      } catch {
        const error = new Error(
          customErrorMessages.training.fetchTrainingError
        );
        throw error;
      } finally {
        commit(
          mutationName.USER_TRAINING.SET_IS_LOADING_USER_TRAINING_LIST,
          false,
          {
            root: true
          }
        );
      }
    } else {
      return infoMessages.thirdParty.noTrainingListFeedback;
    }
  },
  async removeTrainingLearner({ rootState, dispatch }, learner) {
    const { tenantId } = rootState.company;
    const { thirdPartyId, id: courseId, learnerIds } = learner;

    await userTrainingService.removeTrainingLearner(
      { tenantId, learnerIds },
      { root: true }
    );

    dispatch(
      actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_DETAILS,
      { thirdPartyId, courseId },
      { root: true }
    );
  }
};
