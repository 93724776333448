<template>
  <div>
    <div style="overflow: hidden auto; height: calc(100vh - 170px)">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <!-- Add button start -->
            <b-button
              variant="outline-info"
              size="sm"
              class="mr-2"
              @click="showAddModal"
            >
              Add
            </b-button>
            <!-- Add button end -->

            <!-- search bar start -->
            <b-form @submit.prevent>
              <b-input-group size="sm" class="mr-2">
                <b-form-input
                  type="search"
                  @input="onEmpltyFilter($event)"
                  v-model="filter"
                  @keyup.enter="submitSearch"
                  :placeholder="
                    $t('eb360.manageThirdParties.searchPlaceholder')
                  "
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    variant="outline-success"
                    @click="submitSearch"
                    >{{ $t("searchBtn") }}</b-button
                  >
                  <b-button :disabled="!filter" @click="setFIlterClear">{{
                    $t("clearBtnText")
                  }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
            <!-- search bar end -->

            <!-- Reload button start -->
            <b-button
              @click="reloadTable"
              variant="outline-info"
              size="sm"
              class="mr-2"
            >
              <font-awesome-icon icon="sync" />
            </b-button>
            <!-- Reload button end -->
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <div>
        <div>
          <div class="tableHeading">Notification Schemes</div>
          <b-overlay :show="isLoading" rounded="sm">
            <!-- Notification schema Table data start -->
            <div v-if="showTableData">
              <b-table
                bordered
                ref="table"
                id="notification-settings-table"
                :fields="staticFields"
                :items="getTableData"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :per-page="perPage"
                :current-page="currentPage"
                responsive="sm"
                small
                outlined
                style="margintop: 15px"
                :filter="filterTable ? filterInputValue : null"
                @scroll="handleScroll"
              >
                <template v-slot:cell(is_active)="data">
                  <span v-if="data.item.is_active == 1">Active</span>
                  <span v-else>Deactive</span>
                </template>
                <template v-slot:cell(users)="data">
                  <span>
                    <b-badge
                      class="mx-1"
                      v-for="field in data.item.notification_setting_users"
                      :key="field.key"
                      >{{ field.name }}</b-badge
                    >
                  </span>
                  <span v-if="hasAdditionalEmails">
                    <b-badge variant="warning">
                      Additional Emails: {{ renderCCs(data.item) }}</b-badge
                    >
                  </span>
                </template>
                <template v-slot:cell(type)="data">
                  <span>{{ data.item.event_type.text }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-dropdown
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    dropleft
                  >
                    <template #button-content>
                      <font-awesome-icon icon="ellipsis-h" />
                    </template>
                    <b-dropdown-item-button
                      @click="onShowEditModal(data.item)"
                      class="text-secondary"
                      >Edit</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      @click="onShowDeactivateModal(data.item)"
                      class="text-secondary"
                      ><span v-if="data.item.is_active == 1">Deactivate</span>
                      <span v-else>Activate</span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="onDuplicate(data.item)"
                      class="text-secondary"
                      >Duplicate</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      @click="onShowDeleteModal(data)"
                      class="text-secondary"
                      >Delete</b-dropdown-item-button
                    >
                  </b-dropdown>
                </template>
              </b-table>
              <b-overlay :show="tableLoaderTop" rounded="sm" />

              <b-overlay :show="tableLoader && !tableLoaderTop" rounded="sm" />
              <div class="text-center" v-if="totalRows == 0">
                <b> No data available </b>
              </div>
            </div>
            <!-- Notification schema Table data end -->
          </b-overlay>
        </div>
        <b-row>
          <b-col cols="3"> </b-col>
          <b-col cols="6" v-if="totalRows > 0">
            <p class="text-center">
              <b-button
                class="m-1"
                size="sm"
                @click="goToPreviousPage"
                :disabled="this.currentPage <= 1"
                variant="light"
              >
                <font-awesome-icon icon="chevron-left" />
              </b-button>
              {{ $t("eb360.manageThirdParties.resultRecords") }}
              <b>{{ fromRows }}</b>
              {{ $t("eb360.manageThirdParties.toRecords") }}
              <b>{{ toRows }}</b>
              {{ $t("eb360.manageThirdParties.ofRecords") }}
              <b>{{ totalRows }}</b>
              <b-button
                class="m-1 ml-2"
                size="sm"
                @click="goToNextPage"
                :disabled="this.currentPage >= this.last_page"
                variant="light"
              >
                <font-awesome-icon icon="chevron-right" />
              </b-button>
            </p>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Modal for Add/Edit/Duplicate notification schema -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="
        isEditModal
          ? 'Edit Notification Scheme Configuration'
          : isDuplicateModal
          ? 'Duplicate Notification Scheme Configuration'
          : 'Notification Scheme Configuration'
      "
      v-model="showModal"
    >
      <b-form-group label="Name">
        <b-form-input
          size="sm"
          v-model="v$.form.name.$model"
          :state="v$.form.name.$dirty ? !v$.form.name.$error : null"
        ></b-form-input>
        <p
          class="text-danger"
          style="font-size: 14px"
          v-if="isDuplicateModal && form.name == tempNotificationName"
        >
          Please enter a different name
        </p>
      </b-form-group>
      <b-form-group label="Notify Users">
        <!-- multi select -->
        <v-select
          multiple
          v-model="form.notifyUsers"
          :options="notifyUsersOptions"
          @update:modelValue="onSelectNotifyUser"
          label="name"
        />
      </b-form-group>
      <b-form-group label="Notify Additional Emails" :state="tagValidatorState">
        <b-form-tags
          input-id="tags-basic"
          tag-variant="warning"
          tag-pills
          size="sm"
          v-model="form.notifycc"
          :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
          :tag-validator="tagValidator"
          :state="tagValidatorState"
          invalidTagText="Please enter a valid email"
          @input="onInputEmailTag"
          placeholder="Add Email..."
        >
        </b-form-tags>
      </b-form-group>
      <b-form-group label="Visibility">
        <v-select
          v-model="form.visibility"
          :options="visibilityOptions"
          label="text"
          :clearable="false"
        />
      </b-form-group>
      <h6>Conditions</h6>
      <!-- type also multi select -->
      <b-form-group label="Type">
        <v-select
          :options="conditionalTypeOptions"
          label="text"
          v-model="v$.form.conditionType.$model"
          :state="
            v$.form.conditionType.$dirty ? !v$.form.conditionType.$error : null
          "
          :class="{
            'form-control is-invalid p-0': v$.form.conditionType.$error
          }"
        />
      </b-form-group>
      <b-form-group label="Tags">
        <v-select
          v-model="v$.form.conditionTags.$model"
          :state="
            v$.form.conditionTags.$dirty ? !v$.form.conditionTags.$error : null
          "
          :class="{
            'form-control is-invalid p-0': v$.form.conditionTags.$error
          }"
          :options="conditionalTagsOptions"
          label="tag"
          multiple
          @update:modelValue="onSelectConditionTags"
        />
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button
          v-if="!isSubmitting"
          size="md"
          variant="outline-danger"
          @click="resetModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          :disabled="
            isSubmitting ||
            (isDuplicateModal && form.name == tempNotificationName)
          "
          size="md"
          variant="outline-success"
          @click="checkValidation"
          >{{
            isEditModal
              ? $t("updateBtn")
              : isDuplicateModal
              ? "Duplicate"
              : $t("saveBtnText")
          }}
          <span v-if="isSubmitting">
            <b-spinner small></b-spinner>
          </span>
        </b-button>
      </template>
    </b-modal>

    <!-- Modal for delete confirmation -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      title="Delete"
      v-model="showDeleteModal"
    >
      Are you sure you want to delete?
      <template v-slot:modal-footer>
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-danger"
          @click="onCloseDeleteModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-success"
          @click="onDeleteRow()"
          >Delete
          <span v-if="isSubmitting">
            <b-spinner small></b-spinner>
          </span>
        </b-button>
      </template>
    </b-modal>

    <!-- Modal for active deactive confirmation -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="isActiveRow == 0 ? 'Activate' : 'Deactivate'"
      v-model="showDeactivateModal"
    >
      Are you sure you want to perform this action?
      <template v-slot:modal-footer>
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-danger"
          @click="onCloseDeactivateModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-success"
          @click="onDeactivateRow()"
          >Save
          <span v-if="isSubmitting">
            <b-spinner small></b-spinner>
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapGetters, mapState } from "vuex";
import { getterName } from "@/constants";

export default {
  name: "notificationSettings",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      tagValidatorDirty: false,

      form: {
        name: null,
        notifyUsers: null,
        notifycc: null,
        statusSelect: null,
        conditionType: null,
        conditionTags: null,
        visibility: { text: "All Users", value: 1 }
      },
      notifyUsersOptions: [],
      conditionalTypeOptions: [],
      conditionalTagsOptions: [],
      showTableData: false,
      filterTable: false,
      filter: null,
      filterInputValue: null,
      sortBy: "name",
      sortDesc: true,
      perPage: 50,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 75, 100],
      fromRows: 0,
      toRows: 0,
      totalRows: 0,
      last_page: 0,
      tableLoaderTop: false,
      tableLoader: false,
      isLoading: false,
      staticFields: [
        { key: "name", label: "Name", sortable: true, width: "250" },
        { key: "is_active", label: "Status", sortable: true, width: "250" },
        {
          key: "users",
          label: "Users and Additional Emails",
          sortable: false,
          width: "250"
        },
        { key: "type", label: "Type", sortable: false, width: "250" },
        { key: "action", label: "Actions", sortable: false, width: "250" }
      ],
      tableItems: [],
      showModal: false,
      isEditModal: false,
      isDuplicateModal: false,
      isSubmitting: false,
      responseOpt: null,
      showDeleteModal: false,
      showDeactivateModal: false,
      rowId: null,
      ccData: null,
      isActiveRow: null,
      isChangeTag: false,
      isChangeUser: false,
      isChangeName: false,
      tempNotificationName: null,
      visibilityOptions: [
        { text: "All Users", value: 1 },
        { text: "Super Admin Only", value: 2 }
      ]
    };
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        conditionTags: {
          required: requiredIf((x) => x.conditionType == null)
        },
        conditionType: {
          required: requiredIf((x) => x.conditionTags == null)
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    }),
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows;
    },
    tagValidatorState() {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let tempEmailTag = this.form.notifycc
        ? this.form?.notifycc[this.form?.notifycc?.length - 1]
        : "";
      return this.tagValidatorDirty
        ? mailformat.test(String(tempEmailTag).toLowerCase())
        : null;
    },
    hasAdditionalEmails() {
      return this.data?.item?.configuration
        ? !!JSON.parse(this.data?.item?.configuration).cc?.length
        : false;
    }
  },
  mounted() {
    this.getNotifyUsersList();
    this.getConditionalTypeOptions();
    this.getConditionalTagsOptions();
    setTimeout(() => {
      this.showTableData = true;
    }, 10);
  },
  methods: {
    goToPreviousPage() {
      this.showTableData = false;
      this.currentPage--;
      this.tableLoaderTop = true;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },
    goToNextPage() {
      this.showTableData = false;
      this.currentPage++;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },
    handleScroll(event) {
      let tableScrollHeight = event.srcElement.scrollHeight;
      let tableScrollTop = event.srcElement.scrollTop;
      let tableClientHeight = event.srcElement.clientHeight;
      let tableScrollClientHeight = tableScrollHeight - tableScrollTop;
      let fixedTableScrollClientHeight = tableScrollClientHeight.toFixed();
      let parsedFixedTableScrollClientHeight = parseInt(
        fixedTableScrollClientHeight
      );
      let addFixedTableScrollClientHeight =
        parsedFixedTableScrollClientHeight + 1;

      if (
        parsedFixedTableScrollClientHeight == tableClientHeight ||
        addFixedTableScrollClientHeight == tableClientHeight
      ) {
        this.handleScrolledToBottom();
      }

      if (tableScrollTop == 0) {
        this.handleScrolledToTop();
      }
    },
    handleScrolledToBottom() {
      if (this.currentPage >= this.last_page) {
        return false;
      } else {
        this.showTableData = false;
        this.currentPage++;
        this.tableLoader = true;
        setTimeout(() => {
          this.showTableData = true;
        }, 10);
      }
    },
    handleScrolledToTop() {
      if (this.currentPage <= 1) {
        return false;
      } else {
        this.showTableData = false;
        this.currentPage--;
        this.tableLoaderTop = true;
        this.tableLoader = true;
        setTimeout(() => {
          this.showTableData = true;
        }, 10);
      }
    },
    submitSearch() {
      if (this.filter) {
        this.filterInputValue = this.filter;
        this.filterTable = true;
        this.currentPage = 1;
        this.tableItems = [];
      }
    },
    onEmpltyFilter(value) {
      if (value) {
        return false;
      } else {
        this.setFIlterClear();
      }
    },
    setFIlterClear() {
      this.filter = "";
      this.filterTable = false;
      this.filterInputValue = null;
    },

    onSelectNotifyUser() {
      this.isChangeUser = true;
    },
    onSelectConditionTags() {
      this.isChangeTag = true;
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "notification-settings-table");
    },
    async getNotifyUsersList() {
      let formData = {
        filter: this.filter,
        sort: "name|asc",
        companyId: this.companyId,
        userId: this.userId,
        is_active: 1
      };
      let result = HTTP(
        "post",
        "platform/users/list/all",
        formData,
        makeAuthorizationHeader()
      );
      return result.then((response) => {
        this.notifyUsersOptions = response.data.data;
      });
    },
    async getConditionalTypeOptions() {
      let result = HTTP(
        "get",
        "v1/event/log/types",
        null,
        makeAuthorizationHeader()
      );
      return result.then((response) => {
        this.responseOpt = response.data;
        this.conditionalTypeOptions = response.data;
      });
    },
    async getConditionalTagsOptions() {
      let result = HTTP(
        "get",
        "v1/event/log/tags",
        null,
        makeAuthorizationHeader()
      );
      return result.then((response) => {
        this.conditionalTagsOptions = response.data;
      });
    },
    async getTableData() {
      if (!this.tableLoader && !this.tableLoaderTop) {
        this.isLoading = true;
      }
      let formData = {
        filter: this.filter,
        company_id: this.companyId,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage,
        visible_role: this.isUserSuperAdmin ? [1, 2] : [1]
      };
      try {
        let result = await HTTP(
          "post",
          "v1/notification/setting/get",
          formData,
          makeAuthorizationHeader()
        );

        if (result) {
          this.last_page = result.data.last_page;
          this.fromRows = result.data.from == null ? 0 : result.data.from;
          this.toRows = result.data.to == null ? 0 : result.data.to;
          this.totalRows = result.data.total;
          this.tableItems = result.data.data;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          this.isLoading = false;

          return this.tableItems;
        }
      } catch (error) {
        this.isLoading = false;
        this.tableLoader = false;
        this.tableLoaderTop = false;
      }
    },
    showAddModal() {
      this.showModal = true;
    },
    resetModal() {
      this.isEditModal = false;
      this.showModal = false;
      this.isDuplicateModal = false;

      this.form = {
        name: null,
        notifyUsers: null,
        notifycc: null,
        statusSelect: null,
        conditionType: null,
        conditionTags: null,
        visibility: { text: "All Users", value: 1 }
      };
      this.tagValidatorDirty = false;
      this.rowId = null;
      this.isActiveRow = null;

      this.v$.$reset();
    },
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        if (this.isEditModal) {
          this.onUpdate();
        } else {
          this.onSubmit();
        }
      }
    },
    onShowEditModal(data) {
      this.isEditModal = true;
      this.showModal = true;
      this.rowId = data.id;
      if (data) {
        this.form.name = data.name;
        this.form.notifyUsers = data.notification_setting_users;
        this.form.notifycc = JSON.parse(data.configuration).cc;
        this.form.conditionType = data.event_type;
        this.form.conditionTags = data.notification_setting_tags;
        this.form.visibility =
          data.visible_role == 1
            ? { text: "All Users", value: 1 }
            : { text: "Super Admin Only", value: 2 };
      }
    },
    onDuplicate(data) {
      this.isDuplicateModal = true;
      this.showModal = true;
      this.rowId = data.id;

      if (data) {
        this.tempNotificationName = data.name;
        this.form.name = data.name;
        this.form.notifyUsers = data.notification_setting_users;
        this.form.notifycc = JSON.parse(data.configuration).cc;
        this.form.conditionType = data.event_type;
        this.form.conditionTags = data.notification_setting_tags;
        this.form.visibility =
          data.visible_role == 1
            ? { text: "All Users", value: 1 }
            : { text: "Super Admin Only", value: 2 };
      }
    },
    onShowDeactivateModal(data) {
      this.isActiveRow = data.is_active;
      this.showDeactivateModal = true;
      this.rowId = data.id;
    },
    async onUpdate() {
      this.isSubmitting = true;
      let formData = {
        company_id: this.companyId,
        created_by_id: this.userId,
        name: this.form.name,
        is_active: 1,
        configuration: {
          cc: this.form.notifycc
        },
        users: this.form.notifyUsers
          ? this.form.notifyUsers.map((item) => item.member_id)
          : [],
        event_type: this.form.conditionType.id,
        tags: this.form.conditionTags
          ? this.form.conditionTags.map((item) => item.id)
          : [],
        isChangeTag: this.isChangeTag,
        isChangeUser: this.isChangeUser,
        visible_role: this.form.visibility.value
      };
      let result = HTTP(
        "put",
        `v1/notification/setting/update/${this.rowId}`,
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then(() => {
          this.$bvToast.toast("Notification Scheme Updated Successfully", {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;
          this.isChangeTag = false;
          this.isChangeUser = false;
          this.resetModal();
          this.reloadTable();
        })
        .catch((error) => {
          this.$bvToast.toast("", {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;

          return [error];
        });
    },
    async onSubmit() {
      this.isSubmitting = true;
      let formData = {
        company_id: this.companyId,
        created_by_id: this.userId,
        name: this.form.name,
        is_active: 1,
        configuration: {
          cc: this.form.notifycc
        },
        users: this.form.notifyUsers
          ? this.form.notifyUsers.map((item) => item.member_id)
          : [],
        event_type: this.form.conditionType.id,
        tags: this.form.conditionTags
          ? this.form.conditionTags.map((item) => item.id)
          : [],
        visible_role: this.form.visibility.value
      };
      let result = HTTP(
        "post",
        "v1/notification/setting/create",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then(() => {
          this.$bvToast.toast("Notification Scheme Added Successfully", {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;
          this.resetModal();
          this.reloadTable();
        })
        .catch((error) => {
          this.$bvToast.toast("", {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;

          return [error];
        });
    },
    renderCCs(data) {
      let config = JSON.parse(data.configuration);
      return config.cc.toString();
    },
    renderUserNames() {},
    onShowDeleteModal(data) {
      this.showDeleteModal = true;
      this.rowId = data.item.id;
    },
    async onDeleteRow() {
      this.isSubmitting = true;
      let result = HTTP(
        "get",
        `v1/notification/setting/delete/${this.rowId}`,
        null,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.reloadTable();
          this.onCloseDeleteModal();
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$bvToast.toast("Not Deleted", {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;

          return [error];
        });
    },
    onDeactivateRow() {
      this.isSubmitting = true;
      let formData = {
        company_id: this.companyId,
        is_active: this.isActiveRow == 0 ? 1 : 0
      };
      let result = HTTP(
        "put",
        `v1/notification/setting/update/${this.rowId}`,
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.reloadTable();
          this.onCloseDeactivateModal();
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$bvToast.toast("Action did not performed", {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;

          return [error];
        });
    },
    onCloseDeleteModal() {
      this.showDeleteModal = false;
      this.rowId = null;
    },
    onCloseDeactivateModal() {
      this.showDeactivateModal = false;
      this.rowId = null;
      this.isActiveRow = null;
    },

    tagValidator(value) {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (mailformat.test(String(value).toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    onInputEmailTag() {
      if (this.form?.notifycc?.length) {
        this.tagValidatorDirty = true;
      } else {
        this.tagValidatorDirty = false;
      }
    }
  }
};
</script>

<style></style>
