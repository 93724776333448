import { makeDateToMatchFormat } from "@/utils";
import {
  productKeysDTO,
  productKeysAPIDTO,
  eb360DateFormat
} from "@/constants";

const makeProductListDTO = ({
  products = [],
  _makeDateToMatchFormat = makeDateToMatchFormat
} = {}) =>
  products?.map((product) => ({
    [productKeysDTO.ID]: product[productKeysAPIDTO.ID],
    [productKeysDTO.NAME]: product[productKeysAPIDTO.NAME],
    [productKeysDTO.COURSE_ID]: product[productKeysAPIDTO.COURSE_ID],
    [productKeysDTO.APPLICATION_ID]: product[productKeysAPIDTO.APPLICATION_ID],
    [productKeysDTO.DAYS_TO_COMPLETE]:
      product[productKeysAPIDTO.DAYS_TO_COMPLETE],
    [productKeysDTO.ACTIVE]: product[productKeysAPIDTO.ACTIVE],
    [productKeysDTO.CREATED_AT]: product?.[productKeysAPIDTO.CREATED_AT]
      ? _makeDateToMatchFormat({
          stringifiedDate: product[productKeysAPIDTO.CREATED_AT],
          dateFormat: eb360DateFormat.WORDED_LONG_FORM_DATE
        })
      : ""
  })) || [];

const makeProductAPIDTO = (courseIds = []) => ({
  [productKeysAPIDTO.COURSE_IDS]: courseIds
});

export { makeProductListDTO, makeProductAPIDTO };
