<template id="template-input">
  <b-form-group class="mt-2">
    <label>
      {{
        attributeData.tp_alias
          ? attributeData.tp_alias
          : attributeData.tp_customatt
      }}
      <Tooltip
        class="ml-2"
        v-if="getAttribute(attributeData)"
        :text="attributeData.description"
      />
    </label>

    <!-- if custom attribute is required -->
    <div v-if="parseInt(attributeData.tp_is_required)" :ref="attributeData.id">
      <v-select
        placeholder="---Required---"
        v-model="v$.form.$model"
        :state="v$.form.$dirty ? !v$.form.$error : null"
        :class="{
          'form-control is-invalid p-0 template-input__invalid': v$.form.$error
        }"
        :options="userOptions"
        label="name"
        track-by="name"
        @update:modelValue="saveValue"
      >
      </v-select>
      <b-form-invalid-feedback
        >A Required field has no value.</b-form-invalid-feedback
      >
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <v-select
        v-model="form"
        :options="userOptions"
        label="name"
        track-by="name"
        @update:modelValue="saveValue"
      >
      </v-select>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "User",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip
  },
  data() {
    return {
      form: null,
      tpRequired: false
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        form: {
          required
        }
      };
    }
  },
  created() {
    // react on event UserListValidationError
    EventBus.on("UserListValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      this.v$.form?.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      let userValue = JSON.parse(this.values.value);
      this.form = this.userOptions.filter(
        ({ memberId }) => memberId === userValue.id
      );
    }
  },
  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    // save custom attribute value
    saveValue() {
      let userobj = {};
      if (this.form) {
        userobj = {
          id: this.form.memberId,
          value: this.form.name
        };
      }
      if (parseInt(this.attributeData.tp_is_required)) {
        this.v$.form?.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.emit("saveValue", {
          value: JSON.stringify(userobj),
          id: this.attributeData.id,
          error: this.v$.form.$errors?.length,
          type: this.attributeData.tp_type
        });
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.emit("saveValue", {
          value: JSON.stringify(userobj),
          id: this.attributeData.id,
          error: false,
          type: this.attributeData.tp_type
        });
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object,
    userOptions: Array
  }
};
</script>

<style scoped lang="scss">
.template-input__invalid {
  height: calc(1.5em + 0.75rem + 8px) !important;
}
</style>
