<template>
  <div>
    <!-- Add new region modal -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add New Item"
      v-model="showAddModal"
      scrollable
      size="md"
    >
      <b-overlay :show="isLoadingData">
        <div>
          <b-form-group label="Name of Region :">
            <b-form-input
              placeholder="Enter Name of Region"
              v-model="v$.form.name.$model"
              :state="v$.form.name.$dirty ? !v$.form.name.$error : null"
              id="form-name"
            ></b-form-input>
            <b-form-invalid-feedback id="form-name"
              >A Required field has no value.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="resetModal">Close</b-button>
        <b-button size="md" variant="outline-success" @click="addRegion"
          >Add
        </b-button>
      </template>
    </b-modal>

    <div>
      <div class="drop-down">
        <b class="m-2">Region Type: </b>
        <!-- Dropdown for region types -->
        <b-dropdown size="md" :text="selecteditem">
          <b-overlay :show="isLoading || isLoadingDefaultRegion">
            <div
              class="dropdown-item bg-white text-body"
              v-for="(item, index) in listOfRegions"
              :key="index"
            >
              <div style="display: flex">
                <b-form-radio
                  name="some-radios"
                  v-model="defaultRegion"
                  :value="item.id"
                  @change="setDefaultRegion(item.id)"
                ></b-form-radio>
                <b-dropdown-item @click="setSelectedItem(item)">{{
                  item.name
                }}</b-dropdown-item>
                <b-button
                  v-if="parseInt(item.client_id) > 0"
                  class="float-right"
                  variant="outline-danger"
                  size="md"
                  @click="deleteElements(item)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
              </div>
            </div>
          </b-overlay>
        </b-dropdown>

        <div style="margin-left: 40px">
          <!-- add button -->
          <b-button variant="outline-primary" size="md" @click="openAddModal">
            <font-awesome-icon icon="plus" />
          </b-button>
        </div>
        <div class="ml-auto my-2">
          <!-- Submit button for saving configuartion -->
          <b-btn variant="outline-success" @click="sumbitRegion">
            Submit
          </b-btn>
        </div>
      </div>

      <b-overlay :show="isSubmiting">
        <b-row class="ml-5">
          <!-- All Countries -->
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            cols="6"
          >
            <h5 class="pt-1">All Countries</h5>
            <hr style="margin: 0px" />
            <draggable
              :list="allActions"
              :v-model="allActions"
              group="actions"
              style="height: calc(100vh - 300px); overflow-y: auto"
            >
              <template #item="{ element }">
                <b-list-group>
                  <b-list-group-item class="mb-1">{{
                    element.country
                  }}</b-list-group-item>
                </b-list-group>
              </template>
            </draggable>
          </b-col>

          <!-- Selected Countries -->
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            cols="6"
          >
            <h5 class="pt-1">Selected Countries</h5>
            <hr style="margin: 0px" />
            <draggable
              style="height: calc(100vh - 300px); overflow-y: auto"
              :list="actionsOccur"
              :v-model="actionsOccur"
              group="actions"
            >
              <template #item="{ element }">
                <b-list-group>
                  <b-list-group-item class="mb-1">{{
                    element.country
                  }}</b-list-group-item>
                </b-list-group>
              </template>
            </draggable>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "DynamicEDDConfiguration",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    draggable
  },
  data() {
    return {
      isSubmiting: false,
      isLoading: false,
      isLoadingData: false,
      allActions: [],
      actionsOccur: [],
      showAddModal: false,
      form: {
        name: null
      },
      selecteditem: "",
      selectedItemArray: [],
      staticAllActions: [],
      companyId: null,
      isLoadingDefaultRegion: false,
      defaultRegion: this.eddDefaultRegion
    };
  },
  mounted() {
    this.v$.$reset();
    this.companyId = this.$route.params.id;
    this.getCountries();
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  methods: {
    // get countries list
    async getCountries() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          "v1/countries",
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.staticAllActions = result.data.countries;
          this.allActions = result.data.countries;
          this.isSubmiting = false;
        }
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    },
    openAddModal() {
      this.showAddModal = true;
    },
    resetModal() {
      this.showAddModal = false;
      this.form = {};
      this.v$.$reset();
    },

    // Add region API
    async addRegion() {
      this.v$?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        this.isLoadingData = true;
        let formData = {
          name: this.form.name,
          client_id: this.companyId
        };
        try {
          let result = await HTTP(
            "post",
            "thirdparty/edd/regions/create",
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200 && result.data.status != 0) {
            this.$emit("onAddNewRegion", this.form);
            this.$bvToast.toast("Region added successfully", {
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.resetModal();
            this.$emit("onGetDropDownRegions");
            this.isLoadingData = false;
          } else if (result.data.status == 0) {
            this.$bvToast.toast(result.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.isLoadingData = false;
          }
        } catch (error) {
          //
          this.isLoadingData = false;
          return [error];
        }
      }
    },

    // Save configuration API
    async sumbitRegion() {
      if (parseInt(this.selectedItemArray.client_id) == 0) {
        this.$bvToast.toast("You can't change default region's configuration", {
          variant: "warning",
          autoHideDelay: 2000,
          appendToast: false,
          noCloseButton: true
        });
        this.getCountriesByType();
      } else {
        this.isSubmiting = true;
        let idArray = [];
        this.actionsOccur.map((data) => {
          idArray.push({
            id: data.id || data.country_id
          });
        });
        let formData = {
          region_id: this.selectedItemArray.id,
          country_ids: idArray,
          client_id: this.companyId
        };
        try {
          let result = await HTTP(
            "post",
            "thirdparty/edd/regions/add/countries",
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200 && result.data.status == 1) {
            this.$bvToast.toast("Countries has been added successfully", {
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.$emit("onGetDropDownRegions");
            this.isSubmiting = false;
          } else if (result.data.status == 0) {
            this.$bvToast.toast(result.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.isSubmiting = false;
          }
        } catch (error) {
          this.isSubmiting = false;
          return [error];
        }
      }
    },
    setSelectedItem(val) {
      this.selecteditem = val.name;
      this.selectedItemArray = val;
      this.getCountriesByType();
    },

    // delete region API
    async deleteElements(item) {
      this.isLoading = true;
      let formData = {
        client_id: this.companyId,
        region_id: item.id
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/regions/delete",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          this.$bvToast.toast("Region has been deleted successfully", {
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.$emit("onGetDropDownRegions");
          this.isLoading = false;
        }
      } catch (error) {
        //
        this.isLoading = false;
        return [error];
      }
    },

    // get countries by type
    async getCountriesByType() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/regions/countries/${this.selectedItemArray.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.actionsOccur = result.data;
          this.filterCountries();
          this.isSubmiting = false;
        }
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    },
    filterCountries() {
      let customFields = [];
      this.staticAllActions.map((data) => {
        let filterIndex = this.actionsOccur.findIndex(
          (x) => x.country_id == data.id
        );
        if (filterIndex == -1) {
          customFields.push(data);
        }
      });
      this.allActions = customFields;
    },

    // set default region API
    async setDefaultRegion(itemID) {
      this.isLoadingDefaultRegion = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/regions/default/${this.companyId}/${itemID}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          this.$bvToast.toast(result.data.message, {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isLoadingDefaultRegion = false;
        }
      } catch (error) {
        //
        this.isLoadingDefaultRegion = false;
        return [error];
      }
    }
  },
  props: {
    listOfRegions: Array,
    eddDefaultRegion: String
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
.drop-down {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
}
.dropdownFieldsContainer {
  display: flex;
}
</style>
