const productKeysDTO = {
  ID: "id",
  NAME: "name",
  COURSE_ID: "courseId",
  APPLICATION_ID: "applicationId",
  DAYS_TO_COMPLETE: "daysToComplete",
  ACTIVE: "active",
  CREATED_AT: "createdAt"
};

const productKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  COURSE_ID: "course_id",
  APPLICATION_ID: "application_id",
  DAYS_TO_COMPLETE: "days_to_complete",
  ACTIVE: "active",
  CREATED_AT: "created_at",
  COURSE_IDS: "course_ids"
};

export { productKeysDTO, productKeysAPIDTO };
