<template id="template-select">
  <div
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      :class="isPreview ? 'mt-0' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            id="fieldset-1"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validation.message"
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  JSON.parse(fieldData.validation.required)
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>

            <v-select
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              :options="renderOptions"
              v-model="selected"
              @update:modelValue="checkCondition"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        ok-only
        ok-title="Close"
        size="lg"
        v-model="showSettings"
        title="Select Picker Advanced Settings"
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          @label-text="label = $event"
          @helper-text="helperText = $event"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import vSelect from "vue-select";
import MenuButtons from "../menuButtons";
import AdvanceSelectSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent";
import { mapGetters, mapState } from "vuex";

export default {
  name: "TitleSelectGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      helperText: undefined,
      isRequired: 1,
      name: "",
      renderOptions: [],
      selected: null
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  mounted() {
    this.isRequired = this.fieldData.validation.required;

    let getOptions = this.fieldData.options;
    getOptions.map((option) => {
      let optValue = option.value || "No Value Set";
      this.renderOptions.push(optValue);
    });
  },
  methods: {
    getData(data) {
      this.$emit("update-form-settings", data);
    },

    checkCondition(value) {
      let elements = this.form1.formSection[this.sectionIndex].formElements;
      elements.find((ff, index) => {
        if (ff.condition) {
          if (
            ff.condition.sourceFieldId === this.fieldData.id &&
            ff.condition.value === value
          ) {
            this.form1.formSection[this.sectionIndex].formElements[
              index
            ].isHidden = false;
            // return {...found, isHidden: true }
          } else {
            this.form1.formSection[this.sectionIndex].formElements[
              index
            ].isHidden = true;
          }
          // return {...found, isHidden: false }
        }
      });
    }
  },

  validations: {
    selected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
</style>
