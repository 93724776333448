<template>
  <div class="tasks-chart-widget">
    <BaseChartWidget :chart-title="chartTitle" :chart-options="options">
      <template v-if="chartType === chartTypes.POINT_VALUE" v-slot:chart>
        <PointValue
          v-bind="options"
          class="tasks-chart-widget__point-value"
          @click="handlePointValueChartClickEvent"
        />
      </template>
    </BaseChartWidget>
  </div>
</template>

<script>
import BaseChartWidget from "@/atoms/BaseChartWidget/BaseChartWidget";
import PointValue from "@/molecules/PointValue/PointValue";
import { makeBarChartObj, makeScatterChartObject } from "@/utils";
import { colors, chartTypes, drilldownEvents } from "@/constants";

export default {
  name: "TasksChartWidget",
  components: { BaseChartWidget, PointValue },
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Array,
      default: () => []
    },
    chartType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      chartTypes,
      options: {}
    };
  },
  computed: {
    chartTitle() {
      return this.widget?.label || "";
    },
    barChartDataPoints() {
      return this.data?.map((value) => ({
        y: parseFloat(value?.count),
        barValue: value?.value
      }));
    },
    chartLabels() {
      return this.data?.map((value) => value?.label);
    },
    chartHasAllRequiredData() {
      return !!(this.data?.length && this.widget?.key && this.widget?.label);
    },
    scatterDateData() {
      return this.data?.map((value) => {
        const date = new Date(value?.value);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const day = date.getUTCDate();
        const UTCDate = Date.UTC(year, month, day);

        return {
          x: UTCDate,
          y: parseFloat(value?.count),
          dateValue: value?.value
        };
      });
    }
  },
  watch: {
    chartHasAllRequiredData(newValue) {
      this.checkForChartInitialisation(newValue);
    }
  },
  mounted() {
    this.checkForChartInitialisation(this.chartHasAllRequiredData);
  },
  methods: {
    setWidgetOptions() {
      switch (this.chartType) {
        case chartTypes.POINT_VALUE:
          this.options = this.makePointValueOptions();
          break;
        case chartTypes.BAR:
          this.options = this.makeBarChart();
          break;
        case chartTypes.DATETIME:
          this.options = this.makeScatterChart();
          break;
        default:
          this.options = {};
      }
    },
    makeBarChart({
      _handleBarChartClickEvent = this.handleBarChartClickEvent
    } = {}) {
      return {
        ...makeBarChartObj(),
        colors: [colors.carolinaBlue],
        xAxis: {
          categories: this.chartLabels,
          labels: {
            style: {
              color: colors.black
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          endOnTick: true,
          labels: {
            style: {
              color: colors.black
            }
          },
          title: {
            text: ""
          }
        },
        tooltip: {
          pointFormat: "<b>{point.category}:{point.y}</b>"
        },
        series: [
          {
            data: this.barChartDataPoints
          }
        ],
        turboThreshold: 0,
        plotOptions: {
          bar: {
            cursor: "pointer",
            events: {
              click: _handleBarChartClickEvent
            }
          }
        }
      };
    },
    handleBarChartClickEvent({ point }) {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        value: point?.barValue,
        label: point?.category,
        id: this.widget?.id,
        key: this.widget?.key
      });
    },
    makeScatterChart({
      _handleScatterChartClickEvent = this.handleScatterChartClickEvent
    } = {}) {
      const oneDayInTermsOfMilliseconds = 24 * 3600 * 1000;

      return {
        ...makeScatterChartObject(),
        xAxis: {
          type: chartTypes.DATETIME,
          dateTimeLabelFormats: {
            day: "%Y/%m/%d"
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
          ordinal: false,
          minTickInterval: oneDayInTermsOfMilliseconds
        },
        tooltip: {
          pointFormat:
            "<b>Date: {point.x:%Y/%m/%d}</b> <br/> <b>Value: {point.y}</b>"
        },
        series: [
          {
            name: "",
            marker: {
              symbol: "circle"
            },
            data: this.scatterDateData
          }
        ],
        plotOptions: {
          scatter: {
            cursor: "pointer",
            events: {
              click: _handleScatterChartClickEvent
            }
          }
        },
        turboThreshold: 0
      };
    },
    makePointValueOptions() {
      return {
        value: this.data?.[0]?.value || 0,
        secondaryValue: this.data?.[0]?.secondaryValue || ""
      };
    },
    handleScatterChartClickEvent({ point }) {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        value: point?.dateValue,
        label: point?.dateValue,
        id: this.widget?.id,
        key: this.widget?.key
      });
    },
    handlePointValueChartClickEvent() {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        id: this.widget?.id,
        key: this.widget?.key
      });
    },
    checkForChartInitialisation(value) {
      if (value) {
        this.setWidgetOptions();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tasks-chart-widget {
  &__point-value {
    cursor: pointer;
  }
}
</style>
