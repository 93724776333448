<template id="template-checkbox">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      :isHidden="isHidden"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <b-collapse
      :class="isPreview ? 'mt-1' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  JSON.parse(fieldData.validation.required)
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <CheckboxGroup
              v-model="renderOptions"
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              :class="[
                'template-checkbox__list',
                $store.state.rightAlign ? 'text-right' : 'text-left'
              ]"
            />
            <label
              v-if="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                !selectedCheckboxes.length &&
                isFocus
              "
              class="text-danger"
              >{{ fieldData.validationMessage[selectedLanguage] }}</label
            >
            <div>
              <label v-if="showMinAlert" class="text-danger"
                >{{ $t("questionnaires.selectAtLeast") }}
                {{ fieldData.validation.minSelect }}
                {{ $t("questionnaires.options") }}</label
              >
              <label v-if="showMaxAlert" class="text-danger"
                >{{ $t("questionnaires.options") }}
                {{ fieldData.validation.maxSelect }}
                {{ $t("questionnaires.options") }} only.</label
              >
            </div>
          </b-form-group>

          <!-- conditional input field -->
          <b-collapse v-model="showCollapseField" class="mt-2">
            <b-form-group
              :class="
                $store.state.rightAlign ? 'text-right ml-3 ' : 'text-left ml-3'
              "
              :description="
                fieldData.conditionalFieldHelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <span
                v-if="
                  fieldData.validation && fieldData.validation.required === 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.conditionalFieldLabel[selectedLanguage] }}
              <b-form-textarea
                v-model="conditionalFieldInputValue"
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                @input="onInputType"
                :disabled="isDisabled == 1"
                :placeholder="
                  fieldData.conditionalFieldPlaceHolderText[selectedLanguage]
                "
                :rows="fieldData.conditionalFieldMinRows || 3"
                :max-rows="fieldData.conditionalFieldMaxRows || 6"
                :state="
                  fieldData.validation.required == 0
                    ? null
                    : isFocus &&
                      conditionalFieldInputValue &&
                      isMatchCondition &&
                      fieldData.validation.required == 1
                    ? true
                    : false
                "
                @focus="isFocus = true"
              ></b-form-textarea>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>

      <!-- advanced settings tab -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        @hidden="onUpdateSettings"
        size="xl"
        v-model="showSettings"
        title="Checkbox Settings"
      >
        <AdvanceCheckboxSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :isPreview="isPreview"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { required } from "@vuelidate/validators";
import _ from "lodash";
import AdvanceCheckboxSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent.vue";
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";
import { isCurrentSectionHidden } from "@/utils";
import { routeKeys, routeNames } from "@/constants";
import CheckboxGroup from "@/molecules/CheckboxGroup/CheckboxGroup";

export default {
  name: "CheckboxGroups",
  components: {
    AdvanceCheckboxSettings,
    MenuButtons,
    CheckboxGroup
  },
  data() {
    return {
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      isRequired: 1,
      collapse: true,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      isValidateLength: false,
      showMinAlert: false,
      showMaxAlert: false,
      selectedCheckboxes: [],
      cc: null,
      conditionalFieldLabel: null,
      conditionalFieldHelperText: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldIsRequired: 0,
      conditionalFieldMaxRows: 1,
      conditionalFieldInputValue: null,
      conditionalFieldPostName: null,
      conditionalFieldIsVisible: 0,
      showCollapseField: false,
      isMatchCondition: false
    };
  },

  validations: {
    name: {
      required
    }
  },

  mounted() {
    this.isRequired = this.fieldData.validation.required;

    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === routeNames[routeKeys.USER_FORM]
    ) {
      this.setFieldValue();
    }
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (
        isCurrentSectionHidden(this.form1, this.fieldData.id) ||
        value === "progress"
      ) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (this.fieldData.isHidden) {
          if (value !== "progress") {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        } else {
          if (this.selectedCheckboxes && this.selectedCheckboxes.length) {
            this.setValue(this.selectedCheckboxes);
          }
          if (
            this.fieldData.validation &&
            this.fieldData.validation.required == 1
          ) {
            if (!this.selectedCheckboxes || !this.selectedCheckboxes.length) {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            } else if (
              this.selectedCheckboxes &&
              this.selectedCheckboxes.length
            ) {
              if (this.isMatchCondition) {
                if (!this.conditionalFieldInputValue) {
                  this.isFocus = true;
                  this.$store.state.validFields = false;
                  this.fieldData.isInValid = true;
                } else {
                  this.$store.state.validFields = true;
                  this.fieldData.isInValid = false;
                }
              } else {
                this.$store.state.validFields = true;
                this.fieldData.isInValid = false;
              }
            } else {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            }
          }
        }
      }
    });
    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      if (inValidData && inValidData.elementId) {
        document
          .getElementById(inValidData.elementId)
          .scrollIntoView({ behavior: "smooth" });
      }
    });

    EventBus.on("onUpdateCheckboxValues", () => {
      this.selectedCheckboxes = [];
    });

    EventBus.on("onResetCheckBox", (value) => {
      this.resetPregressiveElement(value);
    });
    EventBus.on("onResetSelectedValues", (value) => {
      this.resetSelectedValues(value);
    });
  },

  computed: {
    ...mapGetters(["form1", "selectedLanguage", "userFormSubmitArray"]),
    ...mapState(["toggleAllCoppalse"]),
    renderOptions: {
      get() {
        const options = _.cloneDeep(this.fieldData?.options);

        return (
          options?.map(({ value, text, score }) => ({
            modelValue: this.selectedCheckboxes
              ?.map((checkbox) => checkbox?.value)
              .includes(value)
              ? value
              : "",
            checkedValue: value,
            uncheckedValue: "",
            label: text[this.selectedLanguage] || "No Text Set",
            disabled: this.hasDisableOptionSelected(value),
            score,
            text
          })) || []
        );
      },
      set(options) {
        const updatedOptions = this.makeOptionsForDisableValue(options);
        const selectedCheckboxes = updatedOptions
          .filter((option) => option.modelValue)
          .map(({ score, modelValue, text }) => ({
            value: modelValue,
            score,
            text
          }));

        this.selectedCheckboxes = selectedCheckboxes;
        this.checkCondition(this.selectedCheckboxes);
      }
    }
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(50);
    },

    hasDisableOptionSelected(value) {
      return this.fieldData?.disableOptions?.some((tt) => {
        const foundOpt = this.selectedCheckboxes.find(
          (opt) => opt.value === tt.value
        );
        return foundOpt && foundOpt.value !== value;
      });
    },

    makeOptionsForDisableValue(options) {
      this.fieldData?.disableOptions?.forEach((disableOpt) => {
        let foundOpt = options.find(
          (opt) => opt.modelValue == disableOpt.value
        );
        if (foundOpt) {
          options.forEach((opt) => {
            if (opt.modelValue !== disableOpt.value) {
              opt.modelValue = "";
            }
          });
        }
      });

      return options;
    },

    calculateScoring() {
      let score = null;
      let isFoundDisableOpt = false;
      let selectedOption = this.selectedCheckboxes.find((ele) => ele);
      if (
        this.fieldData.disableOptions &&
        this.fieldData.disableOptions.length
      ) {
        isFoundDisableOpt = this.fieldData.disableOptions.some(
          (opt) => opt.value === selectedOption?.value || selectedOption
        );
      }
      let length = this.selectedCheckboxes.length;
      let ranges =
        this.fieldData.ranges && this.fieldData.ranges.length
          ? this.fieldData.ranges
          : [];

      if (isFoundDisableOpt) {
        score = 1;
      } else if (ranges.length) {
        ranges.map((range) => {
          if (range.minRange <= length && length <= range.maxRange) {
            score = score || range.score;
          }
        });
      } else {
        score = 0;
      }

      return score;
    },
    checkCondition(options) {
      this.isFocus = !(options.length > 0);
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;

      let elements = this.form1.formSection[this.sectionIndex]?.formElements;

      elements.find((ff, index) => {
        if (ff.conditions && ff.conditions.length) {
          ff.conditions.map((aa) => {
            if (options && options.length) {
              // section 12.3 showing properly with this found selected work
              let selectedValue = options.map((option) => option.value);
              let foundSelected = ff.conditions.some((option) =>
                selectedValue.includes(option.value)
              );

              if (
                aa.sourceFieldId === this.fieldData.id &&
                aa.targetFieldId !== this.fieldData.id &&
                foundSelected &&
                aa.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = false;
                this.enableValidations(
                  this.form1.formSection[this.sectionIndex]?.formElements[index]
                );
              } else if (
                aa.sourceFieldId === this.fieldData.id &&
                aa.targetFieldId !== this.fieldData.id &&
                !foundSelected &&
                aa.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = true;

                this.disableValidations(
                  this.form1.formSection[this.sectionIndex]?.formElements[index]
                );
              }
            } else {
              // section 12.3 showing properly with this work
              if (
                aa.sourceFieldId === this.fieldData.id &&
                aa.targetFieldId !== this.fieldData.id &&
                aa.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = true;
                this.disableValidations(
                  this.form1.formSection[this.sectionIndex]?.formElements[index]
                );
              }
            }
          });
        }
      });

      // let found, self;

      // check for min options
      if (this.fieldData.validation.minSelect) {
        let minValue = parseInt(this.fieldData.validation.minSelect);
        if (options.length < minValue) {
          // alert("minmun errorr");
          this.showMinAlert = true;
        } else {
          this.showMinAlert = false;
        }
      }

      // check for max options
      if (this.fieldData.validation.maxSelect) {
        let maxValue = parseInt(this.fieldData.validation.maxSelect);
        if (options.length > maxValue) {
          // this.selectedCheckboxes.splice(1, 1);
          this.showMaxAlert = true;
        } else {
          this.showMaxAlert = false;
          this.isValidateLength = false;
        }
      }
      // new
      let condOptions;
      let selectedConditionalFieldValue =
        this.fieldData.selectedConditionalFieldValue;
      if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "string"
      ) {
        // STRING CONDITION
        condOptions = options.find(
          (opt) => opt.value == selectedConditionalFieldValue
        );
        // condOptions = options.includes(selectedConditionalFieldValue);
      } else if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "object" &&
        selectedConditionalFieldValue.length
      ) {
        // OBJECT CONDITION
        loop: for (let opt of selectedConditionalFieldValue) {
          for (let item of options) {
            if (item.value == opt.value) {
              condOptions = 1;
              break loop;
            }
          }
        }
      }
      if (condOptions) {
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.isFocus = true;
      } else {
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.conditionalFieldInputValue = null;
        this.isMatchCondition = false;
        this.isFocus = true;
      }

      this.setValue(options);
    },

    // for disable vlaidations
    disableValidations(element) {
      switch (element.inputType) {
        case "input":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          EventBus.emit("onUpdateInputValues", "");
          break;
        case "textarea":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          break;
        case "radio":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                EventBus.emit("onResetSelectedRadioValues", element);
                a.elements = [];
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                EventBus.emit("onResetSelectedRadioValues", element);
                a.elements = [];
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          // code block

          break;
        case "checkbox":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
              }
            });
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          // code block

          break;
        case "individual-shareholders":
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          // code block
          break;
        default:
          this.clearPostValuesForExternalConditionalComponents(element);
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
        // code block
      }
    },

    clearPostValuesForExternalConditionalComponents(element) {
      if (element?.conditions?.length && element.isConditional === 1) {
        element.conditions.forEach((condition) => {
          let foundConditionalElement =
            this.$store.state.userFormSubmitArray.find(
              (ele) => ele.elementId === condition.targetFieldId
            );
          if (foundConditionalElement) {
            foundConditionalElement.elements = [];
            foundConditionalElement.isHiddenElement = 1;
          }
        });
      }
    },
    enableValidations(element) {
      switch (element.inputType) {
        case "individual-shareholders":
          element.isInValid = true;
          // code block
          break;
        case "textarea":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          element.isInValid = false;
          element.validation.required = 1;
          break;
        case "checkbox":
          this.$store.state.getUserFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          element.isInValid = false;
          element.validation.required = 1;
          break;
        case "input":
          this.$store.state.getUserFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          break;
        case "radio":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
                a.elements.map(() => {});
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });

          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          element.validation.required = 1;
          break;
        default:
          element.isInValid = true;
          element.validation.required = 1;
        // code block
      }
    },

    setFieldValue() {
      let selectedCheckboxes = [];
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a && a.elementId && a.elements && a.elements.length) {
            if (a.elementId === this.fieldData.id) {
              a.elements.map((el) => {
                if (el.key === "checkbox" && el?.postValue?.length) {
                  el.postValue.map((opt) => {
                    if (typeof opt == "string") {
                      let index = this.renderOptions.findIndex(
                        (x) => x.value.value == opt
                      );
                      if (index != -1) {
                        this.selectedCheckboxes.push(
                          this.renderOptions[index].value
                        );
                        selectedCheckboxes.push(
                          this.renderOptions[index].value
                        );
                      }
                    } else {
                      this.selectedCheckboxes.push(opt);
                      selectedCheckboxes.push(opt);
                    }
                  });
                } else if (el.key == "progressive") {
                  this.conditionalFieldInputValue = el.postValue;
                }
              });
            }
          }
        });
      this.selectedCheckboxes = [
        ...new Map(
          selectedCheckboxes.map((item) => [item.value, item])
        ).values()
      ];
      if (this.selectedCheckboxes && this.selectedCheckboxes.length) {
        this.fieldData.isInValid = false;
        this.checkCondition(this.selectedCheckboxes);
      }
    },

    setConditionInput() {
      if (
        this.selectedCheckboxes &&
        this.selectedCheckboxes.length &&
        this.fieldData.selectedConditionalFieldValue
      ) {
        let dd = false;
        this.selectedCheckboxes.map((opt) => {
          if (opt.value == this.fieldData.selectedConditionalFieldValue) {
            dd = true;
          }
        });
        if (dd) {
          this.showCollapseField = true;
          this.conditionalFieldIsVisible = 1;
          this.isMatchCondition = true;
          this.isFocus = true;
        } else {
          this.showCollapseField = false;
          this.fieldData.selectedConditionalFieldValue = null;
          this.conditionalFieldIsVisible = 0;
          this.isMatchCondition = false;
          this.isFocus = false;
        }
      }
    },

    setValue(e) {
      let score = 0;
      let accumalatedScoring = 0;
      const registerData = _.clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        this.selectedCheckboxes = e;

        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;
        let option;
        let postText = [];
        this.selectedCheckboxes.map((chk) => {
          option = this.fieldData.options.find(
            (opt) =>
              opt.text[this.selectedLanguage] == chk.text[this.selectedLanguage]
          );
          if (option && option.text) {
            postText.push(option.text[this.selectedLanguage]);
          }
          if (option && option.score) {
            score = option.score;
          }
        });

        if (this.fieldData.ranges && this.fieldData.ranges.length) {
          accumalatedScoring = this.calculateScoring();
        } else {
          accumalatedScoring = score;
        }
        let selectedCheckboxOptionsPostValueAbb = [];
        let selectedCheckboxOptionsCustomValues = [];
        this.selectedCheckboxes.map((chk) => {
          let option = this.fieldData.options.find(
            (opt) =>
              opt.text[this.selectedLanguage] == chk.text[this.selectedLanguage]
          );
          selectedCheckboxOptionsPostValueAbb.push(
            (option && option.postValueAbb) || null
          );
          selectedCheckboxOptionsCustomValues.push(
            (option && option.custom_values) || null
          );
        });
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[0] = {
              postValue: this.selectedCheckboxes,
              postValueText: postText || null,
              postValueAbb: selectedCheckboxOptionsPostValueAbb,
              key: "checkbox",
              score: accumalatedScoring,
              custom_values: selectedCheckboxOptionsCustomValues,
              postName:
                (this.fieldData.attributes &&
                  this.fieldData.attributes.postName) ||
                "postNameCheckbox"
            };
          }
        });
      }
    },

    onInputType(e) {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        (this.$route.name === routeNames[routeKeys.USER_FORM] &&
          this.showCollapseField)
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[1] = {
              postValue: e,
              key: "progressive",
              postNameProgressive:
                this.fieldData.conditionalFieldLabel || "postNameProgressive"
            };
          }
        });
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    },

    resetSelectedValues(selectedElement) {
      if (selectedElement.id === this.fieldData.id) {
        this.selectedCheckboxes = [];
      }
    },

    resetPregressiveElement(selectedElement) {
      let foundSection =
        this.form1.formSection[selectedElement.sectionIndex]?.formElements;

      for (let element of foundSection) {
        if (element.id == this.fieldData.id) {
          this.selectedCheckboxes = [];
        }
        if (
          element?.conditions?.length &&
          element.id != this.fieldData.id &&
          element.inputType == "checkbox"
        ) {
          element.isHidden = true;
        }

        if (
          element?.conditions?.length &&
          element.id != this.fieldData.id &&
          element.inputType == "radio"
        ) {
          if (element.isHidden == true) {
            element.selected = null;
          }
        }
      }
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>

<style lang="scss" scoped>
.template-checkbox {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
