<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <InputErrorMessage :error="errorMessage" />
      <BaseText :text="feedbackMessage" />
      <StickyTable
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        data-test-id="training__table"
        @table-row-click="onTableRowClick"
      />
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import {
  trainingsTableHeaders,
  trainingsKeysDTO,
  icons,
  themes,
  shapes,
  views,
  DOMEvents
} from "@/constants";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { getTrainingStatusTheme } from "@/utils";

export default {
  name: "ThirdPartyTrainingList",
  components: {
    StickyTable,
    BaseLoader,
    InputErrorMessage,
    BaseText
  },
  data() {
    return {
      themes
    };
  },
  props: {
    trainingList: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ""
    },
    feedbackMessage: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableHeaders() {
      return this.trainingList.length
        ? Object.keys(trainingsTableHeaders).reduce((acc, key) => {
            if (trainingsTableHeaders[key]) {
              acc.push({
                value: key,
                text: trainingsTableHeaders[key]
              });
            }
            return acc;
          }, [])
        : [];
    },
    tableRows() {
      return this.trainingList?.length
        ? this.trainingList.map((training) =>
            Object.keys(trainingsTableHeaders).reduce((acc, trainingKey) => {
              if (trainingKey === trainingsKeysDTO.STATUS) {
                acc[trainingKey] = {
                  component: BaseBadge,
                  componentOptions: {
                    theme: getTrainingStatusTheme(training[trainingKey]),
                    text: training[trainingKey]
                  }
                };
              } else if (trainingKey === trainingsKeysDTO.REMINDER_SENT) {
                acc[trainingKey] = {
                  component: BaseIcon,
                  componentOptions: {
                    icon: training[trainingKey] ? icons.CHECK : icons.MINUS,
                    size: 16,
                    theme: themes.LIGHT_GREY_INVERSE
                  },
                  styles: {
                    textAlign: "-webkit-center"
                  }
                };
              } else if (trainingKey === trainingsKeysDTO.DETAILS) {
                acc[trainingKey] = {
                  component: CallToAction,
                  componentOptions: {
                    icon: "file",
                    iconSize: 16,
                    theme: themes.SECONDARY,
                    shape: shapes.NONE
                  }
                };
              } else {
                acc[trainingKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: training[trainingKey]
                  }
                };
              }
              return acc;
            }, {})
          )
        : [];
    }
  },
  methods: {
    onTableRowClick(_, tableRowLevel) {
      this.$emit(DOMEvents.CLICK, {
        view: views.DETAILS,
        selectedCourse: this.trainingList[tableRowLevel]
      });
    }
  }
};
</script>
