import { userPermissionKeysDTO, userPermissionKeysAPIDTO } from "@/constants";

const makeUserPermissionsSubsetPayloadAPIDTO = (task = {}) => ({
  [userPermissionKeysAPIDTO.FILTER]: {
    [userPermissionKeysAPIDTO.PERMISSIONS]: [
      {
        [userPermissionKeysAPIDTO.CONTEXTS]: [
          {
            [userPermissionKeysAPIDTO.COMPANY_ID]:
              task[userPermissionKeysDTO.COMPANY_ID],
            [userPermissionKeysAPIDTO.TASK_ID]: task[userPermissionKeysDTO.ID]
          },
          ...(task?.outcomeActions?.map?.(({ outcome } = {}) => ({
            [userPermissionKeysAPIDTO.COMPANY_ID]:
              task[userPermissionKeysDTO.COMPANY_ID],
            [userPermissionKeysAPIDTO.TASK_ID]: task[userPermissionKeysDTO.ID],
            [userPermissionKeysAPIDTO.ACTION_ID]:
              outcome[userPermissionKeysDTO.ACTION_ID]
          })) || [])
        ]
      }
    ]
  }
});

export { makeUserPermissionsSubsetPayloadAPIDTO };
