<template>
  <div>
    <ThirdPartyTrainingList
      v-if="currentView === views.LIST"
      @click="handleChangeView"
      :training-list="thirdPartyTrainingList"
      :error-message="errorMessage"
      :isLoading="isLoadingTrainingList"
      :feedback-message="feedbackMessage"
    />
    <ThirdPartyTrainingDetails
      v-else-if="currentView === views.DETAILS"
      @click="handleChangeView"
      :course-id="courseId"
      :third-party-id="thirdPartyId"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ThirdPartyTrainingList from "@/organisms/ThirdPartyTrainingList/ThirdPartyTrainingList";
import ThirdPartyTrainingDetails from "@/organisms/ThirdPartyTrainingDetails/ThirdPartyTrainingDetails";
import { views, actionName } from "@/constants";

export default {
  name: "ThirdPartyTrainingWrapper",
  components: {
    ThirdPartyTrainingList,
    ThirdPartyTrainingDetails
  },
  data() {
    return {
      currentView: views.LIST,
      courseId: null,
      errorMessage: "",
      feedbackMessage: "",
      views
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (!this.thirdPartyTrainingList?.length) {
      this.fetchThirdPartyTrainingListWrapper();
    }
  },
  computed: {
    ...mapState({
      thirdPartyTrainingList: (state) =>
        state.thirdParty.thirdPartyTrainingList,
      isLoadingTrainingList: (state) => state.thirdParty.isLoadingTrainingList
    })
  },
  methods: {
    ...mapActions({
      fetchThirdPartyTrainingList:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_LIST
    }),
    async fetchThirdPartyTrainingListWrapper() {
      try {
        this.errorMessage = "";
        this.feedbackMessage =
          (await this.fetchThirdPartyTrainingList(this.thirdPartyId)) || "";
      } catch ({ message = "" }) {
        this.errorMessage = message;
      }
    },
    handleChangeView({ view, selectedCourse }) {
      this.currentView = view;
      this.courseId = selectedCourse?.id;
    }
  }
};
</script>
