<template>
  <a
    :class="['dropdown-menu-item', hasIcon && 'dropdown-menu-item--has-icon']"
    data-test-id="dropdown-menu-item"
    :href="path"
    :target="target"
    @click="handleClick"
  >
    <BaseIcon
      v-if="hasIcon"
      v-bind="iconOptions"
      data-test-id="dropdown-menu-item__icon"
    />
    <BaseText
      v-bind="combinedTextOptions"
      class="dropdown-menu-item__text"
      data-test-id="dropdown-menu-item__text"
    />
    <BaseIcon
      v-if="target && path"
      icon="arrow-up-right-from-square"
      :size="14"
    />
  </a>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "DropdownMenuItem",
  components: { BaseText, BaseIcon },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    path: {
      type: String,
      default: ""
    },
    target: {
      type: String,
      default: ""
    },
    textOptions: {
      type: Object,
      default: () => ({})
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    combinedTextOptions() {
      return {
        tag: "p",
        hasDefaultSpacingRemoved: true,
        ...this.textOptions
      };
    },
    hasIcon() {
      return !!this.iconOptions?.icon;
    }
  },
  methods: {
    handleClick(event) {
      if (!this.target || !this.path) {
        event.preventDefault();
      }
      this.$emit("click", this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-item {
  background-color: white;
  cursor: pointer;
  padding: $spacing12 $spacing20;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: $spacing12;

  &__text {
    flex: 1;
  }

  &--has-icon {
    padding: $spacing12 0 $spacing12 $spacing20;
  }

  &:hover {
    background-color: rgba($portal-blue, 0.9);
  }
}
</style>
