<template>
  <div class="users">
    <div class="users__wrapper">
      <b-modal
        v-model="isAddEditUserModalVisible"
        title="User Configuration"
        size="md"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
      >
        <b-overlay>
          <div>
            <b-form>
              <b-form-group label="First name*:">
                <b-form-input
                  size="md"
                  v-model="user.firstName"
                  :state="
                    getState('user.firstName.$dirty')
                      ? !getState('user.firstName.$error')
                      : null
                  "
                  id="first-name"
                  placeholder="John"
                  required
                />
                <b-form-invalid-feedback id="first-name">
                  Required
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Last name*:">
                <b-form-input
                  size="md"
                  v-model="user.lastName"
                  :state="
                    getState('user.lastName.$dirty')
                      ? !getState('user.lastName.$error')
                      : null
                  "
                  id="last-name"
                  placeholder="Doe"
                  required
                />
                <b-form-invalid-feedback id="last-name">
                  Required
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Email*:">
                <b-form-input
                  size="md"
                  v-model="user.email"
                  :disabled="isEditing"
                  :state="
                    getState('user.email.$dirty')
                      ? !getState('user.email.$error')
                      : null
                  "
                  id="email"
                  placeholder="john@doe.com"
                  required
                />
                <b-form-invalid-feedback
                  v-if="!getState('user.email.$model')"
                  id="email"
                >
                  Required
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="
                    getState('user.email.$model') &&
                    getState('user.email.$error')
                  "
                  id="email"
                >
                  Please enter a valid email
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Job title:">
                <b-form-input
                  v-model="user.jobTitle"
                  size="md"
                  placeholder="Manager"
                />
              </b-form-group>
              <b-form-group label="Country:">
                <v-select
                  placeholder="Country"
                  :maxHeight="220"
                  v-model="user.country"
                  label="name"
                  track-by="name"
                  :options="countries"
                >
                  <template slot="option" slot-scope="option">
                    <country-flag
                      v-if="option.icon"
                      :country="option.icon"
                      size="small"
                    />
                    <span>{{ "&nbsp;" + option.name }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group label="Roles:">
                <MultiSelect
                  id="user-role-type"
                  placeholder="Roles"
                  :options="roleOptions"
                  @change="onRolesChange"
                />
              </b-form-group>
            </b-form>
          </div>
        </b-overlay>
        <template v-slot:modal-footer>
          <b-button size="md" @click="resetModal" variant="outline-danger">
            Close
          </b-button>
          <b-button size="md" variant="outline-success" @click="onSubmit">
            {{ isEditing ? "Update" : "Add" }}
          </b-button>
        </template>
      </b-modal>
      <b-modal ref="modal-delete" title="Delete User" @ok="deleteCompanyUser">
        <p class="my-4">
          Are you sure you want to delete user
          <b>{{ fullName }}</b>
          ?
        </p>
      </b-modal>
      <b-modal
        ref="modal-toggle-company-user-state"
        title="Deactivate User"
        @ok="setCompanyUserState"
      >
        <p class="my-4">
          Are you sure you want to {{ getFutureUserStatus(user) }} user
          <b>{{ fullName }}</b>
          ?
        </p>
      </b-modal>
      <b-modal
        ref="modal-reset-password"
        title="Reset Password"
        @ok="resetPassword"
      >
        <p class="my-4">
          Are you sure you want to reset password for
          <b>{{ fullName }}</b>
          ?
        </p>
      </b-modal>
      <div>
        <b-navbar class="users__nav" toggleable="lg" type="dark">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button
                variant="outline-info"
                size="sm"
                class="mr-2"
                @click="addUserModal"
              >
                Add
              </b-button>
              <b-form-group class="mr-2">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    :placeholder="
                      $t('eb360.manageThirdParties.searchPlaceholder')
                    "
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button @click="clearFilter">
                      {{ $t("clearBtnText") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-button
                @click="fetchCompanyUsers"
                variant="outline-info"
                size="sm"
                class="mr-2"
              >
                <font-awesome-icon icon="sync" />
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
        <BaseLoader v-if="isLoading" />
        <b-row v-else>
          <b-col>
            <b-overlay rounded="sm">
              <div>
                <div class="users__operations-feedback-wrapper">
                  <BaseText
                    :text="operationFeedback"
                    theme="success-inverse"
                    data-test-id="users__operations-feedback"
                  />
                  <InputErrorMessage
                    :error="errorMessagesApi"
                    class="users__error-message-api"
                    data-test-id="users__error-message-api"
                  />
                </div>
                <div class="users__table-caption">Platform Users</div>
                <b-table
                  bordered
                  ref="table"
                  class="users__table"
                  :fields="staticFields"
                  :items="companyUsers"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDescending"
                  responsive="sm"
                  small
                  outlined
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{
                        width: field.width ? field.width + 'px' : '200px'
                      }"
                    />
                  </template>
                  <template v-slot:cell(status)="data">
                    <div>{{ getUserStatus(data.item) }}</div>
                  </template>
                  <template v-slot:cell(type)="data">
                    <div class="users__table-roles">
                      <BaseBadge
                        v-for="{ name } in data.item.roles"
                        :key="name"
                        :text="name"
                        :theme="themes.DARK_GREY_INVERSE"
                        :iconSpace="space.SMALL"
                        :textSpace="space.SMALL"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-dropdown
                      size="sm"
                      variant="outline"
                      toggle-class="text-decoration-none"
                      no-caret
                      dropleft
                    >
                      <template #button-content>
                        <font-awesome-icon icon="ellipsis-h" />
                      </template>
                      <b-dropdown-item-button
                        class="text-secondary"
                        @click="editUserModal(data.item)"
                      >
                        Edit
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        class="text-secondary"
                        @click="toogleUserState(data.item)"
                        :disabled="userId === data.item.memberId"
                      >
                        {{ toggleUserStatusDescription(data.item) }}
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        class="text-secondary"
                        @click="showResetPasswordModal(data.item)"
                        :disabled="userId === data.item.memberId"
                      >
                        Reset Password
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        class="text-secondary"
                        @click="showDeleteModal(data.item)"
                        :disabled="userId === data.item.memberId"
                      >
                        Delete
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                </b-table>
                <div v-if="totalRows === 0" class="text-center">
                  <b>No data available</b>
                </div>
                <b-row v-else>
                  <b-col cols="3"></b-col>
                  <b-col cols="6">
                    <p class="text-center">
                      <b-button
                        class="m-1"
                        size="sm"
                        @click="onSetPreviousPage"
                        :disabled="this.currentPage <= 1"
                        variant="light"
                      >
                        <font-awesome-icon icon="chevron-left" />
                      </b-button>
                      {{ $t("eb360.manageThirdParties.resultRecords") }}
                      <b>{{ fromRows }}</b>
                      {{ $t("eb360.manageThirdParties.toRecords") }}
                      <b>{{ toRows }}</b>
                      {{ $t("eb360.manageThirdParties.ofRecords") }}
                      <b>{{ totalRows }}</b>
                      <b-button
                        class="m-1 ml-2"
                        size="sm"
                        @click="onSetNextPage"
                        :disabled="this.currentPage >= this.lastPage"
                        variant="light"
                      >
                        <font-awesome-icon icon="chevron-right" />
                      </b-button>
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";
import {
  actionName,
  memberGroupId as memberGroupIdEnum,
  operations,
  roles as rolesEnum,
  sortOrder,
  space,
  themes,
  timers
} from "@/constants";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import { authService } from "@/services";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import { makeUUID } from "@/utils";
import { cloneDeep, debounce, get } from "lodash";

export default {
  name: "Users",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      staticFields: [
        { key: "name", label: "Name", sortable: true, width: "250" },
        { key: "email", label: "Email", sortable: true, width: "250" },
        { key: "status", label: "Status", sortable: false, width: "250" },
        { key: "type", label: "Roles", sortable: false, width: "250" },
        { key: "action", label: "Actions", sortable: false, width: "250" }
      ],
      roleOptions: [],
      user: this.makeUserObject(),
      sortBy: "name",
      filter: "",
      errorMessagesApi: "",
      operationFeedback: "",
      perPage: 50,
      currentPage: 1,
      fromRows: 0,
      toRows: 0,
      totalRows: 0,
      lastPage: 0,
      isSortDescending: true,
      isLoading: true,
      isEditing: false,
      isAddEditUserModalVisible: false,
      space,
      themes,
      debouncedFetchCompanyUsers: debounce(() => {
        this.currentPage = 1;
        this.fetchCompanyUsers();
      }, timers.MEDIUM)
    };
  },
  components: {
    InputErrorMessage,
    BaseBadge,
    BaseText,
    BaseLoader,
    MultiSelect
  },
  watch: {
    async currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchCompanyUsers();
      }
    },
    filter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.debouncedFetchCompanyUsers();
      }
    }
  },
  validations: {
    user: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      roles: {
        required
      }
    }
  },
  async created() {
    await this.fetchRoles();
    await this.fetchCompanyUsers();
  },
  mounted() {
    this.v$.$reset();
  },
  async beforeDestroy() {
    this.currentPage = 1;
    this.clearFilter();
    await this.fetchCompanyUsers();
  },
  computed: {
    ...mapState({
      companyUsers: (state) => state.company.companyUsers,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      countries: (state) => state.country.countries,
      roles: (state) => state.roles.roles
    }),
    fullName() {
      return `${this.user?.firstName} ${this.user?.lastName}`;
    },
    getRoleOptions() {
      return this.roles.map(({ name, id }) => ({
        text: name,
        value: id,
        id: makeUUID()
      }));
    }
  },
  methods: {
    get,
    ...mapActions({
      fetchCompanyUsersAction: actionName.COMPANY.FETCH_COMPANY_USERS,
      updateCompanyUserAction: actionName.COMPANY.UPDATE_COMPANY_USER,
      addCompanyUserAction: actionName.COMPANY.ADD_COMPANY_USER,
      deleteCompanyUserAction: actionName.COMPANY.DELETE_COMPANY_USER,
      fetchRolesAction: actionName.ROLES.FETCH_ROLES
    }),
    makeUserObject() {
      return {
        firstName: null,
        lastName: null,
        jobTitle: null,
        roles: this.roles?.filter(({ id }) => id === rolesEnum.BASIC) || [],
        country: null,
        memberGroupId: memberGroupIdEnum.ACTIVE,
        email: null
      };
    },
    makeRolesOptions() {
      const selectedValues = this.user.roles?.map(({ id }) => id) || [];
      const amendableValues = selectedValues.filter(
        (value) => value !== rolesEnum.BASIC
      );
      this.roleOptions = makeOptionsForMultiSelect(
        selectedValues,
        amendableValues,
        this.getRoleOptions
      );
    },
    onRolesChange({ eventType, value: selectedRoleId } = {}) {
      if (eventType === operations.ADD) {
        const role = cloneDeep(
          this.roles.find(({ id }) => id === selectedRoleId)
        );
        this.user.roles.push(role);
      } else if (eventType === operations.DELETE) {
        this.user.roles = this.user.roles.filter(
          ({ id }) => id !== selectedRoleId
        );
      }

      this.makeRolesOptions();
    },
    async fetchRoles() {
      try {
        this.errorMessagesApi = "";
        await this.fetchRolesAction();
      } catch ({ response }) {
        const description = response.status
          ? ` There was an error with status ${response.status}.`
          : "";
        this.errorMessagesApi = `Failed to retrieve the available roles.${description}`;
      }
    },
    setCompanyUserState() {
      if (this.user.memberGroupId === memberGroupIdEnum.ACTIVATED) {
        this.updateCompanyUser(this.makeDeactivateCompanyUserPayload());
      } else if (this.user.memberGroupId === memberGroupIdEnum.DEACTIVATED) {
        this.updateCompanyUser(this.makeActivateCompanyUserPayload());
      }
    },
    getFutureUserStatus({ memberGroupId }) {
      return memberGroupId === memberGroupIdEnum.DEACTIVATED
        ? "activate"
        : "deactivate";
    },
    getUserStatus({ memberGroupId }) {
      return memberGroupId === memberGroupIdEnum.DEACTIVATED
        ? "Deactivated"
        : "Active";
    },
    toggleUserStatusDescription({ memberGroupId }) {
      return memberGroupId === memberGroupIdEnum.DEACTIVATED
        ? "Activate"
        : "Deactivate";
    },
    getCountry(countryId) {
      return this.countries.find(({ id }) => id === parseInt(countryId));
    },
    showDeleteModal(data) {
      this.user = cloneDeep(data);
      this.$refs["modal-delete"].show();
    },
    showResetPasswordModal(data) {
      this.user = cloneDeep(data);
      this.$refs["modal-reset-password"].show();
    },
    clearFilter() {
      this.filter = "";
    },
    onSetPreviousPage() {
      this.currentPage--;
    },
    onSetNextPage() {
      this.currentPage++;
    },
    addUserModal() {
      this.isEditing = false;
      this.isAddEditUserModalVisible = true;
      this.user = this.makeUserObject();
      this.makeRolesOptions();
    },
    editUserModal(data) {
      this.user = cloneDeep(data);
      this.user.country = this.getCountry(this.user.countryId);
      this.makeRolesOptions();
      this.isEditing = true;
      this.isAddEditUserModalVisible = true;
    },
    onSubmit() {
      this.v$.user?.$touch();
      if (!this.v$.user.$errors?.length) {
        if (this.isEditing) {
          this.updateCompanyUser(this.makeUpdateCompanyUserPayload());
        } else {
          this.addCompanyUser();
        }

        this.isLoading = false;
      }
    },
    makeFetchCompanyUserPayload() {
      return {
        filter: this.filter,
        sort: `${this.sortBy}|${sortOrder.DESCENDING}`,
        per_page: this.perPage,
        page: this.currentPage,
        is_active: 0
      };
    },
    resetHttpFeedback() {
      this.operationFeedback = "";
      this.errorMessagesApi = "";
      this.isLoading = true;
    },
    async fetchCompanyUsers() {
      try {
        this.resetHttpFeedback();
        const { data = {} } = await this.fetchCompanyUsersAction(
          this.makeFetchCompanyUserPayload()
        );
        this.fetchCompanyUsersSuccess(data);
      } catch ({ response }) {
        this.errorMessagesApi = this.fetchCompanyUsersFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    fetchCompanyUsersFailure({ status } = {}) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to retrieve the company users.${description}`;
    },
    fetchCompanyUsersSuccess(data) {
      this.lastPage = data.lastPage;
      this.fromRows = data.from || 0;
      this.toRows = data.to || 0;
      this.totalRows = data.total;
    },
    makeUpdateCompanyUserPayload() {
      return {
        memberId: this.user.memberId,
        companyId: this.companyId,
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        jobTitle: this.user.jobTitle,
        country: this.user.country,
        member_group_id: this.user.memberGroupId,
        roles: this.user.roles.map(({ id }) => id)
      };
    },
    async updateCompanyUser(payload) {
      try {
        this.resetHttpFeedback();
        await this.updateCompanyUserAction(payload);
        this.resetModal();
        await this.fetchCompanyUsers();
        this.operationFeedback = "User has been updated successfully.";
      } catch ({ response }) {
        this.errorMessagesApi = this.updateCompanyUserFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    updateCompanyUserFailure({ status } = {}) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to update the user.${description}`;
    },
    makeAddCompanyUserPayload() {
      return {
        memberId: this.userId,
        companyId: this.companyId,
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        email: this.user.email,
        roles: this.user.roles.map(({ id }) => id),
        member_group_id: this.user.memberGroupId,
        jobTitle: this.user.jobTitle,
        country: this.user.country
      };
    },
    async addCompanyUser() {
      try {
        this.resetHttpFeedback();
        await this.addCompanyUserAction(this.makeAddCompanyUserPayload());
        this.resetModal();
        await this.fetchCompanyUsers();
        this.operationFeedback = "User has been created successfully.";
      } catch ({ response }) {
        this.errorMessagesApi = this.addCompanyUserFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    addCompanyUserFailure({ status } = {}) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to create an user.${description}`;
    },
    async deleteCompanyUser() {
      try {
        this.resetHttpFeedback();
        const { data = {} } = await this.deleteCompanyUserAction({
          memberId: this.user.memberId
        });
        if (data.fakeHttpStatus === 1) {
          this.resetModal();
          await this.fetchCompanyUsers();
          this.operationFeedback = "User has been deleted successfully.";
        } else {
          this.errorMessagesApi = [
            "Failed to delete user.",
            `Details: ${data.message}`
          ];
        }
      } catch ({ response }) {
        this.errorMessagesApi = this.deleteCompanyUserFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    deleteCompanyUserFailure({ status } = {}) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to delete user.${description}`;
    },
    resetModal() {
      this.v$.$reset();
      this.user = this.makeUserObject();
      this.isEditing = false;
      this.isAddEditUserModalVisible = false;
    },
    toogleUserState(data) {
      this.user = cloneDeep(data);
      this.$refs["modal-toggle-company-user-state"].show();
    },
    makeBaseStateCompanyUserPayload() {
      return {
        memberId: this.user.memberId,
        firstname: this.user.firstName,
        lastname: this.user.lastName
      };
    },
    makeDeactivateCompanyUserPayload() {
      return {
        ...this.makeBaseStateCompanyUserPayload(),
        member_group_id: memberGroupIdEnum.DEACTIVATED
      };
    },
    makeActivateCompanyUserPayload() {
      return {
        ...this.makeBaseStateCompanyUserPayload(),
        member_group_id: memberGroupIdEnum.ACTIVATED
      };
    },
    async resetPassword() {
      try {
        this.resetHttpFeedback();
        await authService.forgotPassword({
          userId: this.userId,
          email: this.user.email
        });
        this.operationFeedback =
          "A link to reset the password has been sent to the company user email address";
      } catch ({ response }) {
        this.errorMessagesApi = this.resetPasswordFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    resetPasswordFailure({ status }) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to reset password.${description}`;
    },
    getState(propertyPath) {
      return this.get(this.v$, propertyPath);
    }
  }
};
</script>

<style lang="scss" scoped>
.users {
  &__wrapper {
    height: calc(100vh - 130px);
    overflow-y: scroll;
    padding: 0 20px 0 0;
    text-align: left;
  }

  &__nav {
    padding-left: 0;
    padding-right: 0;
  }

  &__table {
    &-roles {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
    }

    &-caption {
      text-align: left;
      background: $ghost-white;
      font-size: 18px;
      font-weight: 700;
      padding: 5px;
    }
  }

  &__operations-feedback-wrapper {
    text-align: left;
  }
}
</style>
