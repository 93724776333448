import { createStore } from "vuex";
import uid from "uid";
import _ from "lodash";
import VuexPersistence from "vuex-persist";
import platformSettings from "@/store/platformSettings/platformSettings.store";
import userSettings from "@/store/userSettings/userSettings.store";
import user from "@/store/user/user.store";
import auth from "@/store/auth/auth.store";
import thirdParty from "@/store/thirdParty/thirdParty.store";
import actions from "@/store/actions/actions.store";
import roles from "@/store/roles/roles.store";
import company from "@/store/company/company.store";
import country from "@/store/country/country.store";
import esg from "@/store/esg/esg.store";
import customAttributes from "@/store/customAttributes/customAttributes.store";
import version from "@/store/version/version.store";
import userEmailTemplates from "@/store/userEmailTemplates/userEmailTemplates.store";
import uboEntity from "@/store/uboEntity/uboEntity.store";
import loader from "@/store/loader/loader.store";
import posthog from "@/store/posthog/posthog.store";
import permissions from "@/store/permissions/permissions.store";
import onboardingTasks from "@/store/onboardingTasks/onboardingTasks.store";
import expectedData from "@/store/expectedData/expectedData.store";
import taskTemplates from "@/store/taskTemplates/taskTemplates.store";
import tasks from "@/store/tasks/tasks.store";
import userTraining from "@/store/userTraining/userTraining.store";
import languages from "@/store/languages/languages.store";
import product from "@/store/product/product.store";
import onboardingClient from "@/store/onboardingClient/onboardingClient.store";

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    user: state.user,
    auth: state.auth,
    company: state.company,
    version: state.version,
    permissions: state.permissions,
    onboardingClient: state.onboardingClient
  }),
  storage: window.localStorage
});

export default createStore({
  state: {
    isTpEditModal: false,
    dueDiligenceRemediationMonitoringTabLazyLoad: true,
    auditTrailTabLazyLoad: true,
    documentsTabLazyLoad: true,
    questionnairesPoliciesTrainingTTabLazyLoad: true,
    isSidebarStatus: false,
    hola: "holalala",
    isDisabledTranslation: false,
    authToken: null,
    selectedLanguage: "english",
    rightAlign: false,
    getSelectedSectionIndex: undefined,
    getSelectedFieldIndex: undefined,
    isLoading: false,
    isLoadingMulti: false,
    toggleAllCoppalse: true,
    tempForm: {},
    userForm: {},
    selectedAnswersForExams: [],
    userFormSubmitArray: [],
    getUserFormSubmitArray: [],
    validFields: true,
    progressValue: 0,
    progressMaxValue: 0,
    registerData: {},
    pdfForms: [],
    selectedCompany: null,
    form1: {
      version: 1.0,
      formId: null,
      formStatus: 1, // 1=draft, 2=published, 3=expired
      formDefaultLanguage: "english",
      formVersion: null,
      formTitle: {
        english: "Form Title"
      },
      formDescription: {
        english: "Form Description"
      },
      formIntroText: {
        english: null
      },
      formType: 1, // 1= Questionaire, 2= Survey, 3=Training, 4= Exam
      formTranslations: ["English"],
      formSection: [
        {
          type: "section",
          id: uid(),
          index: 0,
          name: "",
          isRepeatable: false,
          sectionTitle: {
            english: "Section 1 Title"
          },
          formElements: []
        }
      ],
      formJSCode: null,
      formCSS: null,
      formLayout: "vertical", // 'tabs',
      formPassword: null
    },

    // Configurable PRI
    isLoadingPriData: false,
    allPriItems: [],
    clientAllPriItems: [],
    clientAllPriOptions: [],
    priConfigrationforms: {
      negativeMedia: {
        scoring: {
          weighting: {
            lowerUpperLimit: 30,
            mediumUpperLimit: 32,
            ageOfArticleWeightingValue: null,
            siteTypeWeightingValue: null,
            nlpMatchWeightingValue: null,
            keywordCountTotalWeightingValue: null
          },
          ageOfArticleObject: {
            ageOfArticle: true,
            ageOfArticleToValue: [],
            ageOfArticleScoreValue: []
          },
          siteTypeObject: {
            siteType: true,
            localSiteType: null,
            regionalSiteType: null,
            globalSiteType: null,
            unkownSiteType: null
          },
          keywordCountObject: {
            keywordCountTotal: true,
            lowerUpperLimit: 30,
            mediumUpperLimit: 32
          },
          nlpMatchObject: {
            nlpMatch: true,
            matchNlpValue: null
          }
        },
        categories: []
      },
      futureRisks: {
        scoring: {
          weighting: {
            lowerUpperLimit: 30,
            mediumUpperLimit: 32,
            ageOfArticleWeightingValue: null,
            siteTypeWeightingValue: null,
            nlpMatchWeightingValue: null,
            keywordCountTotalWeightingValue: null
          },
          ageOfArticleObject: {
            ageOfArticle: true,
            ageOfArticleToValue: [],
            ageOfArticleScoreValue: []
          },
          siteTypeObject: {
            siteType: true,
            localSiteType: null,
            regionalSiteType: null,
            globalSiteType: null,
            unkownSiteType: null
          },
          keywordCountObject: {
            keywordCountTotal: true,
            lowerUpperLimit: 30,
            mediumUpperLimit: 32
          },
          nlpMatchObject: {
            nlpMatch: true,
            matchNlpValue: null
          }
        },
        categories: []
      },
      sanctions: {
        isIDD: false,
        isIddPlus: false,
        indicatorIsHigh: false,
        indicatorIsOverallHigh: false,
        eventCategoryCodeSelected: null,
        eventSubCategoryCodeSelected: null,
        lowerUpperLimit: 30,
        mediumUpperLimit: 32,
        setMatchScores: null,
        CVIP: false,
        riskScore: false,
        textMatch: false,
        pepMatch: false,
        entityMatch: false,
        identifierMatch: false,
        CVIPCategoryValue: [],
        CVIPScoreValue: [],
        riskScoreCategoryValue: [2],
        riskScoreScoreValue: [],
        pepMatchInput: null,
        processScoreCategoryValue: [2],
        processScoreValue: [],
        similarTextCategoryScoreValue: [2],
        similarTextScoreValue: [],
        tokenSortCategoryScoreValue: [2],
        tokenSortScoreValue: [],
        tokenSortRatioCategoryScoreValue: [2],
        tokenSortRatioScoreValue: [],
        soundEx: null,
        identifiersItems: [],
        validInputField: false
      },
      countryRules: {
        cpiIndex: false,
        activeSanctions: false,
        activeSanctionsScoring: null,
        lowerUpperLimit: 30,
        mediumUpperLimit: 32
      },
      weighting: {
        defaultIndicators: [],
        customIndicators: [],
        lowerUpperLimit: 30,
        mediumUpperLimit: 75
      },
      customIndicationsSettings: {
        customIndicatorsTabs: []
      },
      openCorporate: {
        companyNumber: false,
        partialSearch: false,
        globalSearch: false,
        stopWords: false,
        stopWordsList: [],
        veryRare: null,
        rare: null,
        common: null,
        veryCommon: null,
        disableRemovalText: false
      },
      publicListings: {
        matchExact: false,
        matchPartial: false,
        matchSimilar: false,
        stopWords: false,
        stopWordsList: [],
        disableRemovalText: false
      }
    },

    // --------------------- store for Remediation ----------------------------------------
    totalAlerts: 0,
    totalPendingAlerts: 0,
    totalRemidiatedAlerts: 0,
    totalPositiveAlerts: 0,
    totalNegativeAlerts: 0,
    secUniqueCount: 0,

    // Employees Variable
    approvalModalCategories: [],
    settingSelectedLanguage: [
      {
        text: "English",
        value: "1"
      }
    ],

    // for prefill data
    isPrefillDataFound: false, // eb360
    disabledButtonsToolTipMsg:
      "Please note this functionality is currently not provisioned on your account. Should you be interested in enabling this functionality please contact <a href='mailto:support@ethixbase.com'>support@ethixbase.com</a>",
    DDQquestionnairesTabLazyLoad: true,
    archivedThirdPartyTabLazyLoad: true,
    companyDetail: {},

    // third-party-profile-component
    tabIndexProfilePage: 0,
    tabIndexRiskSummary: 0,
    riskSummaryTabLazyLoad: true,
    riskSummaryOverviewTabLazyLoad: true,
    riskSummaryApriTabLazyLoad: true, //  dueDiligenceRemediationMonitoringTabLazyLoad: true,
    questionnairesPoliciesTrainingTabLazyLoad: true,
    thirdPartyDetailsAssocTabLazyLoad: true,
    contactsTabLazyLoad: true,
    associatesTabLazyLoad: true,
    monitoringTabLazyLoad: true,
    newSidebarData: null,
    questionareTitles: null,
    sidebarMenu: null,
    dueDiligenceTabLazyLoad: true,
    archivedDueDiligenceTabLazyLoad: true
  },
  getters: {
    form1: (state) => state.form1,
    allFormQuestions(state) {
      const allowedInputTypes = [
        "comboSelectProgressive",
        "radio",
        "checkbox",
        "select"
      ];
      return state.form1.formSection.reduce(
        (allQuestions, currentFormSection) => {
          currentFormSection.formElements.forEach((sectionElement) => {
            if (allowedInputTypes.includes(sectionElement.inputType)) {
              allQuestions.push({
                label: sectionElement.label?.english,
                element: sectionElement
              });
            }
          });

          return allQuestions;
        },
        []
      );
    },
    selectedAnswersForExams: (state) => state.selectedAnswersForExams,
    validFields: (state) => state.validFields,
    progressValue: (state) => state.progressValue,
    progressMaxValue: (state) => state.progressMaxValue,
    registerData: (state) => state.registerData,
    userFormSubmitArray: (state) => state.userFormSubmitArray,
    getUserFormSubmitArray: (state) => state.getUserFormSubmitArray,
    toggleAllCoppalse: (state) => state.toggleAllCoppalse,
    selectedLanguage: (state) => state.selectedLanguage,
    isLoading: (state) => state.isLoading,

    isLoadingMulti: (state) => state.isLoadingMulti,
    getSelectedSectionIndex: (state) => state.getSelectedSectionIndex,
    getSelectedFieldIndex: (state) => state.getSelectedFieldIndex,
    userForm: (state) => state.userForm,
    isLoadingPriData: (state) => state.isLoadingPriData,
    allPriItems: (state) => state.allPriItems,
    clientAllPriItems: (state) => state.clientAllPriItems,
    clientAllPriOptions: (state) => state.clientAllPriOptions,
    priConfigrationforms: (state) => state.priConfigrationforms,
    settingSelectedLanguage: (state) => state.settingSelectedLanguage,
    approvalModalCategories: (state) => state.approvalModalCategories,
    disabledButtonsToolTipMsg: (state) => state.disabledButtonsToolTipMsg,
    DDQquestionnairesTabLazyLoad: (state) => state.DDQquestionnairesTabLazyLoad,
    archivedThirdPartyTabLazyLoad: (state) =>
      state.archivedThirdPartyTabLazyLoad,
    companyDetail: (state) => state.companyDetail, // profile
    riskSummaryTabLazyLoad: (state) => state.riskSummaryTabLazyLoad,
    riskSummaryOverviewTabLazyLoad: (state) =>
      state.riskSummaryOverviewTabLazyLoad,
    riskSummaryApriTabLazyLoad: (state) => state.riskSummaryApriTabLazyLoad,
    tabIndexProfilePage: (state) => state.tabIndexProfilePage,
    tabIndexRiskSummary: (state) => state.tabIndexRiskSummary,
    dueDiligenceRemediationMonitoringTabLazyLoad: (state) =>
      state.dueDiligenceRemediationMonitoringTabLazyLoad,
    auditTrailTabLazyLoad: (state) => state.auditTrailTabLazyLoad,
    documentsTabLazyLoad: (state) => state.documentsTabLazyLoad,
    questionnairesPoliciesTrainingTabLazyLoad: (state) =>
      state.questionnairesPoliciesTrainingTabLazyLoad,
    thirdPartyDetailsAssocTabLazyLoad: (state) =>
      state.thirdPartyDetailsAssocTabLazyLoad,
    contactsTabLazyLoad: (state) => state.contactsTabLazyLoad,
    associatesTabLazyLoad: (state) => state.associatesTabLazyLoad,
    monitoringTabLazyLoad: (state) => state.monitoringTabLazyLoad,
    newSidebarData: (state) => state.newSidebarData,
    sidebarMenu: (state) => state.sidebarMenu,
    dueDiligenceTabLazyLoad: (state) => state.dueDiligenceTabLazyLoad,
    archivedDueDiligenceTabLazyLoad: (state) =>
      state.archivedDueDiligenceTabLazyLoad
  },
  mutations: {
    TOGGLE_LOADER: (state, duration) => {
      state.isLoading = true;
      setTimeout(() => {
        state.isLoading = false;
      }, duration);
    },
    TOGGLE_ALL_COLLAPSE: (state) => {
      state.toggleAllCoppalse = !state.toggleAllCoppalse;
    },
    // field actions
    ADD_FIELD: (state, payload) => {
      let index = payload.sectionIndex;
      state.form1.formSection[index].formElements.push(payload);
    },
    ADD_TEMP_FORM: (state, payload) => {
      state.tempForm = payload;
    },
    CLONE_FIELD: (state, payload) => {
      let index = payload.sectionIndex;
      let id = uid();
      let newField;
      newField = {
        ...payload.field,
        id
      };
      state.form1.formSection[index].formElements.push(newField);
    },
    REMOVE_FIELD: (state, payload) => {
      let sectionIndex = payload.sectionIndex;
      let fieldIndex = payload.fieldIndex;
      state.form1.formSection[sectionIndex].formElements.splice(fieldIndex, 1);
    },
    UPDATE_FORM: (state, payload) => {
      state.form1 = payload;
      return state.form1;
    },
    // section actions
    ADD_SECTION: (state, payload) => {
      state.form1.formSection.push(payload);
    },
    CLONE_SECTION: (state, payload) => {
      let section = _.cloneDeep(payload);
      let lang = state.selectedLanguage;
      section.sectionTitle[lang] = `${section.sectionTitle[lang]} copy`;
      let index = state.form1.formSection.length;
      let id = uid();
      let newSection;
      newSection = {
        ...section,
        id,
        index
      };
      newSection.formElements.forEach((el) => {
        el.id = uid();
      });
      state.form1.formSection.push(newSection);
    },
    REMOVE_SECTION: (state, sectionIndex) => {
      state.form1.formSection.splice(sectionIndex, 1);
      state.form1.formSection.forEach((section) => {
        if (section.index > sectionIndex) {
          section.index -= 1;
        }
      });
    },
    // not in use
    ADD_SECTON_FIELD: (state, payload) => {
      state.form1.formSection[0].formElements.push(payload);
    },
    // language action
    SET_LANGUAGE: (state, payload) => {
      state.selectedLanguage = payload;
    },
    SET_FORM_DEFAULT_LANGUAGE: (state, payload) => {
      state.form1.formDefaultLanguage = payload;
    },
    CLONE_SECTION_CONTENT: (state, payload) => {
      let _id = uid();
      let newField;
      newField = {
        ...payload.field,
        _id
      };
      state.form1.formSection[payload.index].fields.push(newField);
    },
    // action for address form
    CLONE_ADDRESS_FIELD: (state, payload) => {
      let sectionIndex = payload.index;
      let elementIndex = payload.index2;
      let _id = uid();
      let newField;
      newField = {
        ...payload.field,
        _id
      };
      state.form1.formSection[sectionIndex].formElements[
        elementIndex
      ].fields.push(newField);
    },
    SET_FORM_TRANSLATIONS: (state, payload) => {
      state.form1.formTranslations = payload;
    },
    REMOVE_ADDRESS_FIELD: (state, payload) => {
      let sectionIndex = payload.index;
      let elementIndex = payload.index2;
      let fieldIndex = payload.index3;

      state.form1.formSection[sectionIndex].formElements[
        elementIndex
      ].fields.splice(fieldIndex, 1);
    },
    // index action
    SET_SECTION_INDEX: (state, payload) => {
      //
      state.getSelectedSectionIndex = payload;
    },
    SET_FIELD_INDEX: (state, payload) => {
      //
      state.getSelectedFieldIndex = payload;
    },
    UPDATE_FORM_JS_CODE: (state, payload) => {
      state.form1.formJSCode = payload;
    },
    UPDATE_FORM_CSS: (state, payload) => {
      state.form1.formCSS = payload;
    },
    SET_FIELD_VALIDATION: (state, payload) => {
      state.form1.formSection[payload.sectionIndex].formElements[
        payload.sectionFieldIndex
      ].validation = payload;
    },
    setTabIndexProfilePage(state, value) {
      state.tabIndexProfilePage = value;
    },
    setSidebarMenu(state, value) {
      state.sidebarMenu = value;
    }
  },
  actions: {
    // for fields
    removeField: (constext, fieldId) => {
      constext.commit("REMOVE_FIELD", fieldId);
    },
    cloneField: (constext, payload) => {
      constext.commit("CLONE_FIELD", payload);
    },
    // for sections
    removeSection: (constext, fieldId) => {
      constext.commit("REMOVE_SECTION", fieldId);
    },
    cloneSection: (constext, payload) => {
      constext.commit("CLONE_SECTION", payload);
    },
    // for language
    setLanguage: (constext, payload) => {
      constext.commit("SET_LANGUAGE", payload);
    },
    setFormDefaultLanguage: (constext, payload) => {
      constext.commit("SET_FORM_DEFAULT_LANGUAGE", payload);
    },
    repeatSectionContentStore: (constext, payload) => {
      constext.commit("CLONE_SECTION_CONTENT", payload);
    }, // address form
    cloneAddressField: (constext, payload) => {
      constext.commit("CLONE_ADDRESS_FIELD", payload);
    },
    removeAddressField: (constext, payload) => {
      constext.commit("REMOVE_ADDRESS_FIELD", payload);
    },
    // to get index
    setSectionIndex: (constext, payload) => {
      constext.commit("SET_SECTION_INDEX", payload);
    },
    setfieldIndex: (constext, payload) => {
      constext.commit("SET_FIELD_INDEX", payload);
    },
    updateField: (constext, payload) => {
      constext.commit("UPDATE_FIELD", payload);
    },
    updateFormJSCode: (constext, payload) => {
      constext.commit("UPDATE_FORM_JS_CODE", payload);
    },
    updateFormCSS: (constext, payload) => {
      constext.commit("UPDATE_FORM_CSS", payload);
    },
    setFieldValidation: (constext, payload) => {
      constext.commit("SET_FIELD_VALIDATION", payload);
    }
  },
  modules: {
    platformSettings,
    userSettings,
    user,
    auth,
    thirdParty,
    actions,
    roles,
    company,
    country,
    esg,
    customAttributes,
    version,
    userEmailTemplates,
    uboEntity,
    loader,
    posthog,
    onboardingTasks,
    expectedData,
    permissions,
    taskTemplates,
    tasks,
    userTraining,
    languages,
    product,
    onboardingClient
  },
  plugins: [vuexLocal.plugin]
});
