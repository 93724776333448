<template id="template-select">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation && fieldData.validation.required == 1
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <v-select
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              label="name"
              :class="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                isFocus &&
                (!selectedCountry1 || selectedCountry1.length == 0) &&
                $store.state.rightAlign
                  ? 'text-right validation-color'
                  : fieldData.validation &&
                    fieldData.validation.required == 1 &&
                    isFocus &&
                    (!selectedCountry1 || selectedCountry1.length == 0)
                  ? 'text-left validation-color'
                  : $store.state.rightAlign
                  ? 'text-right'
                  : 'text-left'
              "
              :options="renderOptions"
              value="value"
              v-model="selectedCountry1"
              @update:modelValue="checkCondition"
              @option:deselected="onClearFirstOpt"
            >
              <template slot="option" slot-scope="option">
                <country-flag
                  v-if="option.icon"
                  :country="option.icon"
                  size="small"
                />
                <span>{{ "&nbsp;" + option.name }}</span>
              </template>
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
            <label
              v-if="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                isFocus &&
                (!selectedCountry1 || selectedCountry1.length == 0)
              "
              :class="
                $store.state.rightAlign
                  ? 'text-right text-danger'
                  : 'text-left text-danger'
              "
              >{{ fieldData.validationMessage[selectedLanguage] }}</label
            >
          </b-form-group>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        cancel-title="Close"
        @ok="onSaveData"
        ok-title="save"
        size="xl"
        v-model="showSettings"
        title="Select Picker Advanced Settings"
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          @label-text="label = $event"
          @helper-text="helperText = $event"
          :sectionIndex="sectionIndex"
          :isPreview="isPreview"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import vSelect from "vue-select";
import MenuButtons from "../menuButtons";
import AdvanceSelectSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent.vue";
import CountryCodes from "../../utils/countryCodesRiskScore.json";
import CountryCodesMsqv3 from "../../utils/countryCodesRiskScoreMsqv3.json";
import { mapGetters, mapMutations, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "lodash";
import { routeKeys, routeNames } from "@/constants";

export default {
  name: "CountriesSelectGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      helperText: undefined,
      selectedListTypeIndex: 0,
      isRequired: 1,
      name: "",
      renderOptions: CountryCodes[0].countries,
      selectedCountry: [],
      selectedCountry1: null,
      isIndigenous: 0
    };
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (!this.selectedCountry1 || this.selectedCountry1.length == 0) {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          } else {
            this.isFocus = false;
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });

    EventBus.on("onRestoreCountry", (e) => {
      if (e.isHiddenElement == 1 && e.elementId === this.fieldData.id) {
        (this.selectedCountry1 = null), (this.fieldData.isInValid = false);
        this.fieldData.validation.required = 0;
      }
    });
  },

  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse", "userFormSubmitArray"]),
    isMSQv3OrSupplyChainDDQ() {
      return (
        this.form1.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 3") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("lieferkettengesetz") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("human rights questionnaire") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 4")
      );
    }
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },

    selectedListTypeIndex(val) {
      this.renderOptions = this.isMSQv3OrSupplyChainDDQ
        ? CountryCodesMsqv3[val].countries
        : CountryCodes[val].countries;
    }
  },

  mounted() {
    this.isRequired = this.fieldData.validation.required;
    if (
      this.fieldData.selectedListTypeIndex &&
      this.fieldData.selectedListTypeIndex.value == 1
    ) {
      setTimeout(() => {
        this.sectionCondition();
      }, 1500);
    }
    if (
      this.fieldData.selectedListTypeIndex &&
      this.fieldData.selectedListTypeIndex.value
    ) {
      let index = parseInt(this.fieldData.selectedListTypeIndex.value);
      this.renderOptions = this.isMSQv3OrSupplyChainDDQ
        ? CountryCodesMsqv3[index].countries
        : CountryCodes[index].countries;
    }
    if (
      [
        routeNames[routeKeys.USER_FORM_PAGE],
        routeNames[routeKeys.USER_FORM],
        "MyCompanyView"
      ].includes(this.$route.name)
    ) {
      this.setFieldValue();
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),
    getData(data) {
      this.$emit("update-form-settings", data);
    },

    checkCondition(value) {
      this.selectedCountry1 = value;
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;
      this.setValue(value);
      EventBus.emit("refreshProductList", value?.length);
      this.restoreSecfourCountryScore();
    },
    onClearFirstOpt() {
      let sections = this.form1.formSection;
      let sectionFour = sections.find(
        (sec) => sec.sectionTitle.english == "Business Operations"
      );
      let sectionFive = sections.find(
        (sec) => sec.sectionTitle.english === "Indigenous People"
      );
      let sectionSeven = sections.find(
        (sec) =>
          sec.sectionTitle.english == "Services/Goods (Additional Question)"
      );

      if (!this.selectedCountry1.length) {
        if (sectionFive) {
          for (let ele of sectionFive.formElements) {
            ele.selected = null;
            if (ele.inputType === "checkbox") {
              EventBus.emit("onResetSelectedValues", ele);
            }
          }
        }

        if (sectionSeven) {
          let sec = this.form1.formSection[sectionSeven.index];
          Object.assign(sec, (sec.isHidden = true));
          EventBus.emit(
            "sectionCheck",
            this.form1.formSection[sectionSeven.index]
          );
        }

        this.$store.state.userFormSubmitArray.find((s4) => {
          if (sectionFour.id == s4.sectionId) {
            s4.elements = [];
          }
        });
        this.$store.state.getUserFormSubmitArray.find((s4) => {
          if (sectionFour.id == s4.sectionId) {
            s4.elements = [];
          }
        });
      }
    },
    restoreSecfourCountryScore() {
      let sections = this.form1.formSection;
      let sectionFour = sections.find(
        (sec) => sec.sectionTitle.english == "Business Operations"
      );

      this.$store.state.userFormSubmitArray.find((s4) => {
        if (sectionFour?.id == s4?.sectionId) {
          for (let ele of s4.elements) {
            if (ele.risk == "Low" && ele.score == 13) {
              ele.score = 0;
            }
          }
        }
      });
    },

    sectionCondition() {
      let indigenousCountr = null;
      // for section conditions
      let sections = this.form1.formSection;

      let indigenousSection1 = sections.find(
        (sec) => sec.sectionTitle.english == "Indigenous People"
      );

      sections.find(
        (sec) =>
          sec.sectionTitle.english == "Services/Goods (Additional Question)"
      );

      sections.find(
        (sec) => sec.sectionTitle.english == "Details of Services/Goods"
      );

      sections.find((sec) => sec.sectionTitle.english == "Business Operations");

      if (this.selectedCountry1 && this.selectedCountry1.length) {
        indigenousCountr = this.selectedCountry1.find(
          (ctr) => ctr.indigenious == 1
        );
      }
      if (
        this.form1.formSection[this.sectionIndex].sectionTitle.english ==
        "Business Operations"
      ) {
        if (indigenousCountr) {
          // if (indigenousSection1) {
          this.isIndigenous = 1;
          let sec = this.form1.formSection[indigenousSection1.index];
          Object.assign(sec, (sec.isHidden = false));
          EventBus.emit(
            "sectionCheck",
            this.form1.formSection[indigenousSection1.index]
          );
          // this.form1.formSection[indigenousSection1.index].formElements.map(
          // (ele) => {
          // ele.validation.required = 1;
          // ele.isInValid = true;
          // }
          // );
          this.form1.formSection[
            indigenousSection1.index
          ].formElements[0].isHidden = false;
        } else {
          this.isIndigenous = 0;
          let sec = this.form1.formSection[indigenousSection1.index];
          Object.assign(sec, (sec.isHidden = true));
          EventBus.emit(
            "sectionCheck",
            this.form1.formSection[indigenousSection1.index]
          );
          this.form1.formSection[indigenousSection1.index].formElements.map(
            (ele) => {
              if (
                !this.form1.formTitle[this.selectedLanguage]
                  .toLowerCase()
                  .includes("lieferkettengesetz") &&
                !this.form1.formTitle[this.selectedLanguage]
                  .toLowerCase()
                  .includes("human rights questionnaire")
              ) {
                ele.validation.required = 0;
              }
              ele.isInValid = false;
              let foundEle = this.$store.state.userFormSubmitArray.find(
                (ele1) => ele1.elementId == ele.id
              );
              if (foundEle) {
                // to clear data when country select is removed
                foundEle.elements = [];
              }
            }
          );
          // this.form1.formSection[indigenousSection.index].isHidden = false;
        }
      }
      // }

      // this.checkExistanceCountry(indigenousSection1);
    },

    checkExistanceCountry() {
      let sections = this.form1.formSection;
      let compareSection = sections.find(
        (sec) => sec.sectionTitle.english == "Business Operations"
      );

      let compareFromSection = sections.find(
        (sec) => sec.sectionTitle.english == "Details of Services/Goods"
      );

      compareFromSection.formElements.find((a) => a.inputType == "countries");

      let eleNew = this.$store.state.userFormSubmitArray.find(
        (ele) => ele.elementId == compareSection.formElements[0].id
      );

      if (eleNew && eleNew.elements.length) {
        let highestScore = eleNew.dataAttributes.find(
          (atr) => atr.dataAttributeKey == "highest_score"
        );
        if (highestScore) {
          highestScore.dataAttributeValue = 0;
        }
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a && a.elementId && a.elements && a.elements.length) {
            if (a.elementId === this.fieldData.id) {
              a.elements.forEach((el) => {
                this.selectedCountry1 = el.postValue || null;
                this.isIndigenous = el.isIndigenous || 0;
              });
              this.sectionCondition();
            }
          }
        });
      if (this.selectedCountry1 && this.selectedCountry1.length) {
        this.setValue(this.selectedCountry1);
      }
    },

    setValue(e) {
      const registerData = _.clone(this.$store.state.registerData);
      let maxXObject;
      if (
        [
          routeNames[routeKeys.USER_FORM_PAGE],
          routeNames[routeKeys.USER_FORM],
          "MyCompanyView"
        ].includes(this.$route.name)
      ) {
        if (e) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;

        if (
          this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect &&
          this.selectedCountry1.length
        ) {
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              if (this.selectedCountry1) {
                let xMax = Math.max(
                  ...Array.from(this.selectedCountry1, (o) => o.riskScore)
                ); // to calculate max risk score
                maxXObject = this.selectedCountry1.find(
                  (o) => o.riskScore === xMax
                );
              }
              // 28/2/22 calling it before setting the element fix
              this.sectionCondition(this.selectedCountry1);
              //
              a.elements[0] = {
                postValue: this.selectedCountry1,
                score: maxXObject ? maxXObject.riskScore : 0,
                risk: maxXObject ? maxXObject.risk : null,
                isIndigenous: this.isIndigenous,
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameCountries"
              };
            }
          });
        } else if (this.selectedCountry1 && this.selectedCountry1.name) {
          let aa = [];
          aa.push(this.selectedCountry1);
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: aa,
                score: this.selectedCountry1.riskScore || null,
                risk: this.selectedCountry1.risk || null,
                isIndigenous: this.isIndigenous,
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameCountries"
              };
            }
          });
        }
        this.sectionCondition();
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      this.selectedListTypeIndex =
        (this.fieldData.selectedListTypeIndex &&
          this.fieldData.selectedListTypeIndex.value) ||
        0;
    }
  },

  validations: {
    selected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
</style>
