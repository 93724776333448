<template>
  <div>
    <div class="d-flex" style="justify-content: space-between">
      <div class="mt-3">
        <b-button
          :style="resetFilter ? 'background-color: #4D9DE0;color:#fff' : ''"
          class="mr-2 mb-1"
          size="sm"
          variant="outline-dark"
          @click="advanceFilterForms = !advanceFilterForms"
        >
          <i aria-hidden="true" class="fa fa-filter"></i>
        </b-button>
        <b-button
          class="mr-2 mb-1"
          size="sm"
          variant="outline-dark"
          @click="printPageContent"
        >
          <i aria-hidden="true" class="fa fa-print"></i>
        </b-button>
        <b-button
          class="mr-2 mb-1"
          size="sm"
          variant="outline-primary"
          @click="configModalShow = !configModalShow"
        >
          <i aria-hidden="true" class="fa fa-cogs"></i>
        </b-button>
      </div>
    </div>
    <b-collapse v-model="advanceFilterForms">
      <b-card class="mb-2 bg-light">
        <b-row class="text-center">
          <b-col cols="12">
            <h4>{{ $t("eb360.manageThirdParties.filterResult") }}</h4>

            <div class="text-right">
              <b-btn
                :disabled="riskScoreChartLoading"
                class="mx-1"
                variant="outline-danger"
                @click="onResetFilters"
                >Clear Filters
              </b-btn>

              <b-btn
                :disabled="riskScoreChartLoading"
                class="mx-1"
                variant="outline-success"
                @click="onApplyFilters"
                >Apply
              </b-btn>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group label="Date Sent From:">
              <b-form-datepicker
                v-model="form.dateSentFrom"
                :max="maxDate"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Date Sent To:">
              <b-form-datepicker
                v-model="form.dateSentTo"
                :max="maxDate"
                :min="form.dateSentFrom"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Count Type:">
              <v-select
                v-model="form.countType"
                :options="countTypeOptions"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <!-- filter collapse end -->

    <!-- filter banner -->
    <b-alert
      v-model="resetFilter"
      style="margin: 0px 0px 1px 0px; background: #4d9de0; text-align: left"
      variant="warning"
    >
      <b style="color: white">You are viewing filtered data.</b>
    </b-alert>
    <!-- filter banner -->
    <div
      id="analytics"
      style="height: calc(100vh - 9rem); overflow-y: auto; overflow-x: hidden"
    >
      <div style="display: flex; justify-content: space-between">
        <h5 class="text-left mb-3 mt-3" style="font-size: 17px">
          <font-awesome-icon icon="chart-bar" />
          {{ questionareTitles }}
        </h5>
      </div>
      <!--form data start  -->
      <div
        class="mt-3 mb-2x d-flex"
        style="overflow-x: auto; overflow-y: hidden"
      >
        <DdqTotalCountCard :overallValues="overallValues" />
        <DdqInProgressCountCard
          :overallValues="overallValues[1]"
          :overallValuesPer="overallValues[5]"
        />
        <DdqCompletedCountCard
          :overallValues="overallValues[2]"
          :overallValuesPer="overallValues[6]"
        />
        <DdqNotStartedCountCard
          :overallValues="overallValues[3]"
          :overallValuesPer="overallValues[7]"
        />
        <DdqAlternateCountCard :overallValues="overallValues" />
      </div>
      <b-row>
        <b-col cols="12">
          <div v-if="!isLoading">
            <!-- graphical representation of form data start -->
            <b-overlay :show="riskScoreChartLoading" class="my-4" rounded="sm">
              <b-row>
                <b-col v-if="!isScoringSuppressed" cols="3">
                  <AverageSupplierRiskComponent
                    :riskScoreChartOptions="riskScoreChartOptions"
                  />

                  <SupplierRiskComponent
                    :supplierRiskChartOptions="supplierRiskChartOptions"
                  />
                </b-col>
                <b-col cols="8">
                  <SupplierBusinessOperationsComponent
                    :mapChartOptions="mapChartOptions"
                  />
                </b-col>
              </b-row>
            </b-overlay>
            <!-- graphical representation of form data end -->

            <!-- pie chart of form data start -->
            <p style="page-break-after: always"></p>
            <b-row class="my-2">
              <b-col
                v-for="(sec, sIndex) in chartConfig.totalSections"
                :key="sIndex"
                :cols="selectedLayout.val"
                :style="isPrintContent ? 'margin-bottom:50px' : ''"
              >
                <div v-for="(opt, index) in sec.section" :key="index">
                  <b-container v-if="showSectionTitle" class="text-left" fluid>
                    <h3 style="font-size: 17px !important">{{ sec.title }}</h3>
                  </b-container>
                  <b-row>
                    <b-col>
                      <h4
                        :style="
                          opt.data[opt.data.length - 1].shortTitle
                            ? ''
                            : 'display: none;'
                        "
                        class="text-center font-size-text"
                      >
                        {{ opt.data[opt.data.length - 1].shortTitle }}
                      </h4>
                      <b-card class="my-4" footer-tag="footer">
                        <p style="font-size: 17px">
                          {{ opt.data[opt.data.length - 1].questionTitle }}
                        </p>

                        <!-- card body -->
                        <b-card-body>
                          <b-row>
                            <b-col :cols="selectedLayout.val == 12 ? 6 : 12">
                              <BaseChart
                                :chartOptions="opt.data[opt.data.length - 1]"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-table
                                v-if="selectedLayout.val == 12"
                                :fields="fields"
                                :items="opt.data"
                              ></b-table>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <p style="page-break-after: always"></p>
            <!-- pie chart of form data end -->

            <!-- bar chart of form data start -->
            <b-row>
              <b-col
                :cols="selectedLayout.val"
                :style="isPrintContent ? 'margin-bottom:60px' : ''"
              >
                <EndStateDistributionComponent
                  :barChartOptions="barChartOptions"
                  :barChartTitle="barChartTitle"
                />
              </b-col>
            </b-row>
            <!-- bar chart of form data end -->
          </div>

          <div v-else class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-modal v-model="configModalShow" title="Select Page Configuration">
        <b-dropdown
          id="dropdown-1"
          :text="'Layout ' + selectedLayout.key"
          class="m-md-2 float-right"
        >
          <b-dropdown-item
            v-for="layout in layoutOptions"
            :key="layout.key"
            @click="onSelectLayout(layout)"
            >{{ layout.key }}
          </b-dropdown-item>
        </b-dropdown>
        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
          <b-button size="sm" variant="success" @click="ok()"> OK </b-button>
        </template>
      </b-modal>
      <ThirdPartyAnalysisModalTable
        :company-id="companyId"
        :modal-title="mapDrillDownOptions.modalTitle"
        :query-specific-data="mapDrillDownOptions.querySpecificData"
        :table-id="mapDrillDownOptions.tableId"
        :table-title="mapDrillDownOptions.tableTitle"
        @hideModal="hideModal"
      />
    </div>
  </div>
</template>

<script>
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import EB2ColorPallete from "@/utils/eb2ColorPallete.json";
import AverageSupplierRiskComponent from "./analyticsComponents/averageSupplierRiskComponent.vue";
import SupplierRiskComponent from "./analyticsComponents/supplierRiskComponent.vue";
import SupplierBusinessOperationsComponent from "./analyticsComponents/supplierBusinessOperationsComponent.vue";
import EndStateDistributionComponent from "./analyticsComponents/EndStateDistributionComponent.vue";
import DdqTotalCountCard from "./analyticsComponents/ddqTotalCountCard.vue";
import DdqInProgressCountCard from "./analyticsComponents/ddqInProgressCountCard.vue";
import DdqCompletedCountCard from "./analyticsComponents/ddqCompletedCountCard.vue";
import DdqNotStartedCountCard from "./analyticsComponents/ddqNotStartedCountCard.vue";
import DdqAlternateCountCard from "./analyticsComponents/ddqAlternateCountCard.vue";
import ThirdPartyAnalysisModalTable from "@/organisms/ThirdPartyAnalysisModalTable/ThirdPartyAnalysisModalTable";
import BaseChart from "@/atoms/BaseChart/BaseChart";
import {
  queryObjectKeys,
  riskScoreCategoryValues,
  localStorageItem
} from "@/constants";
import { mapState } from "vuex";

export default {
  name: "DashboardStats",
  components: {
    BaseChart,
    AverageSupplierRiskComponent,
    SupplierRiskComponent,
    SupplierBusinessOperationsComponent,
    EndStateDistributionComponent,
    DdqTotalCountCard,
    DdqInProgressCountCard,
    DdqCompletedCountCard,
    DdqNotStartedCountCard,
    DdqAlternateCountCard,
    ThirdPartyAnalysisModalTable
  },
  data() {
    return {
      maxDate: new Date(),
      thirdPartyRisk: null,
      isScoringSuppressed: false,
      form: {
        dateSentFrom: null,
        dateSentTo: null,
        countType: [
          {
            value: "0",
            text: "Only Most Recent",
            label: "Only Most Recent"
          }
        ]
      },
      countTypeOptions: [
        {
          value: "0",
          text: "Only Most Recent",
          label: "Only Most Recent"
        },
        {
          value: "1",
          text: "All",
          label: "All"
        }
      ],
      isPrintContent: false,
      barChartTitle: null,
      barChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: null
        },
        colors: ["#4d9de0", "#b4c7e7", "#f2f2f2", "#bfbfbf", "#7f7f7f"],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        series: []
      },
      advanceFilterForms: false,
      questionareFormId: null,
      breadcrumbItems: [
        {
          text: "Home",
          active: true
        },
        {
          text: "Analytics",
          active: true
        },
        {
          text: "Questionnaire",
          active: true
        }
      ],
      riskScoreChartLoading: false,
      mapCharts: false,
      mapChartOptions: {
        chart: {
          borderWidth: 0,
          map: worldMap,
          height: 600,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "",
          style: {
            fontSize: "17px"
          }
        },
        subtitle: {
          text: ""
        },
        credits: {
          enabled: false
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },
        series: []
      },
      riskScoreChartTitle: null,
      riskScoreChartOptions: {
        chart: {
          type: "solidgauge",
          backgroundColor: null,
          height: 300,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        title: {
          text: "",
          style: {
            fontSize: "17px"
          }
        },
        tooltip: {
          enabled: false
        },
        pane: {
          center: ["50%", "50%"],
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "#EEE",
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc"
          }
        },
        yAxis: {
          min: 0,
          max: 10,
          stops: [],
          labels: {
            enabled: false,
            y: 16
          },
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70
          }
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        series: []
      },
      supplierRiskChartOptions: {
        chart: {
          type: "pie",
          height: 300,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "",
          style: {
            fontSize: "17px"
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      perPage: 10,
      currentPage: 1,
      configModalShow: false,
      showSectionTitle: false,
      showQuestionTitle: false,
      chartConfig: {
        totalSections: []
      },
      overallValues: [100, 15, 30, 20, 100],
      max: 100,
      formsOptions: [],
      isLoading: false,
      layoutOptions: [
        {
          val: 12,
          key: 1
        },
        {
          val: 6,
          key: 2
        }
      ],
      selectedDDq: null,
      selectedDDqTitle: null,
      overallStats: {},
      selectedLayout: {
        val: 6,
        key: 2
      },
      selectedChartType: "Bar",
      fields: [
        {
          key: "name",
          label: "Options"
        },
        {
          key: "y",
          label: "Count"
        }
      ],
      questionareTitles: null,
      resetFilter: false,
      mapDrillDownOptions: {
        tableTitle: "",
        tableId: "",
        modalTitle: "",
        querySpecificData: null
      }
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    })
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.questionareTitles = null;
        (this.form = {
          dateSentFrom: null,
          dateSentTo: null,
          countType: [
            {
              value: "0",
              text: "Only Most Recent",
              label: "Only Most Recent"
            }
          ]
        }),
          (this.resetFilter = false);
        this.advanceFilterForms = false;
        this.questionareFormId = this.$route.params.id;
        this.getAllForms();
      }
    }
  },
  async mounted() {
    await this.$router.isReady();
    let filteredFormData = JSON.parse(
      localStorage.getItem(localStorageItem.SINGLE_ANALYTICS)
    );
    if (filteredFormData) {
      this.form = filteredFormData;
      this.resetFilter = true;
      this.selectedLayout = filteredFormData.selectedLayout || {
        val: 6,
        key: 2
      };
    }
    this.questionareFormId = this.$route.params.id;
    this.getAllForms();
  },
  methods: {
    hideModal() {
      this.mapDrillDownOptions.tableTitle = "";
      this.mapDrillDownOptions.tableId = "";
      this.mapDrillDownOptions.modalTitle = "";
      this.mapDrillDownOptions.querySpecificData = null;
    },
    showHideCardForPrint() {
      this.isPrintContent = true;
    },
    printPageContent() {
      this.showHideCardForPrint();
      setTimeout(() => {
        if (this.isPrintContent) {
          let originalContents = document.body.innerHTML;
          let printReport = document.getElementById("analytics").innerHTML;
          document.body.innerHTML = printReport;
          window.print();
          document.body.innerHTML = originalContents;
          window.location.reload();
        }
      }, 2000);
    },
    onApplyFilters() {
      let filterObj = {
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        countType: this.form.countType,
        path: this.$route.path,
        selectedLayout: this.selectedLayout
      };
      localStorage.setItem(
        localStorageItem.SINGLE_ANALYTICS,
        JSON.stringify(filterObj)
      );
      this.getAllForms();
      this.resetFilter = true;
      this.advanceFilterForms = false;
    },
    onResetFilters() {
      this.form = {
        dateSentFrom: null,
        dateSentTo: null,
        countType: [{ value: null }]
      };
      localStorage.removeItem(localStorageItem.SINGLE_ANALYTICS);
      this.getAllForms();
      this.advanceFilterForms = false;
      this.resetFilter = false;
    },
    async getSupplierRiskChartData(formId) {
      this.supplierRiskChartOptions.series = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqriskscorestat",
          formData,
          makeAuthorizationHeader()
        );

        if (result.data.status == 1 && result.status == 200) {
          if (result?.data?.suppressScoreReporting) {
            this.isScoringSuppressed = result?.data?.suppressScoreReporting;
          } else {
            const self = this;

            this.supplierRiskChartOptions.series.push({
              name: "",
              point: {
                events: {
                  click() {
                    self.mapDrillDownOptions.tableTitle = this.name;
                    self.mapDrillDownOptions.modalTitle = "Third Party Risk";
                    self.mapDrillDownOptions.querySpecificData = {
                      formId,
                      thirdParties: this.thirdParties
                    };
                    self.mapDrillDownOptions.tableId =
                      queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                  }
                }
              },
              data: [
                {
                  name: "Low",
                  y: result.data.response.low ? result.data.response.low : 0,
                  thirdParties: result.data.response.thirdParties.low
                },
                {
                  name: "Moderate",
                  y: result.data.response.moderate
                    ? result.data.response.moderate
                    : 0,
                  thirdParties: result.data.response.thirdParties.moderate
                },
                {
                  name: "High",
                  y: result.data.response.high ? result.data.response.high : 0,
                  thirdParties: result.data.response.thirdParties.high
                },
                {
                  name: "Not Completed",
                  y: result.data.response.notDone
                    ? result.data.response.notDone
                    : 0,
                  thirdParties: result.data.response.thirdParties.notDone
                }
              ]
            });
            this.riskScoreChartLoading = false;
          }
        } else {
          this.supplierRiskChartOptions.series.push({
            name: "",
            data: []
          });
          this.riskScoreChartLoading = false;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async getRiskScoreChartData() {
      this.riskScoreChartOptions.series = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId: this.questionareFormId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqaverageriskscore",
          formData,
          makeAuthorizationHeader()
        );
        let scoreValue = result.data?.response.toFixed(0);
        this.riskScoreChartOptions.yAxis.stops = [];
        if (result.data.status == 1 && result.status == 200) {
          if (scoreValue >= riskScoreCategoryValues.HIGH) {
            this.riskScoreChartOptions.yAxis.stops.push([
              10,
              EB2ColorPallete.EB2ColorPallete.chatsRedColor
            ]); // red
          } else if (
            scoreValue >= riskScoreCategoryValues.MODERATE_LOW &&
            scoreValue <= riskScoreCategoryValues.MODERATE_HIGH
          ) {
            this.riskScoreChartOptions.yAxis.stops.push([
              10,
              EB2ColorPallete.EB2ColorPallete.chatsAmberColor
            ]); // yellow
          } else {
            this.riskScoreChartOptions.yAxis.stops.push([
              10,
              EB2ColorPallete.EB2ColorPallete.chatsGreenColor
            ]); // green
          }
          this.riskScoreChartOptions.series.push({
            name: "Risk Score",
            data: [result.data.response],
            dataLabels: {
              format: `<div style="text-align:center"><span style="font-size:25px">${scoreValue}</span><br/></div>`
            }
          });
          this.riskScoreChartLoading = false;
        } else {
          this.riskScoreChartOptions.series.push({
            name: "Risk Score",
            data: [],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span><br/>' +
                "</div>"
            }
          });
          this.riskScoreChartLoading = false;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async worldMapChartsData(formId) {
      this.mapChartOptions.series = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqqcoutrystat",
          formData,
          makeAuthorizationHeader()
        );

        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              point: {
                events: {
                  click() {
                    self.mapDrillDownOptions.tableTitle = this.country;
                    self.mapDrillDownOptions.modalTitle =
                      "Third Party Business Operations";
                    self.mapDrillDownOptions.querySpecificData = {
                      thirdParties: this.thirdParties
                    };
                    self.mapDrillDownOptions.tableId =
                      queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                  }
                }
              },
              color: "#6BDC99",
              joinBy: ["iso-a2", "code"],
              data: result.data.data,
              minSize: 4,
              maxSize: "12%",
              tooltip: {
                pointFormat: "{point.country}: {point.z}"
              }
            }
          );
          this.riskScoreChartLoading = false;
        } else {
          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              color: "#6BDC99",
              joinBy: ["iso-a2", "code"],
              data: [],
              minSize: 4,
              maxSize: "12%"
            }
          );
          this.riskScoreChartLoading = false;
          this.mapCharts = true;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async getDdqstat() {
      this.overallValues = [];
      let formData = {
        companyId: this.companyId,
        type: this.selectedDDq.type,
        formId: this.questionareFormId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        let result = await HTTP(
          "post",
          "chart/getddqstat",
          formData,
          makeAuthorizationHeader()
        );

        this.getRiskScoreChartData();
        this.getSupplierRiskChartData(this.questionareFormId);
        this.worldMapChartsData(this.questionareFormId);
        if (result.data.data) {
          this.overallValues = [
            result.data.data.tpWithDDQ,
            result.data.data.tpWithDDQInProgress,
            result.data.data.tpWithDDQCompleted,
            result.data.data.tpWithDDQNotStarted,
            0,
            result.data.data.tpWithDDQInProgresspar,
            result.data.data.tpWithDDQCompletedpar,
            result.data.data.tpWithDDQNotStartedpar,
            result.data.data.tpWithDDQAlternate,
            result.data.data.tpWithDDQAlternatepar
          ];
        }
      } catch (err) {
        return [err];
      }
    },
    async getAllForms() {
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getallforms",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader()
        );
        if (result.data.data) {
          this.formsOptions = result.data.data;
          this.selectedDDq = this.formsOptions[0];
          this.selectedDDqTitle = this.selectedDDq.title;
          this.getDdqstat();
          this.getSingleDdq();
          this.getDdqstatbar();
          this.riskScoreChartLoading = false;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },

    onSelectDDq(val) {
      this.selectedDDq = val;
      this.getDdqstat();
      this.getSingleDdq();
      this.getDdqstatbar();
    },

    async getSingleDdq() {
      this.chartConfig = {
        totalSections: []
      };
      let formData = {
        companyId: this.companyId,
        type: this.selectedDDq.type,
        formId: this.questionareFormId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqquestionstat",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.response.sections) {
          this.questionareTitles = result.data.form_title;
          result.data.response.sections.map((section) => {
            section.map((sectionData) => {
              let dd = {
                title: sectionData.sectionTitle,
                section: [sectionData]
              };
              this.chartConfig.totalSections.push(dd);
            });
          });
          this.isLoading = false;
          this.newDataSet();
        }
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getDdqstatbar() {
      const self = this;
      let formData = {
        companyId: this.companyId,
        type: this.selectedDDq.type,
        formId: this.questionareFormId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };

      this.chartConfig = {
        totalSections: []
      };

      try {
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqstat",
          formData,
          makeAuthorizationHeader()
        );
        this.barChartTitle = result.data.chart_data[0].questionTitle;
        this.barChartOptions = {
          shortTitle: result.data.chart_data[0].questionTableAbbreviation,
          sectionTitle: result.data.chart_data[0].sectionTitle,
          questionTitle: result.data.chart_data[0].questionTitle,
          title: {
            text: null
          },
          colors: [
            "#b4c7e7",
            "#4d9de0",
            "#f2f2f2",
            "#363537",
            "#515254",
            "#a3a3ac"
          ],
          chart: {
            polar: false,
            type: "bar",
            zooming: {
              mouseWheel: {
                enabled: false
              }
            }
          },
          xAxis: {
            labels: {
              style: {
                color: "#080808"
              }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: ""
            }
          },
          tooltip: {
            valueSuffix: ""
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
                formatter() {
                  return `${this.series.userOptions.percentage}%`;
                }
              }
            },
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click() {
                    const thirdParties = result.data.chart_data[0].thirdParties;
                    const series = result.data.chart_data[0].data[3].series;
                    const statusIndex = series.findIndex(
                      (x) => x.data[0] === this.y
                    );
                    let selectedThirdParties = [];
                    let selectedThirdPartyName = null;

                    thirdParties.map((tp) => {
                      if (tp.name === series[statusIndex].name) {
                        selectedThirdParties = tp.value;
                        selectedThirdPartyName = tp.name;
                      }
                    });

                    self.mapDrillDownOptions.tableTitle =
                      selectedThirdPartyName;
                    self.mapDrillDownOptions.modalTitle =
                      result.data.chart_data[0].questionTitle;
                    self.mapDrillDownOptions.querySpecificData = {
                      thirdParties: selectedThirdParties
                    };
                    self.mapDrillDownOptions.tableId =
                      queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                  }
                }
              }
            }
          },
          legend: {
            borderWidth: 1
          },
          series: result.data.chart_data[0].data[3].series
        };
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    newDataSet() {
      this.chartConfig.totalSections.map((ss) => {
        ss.section.map((q) => {
          let i = 0;
          let tempSeries = [];
          for (i; i < q.data.length; i++) {
            tempSeries.push(q.data[i]);
          }
          let names = [];
          let colors = [];
          q.data.map((d) => {
            names.push(d.name);
            colors.push(
              d.custom_values
                ? d.custom_values.color
                  ? d.custom_values.color
                  : "#3DDc97"
                : "#3DDc97"
            );
          });
          const self = this;
          let data = {
            shortTitle: q.questionTableAbbreviation,
            sectionTitle: q.sectionTitle,
            questionTitle: q.questionTitle,
            chart: {
              polar: false,
              type: q.selectedChart
                ? q.selectedChart == "Null"
                  ? "pie"
                  : q.selectedChart
                : "pie",
              zooming: {
                mouseWheel: {
                  enabled: false
                }
              }
            },
            title: {
              text: ""
            },
            colors,
            credits: {
              enabled: false
            },
            xAxis: {
              labels: {
                style: {
                  color: "#080808"
                }
              },
              categories: names
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              }
            },
            legend: {
              reversed: true
            },
            tooltip: {
              valueSuffix: ""
            },
            plotOptions: {
              pie: {
                size: "60%",
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  connectorShape: "straight",
                  crookDistance: "90%",
                  color: "gray",
                  formatter() {
                    return `${this.point.name}<br><div>${parseInt(
                      this.point.percentage
                    )}%</div>`;
                  }
                }
              },
              series: {
                stacking: "normal"
              }
            },
            series: [
              {
                name: "",
                point: {
                  events: {
                    click() {
                      self.mapDrillDownOptions.tableTitle = this.name;
                      self.mapDrillDownOptions.modalTitle = q.questionTitle;
                      self.mapDrillDownOptions.querySpecificData = {
                        thirdParties: this.thirdParties
                      };
                      self.mapDrillDownOptions.tableId =
                        queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                    }
                  }
                },
                data: tempSeries
              }
            ]
          };
          q.data.push(data);
        });
      });
    },

    onSelectLayout(e) {
      this.selectedLayout = e;
      let filterObj = {
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        countType: this.form.countType,
        path: this.$route.path,
        selectedLayout: this.selectedLayout
      };
      localStorage.setItem(
        localStorageItem.SINGLE_ANALYTICS,
        JSON.stringify(filterObj)
      );
      this.toggleRender();
    },

    onSelectChart(type, sIndex, index) {
      let section = this.chartConfig.totalSections[sIndex].section[index].data;
      this.selectedChartType = type;
      section[section.length - 1].chart.type = type.toLowerCase();

      this.toggleRender();
    },

    toggleRender() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    }
  },
  beforeRouteUpdate(_, __, next) {
    localStorage.removeItem(localStorageItem.SINGLE_ANALYTICS);
    next();
  },
  beforeRouteLeave(_, __, next) {
    localStorage.removeItem(localStorageItem.SINGLE_ANALYTICS);
    next();
  }
};
</script>

<style lang="scss">
.select-custom .vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem) !important;
}

.nav-tabs .nav-link {
  font-weight: bold;
  color: $black;
  border-bottom: 3px solid $white !important;
}

.nav-tabs li.nav-item {
  margin-right: 25px;
}

.statsCard .card-title {
  font-size: 17px !important;
}

.font-size-text {
  font-size: 17px !important;
}
</style>
