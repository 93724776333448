import {
  actionKeys,
  actionStepType,
  inputType,
  numericBinaryOperatorOptions,
  operations,
  variableAssignmentOptionsWithNoValue,
  variableAssignmentOptionsWithNoValueAndEmptyOptions,
  variableAssignmentType,
  variableType,
  variableTypeToDisplayTextMap,
  variableValueBooleanOptions,
  valueDataTypeOptions,
  urls,
  erafCasting,
  predefinedLiteralOptions,
  roundEvents,
  endOperations as endOperationsMap,
  endOperationsPerType,
  themes
} from "@/constants";
import { SetStepClass } from "@/molecules/SetStep/SetStep.class";
import { getSelectedOption, makeUUID } from "@/utils";
import CalculationBracket from "@/molecules/CalculationBracket/CalculationBracket";
import BracketSection from "@/molecules/BracketSection/BracketSection";
import Input from "@/molecules/Input/Input";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import Select from "@/molecules/Select/Select";
import SetStep from "@/molecules/SetStep/SetStep";
import DynamicComponentWithLink from "@/molecules/DynamicComponentWithLink/DynamicComponentWithLink";
import {
  makeBracketDTO,
  makeCalculationStepDTO,
  makeFlatSetStepDTO,
  makeConcatenationValueListDTO,
  makeValueObjDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import { cloneDeep, isEmpty } from "lodash";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import FormRowsList from "@/molecules/FormRowsList/FormRowsList";
import BaseAddDeleteLine from "@/atoms/BaseAddDeleteLine/BaseAddDeleteLine";
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  data() {
    return {
      themes,
      setStepList: []
    };
  },
  components: {
    CalculationBracket,
    BracketSection,
    Input,
    SearchSelect,
    Select,
    DynamicComponentWithLink,
    MultiSelect,
    SetStep,
    FormRowsList,
    BaseAddDeleteLine,
    CallToAction
  },
  methods: {
    cloneDeep,
    makeUUID,
    makeConcatenationValueListDTO,
    makeValueObjDTO,
    isCRUDSetStep(setStepId) {
      return !!this.functionSteps.find(({ id }) => id === setStepId);
    },
    isEntityValueType(valueType) {
      return valueType === variableAssignmentType.ENTITY;
    },
    isLiteralValueType(valueType) {
      return valueType === variableAssignmentType.LITERAL;
    },
    isNoValueType(valueType) {
      return valueType === variableAssignmentType.NO_VALUE;
    },
    isExpressionValueDataType(valueDataType) {
      return valueDataType === variableType.EXPRESSION;
    },
    isConcatenationValueDataType(valueDataType) {
      return valueDataType === variableType.CONCATENATION;
    },
    isCustomERAFValueDataType(valueDataType) {
      return (
        this.isExpressionValueDataType(valueDataType) ||
        this.isConcatenationValueDataType(valueDataType)
      );
    },
    isNumericValueDataType(valueDataType) {
      return valueDataType === variableType.NUMERIC;
    },
    isStringValueDataType(valueDataType) {
      return valueDataType === variableType.STRING;
    },
    isBooleanValueDataType(valueDataType) {
      return valueDataType === variableType.BOOLEAN;
    },
    isProxyValueDataType(valueDataType) {
      return valueDataType === variableType.PROXY;
    },
    isDatetimeValueDataType(valueDataType) {
      return valueDataType === variableType.DATE_TIME;
    },
    isSettableExpectedData(variableName) {
      return !!this.getExpectedDataBaseLists.settableOptions.find(
        ({ text }) => text === variableName
      );
    },
    isSetStepCompleted(setStep) {
      const { value: valueType } = setStep.getSelectedValueTypeOption();
      const isBaseStepCompleted =
        !!setStep.variableNameSearchValue &&
        !!setStep.variableScope &&
        !!setStep.variableType &&
        !!setStep.valueDataType &&
        !!valueType;

      if (valueType === variableAssignmentType.NO_VALUE) {
        return isBaseStepCompleted;
      } else {
        return (
          isBaseStepCompleted &&
          (!!setStep.componentValue || setStep.componentValue === 0)
        );
      }
    },
    isMultipleSelectionExpectedForEntityPropertyName(entityPropertyName) {
      return (
        this.getAllEntities[entityPropertyName]?.data?.data?.isArray || false
      );
    },
    isMultiSelectLiteralRequired({
      entityPropertyName,
      subType,
      comparator,
      valueType
    } = {}) {
      if (this.isLiteralValueType(valueType)) {
        return (
          this.isMultipleSelectionExpectedForEntityPropertyName(
            entityPropertyName
          ) ||
          (subType === variableType.COUNTRY && comparator === operations.IN)
        );
      } else {
        return false;
      }
    },
    removeBelowValuesAssociatedToStep(index) {
      const removedLocalVariableName =
        this.functionSteps[index].variableNameSearchValue;
      if (
        !this.getAboveStepBasedOnVariableName(index, removedLocalVariableName)
      ) {
        for (let i = index + 1; i < this.functionSteps.length; i++) {
          if (this.functionSteps[i].stepType === actionStepType.SET) {
            if (
              this.functionSteps[i].variableNameSearchValue ===
              removedLocalVariableName
            ) {
              break;
            } else if (
              this.functionSteps[i].componentValue === removedLocalVariableName
            ) {
              this.functionSteps[i].setComponentValue(undefined);
              if (this.functionSteps[i]?.componentOptions?.options) {
                this.functionSteps[i].setComponentOptions({
                  ...this.functionSteps[i].componentOptions,
                  options: this.makeOptionsForSelect(
                    undefined,
                    this.functionSteps[i]?.componentOptions?.options
                  )
                });
              }
            }
          }
        }
      }
    },
    getAboveStepBasedOnVariableName(index, variableName) {
      let functionStep;
      for (let i = index - 1; i >= 0; i--) {
        if (this.functionSteps[i]?.variableNameSearchValue === variableName) {
          functionStep = this.cloneDeep(this.functionSteps[i]);
          break;
        }
      }
      return functionStep;
    },
    getStepValue(value, valueDataType) {
      if (value) {
        if (this.isStringValueDataType(valueDataType)) {
          return `${value}`;
        } else if (
          this.isNumericValueDataType(valueDataType) ||
          this.isDatetimeValueDataType(valueDataType)
        ) {
          return parseFloat(value);
        }
      } else {
        return value;
      }
    },
    getInputTypeForValue(valueDataType) {
      if (this.isStringValueDataType(valueDataType)) {
        return "text";
      } else if (
        this.isNumericValueDataType(valueDataType) ||
        this.isDatetimeValueDataType(valueDataType)
      ) {
        return "number";
      }
    },
    getValueForMakeOptionsForLiteralValueSelect(type, value) {
      return type === variableType.NUMERIC ? Number.parseInt(value) : value;
    },
    makeOptionsForLiteralValueSelect(
      functionStep,
      isEmptyOptionRequired = true
    ) {
      const {
        value,
        variableName,
        variableType: functionVariableType
      } = functionStep;
      const expectedDataBaseItem = this.getAllEntities[variableName];
      const parsedValue = this.getValueForMakeOptionsForLiteralValueSelect(
        functionVariableType,
        value
      );

      if (expectedDataBaseItem) {
        return this.makeOptionsForSelect(parsedValue, [
          ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
          ...expectedDataBaseItem?.data?.data?.options
        ]);
      } else {
        return [];
      }
    },
    makeAvailableAndRemainingLists({
      options = [],
      variableType = "",
      valueDataType = "",
      variableName = ""
    }) {
      return options.reduce(
        (acc, option) => {
          const { type, subType, isArray, text, value } = option;
          const entity = this.getAllEntities?.[variableName];
          const isSameTypeAndSubType =
            type === variableType && subType === valueDataType;
          if (
            entity &&
            isSameTypeAndSubType &&
            isArray === entity?.data?.data?.isArray
          ) {
            acc.availableOptionsList.push({ text, value });
          } else if (!entity && isSameTypeAndSubType && isArray === false) {
            acc.availableOptionsList.push({ text, value });
          } else {
            acc.remainingOptionsList.push(option);
          }

          return acc;
        },
        { availableOptionsList: [], remainingOptionsList: [] }
      );
    },
    makeOptionsForExistingValueSelect(
      functionStep = {},
      stepIndex,
      isEmptyOptionRequired = true
    ) {
      const { variableName, variableType, valueDataType, valueType, value } =
        functionStep;
      if (valueDataType && valueType) {
        const baseOptions = this.getAllAvailableOptionsByStepIndex(stepIndex);
        const { availableOptionsList, remainingOptionsList } =
          this.makeAvailableAndRemainingLists({
            options: baseOptions,
            variableType,
            valueDataType,
            variableName
          });
        const options = this.expandOptionsForExistingValueSelect({
          valueDataType: functionStep.valueDataType,
          options: [
            ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
            ...availableOptionsList,
            ...this.getCastedOptionsForVariableType({
              options: remainingOptionsList,
              variableType,
              valueDataType,
              isArray: this.getIsArrayFromVariableName(variableName)
            })
          ]
        });
        return this.makeOptionsForSelect(value, options);
      } else {
        return [];
      }
    },
    getIsArrayFromVariableName(variableName) {
      return this.getAllEntities?.[variableName]?.data?.data?.isArray || false;
    },
    getCastedOptionsForVariableType({
      options = [],
      variableType = "",
      valueDataType = "",
      isArray = false
    }) {
      const { castTo = [] } =
        variableType === valueDataType
          ? erafCasting.types.find(
              (erafCastType) =>
                erafCastType.type === variableType &&
                erafCastType.isArray === isArray
            ) || {}
          : {};
      return castTo.reduce((fullCastedList, castedObj) => {
        const castedList = options.filter(
          (option) =>
            option.type === castedObj.type &&
            option.uniqueType === castedObj.uniqueType &&
            option.isArray === castedObj.isArray
        );
        fullCastedList.push(...castedList);
        return fullCastedList;
      }, []);
    },
    makeBracketSectionsObj(calculationList, options) {
      return Array.isArray(calculationList)
        ? calculationList.map(({ bracket, operator }) => ({
            bracket: {
              component: CalculationBracket,
              componentOptions: {
                calculation: bracket.map((bracketStep) => {
                  const searchSelectOptions = this.makeOptionsForSelect(
                    bracketStep.value,
                    options
                  );

                  return {
                    operator: {
                      options: this.makeOptionsForSelect(
                        bracketStep.operator,
                        numericBinaryOperatorOptions
                      ),
                      error: ""
                    },
                    value: {
                      value:
                        getSelectedOption(searchSelectOptions)?.text ||
                        bracketStep.value,
                      options: searchSelectOptions,
                      error: ""
                    }
                  };
                }),
                name: "calculation-bracket"
              }
            },
            operator: {
              options: this.makeOptionsForSelect(
                operator,
                numericBinaryOperatorOptions
              ),
              error: ""
            }
          }))
        : [];
    },
    makeInputComponent(value, type) {
      return {
        component: Input,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable value",
          id: "setVariableTo",
          name: "setVariableTo",
          class: "add-edit-action__form-input",
          "data-test-id": "add-edit-action__form-input",
          value,
          type,
          ...(type === inputType.NUMBER && { step: 0.000001 })
        }
      };
    },
    makeSelectInput({ options = [], events = {} }) {
      return {
        component: Select,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable value",
          name: "set-value",
          id: "set-value",
          options,
          value: getSelectedOption(options)?.value || "",
          ...events
        }
      };
    },
    makeMultiSelectInput(selectedOptions, amendableOptions, allOptions) {
      return {
        component: MultiSelect,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable values",
          name: "set-multiselect-values",
          id: "set-multiselect-values",
          showAllSelectedOptions: true,
          options: makeOptionsForMultiSelect(
            selectedOptions,
            amendableOptions,
            allOptions
          )
        }
      };
    },
    makeSearchSelectComponent({
      componentOptions = {},
      componentId = this.makeUUID()
    } = {}) {
      return {
        component: SearchSelect,
        id: componentId,
        componentOptions
      };
    },
    makeCTAComponent({
      componentOptions = {},
      componentId = this.makeUUID()
    } = {}) {
      return {
        component: CallToAction,
        id: componentId,
        componentOptions
      };
    },
    makeValueComponent({
      functionStep = this.makeSetStepFlatObj(),
      index,
      setStepId = ""
    }) {
      if (this.isLiteralValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return this.makeValueComponentForLiteral({
          functionStep,
          index,
          setStepId
        });
      } else if (this.isEntityValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return this.makeValueComponentForEntity(functionStep, index);
      } else if (this.isNoValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return {
          componentOptions: { value: null },
          component: null
        };
      }
    },
    makeValueComponentForLiteralExpression(functionStep, stepIndex) {
      return {
        component: BracketSection,
        componentOptions: {
          bracketSection: this.makeBracketSectionsObj(
            functionStep.value,
            this.makeOptionsForExistingValueSelect(
              {
                ...functionStep,
                valueDataType: variableType.NUMERIC
              },
              stepIndex,
              false
            )
          )
        }
      };
    },
    makeConcatenationFormRowList({
      stepIndex = -1,
      setStepId = "",
      value = "",
      functionStep = {}
    } = {}) {
      const ctaRowId = this.makeUUID();
      const formRowListId = this.makeUUID();
      const formRowElementId = this.makeUUID();
      return {
        formRowList: [
          this.makeSearchSelectComponent({
            componentOptions: {
              id: `set-step-concatenation-input-${this.makeUUID()}`,
              dataTestId: `set-step-concatenation-input`,
              label: "",
              name: "",
              error: "",
              options: this.makeOptionsForExistingValueSelect(
                {
                  ...functionStep,
                  valueDataType: variableType.STRING
                },
                stepIndex,
                false
              ),
              isLabelHidden: true,
              searchValue: value,
              placeholder: "Select...",
              style: { paddingBottom: "20px" },
              onChange: (event) =>
                this.onAmendConcatenationValue({
                  event,
                  setStepId,
                  formRowListId,
                  formRowElementId
                }),
              onCreate: (event) =>
                this.onAmendConcatenationValue({
                  event,
                  setStepId,
                  formRowListId,
                  formRowElementId
                }),
              onReset: (event) =>
                this.onAmendConcatenationValue({
                  event,
                  setStepId,
                  formRowListId,
                  formRowElementId
                })
            },
            componentId: formRowElementId
          })
        ],
        editCtaList: [
          this.makeCTAComponent({
            componentOptions: {
              icon: "trash-alt",
              size: 20,
              theme: themes.GREY,
              dataTestId: `set-step-concatenation-input-cta`,
              onClick: () =>
                this.removeConcatenationInput({
                  setStepId,
                  formRowElementId
                })
            },
            componentId: ctaRowId
          })
        ],
        id: formRowListId
      };
    },
    makeValueComponentForLiteralConcatenation({
      functionStep = {},
      stepIndex = -1,
      setStepId = ""
    } = {}) {
      const formCtaListId = this.makeUUID();
      return {
        component: FormRowsList,
        componentOptions: {
          formRowsList:
            functionStep?.value?.map?.(({ value }) =>
              this.makeConcatenationFormRowList({
                stepIndex,
                value,
                functionStep,
                setStepId
              })
            ) || [],
          formCtaList: [
            {
              component: BaseAddDeleteLine,
              componentOptions: {
                isHidden: true,
                onClick: () => this.addConcatenationInput({ setStepId }),
                onMouseenter: () =>
                  this.setBaseAddDeleteLineVisibility({
                    setStepId,
                    formCtaListId,
                    isHidden: false
                  }),
                onMouseleave: () =>
                  this.setBaseAddDeleteLineVisibility({
                    setStepId,
                    formCtaListId,
                    isHidden: true
                  }),
                onFocusin: () =>
                  this.setBaseAddDeleteLineVisibility({
                    setStepId,
                    formCtaListId,
                    isHidden: false
                  }),
                onFocusout: () =>
                  this.setBaseAddDeleteLineVisibility({
                    setStepId,
                    formCtaListId,
                    isHidden: true
                  })
              },
              id: formCtaListId
            }
          ],
          id: this.makeUUID()
        }
      };
    },
    setBaseAddDeleteLineVisibility({ setStepId, formCtaListId, isHidden }) {
      const setStepInstance = this.getSetStepBasedOnId(setStepId);
      const formCtaListElement =
        setStepInstance.componentOptions.formCtaList.find(
          ({ id }) => id === formCtaListId
        );
      formCtaListElement.componentOptions.isHidden = isHidden;
    },
    removeConcatenationInput({ setStepId = "", formRowElementId = "" } = {}) {
      const setStepInstance = this.getSetStepBasedOnId(setStepId);
      setStepInstance.componentOptions.formRowsList =
        setStepInstance.componentOptions.formRowsList.reduce(
          (acc, { formRowList }, index) => {
            const isConcatenationRowNeeded = !formRowList.find(
              ({ id }) => id === formRowElementId
            );
            if (isConcatenationRowNeeded) {
              acc.push(setStepInstance.componentOptions.formRowsList[index]);
            }
            return acc;
          },
          []
        );
      setStepInstance.componentValue =
        setStepInstance.componentOptions.formRowsList.reduce(
          (acc, { formRowList }) => {
            acc.push(
              this.makeValueObjDTO(formRowList[0].componentOptions.searchValue)
            );
            return acc;
          },
          []
        );
    },
    addConcatenationInput({ setStepId }) {
      const { setStep = {}, stepIndex = 0 } =
        this.setStepList.find(({ setStep }) => setStep.id === setStepId) || {};
      setStep.componentOptions.formRowsList.push(
        this.makeConcatenationFormRowList({
          stepIndex,
          functionStep: makeFlatSetStepDTO(setStep),
          setStepId
        })
      );
      setStep.componentValue.push(this.makeValueObjDTO());
    },
    onAmendConcatenationValue({
      event = "",
      setStepId = "",
      formRowListId = "",
      formRowElementId = ""
    } = {}) {
      const setStepInstance = this.getSetStepBasedOnId(setStepId);
      const { formRowList = [] } =
        setStepInstance?.componentOptions?.formRowsList?.find?.(
          ({ id }) => id === formRowListId
        ) || {};
      const formRowElement =
        formRowList.find?.(({ id }) => id === formRowElementId) || {};
      formRowElement.componentOptions.searchValue = event;
      formRowElement.componentOptions.options = this.makeOptionsForSelect(
        event,
        formRowElement.componentOptions.options
      );
      formRowElement.componentOptions.error = "";
      setStepInstance.componentValue =
        setStepInstance.componentOptions.formRowsList.reduce(
          (acc, { formRowList }) => {
            acc.push(
              this.makeValueObjDTO(formRowList[0].componentOptions.searchValue)
            );
            return acc;
          },
          []
        );
    },
    makeValueComponentForEntityProxy(functionStep, index) {
      return {
        component: DynamicComponentWithLink,
        componentOptions: {
          ...this.makeSelectInput({
            options: this.makeOptionsForExistingValueSelect(
              functionStep,
              index
            ),
            events: {
              onChange: (event) => {
                this.changeValue({ event, id: functionStep.id }, index);
              }
            }
          }),
          routerLinkOptions: {
            to: ""
          },
          linkName: ""
        }
      };
    },
    makeValueComponentForLiteralDefault(functionStep) {
      const optionsForLiteralValueSelect =
        this.makeOptionsForLiteralValueSelect(functionStep);
      if (
        isEmpty(getSelectedOption(optionsForLiteralValueSelect)) &&
        optionsForLiteralValueSelect?.length
      ) {
        optionsForLiteralValueSelect[0].selected = true;
      }
      return this.makeSelectInput({ options: optionsForLiteralValueSelect });
    },
    makeValueComponentForLiteralMultiselect(functionStep) {
      const optionsForLiteralValueSelect =
        this.makeOptionsForLiteralValueSelect(functionStep, false);
      const amendableOptions = optionsForLiteralValueSelect.map(
        ({ value }) => value
      );

      return this.makeMultiSelectInput(
        functionStep.value,
        amendableOptions,
        optionsForLiteralValueSelect
      );
    },
    makeValueComponentForLiteralAlphaNumeric(functionStep) {
      const type = this.getInputTypeForValue(
        functionStep[actionKeys.VALUE_DATA_TYPE]
      );
      const value = this.getStepValue(
        functionStep[actionKeys.VALUE],
        functionStep[actionKeys.VALUE_DATA_TYPE]
      );

      return this.makeInputComponent(value, type);
    },
    makeValueComponentForLiteral({
      functionStep = {},
      index = -1,
      setStepId = ""
    } = {}) {
      if (
        this.isExpressionValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeValueComponentForLiteralExpression(functionStep, index);
      } else if (
        this.isConcatenationValueDataType(
          functionStep[actionKeys.VALUE_DATA_TYPE]
        )
      ) {
        return this.makeValueComponentForLiteralConcatenation({
          functionStep,
          stepIndex: index,
          setStepId
        });
      } else if (
        this.isNumericValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE]) ||
        this.isStringValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE]) ||
        this.isDatetimeValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeValueComponentForLiteralAlphaNumeric(functionStep);
      } else if (
        this.isBooleanValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeSelectInput({
          options: this.makeOptionsForSelect(
            `${functionStep[actionKeys.VALUE]}`,
            variableValueBooleanOptions
          )
        });
      } else if (
        this.isMultipleSelectionExpectedForEntityPropertyName(
          functionStep[actionKeys.VARIABLE_NAME]
        )
      ) {
        return this.makeValueComponentForLiteralMultiselect(functionStep);
      } else {
        return this.makeValueComponentForLiteralDefault(functionStep);
      }
    },
    makeValueComponentForEntity(functionStep, index) {
      if (this.isProxyValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])) {
        return this.makeValueComponentForEntityProxy(functionStep, index);
      } else {
        return this.makeSelectInput({
          options: this.makeOptionsForExistingValueSelect(functionStep, index)
        });
      }
    },
    expandOptionsForExistingValueSelect({ valueDataType = "", options = [] }) {
      if (predefinedLiteralOptions[valueDataType]) {
        return [...options, ...predefinedLiteralOptions[valueDataType]];
      } else {
        return options;
      }
    },
    getStepBasedOnVariableName(index, variableName) {
      let functionStep = this.getAboveStepBasedOnVariableName(
        index,
        variableName
      );
      if (!functionStep) {
        const entity = this.getAllEntities[variableName];
        functionStep = this.makeSetStep(
          this.makeSetStepFlatObj({
            [actionKeys.DISPLAY_VALUE_TYPE]: entity?.data?.type,
            [actionKeys.VARIABLE_NAME]: entity?.data?.data?.name,
            [actionKeys.VARIABLE_SCOPE]: entity?.type,
            [actionKeys.VARIABLE_TYPE]: entity?.data?.type,
            [actionKeys.VALUE_DATA_TYPE]:
              entity?.data?.data?.subType || entity?.data?.type,
            [actionKeys.VALUE_TYPE]: variableAssignmentType.LITERAL
          })
        );
      }
      return functionStep;
    },
    makeSuccessSetStep(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1
    ) {
      return {
        stepType: actionStepType.SET,
        component: SetStep,
        componentOptions: this.makeSetStepAndExtendSetStepList(
          functionStep,
          index
        )
      };
    },
    getValueTypeForMakeSetStep({ valueType, valueDataType }) {
      return this.isCustomERAFValueDataType(valueDataType)
        ? variableAssignmentType.LITERAL
        : valueType;
    },
    getValueDataTypeForMakeSetStep({ valueDataType, variableType }) {
      return this.isCustomERAFValueDataType(valueDataType)
        ? valueDataType
        : variableType;
    },
    getIsReadOnlyForMakeSetStep({ variableName, index }) {
      return (
        this.isSettableExpectedData(variableName) ||
        !!this.getAboveStepBasedOnVariableName(index, variableName)
      );
    },
    getIsMiddleSectionHiddenForMakeSetStep({ valueDataType }) {
      return (
        this.isCustomERAFValueDataType(valueDataType) ||
        this.isProxyValueDataType(valueDataType)
      );
    },
    makeSetStep(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1 || 0
    ) {
      const valueType = this.getValueTypeForMakeSetStep(functionStep);
      const valueDataType = this.getValueDataTypeForMakeSetStep(functionStep);
      const isReadOnly = this.getIsReadOnlyForMakeSetStep({
        ...functionStep,
        index
      });
      const endOperations = this.makeEndOperations(functionStep);
      const isMiddleSelectHidden =
        this.getIsMiddleSectionHiddenForMakeSetStep(functionStep);

      const setStepInstance = new SetStepClass({
        variableNameSearchValue: functionStep.variableName,
        variableNameOptions: this.makeOptionsForSelect(
          functionStep[actionKeys.VARIABLE_NAME],
          this.getListOfSettableOptionsPerEachStep[index]
        ),
        variableType: functionStep.variableType,
        variableScope: functionStep.variableScope,
        valueDataTypeOptions: this.makeOptionsForSelect(
          valueDataType,
          valueDataTypeOptions
        ),
        valueDataTypeText:
          variableTypeToDisplayTextMap[
            functionStep[actionKeys.DISPLAY_VALUE_TYPE]
          ],
        isReadOnly,
        isMiddleSelectHidden,
        valueTypeOptions: this.makeOptionsForSelect(
          valueType,
          variableAssignmentOptionsWithNoValueAndEmptyOptions
        ),
        valueDataType: functionStep.valueDataType,
        componentValue: functionStep.value,
        isHidden: false,
        comment: functionStep?.comment,
        hasComment: !!functionStep?.comment,
        endOperations
      });

      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep,
          index,
          setStepId: setStepInstance.id
        }) || {};

      setStepInstance.setComponent(component);
      setStepInstance.setComponentOptions(componentOptions);

      return setStepInstance;
    },
    makeSetStepAndExtendSetStepList(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1
    ) {
      const setStep = this.makeSetStep(functionStep, index);
      this.setStepList.push({
        stepIndex: index,
        setStep
      });

      return setStep;
    },
    amendSuccessSetStep({ event }, index) {
      if (event.name === actionKeys.VARIABLE_NAME) {
        this.amendVariableNameSuccessSetStep({
          event: event.event,
          id: event.id,
          index
        });
      } else if (event.name === actionKeys.VALUE_TYPE) {
        this.changeValueType(event, index);
      } else if (event.name === actionKeys.VALUE) {
        this.changeValue(event, index);
      } else if (event.name === roundEvents.ADD_ROUND) {
        this.addRoundToSetStep({ setStepId: event.setStepId });
      } else if (event.name === roundEvents.REMOVE_ROUND) {
        this.removeRoundFromSetStep({
          roundId: event.id,
          setStepId: event.setStepId
        });
      } else if (event.name === roundEvents.ROUND_DECIMAL) {
        this.amendRoundInstance({
          roundId: event.id,
          decimal: event.event
        });
      }
    },
    onSetStepChange($event, index) {
      if ($event.name === actionKeys.VARIABLE_NAME) {
        this.changeVariableName({ event: $event.event, id: $event.id, index });
      } else if ($event.name === actionKeys.VALUE_DATA_TYPE) {
        this.changeValueDataType($event, index);
      } else if ($event.name === actionKeys.VALUE_TYPE) {
        this.changeValueType($event, index);
      } else if ($event.name === actionKeys.VALUE) {
        this.changeValue($event, index);
      } else if ($event.name === roundEvents.ADD_ROUND) {
        this.addRoundToSetStep({ setStepId: $event.setStepId });
      } else if ($event.name === roundEvents.REMOVE_ROUND) {
        this.removeRoundFromSetStep({
          roundId: $event.id,
          setStepId: $event.setStepId
        });
      } else if ($event.name === roundEvents.ROUND_DECIMAL) {
        this.amendRoundInstance({
          roundId: $event.id,
          decimal: $event.event
        });
      }
    },
    onSetStepCreate($event, index) {
      if ($event.name === actionKeys.VARIABLE_NAME) {
        this.createVariableName($event, index);
      }
    },
    setSuccessStepToBeInvalid({ event = "", id = 0, index = -1 } = {}) {
      const successSetStep = this.getSetStepBasedOnId(id);
      successSetStep.setVariableNameSearchValue(event);
      successSetStep.setVariableNameOptions(
        this.getListOfSettableOptionsPerEachStep[index]
      );
      successSetStep.setIsReadOnly(true);
      successSetStep.setValueDataTypeText("");
      successSetStep.setValueDataType("");
      successSetStep.setHideNewExisting(true);
      successSetStep.setComponentValue("");
      successSetStep.setComponent({});
      successSetStep.setComponentOptions({});
      successSetStep.setVariableScope("");
      successSetStep.setVariableType("");
    },
    amendVariableNameSuccessSetStep({ event = "", id = 0, index = -1 } = {}) {
      const isSettableOption = !!this.getListOfSettableOptionsPerEachStep[
        index
      ].find(({ value }) => value === event);
      if (isSettableOption || this.getSetStepBasedOnVariableName(event)) {
        this.changeVariableName({ event, id, index });
      } else {
        this.setSuccessStepToBeInvalid({ event, id, index });
      }
    },
    changeVariableName({ event, id, index }) {
      const setStep = this.getSetStepBasedOnId(id);
      const providerSetStep = this.getProviderSteStepForChangeVariableName({
        event,
        index,
        setStep
      });
      const isReadOnly =
        this.isSettableExpectedData(providerSetStep.variableNameSearchValue) ||
        !!this.getAboveStepBasedOnVariableName(index, event);

      setStep.setVariableNameSearchValue(
        providerSetStep.variableNameSearchValue
      );
      setStep.setVariableNameOptions(
        this.makeOptionsForSelect(
          event,
          this.getListOfSettableOptionsPerEachStep[index]
        )
      );
      setStep.setVariableType(providerSetStep.variableType);
      setStep.setVariableScope(providerSetStep.variableScope);
      setStep.setValueDataTypeOptions(providerSetStep.valueDataTypeOptions);
      setStep.setValueDataType(providerSetStep.valueDataType);
      setStep.setValueDataTypeText(providerSetStep.valueDataTypeText);
      setStep.setIsReadOnly(isReadOnly);
      setStep.setHideNewExisting(providerSetStep.isMiddleSelectHidden);
      setStep.setValueTypeOptions(providerSetStep.valueTypeOptions);
      setStep.setComponentValue(providerSetStep.componentValue);
      setStep.setIsHidden(providerSetStep.isHidden);
      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(providerSetStep),
          index,
          setStepId: setStep.id
        });
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
    },
    getProviderSteStepForChangeVariableName({ event, index, setStep }) {
      if (event) {
        return this.getStepBasedOnVariableName(index, event);
      } else {
        this.removeBelowValuesAssociatedToStep(index);
        this.validateUniqueAssignmentForProxyVariables("", index);
        this.removeSetStepEndOperations({ setStep });
        return this.makeSetStep();
      }
    },
    getSetStepBasedOnVariableName(name) {
      return this.functionSteps.find(
        ({ variableNameSearchValue }) => variableNameSearchValue === name
      );
    },
    getProviderSetStepForCreateVariableName(name) {
      const setStep = this.getSetStepBasedOnVariableName(name);
      return this.cloneDeep(setStep) || this.makeSetStep();
    },
    async createVariableName({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      const providerSetStep =
        this.getProviderSetStepForCreateVariableName(event);

      if (!this.isProxyValueDataType(providerSetStep.valueDataType)) {
        const isReadOnly =
          this.isSettableExpectedData(
            providerSetStep.variableNameSearchValue
          ) || !!this.getAboveStepBasedOnVariableName(index, event);
        const valueType = providerSetStep.variableNameSearchValue
          ? providerSetStep.getSelectedValueTypeOption().value
          : variableAssignmentType.LITERAL;
        setStep.setVariableNameSearchValue(
          providerSetStep.variableNameSearchValue || event
        );
        setStep.setVariableType(providerSetStep.variableType);
        setStep.setVariableScope(providerSetStep.variableScope);
        setStep.setValueDataTypeOptions(providerSetStep.valueDataTypeOptions);
        setStep.setValueDataType(providerSetStep.valueDataType);
        setStep.setValueDataTypeText(providerSetStep.valueDataTypeText);
        setStep.setIsReadOnly(isReadOnly);
        setStep.setHideNewExisting(providerSetStep.isMiddleSelectHidden);
        setStep.setValueTypeOptions(
          this.makeOptionsForSelect(valueType, providerSetStep.valueTypeOptions)
        );
        setStep.setComponent(providerSetStep.component);
        setStep.setComponentOptions(providerSetStep.componentOptions);
        setStep.setComponentValue(providerSetStep.componentValue);
        setStep.setIsHidden(providerSetStep.isHidden);
        this.validateUniqueAssignmentForProxyVariables("", index);
      } else {
        setStep.setIsReadOnly(false);
        setStep.setValueDataTypeOptions(this.makeFirstOptionForSelect());
        this.validateUniqueAssignmentForProxyVariables(event, index);
      }
    },
    changeValueDataTypeToExpression({ setStep, index }) {
      setStep.setVariableType(variableType.NUMERIC);
      setStep.setValueDataTypeText(
        variableTypeToDisplayTextMap[variableType.EXPRESSION]
      );
      setStep.setValueDataTypeOptions(
        this.makeOptionsForSelect(
          variableType.EXPRESSION,
          setStep.valueDataTypeOptions
        )
      );
      setStep.setValueDataType(variableType.EXPRESSION);
      setStep.setHideNewExisting(true);
      setStep.setValueTypeOptions(
        this.makeOptionsForSelect(
          variableAssignmentType.LITERAL,
          variableAssignmentOptionsWithNoValue
        )
      );
      setStep.setComponentValue([makeBracketDTO()]);
      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(setStep),
          index,
          setStepId: setStep.id
        });
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
      setStep.setIsHidden(false);

      this.amendSetStepOperations({ setStep });
    },
    changeValueDataTypeToConcatenation({ setStep, index }) {
      setStep.setVariableType(variableType.STRING);
      setStep.setValueDataTypeText(
        variableTypeToDisplayTextMap[variableType.CONCATENATION]
      );
      setStep.setValueDataTypeOptions(
        this.makeOptionsForSelect(
          variableType.CONCATENATION,
          setStep.valueDataTypeOptions
        )
      );
      setStep.setValueDataType(variableType.CONCATENATION);
      setStep.setHideNewExisting(true);
      setStep.setValueTypeOptions(
        this.makeOptionsForSelect(
          variableAssignmentType.LITERAL,
          variableAssignmentOptionsWithNoValue
        )
      );
      setStep.setComponentValue(this.makeConcatenationValueListDTO());
      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(setStep),
          index,
          setStepId: setStep.id
        });
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
      setStep.setIsHidden(false);

      this.amendSetStepOperations({ setStep });
    },
    changeValueDataTypeDefault({ event, setStep, index }) {
      setStep.setVariableType(event);
      setStep.setValueDataTypeText(variableTypeToDisplayTextMap[event]);
      setStep.setValueDataTypeOptions(
        this.makeOptionsForSelect(event, setStep.valueDataTypeOptions)
      );
      setStep.setHideNewExisting(event === variableType.PROXY);
      setStep.setValueDataType(event);
      setStep.setValueTypeOptions(
        this.makeOptionsForSelect(
          event === variableType.PROXY
            ? variableAssignmentType.ENTITY
            : variableAssignmentType.LITERAL,
          setStep.valueTypeOptions
        )
      );
      setStep.setComponentValue(undefined);
      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(setStep),
          index,
          setStepId: setStep.id
        });
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
      setStep.setIsHidden(false);
    },
    changeValueDataType({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      this.resetVariableReassignments({
        stepIndex: index,
        variableNameSearchValue: setStep.variableNameSearchValue
      });

      if (this.isExpressionValueDataType(event)) {
        this.changeValueDataTypeToExpression({ setStep, index });
      } else if (this.isConcatenationValueDataType(event)) {
        this.changeValueDataTypeToConcatenation({ setStep, index });
      } else {
        this.changeValueDataTypeDefault({ setStep, index, event });
      }
    },
    resetVariableReassignments({ stepIndex, variableNameSearchValue }) {
      if (stepIndex + 1 < this.functionSteps.length) {
        for (let i = stepIndex + 1; i <= this.functionSteps.length; i++) {
          if (
            this.functionSteps[i]?.stepType === actionStepType.SET &&
            this.functionSteps[i]?.variableNameSearchValue ===
              variableNameSearchValue
          ) {
            this.resetSetStepData(this.functionSteps[i], i);
          }
        }
      }
    },
    changeValueType({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      setStep.setComponentValue(undefined);
      setStep.setValueTypeOptions(
        this.makeOptionsForSelect(event, setStep.valueTypeOptions)
      );
      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(setStep),
          index,
          setStepId: id
        });
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
    },
    amendSetStepOperations({ setStep }) {
      const deletedOperations = [];
      const endOperationsForType = setStep.endOperations.filter(
        ({ endOperationType, id } = {}) => {
          const isOperationIncluded =
            endOperationsPerType[setStep.variableType]?.includes(
              endOperationType
            ) || false;

          if (!isOperationIncluded)
            deletedOperations.push({ endOperationType, id });

          return isOperationIncluded;
        }
      );
      setStep.setEndOperations(endOperationsForType);

      deletedOperations.forEach(({ endOperationType, id }) => {
        if (endOperationType === endOperationsMap.ROUND) {
          this.removeIdFromRoundList({ roundId: id });
        }
      });
    },
    removeSetStepEndOperations({ setStep = {} } = {}) {
      setStep?.endOperations?.forEach(({ endOperationType, id }) => {
        if (endOperationType === endOperationsMap.ROUND) {
          this.removeRoundFromSetStep({
            setStepId: setStep.id,
            roundId: id
          });
        }
      });
    },
    addRoundToSetStep({ setStepId }) {
      const roundInstance = this.makeRoundOperationAndExtendRoundList();

      const { setStep } = this.setStepList.find(
        ({ setStep }) => setStep.id === setStepId
      );
      setStep.endOperations.push(roundInstance);
      setStep.setEndOperations(setStep.endOperations);
    },
    removeRoundFromSetStep({ roundId, setStepId }) {
      const { setStep } = this.setStepList.find(
        ({ setStep }) => setStep.id === setStepId
      );
      const endOperations = setStep.endOperations.filter(
        ({ id }) => id !== roundId
      );
      setStep.setEndOperations(endOperations);
      this.removeIdFromRoundList({ roundId });
    },
    getSetStepBasedOnId(id) {
      return (
        this.setStepList.find(({ setStep }) => setStep.id === id)?.setStep || {}
      );
    },
    changeValueForLiteralExpression({ event, successSetStep, index }) {
      this.amendBracketSection(event, successSetStep.componentValue);
      const componentOptions = {
        bracketSection: this.makeBracketSectionsObj(
          successSetStep.componentValue,
          this.makeOptionsForExistingValueSelect(successSetStep, index)
        )
      };
      successSetStep.setComponentOptions(componentOptions);
    },
    changeValueForLiteralMultiselect({ successSetStep, event }) {
      let componentValue = Array.isArray(successSetStep.componentValue)
        ? successSetStep.componentValue
        : [];
      if (event.eventType === operations.ADD) {
        componentValue.push(event.value);
      } else if (event.eventType === operations.DELETE) {
        componentValue = componentValue.filter(
          (value) => value !== event.value
        );
      }
      successSetStep.setComponentValue(componentValue);
    },
    changeValueForLiteral({ successSetStep, event, index }) {
      if (this.isExpressionValueDataType(successSetStep.valueDataType)) {
        this.changeValueForLiteralExpression({
          event,
          successSetStep,
          index
        });
      } else if (
        this.isMultipleSelectionExpectedForEntityPropertyName(
          successSetStep.variableNameSearchValue
        )
      ) {
        this.changeValueForLiteralMultiselect({ successSetStep, event });
      } else {
        successSetStep.setComponentValue(event);
      }

      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(successSetStep),
          index,
          setStepId: successSetStep.id
        });
      successSetStep.setComponent(component);
      successSetStep.setComponentOptions(componentOptions);
    },
    makeTextForProxyLink(formId = "") {
      return formId ? "Legend" : "";
    },
    changeValueForEntityProxy({ successSetStep, event, componentOptions }) {
      const { formId } = this.getAllEntities[event]?.data?.data?.context || {};
      successSetStep.setComponentOptions({
        ...componentOptions,
        routerLinkOptions: {
          to: urls.QUESTIONS_REFERENCE(formId),
          target: "_blank"
        },
        linkName: this.makeTextForProxyLink(formId)
      });
      this.fetchParsedProxyExpectedDataWrapper({
        setStep: successSetStep,
        payload: this.makeFetchProxyExpectedDataPayload(successSetStep)
      });
    },
    changeValueForEntity({ successSetStep, event, index }) {
      successSetStep.setComponentValue(event);

      const { component = null, componentOptions = null } =
        this.makeValueComponent({
          functionStep: makeFlatSetStepDTO(successSetStep),
          index,
          setStepId: successSetStep.id
        });
      successSetStep.setComponent(component);

      if (this.isProxyValueDataType(successSetStep.valueDataType)) {
        this.changeValueForEntityProxy({
          successSetStep,
          event,
          componentOptions
        });
      } else {
        successSetStep.setComponentOptions(componentOptions);
      }
    },
    changeValue({ event, id }, index) {
      const successSetStep = this.getSetStepBasedOnId(id);
      const selectedValueType =
        getSelectedOption(successSetStep.valueTypeOptions)?.value || "";

      if (this.isLiteralValueType(selectedValueType)) {
        this.changeValueForLiteral({
          event,
          successSetStep,
          index
        });
      } else if (this.isEntityValueType(selectedValueType)) {
        this.changeValueForEntity({
          successSetStep,
          event,
          index
        });
      }
    },
    async fetchAllProxyExpectedData() {
      const proxySetStepList = this.setStepList.reduce((acc, { setStep }) => {
        if (this.isProxyValueDataType(setStep.valueDataType)) {
          acc.push(
            this.fetchParsedProxyExpectedDataWrapper({
              setStep,
              payload: {
                ...(this.makeParsedExpectedDataAPIDTO(
                  this.getAllEntities[setStep.componentValue]
                )?.data?.data || {}),
                name: setStep?.variableNameSearchValue
              }
            })
          );
        }
        return acc;
      }, []);
      await Promise.allSettled(proxySetStepList);
    },
    updateSetSteps() {
      this.setStepList.forEach(({ stepIndex, setStep }) => {
        const isCRUDStep = this.isCRUDSetStep(setStep.id);
        const isPreviouslyDefined = !!this.getListOfSettableOptionsPerEachStep[
          stepIndex
        ]?.find(({ value }) => value === setStep.variableNameSearchValue);
        setStep.setIsReadOnly(isPreviouslyDefined);
        const { value: selectedValueTypeOption } = getSelectedOption(
          setStep.valueTypeOptions
        );

        if (!isCRUDStep && !isPreviouslyDefined) {
          this.resetSetStepData(setStep, stepIndex);
        } else {
          if (this.isEntityValueType(selectedValueTypeOption)) {
            const tempFunctionStep = {
              variableName: setStep.variableNameSearchValue,
              variableType: setStep.variableType,
              valueDataType: setStep.valueDataType,
              valueType: setStep.variableScope,
              value: setStep.componentValue
            };

            if (this.isProxyValueDataType(setStep.valueDataType)) {
              const options = this.makeOptionsForExistingValueSelect(
                tempFunctionStep,
                stepIndex
              );
              const { formId } =
                this.getAllEntities[setStep.componentValue]?.data?.data
                  ?.context || {};
              setStep.setComponentOptions({
                ...setStep.componentOptions,
                componentOptions: {
                  ...setStep.componentOptions.componentOptions,
                  options
                },
                routerLinkOptions: {
                  to: urls.QUESTIONS_REFERENCE(formId),
                  target: "_blank"
                },
                linkName: this.makeTextForProxyLink(formId)
              });
            } else {
              setStep.setComponentOptions({
                ...setStep.componentOptions,
                options: this.makeOptionsForExistingValueSelect(
                  tempFunctionStep,
                  stepIndex
                )
              });
            }
          } else if (this.isBooleanValueDataType(setStep.valueDataType)) {
            setStep.setComponentOptions({
              ...setStep.componentOptions,
              options: this.makeOptionsForSelect(
                `${setStep.componentValue}`,
                variableValueBooleanOptions
              )
            });
          } else if (this.isExpressionValueDataType(setStep.valueDataType)) {
            setStep.setComponentOptions({
              bracketSection: this.makeBracketSectionsObj(
                setStep.componentValue,
                this.makeOptionsForExistingValueSelect(
                  {
                    ...makeFlatSetStepDTO(setStep),
                    valueDataType: variableType.NUMERIC
                  },
                  stepIndex,
                  false
                )
              )
            });
          } else if (this.isConcatenationValueDataType(setStep.valueDataType)) {
            setStep.componentOptions.formRowsList.forEach(({ formRowList }) => {
              formRowList.forEach(({ componentOptions }) => {
                const options = this.makeOptionsForExistingValueSelect(
                  {
                    ...makeFlatSetStepDTO(setStep),
                    valueDataType: variableType.STRING
                  },
                  stepIndex,
                  false
                );
                componentOptions.options = this.makeOptionsForSelect(
                  componentOptions.searchValue,
                  options
                );
              });
            });
          }

          setStep.setVariableNameOptions(
            this.makeOptionsForSelect(
              setStep.variableNameSearchValue,
              this.getListOfSettableOptionsPerEachStep[stepIndex]
            )
          );
        }
      });
    },
    resetSetStepData(setStep, stepIndex) {
      const emptyStep = this.makeSetStep(undefined, stepIndex);
      setStep.setVariableNameSearchValue("");
      setStep.setVariableNameOptions(emptyStep.variableNameOptions);
      setStep.setVariableType(emptyStep.variableType);
      setStep.setVariableScope(emptyStep.variableScope);
      setStep.setValueDataTypeOptions(emptyStep.valueDataTypeOptions);
      setStep.setValueDataType(emptyStep.valueDataType);
      setStep.setValueDataTypeText(emptyStep.valueDataTypeText);
      setStep.setIsReadOnly(false);
      setStep.setHideNewExisting(false);
      setStep.setValueTypeOptions(emptyStep.valueTypeOptions);
      setStep.setComponent(emptyStep.component);
      setStep.setComponentOptions(emptyStep.componentOptions);
      setStep.setComponentValue(emptyStep.componentValue);
      setStep.setIsHidden(emptyStep.isHidden);
    },
    deleteSetStepIndexFromList(index) {
      this.setStepList = this.setStepList.filter(({ stepIndex, setStep }) => {
        if (
          this.isProxyValueDataType(setStep.valueDataType) &&
          stepIndex === index
        ) {
          delete this.proxyExpectedData[setStep.id];
        }
        return stepIndex !== index;
      });
    },
    deleteSetStepIdFromList(id) {
      this.setStepList = this.setStepList.filter(
        ({ setStep }) => setStep.id !== id
      );
    },
    updateSetStepsIndexes(editType, index) {
      for (
        let successSetStepsIndex = 0;
        successSetStepsIndex < this.setStepList.length;
        successSetStepsIndex++
      ) {
        if (this.setStepList[successSetStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.setStepList[successSetStepsIndex].stepIndex >= index
          ) {
            this.setStepList[successSetStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.setStepList[successSetStepsIndex].stepIndex > index
          ) {
            this.setStepList[successSetStepsIndex].stepIndex -= 1;
          }
        }
      }
    },
    updateCalculationBracket({ index, property, event }, calculationList) {
      if (property === actionKeys.COMPONENT) {
        if (event.type === operations.ADD) {
          calculationList[index].bracket.splice(
            event.index,
            0,
            makeCalculationStepDTO()
          );
        } else if (event.type === operations.DELETE) {
          calculationList[index].bracket.splice(event.index, 1);
        } else if (event.type === operations.EDIT) {
          calculationList[index].bracket[event.index][event.property] =
            event.value;
        }
      } else {
        calculationList[index][property] = event;
      }
    },
    makeNewSetStep(stepIndex) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeSetStepAndExtendSetStepList(undefined, this.addBlockIndex)
      );
    }
  }
};
