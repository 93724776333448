const validationKeys = {
  titles: {
    INCORRECT_FILE_TYPE: "incorrectFileType",
    ERROR: "error",
    VALIDATION_ERROR: "validationError",
    SYSTEM_ERROR: "systemError"
  },
  REQUIRED: "required",
  NUMBER: "number",
  MIN_LENGTH: "minLength",
  MAX_LENGTH: "maxLength",
  MAX_LENGTH_255: "maxLength255",
  IS_VALID_URL: "isValidUrl",
  IS_VALID_MAIL_TO_LINK: "isValidMailToLink",
  IS_VALID_LINK: "isValidLink",
  IS_VALID_EMAIL: "isValidEmail",
  IS_UNIQUE_IN_LIST: "isUniqueInList",
  ALPHA_NUMERIC: "alphaNumeric",
  DUPLICATE_EMAIL: "duplicateEmail",
  ADD_LEARNER_ERROR: "addLearnerError",
  FETCH_TRAINING_ERROR: "fetchTrainingError",
  INVALID_QUERY_FILTER: "invalidQueryFilter",
  INCORRECT_FILE_TYPE_ONLY_XLSX: "incorrectFileTypeOnlyAllowXLSX",
  EXCEL_VALIDATION_ERROR: "excelValidationError",
  ONLY_UPLOAD_ONE_FILE: "onlyUploadOneFile",
  UNEXPECTED_ERROR: "unexpectedError",
  ADD_DATA_ERROR: "addDataError"
};

const errorLinkMessages = {
  [validationKeys.REQUIRED]: "Please enter a link.",
  [validationKeys.IS_VALID_URL]:
    "Link must start with mailto:, https:// or http://.",
  [validationKeys.IS_VALID_MAIL_TO_LINK]:
    "Please enter a valid email starting with mailto:",
  [validationKeys.IS_VALID_LINK]: "Please enter a valid link."
};

const errorMessages = {
  companyName: {
    [validationKeys.REQUIRED]: "Please enter the legal company name."
  },
  companyAddress: {
    [validationKeys.REQUIRED]: "Please enter the company address."
  },
  legalDisclaimer: {
    [validationKeys.REQUIRED]: "Please enter the legal disclaimer."
  },
  copyrightNotice: {
    [validationKeys.REQUIRED]: "Please enter the copyright notice link."
  },
  companyNameAbbreviation: {
    [validationKeys.REQUIRED]:
      "Please enter the legal company name abbreviation."
  },
  releaseNoteLink: {
    ...errorLinkMessages
  },
  privacyCookieLink: {
    ...errorLinkMessages
  },
  faqLink: {
    ...errorLinkMessages
  },
  supportEmailLink: {
    ...errorLinkMessages
  },
  requestSupportLink: {
    ...errorLinkMessages
  },
  tncLink: {
    ...errorLinkMessages
  },
  companyWebsite: {
    ...errorLinkMessages,
    [validationKeys.REQUIRED]: "Please enter the company website link."
  },
  knowledgeCenterLink: {
    ...errorLinkMessages
  },
  attributeName: {
    [validationKeys.REQUIRED]: "Attribute name is required."
  },
  attributeDataType: {
    [validationKeys.REQUIRED]: "Attribute type is required."
  },
  attributeSubtype: {
    [validationKeys.REQUIRED]: "Attribute subtype is required."
  },
  username: {
    [validationKeys.REQUIRED]: "Enter your username."
  },
  password: {
    [validationKeys.REQUIRED]: "Enter your password."
  },
  forgotPasswordEmail: {
    [validationKeys.REQUIRED]: "Please enter an email address.",
    [validationKeys.IS_VALID_EMAIL]: "Please enter a valid email address."
  },
  productId: {
    [validationKeys.REQUIRED]: "Please select any item from list."
  },
  actionName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action name must not exceed 255 characters in length."
  },
  actionDescription: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action description must not exceed 255 characters in length."
  },
  expectedVariableName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.IS_UNIQUE_IN_LIST]: "Name has to be unique."
  },
  expectedVariableType: {
    [validationKeys.REQUIRED]: "Required"
  },
  variableName: {
    [validationKeys.REQUIRED]: "Required"
  },
  valueDataType: {
    [validationKeys.REQUIRED]: "Required"
  },
  value: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.NUMBER]: "Requires a number."
  },
  valueType: {
    [validationKeys.REQUIRED]: "Required"
  },
  functionStepsList: {
    [validationKeys.MIN_LENGTH]: "The function body is required."
  },
  nextActionStep: {
    [validationKeys.REQUIRED]: "Required"
  }
};

const customErrorMessages = {
  actions: {
    variableName: {
      [validationKeys.IS_UNIQUE_IN_LIST]: "The variable name is already in use."
    },
    value: {
      [validationKeys.NUMBER]: "Require a number."
    },
    functionStepsList: {
      [validationKeys.MIN_LENGTH]: "At least 1 step is required."
    }
  },
  training: {
    [validationKeys.DUPLICATE_EMAIL]:
      "A learner with the provided email is already registered on the course, please try a new one.",
    [validationKeys.ADD_LEARNER_ERROR]:
      "An error occurred while adding a learner, please try again.",
    [validationKeys.FETCH_TRAINING_ERROR]:
      "Failed to retrieve the available training list."
  },
  tasks: {
    [validationKeys.INVALID_QUERY_FILTER]: (filter) =>
      `Removed invalid filter '${filter}'.`
  },
  manageThirdParties: {
    [validationKeys.UNEXPECTED_ERROR]:
      "We've encountered an issue with the data selected for export. Please contact Support for assistance.",
    [validationKeys.INVALID_QUERY_FILTER]:
      "The selected export does not match any third party on the platform. You can either double-check the selected export and try again, or contact support."
  },
  uploadingFiles: {
    [validationKeys.EXCEL_VALIDATION_ERROR]:
      "We've encountered one or more issues with the Excel file that prevent it from being processed correctly. Please go through the list of validation errors below, correct them in the Excel file, and then re-upload it. If you need assistance with this, please contact the Support team.",
    [validationKeys.INCORRECT_FILE_TYPE_ONLY_XLSX]:
      "Only .xlsx files are allowed. Please upload an Excel file in the .xlsx format and try again. If you need assistance, please contact Support.",
    [validationKeys.ONLY_UPLOAD_ONE_FILE]: "Please only upload one file."
  },
  generic: {
    titles: {
      [validationKeys.titles.INCORRECT_FILE_TYPE]: "Error: Incorrect File Type",
      [validationKeys.titles.ERROR]: "Error",
      [validationKeys.titles.VALIDATION_ERROR]: "Validation Error",
      [validationKeys.titles.SYSTEM_ERROR]: "System Error"
    },
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]: (value) => `Maximum of ${value} characters.`,
    [validationKeys.UNEXPECTED_ERROR]:
      "We’ve encountered an unexpected error. Please try again later. If the issue persists, contact support for assistance.",
    [validationKeys.ADD_DATA_ERROR]: (value) =>
      `An error occurred while adding a ${value}, Please contact support for further assistance.`
  }
};

const infoKeys = {
  thirdParty: {
    VERIFY_ENTITY_OPTIONAL: "verifyEntityOptional",
    SUCCESS_SEARCH: "successSearch",
    SUBSCRIPTION_MATCH: "subscriptionMatch",
    NO_MATCHES_FOUND: "noMatchesFound",
    ADD_PREMIUM_CORPORATE_DATA: "addPremiumCorporateData",
    ENABLE_PREMIUM_CORPORATE_DATA: "enablePremiumCorporateData",
    VERIFIED_DETAILS: "verifiedDetails",
    NO_TRAINING_LIST_FEEDBACK: "noTrainingListFeedback",
    IDD_INFO_MESSAGE: "iddInfoMessage"
  },
  generic: {
    NUMBER_OF_EMAILS: "numberOfEmails"
  }
};

const infoMessages = {
  thirdParty: {
    [infoKeys.thirdParty.VERIFY_ENTITY_OPTIONAL]:
      "An instant global search for registered companies matching this third party, to help you confirm that you are dealing with the correct legal entity.",
    [infoKeys.thirdParty.SUCCESS_SEARCH]:
      "We have found these potential matches for your third party. If one of these is the correct legal entity, please select it and confirm. This will enable you to access a wider range of services.",
    [infoKeys.thirdParty.SUBSCRIPTION_MATCH]:
      "<b>Note:</b> If you are subscribed to IDD or IDD+ screening services, you can run these with or without selecting a match.",
    [infoKeys.thirdParty.NO_MATCHES_FOUND]:
      "<b>No Matches Found.</b> For Premium Corporate Data or Ultimate Beneficial Owner services, a match with the correct legal entity is required. If you want to use these services, please amend the third party details and try again.<br><br><b>Note:</b> If you are subscribed to IDD or IDD+, you can still run these screening services. A match with a legal entity is not required.",
    [infoKeys.thirdParty.ADD_PREMIUM_CORPORATE_DATA]:
      "If you want to view this information, you must add Premium Corporate Data to this third party.",
    [infoKeys.thirdParty.ENABLE_PREMIUM_CORPORATE_DATA]:
      "To enable please upgrade to Premium Corporate Data.",
    [infoKeys.thirdParty.VERIFIED_DETAILS]:
      "These company details were captured at the time of verification. They are static, and will not update to reflect real-world changes. If you require ongoing monitoring to keep these details up to date, you can add Premium Corporate Data to this third party.",
    [infoKeys.thirdParty.NO_TRAINING_LIST_FEEDBACK]:
      "This third party doesn't have any Training present and will appear once Training product has been ordered. If this product has not been activated on your account, please speak with your Account Manager.",
    [infoKeys.thirdParty.IDD_INFO_MESSAGE]:
      "If you are subscribed to IDD or IDD+, you can still run these screening services. A match with a legal entity is not required"
  },
  downloadableList: {
    [infoKeys.generic.NUMBER_OF_EMAILS]: ({ numberOfEmails = 0 } = {}) =>
      `Your request has been submitted successfully. You will receive your template via email shortly. You are expecting to receive ${numberOfEmails} email${
        numberOfEmails > 1 ? "s" : ""
      } shortly.`
  },
  manageThirdParties: {
    [infoKeys.generic.NUMBER_OF_EMAILS]: ({ numberOfEmails = 0 } = {}) =>
      `Your request has been submitted, and the export data will be sent to you in ${numberOfEmails} email${
        numberOfEmails > 1 ? "s" : ""
      }. Delivery time may vary depending on the size of the export, but you can use the Event Log to confirm when delivery is complete - look for the event type 'Filtered Export Completed' with the tag 'BULK, SUCCESS'.`
  }
};

export {
  customErrorMessages,
  errorMessages,
  errorLinkMessages,
  validationKeys,
  infoKeys,
  infoMessages
};
