import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeProductListDTO, makeProductAPIDTO } from "./product.dto";

const fetchPlatformProductList = async ({ tenantId }) => {
  const result = await HTTP(
    "get",
    endpoints.FETCH_PRODUCT_LIST,
    null,
    makeAuthorizationHeader({ tenantId })
  );
  result.data = makeProductListDTO({
    products: result?.data?.courses
  });
  return result;
};

const fetchAvailableProductList = async () => {
  const result = await HTTP(
    "get",
    endpoints.FETCH_PRODUCT_LIST,
    null,
    makeAuthorizationHeader()
  );
  result.data = makeProductListDTO({
    products: result?.data?.courses
  });
  return result;
};

const assignAvailableProductToPlatform = async ({ tenantId, courseIds } = {}) =>
  HTTP(
    "post",
    endpoints.ASSIGN_AVAILABLE_PRODUCT_TO_PLATFORM,
    makeProductAPIDTO(courseIds),
    makeAuthorizationHeader({ tenantId })
  );

export const productService = {
  fetchPlatformProductList,
  fetchAvailableProductList,
  assignAvailableProductToPlatform
};
