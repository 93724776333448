import { languageKeysDTO, languageKeysAPIDTO } from "@/constants";

const makeLanguagesDTO = ({ languages = [] }) =>
  languages?.map?.((language) => ({
    [languageKeysDTO.ID]: language[languageKeysAPIDTO.ID],
    [languageKeysDTO.LANGUAGE_CODE]: language[languageKeysAPIDTO.LANGUAGE_CODE],
    [languageKeysDTO.TEXT]: language[languageKeysAPIDTO.TEXT],
    [languageKeysDTO.VALUE]: language[languageKeysAPIDTO.VALUE]
  })) || [];

export { makeLanguagesDTO };
