import { state as makeState } from "./permissions.state.store";

export const mutations = {
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  setFeaturePermissions(state, payload) {
    state.featurePermissions = payload;
  },
  setIsFeaturePermissionsLoading(state, payload) {
    state.isFeaturePermissionsLoading = payload;
  },
  setIsUpdatingFeaturePermissions(state, payload) {
    state.isUpdatingFeaturePermissions = payload;
  },
  setIsUserPermissionsLoading(state, payload) {
    state.isUserPermissionsLoading = payload;
  },
  setIsUserPermissionsSubsetLoading(state, payload) {
    state.isUserPermissionsSubsetLoading = payload;
  },
  resetPermissions(state) {
    Object.assign(state, makeState());
  }
};
