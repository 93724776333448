import { mapGetters, mapState, mapActions } from "vuex";
import { getterName, actionName, themes } from "@/constants";
import {
  isExpectedDataEntityType,
  isExpectedDataInstanceSubType,
  isExpectedDataPrimitiveType,
  makeUUID
} from "@/utils";
import { makeExpectedDataPayloadAPIDTO } from "@/services/expectedData/dto/expectedData.dto";
import { expectedDataService } from "@/services";
import {
  validateExpectedDataNameSpelling,
  validateExpectedData,
  hasExpectedDataVariableSubType,
  getLegendRouteLink,
  makeExpectedDataBaseObj,
  makeExpectedDataObjFromEntityType,
  makeExpectedDataObjFromInstanceSubType,
  makeExpectedDataObjFromPrimitiveType
} from "./expectedData.mixin.logic";
import Input from "@/molecules/Input/Input";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { RouterLink } from "vue-router";
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  data() {
    return {
      proxyExpectedData: {},
      expectedData: [],
      detailedExpectedData: [],
      isFetchParsedExpectedDataNeeded: true
    };
  },
  computed: {
    ...mapGetters({
      getExpectedDataGroupedOptions:
        getterName.EXPECTED_DATA.GET_EXPECTED_DATA_GROUPED_OPTIONS,
      getExpectedDataDDQTasks:
        getterName.EXPECTED_DATA.GET_EXPECTED_DATA_DDQ_TASKS
    }),
    ...mapState({
      getExpectedData: (state) => state.expectedData.expectedData,
      companyId: (state) => state.company.companyId
    }),
    expectedDataTypeOptions() {
      return this.getExpectedDataGroupedOptions?.types
        ? Object.values(this.getExpectedDataGroupedOptions?.types)
        : [];
    },
    makeExpectedDataListComponents() {
      const expectedDataListComponents = {
        formRowsList: [],
        formCtaList: [this.makeExpectedDataFormRowsAddCTA()]
      };
      return (
        this.expectedData?.reduce((acc, expectedDataObj, index) => {
          const formRow = {
            id: makeUUID(),
            formRowList: [],
            editCtaList: []
          };

          formRow.formRowList.push(
            this.makeExpectedDataNameComponent({
              index,
              value: expectedDataObj.expectedVariableName,
              error: expectedDataObj.expectedVariableNameErrorMessage
            }),
            this.makeExpectedDataTypeComponent({
              index,
              value: expectedDataObj.expectedVariableType,
              error: expectedDataObj.expectedVariableTypeErrorMessage
            })
          );

          if (
            this.isExpectedDataSubTypeAvailable(
              expectedDataObj.expectedVariableType
            )
          ) {
            formRow.formRowList.push(
              this.makeExpectedDataSubTypeComponent({
                index,
                value: expectedDataObj.expectedVariableSubType,
                type: expectedDataObj.expectedVariableType,
                error: expectedDataObj.expectedVariableSubTypeErrorMessage
              })
            );
          } else {
            formRow.formRowList.push({});
          }

          if (this.hasExpectedDataVariableSubType(expectedDataObj)) {
            formRow.formRowList.push(
              this.makeExpectedDataReferenceComponent(expectedDataObj)
            );
          } else {
            formRow.formRowList.push({});
          }

          formRow.editCtaList = this.makeExpectedDataFormRowEditCTAs(index);

          acc.formRowsList.push(formRow);
          return acc;
        }, expectedDataListComponents) || expectedDataListComponents
      );
    }
  },
  methods: {
    ...mapActions({
      fetchExpectedData: actionName.EXPECTED_DATA.FETCH_EXPECTED_DATA
    }),
    validateExpectedDataNameSpelling,
    validateExpectedData,
    hasExpectedDataVariableSubType,
    getLegendRouteLink,
    makeExpectedDataBaseObj,
    makeExpectedDataObjFromEntityType,
    makeExpectedDataObjFromInstanceSubType,
    makeExpectedDataObjFromPrimitiveType,
    makeExpectedDataNameComponent({ index = 0, value = "", error = "" } = {}) {
      return {
        component: Input,
        componentOptions: {
          label: "Expected data name",
          id: `expected-data-name-${index}`,
          name: "expectedDataName",
          "data-test-id": `expected-data-name-${index}`,
          value,
          error,
          onChange: (event) => this.onChangeExpectedDataName({ index, event })
        },
        id: makeUUID()
      };
    },
    makeExpectedDataTypeComponent({ index = 0, value = "", error = "" } = {}) {
      return {
        component: SearchSelect,
        componentOptions: {
          label: "Expected data type",
          id: `expected-data-type-${index}`,
          name: "expectedDataType",
          "data-test-id": `expected-data-type-${index}`,
          options: makeOptionsForSelect(value, this.expectedDataTypeOptions),
          error,
          onChange: (event) => this.onChangeExpectedDataType({ index, event })
        },
        id: makeUUID()
      };
    },
    makeExpectedDataSubTypeComponent({
      index = 0,
      value = "",
      type = "",
      error
    } = {}) {
      return {
        component: SearchSelect,
        componentOptions: {
          label: "Expected data sub type",
          id: `expected-data-sub-type-${index}`,
          name: "expectedDataSubType",
          "data-test-id": `expected-data-sub-type-${index}`,
          options: makeOptionsForSelect(
            value,
            this.getVariableSubTypeOptions(type)
          ),
          error,
          onChange: (event) =>
            this.onChangeExpectedDataSubType({ index, event })
        },
        id: makeUUID()
      };
    },
    makeExpectedDataReferenceComponent({ expectedVariableSubType }) {
      return {
        component: RouterLink,
        componentOptions: {
          class: "form-row-wrapper__list-item--center-vertically",
          to: this.getLegendRouteLink({
            ddqName: expectedVariableSubType,
            expectedDataDDQ: this.getExpectedDataDDQTasks
          }),
          "data-test-id": "expected-data-legend-link",
          target: "_blank"
        },
        customText: "Legend"
      };
    },
    makeExpectedDataFormRowEditCTAs(index) {
      return [this.makeExpectedDataFormRowDeleteCTA(index)];
    },
    makeExpectedDataFormRowDeleteCTA(index) {
      return {
        component: CallToAction,
        id: makeUUID(),
        componentOptions: {
          class: "form-row-wrapper__edit--delete",
          icon: "trash-alt",
          size: 20,
          theme: themes.GREY,
          "data-test-id": "form-row-wrapper__edit--delete",
          onClick: () => this.deleteExpectedData(index)
        }
      };
    },
    makeExpectedDataFormRowsAddCTA() {
      return {
        component: CallToAction,
        id: makeUUID(),
        componentOptions: {
          class: "form-rows-wrapper__edit-cta",
          type: "button",
          value: "Add",
          theme: themes.SECONDARY,
          "data-test-id": "form-rows-wrapper__edit-cta",
          onClick: () => this.addExpectedData()
        }
      };
    },
    onChangeExpectedDataName({ index = 0, event = "" } = {}) {
      this.expectedData[index].expectedVariableName = event;
      this.isFetchParsedExpectedDataNeeded = true;

      this.validateExpectedDataNameSpelling({
        expectedData: this.expectedData,
        index
      });
    },
    onChangeExpectedDataType({ index = 0, event = "" } = {}) {
      this.expectedData[index].expectedVariableType = event;
      this.expectedData[index].expectedVariableSubType = "";
      this.isFetchParsedExpectedDataNeeded = true;
    },
    onChangeExpectedDataSubType({ index = 0, event = "" } = {}) {
      this.expectedData[index].expectedVariableSubType = event;
      this.isFetchParsedExpectedDataNeeded = true;
    },
    isExpectedDataSubTypeAvailable(type) {
      return !!this.getExpectedDataGroupedOptions.subTypes[type];
    },
    getVariableSubTypeOptions(type) {
      return this.getExpectedDataGroupedOptions?.subTypes[type];
    },
    deleteExpectedData(index) {
      this.expectedData = this.expectedData.filter(
        (_, position) => index !== position
      );
      this.isFetchParsedExpectedDataNeeded = true;
    },
    addExpectedData() {
      this.expectedData.push(this.makeExpectedDataBaseObj());
    },
    makeExpectedDataObj(expectedData = {}) {
      if (isExpectedDataEntityType(expectedData?.entity)) {
        return this.makeExpectedDataObjFromEntityType({
          expectedData,
          expectedDataList: this.getExpectedData
        });
      } else if (
        isExpectedDataInstanceSubType({ value: expectedData?.subType })
      ) {
        return this.makeExpectedDataObjFromInstanceSubType({ expectedData });
      } else if (isExpectedDataPrimitiveType(expectedData?.uniqueType)) {
        return this.makeExpectedDataObjFromPrimitiveType({ expectedData });
      }
    },
    async fetchParsedExpectedData() {
      const { data = [] } = await expectedDataService.fetchParsedExpectedData(
        this.companyId,
        makeExpectedDataPayloadAPIDTO({
          companyId: this.companyId,
          expectedDataList: this.expectedData,
          storeExpectedData: this.getExpectedData
        })
      );

      this.detailedExpectedData = data;
    },
    async fetchParsedProxyExpectedData({ id = 0, payload = {} } = {}) {
      this.proxyExpectedData[id] = {};
      const { data = {} } =
        await expectedDataService.fetchParsedProxyExpectedData(
          this.companyId,
          payload
        );
      this.proxyExpectedData[id] = data;
    }
  },
  async created() {
    if (!this.getExpectedData?.length) {
      await this.fetchExpectedData();
    }
  }
};
