<template id="template-radio">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      :class="isPreview ? 'mt-2' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation && fieldData.validation.required == 1
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
              <br />
              <span v-if="fieldData.hyperLinkTextBefore"
                >{{ fieldData.hyperLinkTextBefore[selectedLanguage] }}
              </span>
              <b-link
                v-if="fieldData.hyperLinkUrl"
                target="_blank"
                :href="fieldData.hyperLinkUrl[selectedLanguage]"
                >{{
                  fieldData.hyperLinkText
                    ? fieldData.hyperLinkText[selectedLanguage]
                    : fieldData.hyperLinkUrl[selectedLanguage]
                }}</b-link
              >
              <span v-if="fieldData.hyperLinkTextAfter">
                {{ fieldData.hyperLinkTextAfter[selectedLanguage] }}</span
              >
            </label>
            <div class="clearfix"></div>
            <b-form-radio-group
              :stacked="fieldData.configuration.isStacked"
              :disabled="isDisabled == 1"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              v-model="fieldData.selected"
              :options="radioOptions"
              value-field="value"
              text-field="text"
              @change="checkCondition"
            ></b-form-radio-group>
            <label
              v-if="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                !selected &&
                isFocus
              "
              class="text-danger"
              >{{ fieldData.validationMessage[selectedLanguage] }}</label
            >
          </b-form-group>

          <!-- conditional input field -->
          <b-collapse v-model="showCollapseField" class="mt-2">
            <div
              v-for="(input, index) in fieldData.selectedMutiInputs"
              :key="index"
            >
              <b-form-group
                :class="
                  $store.state.rightAlign
                    ? 'text-right ml-3 '
                    : 'text-left ml-3'
                "
                :description="
                  fieldData.conditionalFieldHelperText[selectedLanguage]
                "
                :label="fieldData.conditionalFieldLabel[selectedLanguage]"
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <b-form-textarea
                  v-model="conditionalFieldInputValue"
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  @input="onInputType"
                  :disabled="isDisabled == 1"
                  :placeholder="
                    fieldData.conditionalFieldPlaceHolderText[selectedLanguage]
                  "
                  :rows="fieldData.conditionalFieldMinRows || 3"
                  :max-rows="fieldData.conditionalFieldMaxRows || 6"
                  :state="
                    fieldData.validation.required == 0
                      ? null
                      : isFocus &&
                        conditionalFieldInputValue &&
                        isMatchCondition &&
                        fieldData.validation.required == 1
                      ? true
                      : false
                  "
                  @focus="isFocus = true"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-collapse>
          <div
            v-if="
              form1.formSection[sectionIndex].sectionTitle.english ===
                'Services/Goods (Additional Question)' &&
              selectedCountriesFromSectionFour.length
            "
          >
            <em class="text-italic">{{
              $t("questionnaires.additionalCountryBoxTitle")
            }}</em>
            <v-select
              :id="fieldData.id"
              v-model="selectedProductCountry"
              :options="selectedCountriesFromSectionFour"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              @update:modelValue="onSelectCountryFromSectionFour"
            ></v-select>
            <b-badge
              class="mr-2 mt-2"
              v-for="(product, index) in selectedProducts"
              :key="index"
              variant="primary"
              >{{ product.product }}</b-badge
            >
          </div>
        </b-col>
      </b-row>
      <!-- advance settings tab -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        no-close-on-backdrop
        @hidden="onUpdateSettings"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Radio button Advanced Settings"
      >
        <AdvanceRadioSettings
          v-if="showSettings"
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          :fieldIndex="fieldIndex"
          :isPreview="isPreview"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import AdvanceRadioSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";
import { Helper, HideSection } from "@/helpers";
import HighRiskProducts from "@/utils/highRiskProducts.json";
import _ from "lodash";
import { isCurrentSectionHidden } from "@/utils";
import { routeKeys, routeNames } from "@/constants";

export default {
  name: "RadioGroup",
  components: {
    AdvanceRadioSettings,
    MenuButtons
  },
  data() {
    return {
      selectedHA: null,
      selectedProductCountry: null,
      selectedCountriesFromSectionFour: [],
      selectedProducts: [],
      allProducts: [],
      selectedSubDivisions: [],
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      isRequired: 1,
      selected: this.fieldData.selected || "",
      radioOptions: [],
      conditionalFieldLabel: null,
      conditionalFieldHelperText: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldIsRequired: 0,
      conditionalFieldMaxRows: 1,
      conditionalFieldInputValue: null,
      conditionalFieldPostName: null,
      conditionalFieldIsVisible: 0,
      showCollapseField: false,
      isMatchCondition: false
    };
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (
        isCurrentSectionHidden(this.form1, this.fieldData.id) ||
        value === "progress"
      ) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (this.fieldData.isHidden) {
          if (value !== "progress") {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        } else {
          if (this.selected) {
            this.setValue(this.selected);
          }
          if (
            this.fieldData.validation &&
            this.fieldData.validation.required == 1
          ) {
            if (!this.selected) {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            } else if (this.selected) {
              if (
                this.form1.formTitle.english
                  .toLowerCase()
                  .includes("lieferkettengesetz") ||
                this.form1.formTitle.english
                  .toLowerCase()
                  .includes("human rights questionnaire")
              ) {
                this.$store.state.validFields = true;
                this.fieldData.isInValid = false;
              }
              if (
                this.fieldData.conditions &&
                this.fieldData.conditions.length
              ) {
                this.$store.state.validFields = true;
                this.fieldData.isInValid = false;
              }
              if (this.isMatchCondition) {
                if (!this.conditionalFieldInputValue) {
                  this.isFocus = true;
                  this.$store.state.validFields = false;
                  this.fieldData.isInValid = true;
                } else {
                  this.$store.state.validFields = true;
                  this.fieldData.isInValid = false;
                }
              }
            } else {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            }
          }
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      if (inValidData && inValidData.elementId) {
        document
          .getElementById(inValidData.elementId)
          .scrollIntoView({ behavior: "smooth" });
      }
    });

    EventBus.on("onUpdateRadioValues", () => {
      this.setFieldValue();
    });

    EventBus.on("getRefreshProducts", (products) => {
      this.allProducts = products;
    });
    EventBus.on("getRefreshCountries", (countries) => {
      this.selectedCountriesFromSectionFour = countries;
    });

    EventBus.on("onResetSelectedRadioValues", (ele) => {
      this.resetSelectedRadioValues(ele);
    });

    EventBus.on("refreshProductList", (value) => {
      let sections = this.form1.formSection;
      let sectionSeven = sections.find(
        (sec) =>
          sec.sectionTitle.english == "Services/Goods (Additional Question)"
      );
      if (sectionSeven?.formElements[0]?.id == this.fieldData.id) {
        this.selectedProductCountry = null;
        this.allProducts = [];
        this.selectedProducts = [];
        if (value == 0) {
          this.selectedCountriesFromSectionFour = [];
          HideSection.hideSection(
            this.$store.state,
            sectionSeven,
            this.selectedCountriesFromSectionFour
          );
        } else {
          this.getCountryListFromSectionFour();
          this.refreshProducts(
            this.$store.state,
            sectionSeven,
            this.selectedCountriesFromSectionFour
          );
        }
      }
    });
    EventBus.on("onClearSelectedRadioValue", (element) => {
      if (element.id == this.fieldData.id) {
        this.fieldData.selected = null;
      }
    });
  },

  beforeMount() {
    this.fieldData.selected = null;
  },
  beforeDestroy() {
    this.fieldData.selected = null;
  },
  mounted() {
    this.setValueForRenderOptions();
    let sections = this.form1.formSection;
    this.isRequired = this.fieldData.validation.required;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === routeNames[routeKeys.USER_FORM]
    ) {
      this.setFieldValue();
      if (this.selected) {
        this.setConditionInput(this.selected);
      }
    }

    let sectionSeven = sections.find(
      (sec) =>
        sec.sectionTitle.english == "Services/Goods (Additional Question)"
    );
    if (
      sectionSeven &&
      sectionSeven?.formElements[0]?.id == this.fieldData.id
    ) {
      if (sectionSeven) {
        this.getCountryListFromSectionFour();
        HideSection.hideSection(
          this.$store.state,
          sectionSeven,
          this.selectedCountriesFromSectionFour
        );
      }
    }
  },

  computed: {
    ...mapGetters([
      "form1",
      "selectedLanguage",
      "userForm",
      "userFormSubmitArray"
    ]),
    ...mapState(["toggleAllCoppalse"])
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },

    selectedHA() {},

    selectedLanguage() {
      this.setValueForRenderOptions();
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    setValueForRenderOptions() {
      this.radioOptions = [];
      let radios = this.fieldData.options;
      radios.map((radio) => {
        let newOption = {
          value: radio,
          text: radio.text[this.selectedLanguage] || "No Text Set"
        };
        this.radioOptions.push(newOption);
      });
    },

    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },

    getCountryListFromSectionFour() {
      this.selectedCountriesFromSectionFour = [];
      let sections = this.form1.formSection;
      let sectionFour = sections.find(
        (sec) => sec.sectionTitle.english == "Business Operations"
      );

      let eleNew = this.$store.state.userFormSubmitArray.find(
        (ele) => ele.elementId == sectionFour?.formElements[0]?.id
      );

      if (eleNew && eleNew.elements.length) {
        eleNew.elements.map((el) => {
          if (el.postValue && el.postValue.length) {
            el.postValue.map((ans) => {
              this.selectedCountriesFromSectionFour.push(ans.name);
            });
          } else {
            this.selectedCountriesFromSectionFour = [];
          }
        });
      }
    },

    checkForProductCountry(country) {
      let countryProducts = [];
      HighRiskProducts.industries.map((ind) => {
        if (ind && ind.subDivisions && ind.subDivisions.length) {
          ind.subDivisions.map((sd) => {
            if (sd && sd.counties && sd.counties.length) {
              sd.counties.map((cr) => {
                if (
                  cr.country == country &&
                  cr.products &&
                  cr.products.length > 0
                ) {
                  cr.products.map((productObj) => {
                    if (productObj.product) {
                      countryProducts.push(productObj.product);
                    }
                  });
                }
              });
            }
          });
        }
      });
      return countryProducts;
    },

    refreshProducts(state, section, countries) {
      HideSection.hideSection(state, section, countries);
    },

    onSelectCountryFromSectionFour(e) {
      this.selectedProductCountry = e;
      this.selectedProducts = [];
      for (let pr of this.allProducts) {
        let index = _.findIndex(
          this.selectedProducts,
          (prd) => prd.product == pr.product
        );
        if (pr.country == e && index == -1) {
          this.selectedProducts.push(pr);
        }
      }
      this.setValue(this.selected);
    },

    // temp disable
    onSelectCountryFromSectionFout(e) {
      this.selectedProductCountry = e;
      this.selectedProducts = [];
      let sections = this.form1.formSection;
      let selectedSubDivision = null;
      let multi = false;
      let selectedCountry = e;
      let sectionSix = sections.find(
        (sec) => sec.sectionTitle.english == "Details of Services/Goods"
      );

      let foundCombo = sectionSix?.formElements?.find(
        (a) => a.inputType == "comboSelectProgressive"
      );
      let eleNewCombo = this.$store.state.userFormSubmitArray.find(
        (ele) => ele.elementId == foundCombo.id
      );

      if (eleNewCombo && eleNewCombo.elements.length) {
        eleNewCombo.elements.map((el) => {
          if (el.postValue && el.postValue.comboSelectTwo) {
            selectedSubDivision = el.postValue.comboSelectTwo;
            multi = el.isMultipleSelectSecond;
          }
        });
      }
      HighRiskProducts.industries.map((ind) => {
        if (ind && ind.subDivisions && ind.subDivisions.length) {
          ind.subDivisions.map((sd) => {
            let foundSubDivision =
              multi && selectedSubDivision
                ? selectedSubDivision.find(
                    (d) => d.subDivisionName == sd.subDivisionName
                  )
                : selectedSubDivision;
            if (
              (foundSubDivision &&
                sd.subDivisionName == foundSubDivision.subDivisionName) ||
              (foundSubDivision && sd.subDivisionName == foundSubDivision)
            ) {
              if (sd && sd.counties && sd.counties.length) {
                sd.counties.map((cr) => {
                  if (cr.country == selectedCountry) {
                    if (cr.products && cr.products.length) {
                      let product = cr.products.find((pr) => pr.product);
                      this.selectedProducts.push(product);
                    }
                  }
                });
              }
            }
          });
        }
      });
    },

    initialCheckCondition(value) {
      let elements = this.form1.formSection[this.sectionIndex]?.formElements;
      if (value) {
        this.checkForSectionConditions(elements, this.fieldData, value);
      }
      elements.find((element, index) => {
        if (element.conditions && element.conditions.length) {
          for (let aa of element.conditions) {
            if (
              aa.sourceFieldId === this.fieldData.id &&
              aa.targetFieldId !== this.fieldData.id &&
              aa.value === value.value &&
              aa.label === this.fieldData.label.english
            ) {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = false;
              this.enableValidations(
                this.form1.formSection[this.sectionIndex]?.formElements[index]
              );
              break;
            } else if (
              aa.sourceFieldId === this.fieldData.id &&
              aa.targetFieldId !== this.fieldData.id &&
              aa.value !== value.value &&
              aa.label === this.fieldData.label.english
            ) {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = true;

              this.disableValidations(
                this.form1.formSection[this.sectionIndex]?.formElements[index]
              );
            }
          }
        }
      });
      if (value) {
        this.setValue(value);
      }
    },

    checkForSectionConditions(elements, fieldData, value) {
      // for section conditions
      elements.find((foundSection) => {
        if (
          foundSection.sectionConditions &&
          foundSection.sectionConditions.length
        ) {
          foundSection.sectionConditions.map((section) => {
            if (section.forSection) {
              if (
                this.form1.formSection[section.sourceSectionIndex] &&
                this.form1.formSection[section.sourceSectionIndex].isConditional
              ) {
                if (
                  section.targetElementId &&
                  section.elementLabel == fieldData.label.english
                ) {
                  if (
                    section.targetElementId === fieldData.id &&
                    section.elementValue.includes(value.value)
                  ) {
                    this.selected = "";
                    fieldData.selected = null;
                    this.form1.formSection[
                      section.sourceSectionIndex
                    ].isHidden = true;
                    this.$store.state.form1.formSection[
                      section.sourceSectionIndex
                    ].isHidden = true;

                    let hiddenSection =
                      this.$store.state.form1.formSection[
                        section.sourceSectionIndex
                      ];
                    this.clearHiddenSectionElementsValue(hiddenSection); // clear elements data if section is hidden
                  } else {
                    this.form1.formSection[
                      section.sourceSectionIndex
                    ].isHidden = false;
                    this.$store.state.form1.formSection[
                      section.sourceSectionIndex
                    ].isHidden = false;
                  }
                } else {
                  // EventBus.emit("onSectionToggleOnly",this.$store.state.form1.formSection[section.sourceSectionIndex], true);
                }
              }
            }
          });
        }
      });
    },

    clearHiddenSectionElementsValue(section) {
      for (let ele of section.formElements) {
        ele.selected = null;
        ele.isHidden = ele.isConditional === "1";
        for (let postEle of this.$store.state.userFormSubmitArray) {
          if (postEle.elementId === ele.id) {
            postEle.elements = [];
          }
        }
      }
    },

    checkCondition(value) {
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;

      let elements = this.form1.formSection[this.sectionIndex]?.formElements;

      // for section conditions
      this.checkForSectionConditions(elements, this.fieldData, value);

      elements.find((element, index) => {
        if (element.conditions && element.conditions.length) {
          for (let elementConditions of element.conditions) {
            if (value) {
              if (
                elementConditions.sourceFieldId === this.fieldData.id &&
                elementConditions.targetFieldId !== this.fieldData.id &&
                elementConditions.value === value.value &&
                elementConditions.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = false;
                this.enableValidations(
                  this.form1.formSection[this.sectionIndex]?.formElements[index]
                );
                break;
              } else if (
                elementConditions.sourceFieldId === this.fieldData.id &&
                elementConditions.targetFieldId !== this.fieldData.id &&
                elementConditions.value !== value.value &&
                elementConditions.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = true;

                this.disableValidations(
                  this.form1.formSection[this.sectionIndex]?.formElements[
                    index
                  ],
                  true
                );
                this.hideNestedElements(
                  this.form1.formSection[this.sectionIndex]?.formElements[
                    index
                  ],
                  value
                );
              }
            } else {
              if (
                elementConditions.sourceFieldId === this.fieldData.id &&
                elementConditions.targetFieldId !== this.fieldData.id &&
                elementConditions.label === this.fieldData.label.english
              ) {
                this.form1.formSection[this.sectionIndex].formElements[
                  index
                ].isHidden = true;
              }
            }
          }
        }
      });

      // for new conditional fields..

      let condOptions;
      let selectedConditionalFieldValue =
        this.fieldData.selectedConditionalFieldValue;

      if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "string"
      ) {
        if (typeof value == "object") {
          condOptions = _.isEqual(selectedConditionalFieldValue, value.value);
        } else {
          condOptions = _.isEqual(selectedConditionalFieldValue, value);
        }
      } else if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "object" &&
        selectedConditionalFieldValue.length
      ) {
        for (let opt of selectedConditionalFieldValue) {
          if (typeof value == "object") {
            condOptions = _.isEqual(opt.value, value.value);
          } else {
            condOptions = _.isEqual(opt.value, value);
          }
          if (condOptions) {
            break;
          }
        }
      }

      if (condOptions) {
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.isFocus = true;
      } else {
        this.clearProgressiveInputData();
        this.fieldData.conditionalFieldIsVisible = false;
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.conditionalFieldInputValue = null;
        this.isMatchCondition = false;
        this.isFocus = true;
      }

      this.setValue(value);
    },
    clearProgressiveInputData() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((ele) => {
          if (ele?.elementId && ele?.elements?.length) {
            if (ele.elementId === this.fieldData.id) {
              ele.elements.splice(1, 1);
            }
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((ele) => {
          if (ele?.elementId && ele?.elements?.length) {
            if (ele.elementId === this.fieldData.id) {
              ele.elements.splice(1, 1);
            }
          }
        });
    },
    setConditionInput(value) {
      let condOptions;
      let selectedConditionalFieldValue =
        this.fieldData.selectedConditionalFieldValue;

      if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "string"
      ) {
        if (typeof value == "object") {
          condOptions = _.isEqual(selectedConditionalFieldValue, value.value);
        } else {
          condOptions = _.isEqual(selectedConditionalFieldValue, value);
        }
      } else if (
        selectedConditionalFieldValue &&
        typeof selectedConditionalFieldValue == "object" &&
        selectedConditionalFieldValue.length
      ) {
        for (let opt of selectedConditionalFieldValue) {
          if (typeof value == "object") {
            condOptions = _.isEqual(opt.value, value.value);
          } else {
            condOptions = _.isEqual(opt.value, value);
          }
          if (condOptions) {
            break;
          }
        }
      }

      if (condOptions) {
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.isFocus = true;
      } else {
        this.clearProgressiveInputData();
        this.fieldData.conditionalFieldIsVisible = false;
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.conditionalFieldInputValue = null;
        this.isMatchCondition = false;
        this.isFocus = true;
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a && a.elementId && this.fieldData && this.fieldData.id) {
            if (a.elementId === this.fieldData.id) {
              this.fieldData.isHidden = a.isHiddenElement != 0;
            }
          }
          if (a && a.elementId && a.elements && a.elements.length) {
            if (a.elementId === this.fieldData.id) {
              a.elements.map((el) => {
                if (el.key == "radio") {
                  this.selectedProductCountry = el.selectedProductCountry
                    ? el.selectedProductCountry
                    : null;
                  this.selectedProducts = el.selectedProducts || [];
                  if (typeof el.postValue == "string") {
                    let index = this.radioOptions.findIndex(
                      (x) => x.value.value == el.postValue
                    );
                    if (index != -1) {
                      this.selected = this.radioOptions[index].value;
                      // added this line to fix as of ishidden elements with post value having ishidden = true
                      this.fieldData.isHidden = !(
                        el.postValue && a.isHiddenElement == 0
                      );
                      this.fieldData.selected = this.radioOptions[index].value;
                    }
                  } else if (typeof el.postValue == "object") {
                    let index = this.radioOptions.findIndex(
                      (x) => x.value.value == el.postValue.value
                    );
                    if (index != -1) {
                      this.selected = this.radioOptions[index].value;
                      // added this line to fix as of ishidden elements with post value having ishidden = true
                      this.fieldData.isHidden = !(
                        el.postValue &&
                        el.postValue.value &&
                        a.isHiddenElement == 0
                      );
                      this.fieldData.selected = this.radioOptions[index].value;
                    }
                  } else {
                    this.selected = el.postValue;
                    this.fieldData.selected = el.postValue;
                  }
                } else if (el.key == "progressive") {
                  this.conditionalFieldInputValue = el.postValue;
                }
              });
            }
          }
        });
      this.initialCheckCondition(this.selected);
    },

    setValue(e) {
      let languageValue = e.text[this.selectedLanguage];
      const registerData = _.clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        this.selected = e;
        this.fieldData.selected = e;
        if (e && e.length > 0 && !this.conditionalFieldIsVisible) {
          this.fieldData.conditionalFieldInputValue = null;
          this.clearProgressiveInputData();
          this.conditionalFieldInputValue = null;
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        if (this.selected) {
          let option = this.fieldData.options.find(
            (opt) => opt.value == this.selected.value
          );
          let selectedRadioOptionsPostValueAbb = [];
          let selectedRadioOptionsCustomValues = [];
          selectedRadioOptionsPostValueAbb.push(option.postValueAbb);
          selectedRadioOptionsCustomValues.push(option.custom_values);
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                language: this.selectedLanguage,
                valueForPDF: languageValue,
                postValue: this.selected,
                postValueText: option.text || null,
                postValueAbb: selectedRadioOptionsPostValueAbb || null,
                key: "radio",
                score: option && option.score ? option.score : null,
                custom_values: selectedRadioOptionsCustomValues || null,
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameRadio",
                highRiskCountries: this.selectedCountriesFromSectionFour,
                highRiskProducts: this.allProducts,
                selectedProductCountry: this.selectedProductCountry,
                selectedProducts: this.selectedProducts
              };
            }
          });
        }
      }
    },

    onInputType(e) {
      const registerData = _.clone(this.$store.state.registerData);
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        (this.$route.name === routeNames[routeKeys.USER_FORM] &&
          this.showCollapseField)
      ) {
        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        let numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[1] = {
              postValue: e,
              key: "progressive",
              postNameProgressive:
                this.fieldData.conditionalFieldLabel || "postNameProgressive"
            };
          }
        });
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    },
    clearPostValuesForExternalConditionalComponents(element) {
      if (element?.conditions?.length && element.isConditional === 1) {
        element.conditions.forEach((condition) => {
          let foundConditionalElement =
            this.$store.state.userFormSubmitArray.find(
              (ele) => ele.elementId === condition.targetFieldId
            );
          if (foundConditionalElement) {
            foundConditionalElement.elements = [];
            foundConditionalElement.isHiddenElement = 1;
          }
        });
      }
    },

    disableValidations(element, bool) {
      switch (element.inputType) {
        case "input":
        case "number":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId === element.id) {
                a.isHiddenElement = 1;
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          this.$store.state.userFormSubmitArray.forEach((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 1;
              a.elements.forEach((el) => {
                el.postValue = null;
              });
            }
          });
          EventBus.emit("onUpdateInputValues", "");
          break;
        case "textarea":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                if (bool)
                  a.elements.forEach((el) => {
                    el.postValue = null;
                  });
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                if (bool)
                  a.elements.forEach((el) => {
                    el.postValue = null;
                  });
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          break;
        case "date":
          this.$store.state.getUserFormSubmitArray.forEach((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 1;
              a.elements.forEach((el) => {
                el.postValue = null;
              });
            }
          });
          this.$store.state.userFormSubmitArray.forEach((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 1;
              a.elements.forEach((el) => {
                el.postValue = null;
              });
            }
          });
          element.isInValid = false;
          element.validation.required = 0;
          break;
        case "file":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements.forEach((el) => {
                  el.postValue = null;
                });
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          break;
        case "radio":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
                this.resetPregressiveElement(a);
                this.resetSelectedRadioValues(element);
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                a.elements = [];
                this.resetPregressiveElement(a);
                this.resetSelectedRadioValues(element);
              }
            });

          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          // code block

          break;
        case "checkbox":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                EventBus.emit("onResetCheckBox", a);
                if (a?.length) {
                  a.elements.forEach((el) => {
                    el.postValue = null;
                    el.score = 0;
                  });
                }
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.forEach((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 1;
                EventBus.emit("onResetCheckBox", a);
                if (a?.length) {
                  a.elements.forEach((el) => {
                    el.postValue = null;
                    el.score = 0;
                  });
                }
              }
            });
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          // code block

          break;
        case "countries":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.elements = [];
                a.isHiddenElement = 1;
                EventBus.emit("onRestoreCountry", a);
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.elements = [];
                a.isHiddenElement = 1;
                EventBus.emit("onRestoreCountry", a);
              }
            });
          break;
        default:
          this.clearPostValuesForExternalConditionalComponents(element);
          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
        // code block
      }
    },

    enableValidations(element) {
      switch (element.inputType) {
        case "textarea":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          element.isInValid = false;
          element.validation.required = 1;
          break;
        case "file":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          element.isInValid = false;
          element.validation.required = 1;
          break;
        case "checkbox":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          element.isInValid = true;
          element.validation.required = 1;
          break;
        case "radio":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });

          element.isInValid = false;
          if (
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("lieferkettengesetz") &&
            !this.form1.formTitle.english
              .toLowerCase()
              .includes("human rights questionnaire")
          ) {
            element.validation.required = 0;
          }
          element.validation.required = 1;

          break;
        case "input":
        case "number":
          this.$store.state.getUserFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          break;
        case "date":
          this.$store.state.getUserFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === element.id) {
              a.isHiddenElement = 0;
            }
          });
          element.isInValid = true;
          element.validation.required = 1;
          break;
        case "countries":
          this.$store.state.getUserFormSubmitArray.length &&
            this.$store.state.getUserFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          this.$store.state.userFormSubmitArray.length &&
            this.$store.state.userFormSubmitArray.map((a) => {
              if (a.elementId == element.id) {
                a.isHiddenElement = 0;
              }
            });
          element.isInValid = true;
          element.validation.required = 1;
          break;
        default:
          element.isInValid = true;
          element.validation.required = 1;
      }
    },
    hideNestedElements(selectedElement) {
      if (!selectedElement?.id || !selectedElement?.label?.english) {
        return;
      }
      const elements = this.form1.formSection[this.sectionIndex].formElements;
      elements.forEach((element) => {
        if (!element?.conditions?.length || element.id === this.fieldData.id) {
          return;
        }
        const isHidden = element.conditions?.some(
          (condition) =>
            condition?.sourceFieldId === selectedElement.id &&
            condition?.label === selectedElement.label.english
        );

        if (isHidden) {
          element.isHidden = true;
          element.selected = null;
        }
      });
    },

    resetPregressiveElement(selectedElement) {
      let foundSection =
        this.form1.formSection[selectedElement.sectionIndex]?.formElements;
      for (let element of foundSection) {
        if (element?.conditions?.length && element.id != this.fieldData.id) {
          if (element.inputType === "checkbox") {
            EventBus.emit("onResetSelectedValues", element);
          }
          element.selected = null;
          element.isHidden = true;
          if (this.$store.state.getUserFormSubmitArray.length) {
            for (let ele of this.$store.state.getUserFormSubmitArray) {
              if (ele.elementId == element.id) {
                ele.elements = [];
                ele.isHiddenElement = 1;
              }
            }
          }
          if (this.$store.state.userFormSubmitArray.length) {
            for (let ele of this.$store.state.userFormSubmitArray) {
              if (ele.elementId == element.id) {
                ele.elements = [];
                ele.isHiddenElement = 1;
              }
            }
          }
          break;
        }
      }
    },

    resetSelectedRadioValues(selectedElement) {
      if (selectedElement.id === this.fieldData.id) {
        this.fieldData.selected = null;
        this.selected = "";
        this.initialCheckCondition(this.selected);
      }
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style>
.custom-control-label::before {
  /* left: -1.5rem; */
  right: 0 !important;
}
.custom-control-label {
  padding-right: 25px;
}
.custom-control-input:checked ~ .custom-control-label::after {
  right: 0 !important;
}
</style>
