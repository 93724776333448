import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makePermissionsListDTO,
  makePermissionsListAPIDTO,
  makeUserPermissionsDTO
} from "@/services/permissions/dto/permissions.dto";

const fetchCompanyPermissions = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.COMPANY_PERMISSIONS(companyId),
    null,
    makeAuthorizationHeader()
  );

  result.data.permissions = makePermissionsListDTO(result.data.permissions);

  return result;
};

const updateCompanyPermissions = async ({ companyId, payload }) =>
  HTTP(
    "post",
    endpoints.COMPANY_PERMISSIONS(companyId),
    makePermissionsListAPIDTO(payload),
    makeAuthorizationHeader()
  );

const fetchUserPermissions = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.COMPANY_USER_PERMISSIONS(companyId),
    null,
    makeAuthorizationHeader()
  );

  result.data = makeUserPermissionsDTO(result.data);

  return result;
};

const fetchUserPermissionsSubset = async ({ companyId, payload }) => {
  const result = await HTTP(
    "post",
    endpoints.COMPANY_USER_PERMISSIONS_SUBSET(companyId),
    payload,
    makeAuthorizationHeader()
  );

  result.data = makeUserPermissionsDTO(result.data);

  return result;
};

export const permissionsService = {
  fetchCompanyPermissions,
  updateCompanyPermissions,
  fetchUserPermissions,
  fetchUserPermissionsSubset
};
