<template>
  <b-tabs content-class="mt-3">
    <!-- Text -->
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Signature Title Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="signatureTitle"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="fieldData.abbreviationLabelText"
              label="Abbreviation Label"
            >
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="fieldData.abbreviationLabelText[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group v-if="fieldData.labelForTable" label="Text for table">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="fieldData.labelForTable[transalationLanguage]"
                maxlength="18"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Signature Description Label">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="signatureDescription"
                rows="3"
                min-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Accept terms text">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="acceptTermsText"
                rows="3"
                min-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Accept terms description text">
              <quill-editor
                v-model="
                  fieldData.acceptTermsDescriptionText[transalationLanguage]
                "
              />
            </b-form-group>

            <b-form-group label="Sign in the area below label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="
                  fieldData.signInTheAreaBelowLabel[transalationLanguage]
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Your Name Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="printNameLabel"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Your Name Placeholder">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="printNamePlaceholder"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Your Postion/Role Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="positionRoleLabel"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Your Postion/Role Placeholder">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="positionRolePlaceholder"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Date Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="dateFieldLabel"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Date Placeholder">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="dateFieldPlaceholder"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Company Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="printCompanyLabel"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Place Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="printPlaceLabel"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- ----------------Attributes------------------- -->
    <b-tab title="Attributes">
      <b-form @submit.stop.prevent class="text-left mt-2">
        <div class="form-group col-md-6">
          <label for>Post name for Signature</label>
          <b-form-input v-model="signaturePostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post name for Name</label>
          <b-form-input v-model="namePostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post name for Postion/Role</label>
          <b-form-input v-model="positionRolePostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post name for Date</label>
          <b-form-input v-model="datePostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post name for Company</label>
          <b-form-input v-model="companyPostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post name for Place</label>
          <b-form-input v-model="placePostName"></b-form-input>
        </div>
        <div class="form-check">
          <Checkbox
            id="checkbox-4"
            checked-value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForThirdPartyProfile"
            name="checkbox-4"
            label="Show Self Reporting Data in Third Party Profile"
          />
          <Checkbox
            id="checkbox-5"
            checked-value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForManageThirdParty"
            name="checkbox-5"
            label="Show Self Reporting Data in Manage Third Party"
          />
        </div>
      </b-form>
    </b-tab>

    <b-tab title="Advanced">
      <b-row>
        <b-col cols="10">
          <h3 class="text-center">Advanced Options</h3>
          <Checkbox
            class="text-left mb-3"
            v-model="signatureTitleTextIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable signature title?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="signatureDescriptionIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable signature description?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="acceptTermsTextIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable accept terms?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="signInTheAreaBelowLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable sign in text?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="printNameLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable your name?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="positionRoleLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable your Position/Role?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="dateFieldLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable date?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="companyFieldLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable Company?"
          />
          <Checkbox
            class="text-left mb-3"
            v-model="placeFieldLabelIsDisabled"
            checked-value="1"
            unchecked-value="0"
            label="Disable Place?"
          />
        </b-col>
      </b-row>
    </b-tab>

    <!-- data validation -->
    <b-tab title="Validation">
      <div class="text-left">
        <b-col>
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireCheck"
            checked-value="1"
            unchecked-value="0"
            label="Is signature area required?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireName"
            checked-value="1"
            unchecked-value="0"
            label="Is required Name?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequirePositionRole"
            checked-value="1"
            unchecked-value="0"
            label="Is required Position/Role?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireDate"
            checked-value="1"
            unchecked-value="0"
            label="Is required Date?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireTerms"
            checked-value="1"
            unchecked-value="0"
            label="Is required Terms Text?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequiredCompany"
            checked-value="1"
            unchecked-value="0"
            label="Is required Company?"
          />
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequiredPlace"
            checked-value="1"
            unchecked-value="0"
            label="Is required Place?"
          />
        </b-col>
      </div>
    </b-tab>

    <!-- ----------------Date Advanced------------------- -->
    <b-tab title="Date Advanced">
      <div @submit.stop.prevent class="text-left mt-4">
        <div class="col-12">
          <Checkbox
            v-model="disabledMinDateInput"
            class="col-md-12 my-1"
            @update:modelValue="onToggleDatesCheckbox('min')"
            label="Disable Past Dates"
          />
          <b-collapse :visible="!disabledMinDateInput">
            <div class="form-group col-md-6">
              <label for>Minimum date</label>
              <b-form-input type="date" v-model="minDate"></b-form-input>
            </div>
          </b-collapse>

          <Checkbox
            class="col-md-12 my-1"
            @update:modelValue="onToggleDatesCheckbox('max')"
            v-model="disabledMaxDateInput"
            label="Disable Future Dates"
          />
          <b-collapse :visible="!disabledMaxDateInput">
            <div class="form-group col-md-6">
              <label for>Maximum date</label>
              <b-form-input type="date" v-model="maxDate"></b-form-input>
            </div>
          </b-collapse>

          <b-form-group label="Select Date Format">
            <b-form-radio-group
              @input="onSetDateFormat(selectedDateFormat)"
              stacked
              v-model="selectedDateFormat"
              :options="optionsDateFormatt"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <div
        class="active text-left mt-2"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="form-group col-md-6">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id />
            <label class="form-check-label" for>Hide on load</label>
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "SignatureSettings",
  components: { Checkbox },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  data() {
    return {
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ],
      isLoading: false,
      transalationLanguage: "english",
      label: null,

      // Signature title
      signatureTitleTextIsDisabled: false,
      signatureTitle: null,

      // Description
      signatureDescriptionIsDisabled: false,
      signatureDescription: null,

      // Accept terms
      acceptTermsTextIsDisabled: false,
      acceptTermsText: null,

      acceptTermsDescriptionTextIsDisabled: false,

      // date field
      dateFieldLabelIsDisabled: false,
      dateFieldLabel: null,
      dateFieldPlaceholder: null,

      signInTheAreaBelowLabel: null,
      signInTheAreaBelowLabelIsDisabled: null,

      // company
      companyFieldLabelIsDisabled: false,
      printCompanyLabel: null,

      // Place
      placeFieldLabelIsDisabled: false,
      printPlaceLabel: null,

      // Name field
      printNameLabelIsDisabled: false,
      printNameLabel: null,
      printNamePlaceholder: null,

      // PositionRole field
      positionRoleLabelIsDisabled: false,
      positionRoleLabel: null,
      positionRolePlaceholder: null,

      // validation
      isRequireCheck:
        this.fieldData.validation && this.fieldData.validation.required,
      isRequireName:
        this.fieldData.validation && this.fieldData.validation.requiredName,
      isRequirePositionRole:
        this.fieldData.validation &&
        this.fieldData.validation.requirePositionRole,
      isRequireDate:
        this.fieldData.validation && this.fieldData.validation.requiredDate,
      isRequireTerms:
        this.fieldData.validation && this.fieldData.validation.requiredTerms,
      isRequiredCompany:
        this.fieldData.validation && this.fieldData.validation.requiredCompany,
      isRequiredPlace:
        this.fieldData.validation && this.fieldData.validation.requiredPlace,

      // attributes
      namePostName:
        this.fieldData.attributes && this.fieldData.attributes.postNameName
          ? this.fieldData.attributes.postNameName
          : undefined,
      positionRolePostName:
        this.fieldData.attributes &&
        this.fieldData.attributes.postNamePositionRole
          ? this.fieldData.attributes.postNamePositionRole
          : undefined,
      signaturePostName:
        this.fieldData.attributes && this.fieldData.attributes.postNameSignature
          ? this.fieldData.attributes.postNameSignature
          : undefined,
      datePostName:
        this.fieldData.attributes && this.fieldData.attributes.postNameDate
          ? this.fieldData.attributes.postNameDate
          : undefined,
      companyPostName:
        this.fieldData.attributes && this.fieldData.attributes.postNameCompany
          ? this.fieldData.attributes.postNameCompany
          : undefined,
      placePostName:
        this.fieldData.attributes && this.fieldData.attributes.postNamePlace
          ? this.fieldData.attributes.postNamePlace
          : undefined,

      // date-----
      minDate:
        this.fieldData.advance && this.fieldData.advance.minDate
          ? this.fieldData.advance.minDate
          : undefined,
      maxDate:
        this.fieldData.advance && this.fieldData.advance.maxDate
          ? this.fieldData.advance.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advance && this.fieldData.advance.disabledMinDateInput
          ? this.fieldData.advance.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advance && this.fieldData.advance.disabledMaxDateInput
          ? this.fieldData.advance.disabledMaxDateInput
          : false
    };
  },
  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
      this.addAdvacne();
    });
  },
  mounted() {
    this.addExtras();
    this.label = this.fieldData.label[this.transalationLanguage];
    this.signatureDescription =
      this.fieldData.signatureDescription[this.transalationLanguage];
    this.signatureTitle =
      this.fieldData.signatureTitle[this.transalationLanguage];
    this.acceptTermsText =
      this.fieldData.acceptTermsText[this.transalationLanguage];

    this.printNameLabel =
      this.fieldData.printNameLabel[this.transalationLanguage];
    this.printNamePlaceholder =
      this.fieldData.printNamePlaceholder[this.transalationLanguage];

    this.printCompanyLabel =
      this.fieldData.printCompanyLabel[this.transalationLanguage];
    this.printPlaceLabel =
      this.fieldData.printPlaceLabel[this.transalationLanguage];

    this.positionRoleLabel =
      this.fieldData.positionRoleLabel[this.transalationLanguage];
    this.positionRolePlaceholder =
      this.fieldData.positionRolePlaceholder[this.transalationLanguage];

    this.dateFieldPlaceholder =
      this.fieldData.dateFieldPlaceholder[this.transalationLanguage];
    this.dateFieldLabel =
      this.fieldData.dateFieldLabel[this.transalationLanguage];
    this.signInTheAreaBelowLabel =
      this.fieldData.signInTheAreaBelowLabel[this.transalationLanguage];

    this.signatureTitleTextIsDisabled =
      this.fieldData.signatureTitleTextIsDisabled;
    this.signatureDescriptionIsDisabled =
      this.fieldData.signatureDescriptionIsDisabled;
    this.acceptTermsTextIsDisabled = this.fieldData.acceptTermsTextIsDisabled;
    this.acceptTermsDescriptionTextIsDisabled =
      this.fieldData.acceptTermsDescriptionTextIsDisabled;
    this.printNameLabelIsDisabled = this.fieldData.printNameLabelIsDisabled;
    this.positionRoleLabelIsDisabled =
      this.fieldData.positionRoleLabelIsDisabled;
    this.dateFieldLabelIsDisabled = this.fieldData.dateFieldLabelIsDisabled;
    this.companyFieldLabelIsDisabled =
      this.fieldData.companyFieldLabelIsDisabled;
    this.placeFieldLabelIsDisabled = this.fieldData.placeFieldLabelIsDisabled;
    this.signInTheAreaBelowLabelIsDisabled =
      this.fieldData.signInTheAreaBelowLabelIsDisabled;
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    // advance date tab
    addExtras() {
      if (!this.fieldData.abbreviationLabelText) {
        let abbreviationLabelText = { english: " " };
        Object.assign(this.fieldData, { abbreviationLabelText });
      }
      if (!this.fieldData.labelForTable) {
        let labelForTable = { english: "" };
        Object.assign(this.fieldData, { labelForTable });
      }
    },

    addAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();

      let advance = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advance });
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    onSetDateFormat() {
      this.fieldData.format = this.selectedDateFormat;
    },
    tabClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.signatureTitle =
        this.fieldData.signatureTitle[this.transalationLanguage];
      this.signatureDescription =
        this.fieldData.signatureDescription[this.transalationLanguage];
      this.acceptTermsText =
        this.fieldData.acceptTermsText[this.transalationLanguage];
      this.printNameLabel =
        this.fieldData.printNameLabel[this.transalationLanguage];
      this.printNamePlaceholder =
        this.fieldData.printNamePlaceholder[this.transalationLanguage];
      this.positionRoleLabel =
        this.fieldData.positionRoleLabel[this.transalationLanguage];
      this.positionRolePlaceholder =
        this.fieldData.positionRolePlaceholder[this.transalationLanguage];
      this.dateFieldLabel =
        this.fieldData.dateFieldLabel[this.transalationLanguage];
      this.dateFieldPlaceholder =
        this.fieldData.dateFieldPlaceholder[this.transalationLanguage];

      this.printCompanyLabel =
        this.fieldData.printCompanyLabel[this.transalationLanguage];

      this.printPlaceLabel =
        this.fieldData.printPlaceLabel[this.transalationLanguage];
    },

    updateAdvancedSettings() {
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].signatureTitleTextIsDisabled = this.signatureTitleTextIsDisabled;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].signatureDescriptionIsDisabled = this.signatureDescriptionIsDisabled;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].acceptTermsTextIsDisabled = this.acceptTermsTextIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].acceptTermsDescriptionTextIsDisabled =
        this.acceptTermsDescriptionTextIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].signInTheAreaBelowLabelIsDisabled =
        this.signInTheAreaBelowLabelIsDisabled;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].printNameLabelIsDisabled = this.printNameLabelIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].positionRoleLabelIsDisabled = this.positionRoleLabelIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].dateFieldLabelIsDisabled = this.dateFieldLabelIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].companyFieldLabelIsDisabled = this.companyFieldLabelIsDisabled;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].placeFieldLabelIsDisabled = this.placeFieldLabelIsDisabled;
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].label[tempKey] = this.label || this.signatureTitle;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].signatureTitle[tempKey] = this.signatureTitle;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].signatureDescription[tempKey] = this.signatureDescription;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].acceptTermsText[tempKey] = this.acceptTermsText;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].printNameLabel[tempKey] = this.printNameLabel;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].printNamePlaceholder[tempKey] = this.printNamePlaceholder;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].positionRoleLabel[tempKey] = this.positionRoleLabel;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].positionRolePlaceholder[tempKey] = this.positionRolePlaceholder;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].dateFieldPlaceholder[tempKey] = this.dateFieldPlaceholder;
      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].dateFieldLabel[tempKey] = this.dateFieldLabel;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].printCompanyLabel[tempKey] = this.printCompanyLabel;

      this.form.formSection[this.sectionIndex].formElements[
        this.fieldIndex
      ].printPlaceLabel[tempKey] = this.printPlaceLabel;
    },

    setValidation() {
      this.isLoading = true;
      let validation = {
        required: this.isRequireCheck,
        requiredName: this.isRequireName,
        requirePositionRole: this.isRequirePositionRole,
        requiredDate: this.isRequireDate,
        requiredTerms: this.isRequireTerms,
        requiredCompany: this.isRequiredCompany,
        requiredPlace: this.isRequiredPlace
      };
      this.isLoading = false;
      Object.assign(this.fieldData, { validation });
    },

    setAttributes() {
      let attributes = {
        postNameSignature: this.signaturePostName
          ? this.signaturePostName
          : undefined,
        postNameName: this.namePostName ? this.namePostName : undefined,
        postNamePositionRole: this.positionRolePostName
          ? this.positionRolePostName
          : undefined,
        postNameDate: this.datePostName ? this.datePostName : undefined,
        postNameCompany: this.companyPostName
          ? this.companyPostName
          : undefined,
        postNamePlace: this.placePostName ? this.placePostName : undefined
      };
      Object.assign(this.fieldData, { attributes });
    }
  }
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
.checkbox {
  justify-content: flex-start;
}
</style>
