<template>
  <AgGridVue
    v-bind="$attrs"
    ref="grid"
    :grid-options="mergedGridOptions"
    @grid-ready="onGridReady"
    @sort-changed="$emit(dataTableEvents.SORT_CHANGED, $event)"
    class="ag-theme-data-table"
    data-test-id="data-table"
  />
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { merge } from "lodash";
import "ag-grid-community/styles/ag-grid.css";
import { dataTableEvents, themes, typographySize } from "@/constants";
import { sizeColumnsToGridWidth } from "@/utils";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseGridComponentWrapper from "@/atoms/BaseGridComponentWrapper/BaseGridComponentWrapper";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "AgGridTable",
  components: {
    AgGridVue,
    // DO NOT DELETE BaseGridComponentWrapper or BaseLoader.
    // This needs to be declared here, although it is not used within this file.
    /* eslint-disable vue/no-unused-components */
    BaseLoader,
    BaseText,
    BaseGridComponentWrapper
    /* eslint-enable vue/no-unused-components */
  },
  data() {
    return {
      dataTableEvents,
      gridApi: {},
      defaultGridOptions: {
        rowHeight: 66,
        headerHeight: 66,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        loadingOverlayComponent: "BaseLoader",
        noRowsOverlayComponent: "BaseGridComponentWrapper",
        noRowsOverlayComponentParams: {
          value: {
            component: BaseText,
            componentOptions: {
              text: "No results found",
              tag: "span",
              theme: themes.ERROR_INVERSE,
              size: typographySize.BODY_TEXT_BOLD
            },
            parentStyles: {
              justifyContent: "center"
            }
          }
        },
        defaultColDef: {
          resizable: true,
          suppressMovable: true,
          cellStyle: { textAlign: "left" },
          autoHeaderHeight: true,
          wrapHeaderText: true,
          headerComponentParams: {
            template: `<div class="ag-cell-label-container" role="presentation" data-test-id="ag-grid__header-cell-label-container">
  <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" data-test-id="ag-grid__header-cell-label">
    <span data-ref="eText" class="ag-header-cell-text" data-test-id="ag-grid__header-cell-label-text"></span>
    <span data-ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden" aria-hidden="true" data-test-id="ag-grid__header-cell-filter"></span>
        <span data-ref="eSortOrder" class="ag-sort-indicator-icon ag-sort-order ag-hidden" aria-hidden="true" data-test-id="ag-grid__header-cell-sort-order"></span>
        <span data-ref="eSortAsc" class="ag-sort-indicator-icon ag-sort-ascending-icon" aria-hidden="true" data-test-id="ag-grid__header-cell--sort--asc"></span>
        <span data-ref="eSortDesc" class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden" aria-hidden="true" data-test-id="ag-grid__header-cell-sort--desc"></span>
        <span data-ref="eSortMixed" class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden" aria-hidden="true" data-test-id="ag-grid__header-cell-sort--mixed"></span>
        <span data-ref="eSortNone" class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden" aria-hidden="true" data-test-id="ag-grid__header-cell-sort--none"></span>
  </div>
</div>`
          }
        }
      }
    };
  },
  props: {
    gridOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mergedGridOptions() {
      return merge(this.defaultGridOptions, this.gridOptions);
    }
  },
  watch: {
    "gridOptions.showLoader": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          newValue ? this.showLoadingState() : this.hideLoadingState();
        }
      }
    }
  },
  methods: {
    onGridReady(event) {
      this.gridApi = event.api;
      if (!this.gridOptions.suppressAutoSizing) {
        sizeColumnsToGridWidth({
          gridAPI: event.api,
          sizingElement: this.$refs?.grid?.$el
        });
      }
      this.$emit(dataTableEvents.GRID_READY, event);
    },
    showLoadingState() {
      this.gridApi.setGridOption("loading", true);
    },
    hideLoadingState() {
      this.gridApi.setGridOption("loading", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.ag-theme-data-table {
  width: 100%;
  height: 100%;
  @include ag-grid-table;
}
</style>
