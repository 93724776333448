import {
  getAllSelectedOptionValues,
  getSelectedOption,
  isValidDate
} from "@/utils";
import { customErrorMessages, tasksFilterKeysDTO } from "@/constants";

const isDateFilterSelected = ({
  options = [],
  _getSelectedOption = getSelectedOption
}) =>
  [
    tasksFilterKeysDTO.CREATED_DATE,
    tasksFilterKeysDTO.CREATED_DATE_AFTER,
    tasksFilterKeysDTO.CREATED_DATE_BEFORE,
    tasksFilterKeysDTO.UPDATED_DATE,
    tasksFilterKeysDTO.UPDATED_DATE_AFTER,
    tasksFilterKeysDTO.UPDATED_DATE_BEFORE
  ].includes(_getSelectedOption(options)?.value);

const isFilterValueValid = ({
  isDateFilterSelected = false,
  value = "",
  optionValues = [],
  _isValidDate = isValidDate
} = {}) =>
  isDateFilterSelected
    ? _isValidDate(value)
    : !!value || !!optionValues?.length;

const isFilterCriteriaValueValid = ({
  formRow,
  _getAllSelectedOptionValues = getAllSelectedOptionValues,
  _isFilterValueValid = isFilterValueValid,
  _isDateFilterSelected = isDateFilterSelected
} = {}) => {
  const { value = "" } = formRow.availableFilterValueComponentOptions;
  const optionValues = _getAllSelectedOptionValues(
    formRow.availableFilterValueComponentOptions?.options
  );

  if (
    _isFilterValueValid({
      isDateFilterSelected: _isDateFilterSelected({
        options: formRow.availableFilterOptions
      }),
      value,
      optionValues
    })
  ) {
    formRow.availableFilterValueComponentOptions.error = "";
    return true;
  }

  formRow.availableFilterValueComponentOptions.error =
    customErrorMessages.generic.required;
  return false;
};

const isFilterCriteriaValid = ({
  formRow = {},
  _getSelectedOption = getSelectedOption
} = {}) => {
  const { value } = _getSelectedOption(formRow.availableFilterOptions);
  if (value) {
    formRow.availableFilterError = "";
    return true;
  }

  formRow.availableFilterError = customErrorMessages.generic.required;
  return false;
};

const isFilterValid = ({
  searchFilterBarDetails,
  _isFilterCriteriaValueValid = isFilterCriteriaValueValid,
  _isFilterCriteriaValid = isFilterCriteriaValid
} = {}) =>
  !searchFilterBarDetails?.filterOptions
    ?.reduce?.((acc, formRow) => {
      acc.push(_isFilterCriteriaValueValid({ formRow }));
      acc.push(_isFilterCriteriaValid({ formRow }));
      return acc;
    }, [])
    .includes(false);

export {
  isFilterCriteriaValueValid,
  isFilterCriteriaValid,
  isFilterValid,
  isDateFilterSelected,
  isFilterValueValid
};
