<template>
  <a
    :class="[
      'sidebar-menu-item',
      isActive && 'sidebar-menu-item--is-active',
      isDropdownVariant && 'sidebar-menu-item--is-dropdown-variant'
    ]"
    data-test-id="sidebar-menu-item"
    :href="path"
    @click="handleClick"
  >
    <BaseIcon
      v-if="hasIcon"
      v-bind="combinedIconOptions"
      data-test-id="sidebar-menu-item__icon"
    />
    <BaseText
      v-bind="combinedTextOptions"
      class="sidebar-menu-item__text"
      data-test-id="sidebar-menu-item__text"
    />
    <BaseIcon
      v-if="isDropdownVariant"
      class="sidebar-menu-item__dropdown-icon"
      :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'"
      :theme="isActive ? themes.SECONDARY : themes.NONE"
      :size="10"
    />
  </a>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { computed, defineComponent } from "vue";
import { DOMEvents, themes, typographySize } from "@/constants";

export default defineComponent({
  name: "SidebarMenuItem",
  components: { BaseText, BaseIcon },
  compatConfig: {
    MODE: 3
  },
  props: {
    path: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isDropdownVariant: {
      type: Boolean,
      default: false
    },
    isDropdownOpen: {
      type: Boolean,
      default: false
    },
    textOptions: {
      type: Object,
      default: () => ({})
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  emits: [DOMEvents.CLICK],
  setup(props, { emit }) {
    const combinedTextOptions = computed(() => ({
      tag: "p",
      hasDefaultSpacingRemoved: true,
      title: props.textOptions?.text || "",
      theme: props.isActive ? themes.SECONDARY : themes.NONE,
      size: props.isActive
        ? typographySize.BODY_TEXT_BOLD
        : typographySize.BODY_TEXT,
      ...props.textOptions
    }));

    const combinedIconOptions = computed(() => ({
      size: 14,
      theme: props.isActive ? themes.SECONDARY : themes.NONE,
      ...props.iconOptions
    }));

    const hasIcon = computed(() => !!props.iconOptions?.icon);

    const handleClick = (event) => {
      event.preventDefault();

      emit(DOMEvents.CLICK, event);
    };

    return {
      combinedTextOptions,
      combinedIconOptions,
      handleClick,
      hasIcon,
      themes
    };
  }
});
</script>

<style lang="scss" scoped>
.sidebar-menu-item {
  text-align: left;
  background-color: white;
  cursor: pointer;
  padding: $spacing20 $spacing24 $spacing20 $spacing32;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: $spacing8;
  border-bottom: 1px solid $grey;
  position: relative;

  &--is-active {
    background-color: rgba($portal-blue, 0.9);

    &::before {
      content: "";
      height: -webkit-fill-available;
      width: 4px;
      background-color: $primary-color;
      position: absolute;
      top: 0;
      left: 0;
      margin: $spacing12 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &--is-dropdown-variant {
    background-color: white;

    &::before {
      width: 0;
      height: 0;
    }
  }

  &__text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;

    :deep(.base-text) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    background-color: rgba($portal-blue, 0.9);
  }
}
</style>
