<!-- clients configuartion profile page sidebar menu -->
<template>
  <div>
    <InputErrorMessage v-if="errorMessage" :error="errorMessage" />
    <b-row style="background-color: #f4f5fd; height: 100%">
      <b-col cols="2" style="max-width: 20%">
        <sidebar-menu
          class="bg-light"
          :menu="makeMenu"
          :relative="true"
          :hideToggle="true"
          theme="white-theme"
        >
          <span slot="dropdown-icon">
            <font-awesome-icon icon="caret-down" />
          </span>
          <span slot="toggle-icon">
            <font-awesome-icon icon="caret-up" />
          </span>
        </sidebar-menu>
      </b-col>
      <b-col cols="9">
        <transition name="fade">
          <router-view />
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import { EventBus } from "@/event-bus.js";
import { localStorageItem, actionName } from "@/constants";
import { mapActions, mapState } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "ClientsConfigurationProfilePage",
  components: {
    SidebarMenu,
    InputErrorMessage
  },
  data() {
    return {
      isSubmiting: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      companyDetails: (state) => state.company.companyDetails,
      client: (state) => state.onboardingClient.client
    }),
    makeMenu() {
      return [
        {
          href: `/client-configuration-profile/${this.$route.params.id}/platform-settings`,
          title: "Platform Settings"
        },
        {
          href: `/client-configuration-profile/${this.$route.params.id}/settings`,
          title: "Settings"
        },
        {
          href: `/client-configuration-profile/${this.$route.params.id}/email-templates`,
          title: "Emails Templates"
        },
        {
          href: `/client-configuration-profile/${this.$route.params.id}/manage-training-course`,
          title: "Manage Training Courses",
          hidden: !this.client?.tenantId
        },
        {
          title: "Third Party",
          child: [
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-actions`,
              title: "Configure Actions"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-columns`,
              title: "Configure Columns"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-bulk-order-actions`,
              title: "Configure Bulk Order Actions"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-edd-configuration`,
              title: "Configure EDD"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-analytics`,
              title: "Configure Analytics"
            }
          ]
        },
        {
          title: "Account",
          child: [
            {
              href: `/client-configuration-profile/${this.$route.params.id}/action-details`,
              title: "Action Details"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/action-settings`,
              title: "Action Settings"
            }
          ]
        }
      ];
    }
  },
  beforeRouteUpdate({ name }, _, next) {
    if (name === "ActionSettings") {
      this.getAdditionalVouchersWrapper();
    }
    next();
  },
  mounted() {
    this.getAdditionalVouchersWrapper();
  },
  methods: {
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS
    }),
    getAdditionalVouchersWrapperFailure({ status = "" } = {}) {
      const description = status ? ` with status ${status}` : "";
      this.errorMessage = `There was an error${description}. Pleasy try again or contact support.`;
    },
    async getAdditionalVouchersWrapper() {
      try {
        this.errorMessage = "";
        this.isSubmiting = true;
        const { data = {}, status } = await this.fetchAdditionalVouchers(
          this.$route.params.id
        );

        if (data.fakeHttpStatus === 1 && status === 200) {
          localStorage.setItem(
            localStorageItem.ACTIONS_SCHEMA,
            JSON.stringify(this.companyDetails.additionalData)
          );

          localStorage.setItem(
            "openedCompanyProfile",
            this.companyDetails.companyName
          );
          localStorage.setItem(
            "autoFillMsqV2",
            this.companyDetails.autoFillMsqV2
          );
          EventBus.emit("callgetAdditionalVouchers");
        } else {
          this.getAdditionalVouchersWrapperFailure();
        }
      } catch ({ response }) {
        this.getAdditionalVouchersWrapperFailure(response);
      } finally {
        this.isSubmiting = false;
      }
    }
  }
};
</script>

<style lang="scss">
// Styling to be removed once sidebar menu library has been deprecated.
.v-sidebar-menu .vsm--title {
  color: $black;
  font-weight: 450;
  padding-left: 30px;
  line-height: initial;
}

.v-sidebar-menu .vsm--menu {
  width: 292px;
}

.v-sidebar-menu .vsm--link.vsm--link_exact-active .vsm--title {
  font-weight: 550;
  color: $azure !important;
  background: $bright-gray;
  line-height: 20px;
}

.v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
  background-color: $ghost-white !important;
}
.v-sidebar-menu .vsm--title {
  -ms-flex-positive: 1;
  font-size: 13px;
  padding-left: 0px;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1 {
  color: $white;
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  white-space: normal;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0px 0px 0px $white inset !important;
  background: $bright-gray;
}

.v-sidebar-menu .vsm--icon {
  color: $spanish-gray !important;
  background: $ghost-white !important;
  width: 20px !important;
  height: 30px !important;
  margin-right: 12px;
}

.v-sidebar-menu .vsm--child .vsm--icon {
  width: 14px !important;
  height: 22px !important;
}

.v-sidebar-menu .vsm--arrow {
  color: $spanish-gray !important;
}

.v-sidebar-menu .vsm--arrow {
  color: $spanish-gray !important;
  width: fit-content !important;
  padding-right: 4px;
}

.v-sidebar-menu .vsm--link_open {
  background-color: inherit !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: inherit !important;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1
  .vsm--icon {
  background-color: $bright-gray !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: $spanish-gray !important;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  height: 100%;
  margin-right: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-sidebar-menu .vsm--dropdown > .vsm--list {
  padding: 0px !important;
}

.v-sidebar-menu .vsm--dropdown {
  padding: 0 0 0 24px !important;
}
.vsm--link .vsm--link_level-3 .vsm--link_active .vsm--link_exact-active {
  background-color: $bright-gray !important;
}
.v-sidebar-menu .vsm--link_level-1 {
  font-weight: bolder !important;
}
.v-sidebar-menu .vsm--link_level-2 {
  color: $white;
  background-color: $ghost-white !important;
  padding-left: 18px !important;
}
.v-sidebar-menu .vsm--link_level-2 .vsm--link_active {
  padding: 10px !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active {
  background: $bright-gray !important;
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link_level-3 .vsm--link_active {
  color: $white;
  background-color: $bright-gray !important;
}
.vsm--link_active {
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link {
  display: none;
}
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  display: none !important;
}
.v-sidebar-menu .vsm--link.vsm--link_active.vsm--title {
  font-weight: bolder !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--dropdown {
  background-color: $ghost-white !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active .vsm--title {
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link {
  height: auto;
  padding: 10px !important;
}
</style>
