<template>
  <section class="product">
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <InputErrorMessage :error="errorMessage" />
      <BaseText :text="feedbackMessage" />
      <StickyTable
        class="product__table"
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        data-test-id="product__table"
      />
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import { productListTableHeaders, themes } from "@/constants";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "ProductList",
  components: {
    StickyTable,
    BaseLoader,
    InputErrorMessage,
    BaseText
  },
  data() {
    return {
      themes
    };
  },
  props: {
    productList: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ""
    },
    feedbackMessage: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableHeaders() {
      return this.productList.length
        ? Object.keys(productListTableHeaders).reduce((acc, key) => {
            if (productListTableHeaders[key]) {
              acc.push({
                value: key,
                text: productListTableHeaders[key]
              });
            }
            return acc;
          }, [])
        : [];
    },
    tableRows() {
      return this.productList?.length
        ? this.productList.map((product) =>
            Object.keys(productListTableHeaders).reduce((acc, productKey) => {
              acc[productKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: product[productKey]
                }
              };
              return acc;
            }, {})
          )
        : [];
    }
  }
};
</script>
<style scoped lang="scss">
.product {
  &__table {
    background-color: $white;
  }
}
</style>
