/**
 * Returns positive number if `numberA` is comes before `numberB`
 * Returns negative number if `numberA` comes after `numberB`
 * Returns `0` if both `numberA` and `numberB` are the same
 * @param {object} parameter - provided object
 * @param {number|string} parameter.numberA - number value to compare
 * @param {number|string} parameter.numberB - second string value to compare first against
 * @returns {number} - result of compare
 */

const compareNumber = ({ numberA, numberB }) => {
  const parsedNumberA = Number.parseFloat(numberA);
  const parsedNumberB = Number.parseFloat(numberB);

  if (Number.isNaN(parsedNumberA)) {
    return -1;
  }

  if (Number.isNaN(parsedNumberB)) {
    return 1;
  }

  return parsedNumberA - parsedNumberB;
};

/**
 * Returns percentage string of values provided
 * @param {object} parameter - provided object
 * @param {number} parameter.value - number value - numerator
 * @param {number} parameter.total - number value - denominator
 * @returns {string} - result as percentage - defaults to 0%.
 */

const calculatePercentage = ({ value = 0, total = 0 } = {}) => {
  const result = Math.round((value / total) * 100);

  return `${isNaN(result) ? 0 : result}%`;
};

export { compareNumber, calculatePercentage };
