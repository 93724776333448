<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClickLabel(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Title label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="title"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="First Name Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="foreNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Middel Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="middelNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Last Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="lastNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Known As Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="knownAsTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Date of birth Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="dateOfBirthTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Marital Status Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="maritalStatusTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Contact Number Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="contactNumberTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Email Address Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="emailAddressTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="LinkedIn Profile Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="linkedInProfileTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Free Form Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabClickHelperText(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Title</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="titleHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text First Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="foreNameHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Middel Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="middelNameHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text Last Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="lastNameHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Known As</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="knownAsHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text Date of birth</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="dateOfBirthHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Marital Status</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="maritalStatusHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text Contact Number</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="contactNumberHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Email Address</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="emailAddressHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text LinkedIn Profile</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="linkedInProfileHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------PlaceHolder Text------------------- -->
        <b-tab title="Place Holder Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabClickPlaceHolderText(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text First Name</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="foreNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder Text Middel Name</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="middelNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text Last Name</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="lastNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder Text Known As</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="knownAsPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text Contact Number</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="contactNumberPlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder Text Email Address</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="emailAddressPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder Text LinkedIn Profile</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="linkedInProfilePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Place Holder Text Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabClickValidationMessage(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Title</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="titleValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message First Name</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="foreNameValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Middel Name</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="middelNameValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message Last Name</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="lastNameValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Known As</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="knownAsValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message Date of birth</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="dateOfBirthValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Marital Status</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="maritalStatusValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message Contact Number</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="contactNumberValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Email Address</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="emailAddressValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message LinkedIn Profile</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="linkedInProfileValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message Free Form</label>
                    <b-form-input
                      @blur="addValidationLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Title</label>
                <b-form-input v-model="titlePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for First Name</label>
                <b-form-input v-model="foreNamePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Middel Name</label>
                <b-form-input v-model="middelNamePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Last Name</label>
                <b-form-input v-model="lastNamePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Known As</label>
                <b-form-input v-model="knownAsPostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Date of birth</label>
                <b-form-input v-model="dateOfBirthPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Marital Status</label>
                <b-form-input v-model="maritalStatusPostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Contact Number</label>
                <b-form-input v-model="contactNumberPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Email Address</label>
                <b-form-input v-model="emailAddressPostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for LinkedIn Profile</label>
                <b-form-input v-model="linkedInProfilePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <Checkbox
                class="text-left mb-3"
                v-model="titleDisabled"
                label="Disable Title?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="foreNameDisabled"
                label="Disable First Name?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="middelNameDisabled"
                label="Disable Middel Name?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="lastNameDisabled"
                label="Disable Last Name?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="knownAsDisabled"
                label="Disable Known As?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="dateOfBirthDisabled"
                label="Disable Date of birth?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="maritalStatusDisabled"
                label="Disable Marital Status?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="contactNumberDisabled"
                label="Disable Contact Number?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="emailAddressDisabled"
                label="Disable Email Address?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="linkedInProfileDisabled"
                label="Disable LinkedIn Profile?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="freeFormDisabled"
                label="Disable Free Form?"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireSelectedTitle"
                label="Is required Title?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireForeName"
                label="Is required First Name?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireMiddelNames"
                label="Is required Middel Name?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireLastName"
                label="Is required Last Name?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireKnownAs"
                label="Is required Known As?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireDateOfBirth"
                label="Is required Date of birth?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireSelectedMaritalStatus"
                label="Is required Marital Status?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireMobileContactNumber"
                label="Is required Contact Number?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequirePersonalEmailAddress"
                label="Is required Email Address?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireLinkedInProfile"
                label="Is required LinkedIn Profile?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                label="Is required Free Form?"
              />
            </b-col>
          </div>
        </b-tab>
        <!-- ----------------Advanced Date------------------- -->
        <b-tab title="Advanced Date">
          <div class="text-left mt-4">
            <div class="col-12">
              <Checkbox
                v-model="disabledMinDateInput"
                class="col-md-12 my-1"
                @update:modelValue="onToggleDatesCheckbox('min')"
                label="Disable Past Dates"
              />
              <b-collapse :visible="!disabledMinDateInput">
                <div class="form-group col-md-6">
                  <label for>Minimum date</label>
                  <b-form-input type="date" v-model="minDate"></b-form-input>
                </div>
              </b-collapse>

              <Checkbox
                class="col-md-12 my-1"
                @update:modelValue="onToggleDatesCheckbox('max')"
                v-model="disabledMaxDateInput"
                label="Disable Future Dates"
              />
              <b-collapse :visible="!disabledMaxDateInput">
                <div class="form-group col-md-6">
                  <label for>Maximum date</label>
                  <b-form-input type="date" v-model="maxDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-group label="Select Date Format">
                <b-form-radio-group
                  @input="onSetDateFormat(selectedDateFormat)"
                  stacked
                  v-model="selectedDateFormat"
                  :options="optionsDateFormatt"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-tab>
        <!-- --------------Conditional------------------ -->
        <b-tab title="Conditional">
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab title="Simple">
              <b-row> </b-row>
              <b-col cols="12" class="mr-0">
                <Checkbox
                  v-model="isConditional"
                  name="conditional"
                  checked-value="1"
                  unchecked-value="0"
                  7
                  label="Is Conditional?"
                />

                <div v-if="isConditional == 1">
                  <b-row>
                    <table class="table table-sm">
                      <thead>
                        <tr class="text-center">
                          <th>No</th>
                          <th>When Field</th>
                          <th>Has Value</th>
                          <th>Actions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(selectedOpt, index) in fieldData.conditions"
                          :key="selectedOpt.id"
                        >
                          <td>{{ index }}</td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.label" />
                          </td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.value" />
                          </td>
                          <td>
                            <b-button
                              variant="outline-danger"
                              class="btn-sm ml-1"
                              @click="
                                onRemoveConditionOpt(selectedOpt.id, index)
                              "
                            >
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>

                        <tr
                          v-for="(noOfOption, index) in noOfConditions"
                          :key="index"
                        >
                          <td>{{ fieldData.conditions.length }}</td>
                          <td>
                            <v-select
                              v-model="conditionalField"
                              class="mb-2"
                              :options="conditionalSectionElementLables"
                              label="label"
                              @update:modelValue="getSelectedElement"
                            ></v-select>
                          </td>
                          <td>
                            <v-select
                              class="mb-2"
                              label="text"
                              v-model="conditionalFieldValue"
                              :options="selectedElementOption"
                            ></v-select>
                          </td>

                          <td>
                            <b-button
                              class="btn-sm"
                              variant="outline-primary"
                              @click="setFieldConditions"
                              >Apply</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row>
                </div>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "CorporateShareHoldersSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: { Checkbox },

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      title: null,
      foreNameTitle: null,
      middelNameTitle: null,
      lastNameTitle: null,
      knownAsTitle: null,
      dateOfBirthTitle: null,
      maritalStatusTitle: null,
      contactNumberTitle: null,
      emailAddressTitle: null,
      linkedInProfileTitle: null,
      freeFormTitle: null,

      // Helper Text
      titleHelperText: null,
      foreNameHelperText: null,
      middelNameHelperText: null,
      lastNameHelperText: null,
      knownAsHelperText: null,
      dateOfBirthHelperText: null,
      maritalStatusHelperText: null,
      contactNumberHelperText: null,
      emailAddressHelperText: null,
      linkedInProfileHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      foreNamePlaceHolderText: null,
      middelNamePlaceHolderText: null,
      lastNamePlaceHolderText: null,
      knownAsPlaceHolderText: null,
      contactNumberPlaceHolderText: null,
      emailAddressPlaceHolderText: null,
      linkedInProfilePlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      titleValidationMessage: null,
      foreNameValidationMessage: null,
      middelNameValidationMessage: null,
      lastNameValidationMessage: null,
      knownAsValidationMessage: null,
      dateOfBirthValidationMessage: null,
      maritalStatusValidationMessage: null,
      contactNumberValidationMessage: null,
      emailAddressValidationMessage: null,
      linkedInProfileValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      titleDisabled: false,
      foreNameDisabled: false,
      middelNameDisabled: false,
      lastNameDisabled: false,
      knownAsDisabled: false,
      dateOfBirthDisabled: false,
      maritalStatusDisabled: false,
      contactNumberDisabled: false,
      emailAddressDisabled: false,
      linkedInProfileDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireSelectedTitle: true,
      isRequireForeName: true,
      isRequireMiddelNames: true,
      isRequireLastName: true,
      isRequireKnownAs: true,
      isRequireDateOfBirth: true,
      isRequireSelectedMaritalStatus: true,
      isRequireMobileContactNumber: true,
      isRequirePersonalEmailAddress: true,
      isRequireLinkedInProfile: true,
      isRequireFreeForm: true,

      // attributes
      titlePostName: null,
      foreNamePostName: null,
      middelNamePostName: null,
      lastNamePostName: null,
      knownAsPostName: null,
      dateOfBirthPostName: null,
      maritalStatusPostName: null,
      contactNumberPostName: null,
      emailAddressPostName: null,
      linkedInProfilePostName: null,
      freeFormPostName: null,

      // advance date
      minDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
          ? this.fieldData.advanceDate.minDate
          : undefined,
      maxDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
          ? this.fieldData.advanceDate.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMinDateInput
          ? this.fieldData.advanceDate.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMaxDateInput
          ? this.fieldData.advanceDate.disabledMaxDateInput
          : false,
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ]
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      // this.addLabelLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderLanguageToField(this.transalationLanguage);
      // this.addValidationLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
      this.addDateAdvacne();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.title = this.fieldData.selectedTitleLabel[this.transalationLanguage];
      this.foreNameTitle =
        this.fieldData.foreNameLabel[this.transalationLanguage];
      this.middelNameTitle =
        this.fieldData.middelNamesLabel[this.transalationLanguage];

      this.lastNameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];

      this.knownAsTitle =
        this.fieldData.knownAsLabel[this.transalationLanguage];

      this.dateOfBirthTitle =
        this.fieldData.dateOfBirthLabel[this.transalationLanguage];

      this.maritalStatusTitle =
        this.fieldData.selectedMaritalStatusLabel[this.transalationLanguage];

      this.contactNumberTitle =
        this.fieldData.mobileContactNumberLabel[this.transalationLanguage];

      this.emailAddressTitle =
        this.fieldData.personalEmailAddressLabel[this.transalationLanguage];

      this.linkedInProfileTitle =
        this.fieldData.linkedInProfileLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper Text
      this.titleHelperText =
        this.fieldData.selectedTitleHelperText[this.transalationLanguage];
      this.foreNameHelperText =
        this.fieldData.foreNameHelperText[this.transalationLanguage];
      this.middelNameHelperText =
        this.fieldData.middelNamesHelperText[this.transalationLanguage];

      this.lastNameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];

      this.knownAsHelperText =
        this.fieldData.knownAsHelperText[this.transalationLanguage];

      this.dateOfBirthHelperText =
        this.fieldData.dateOfBirthHelperText[this.transalationLanguage];

      this.maritalStatusHelperText =
        this.fieldData.selectedMaritalStatusHelperText[
          this.transalationLanguage
        ];

      this.contactNumberHelperText =
        this.fieldData.mobileContactNumberHelperText[this.transalationLanguage];

      this.emailAddressHelperText =
        this.fieldData.personalEmailAddressHelperText[
          this.transalationLanguage
        ];

      this.linkedInProfileHelperText =
        this.fieldData.linkedInProfileHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      // PlaceHolder Text
      this.foreNamePlaceHolderText =
        this.fieldData.foreNamePlaceholder[this.transalationLanguage];

      this.middelNamePlaceHolderText =
        this.fieldData.middelNamesPlaceholder[this.transalationLanguage];

      this.lastNamePlaceHolderText =
        this.fieldData.lastNamePlaceholder[this.transalationLanguage];

      this.knownAsPlaceHolderText =
        this.fieldData.knownAsPlaceholder[this.transalationLanguage];

      this.contactNumberPlaceHolderText =
        this.fieldData.mobileContactNumberPlaceholder[
          this.transalationLanguage
        ];

      this.emailAddressPlaceHolderText =
        this.fieldData.personalEmailAddressPlaceholder[
          this.transalationLanguage
        ];

      this.linkedInProfilePlaceHolderText =
        this.fieldData.linkedInProfilePlaceholder[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholder[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message
      this.titleValidationMessage =
        this.fieldData.selectedTitleValidationMessage[
          this.transalationLanguage
        ];
      this.foreNameValidationMessage =
        this.fieldData.foreNameValidationMessage[this.transalationLanguage];
      this.middelNameValidationMessage =
        this.fieldData.middelNamesValidationMessage[this.transalationLanguage];

      this.lastNameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];

      this.knownAsValidationMessage =
        this.fieldData.knownAsValidationMessage[this.transalationLanguage];

      this.dateOfBirthValidationMessage =
        this.fieldData.dateOfBirthValidationMessage[this.transalationLanguage];

      this.maritalStatusValidationMessage =
        this.fieldData.selectedMaritalStatusValidationMessage[
          this.transalationLanguage
        ];

      this.contactNumberValidationMessage =
        this.fieldData.mobileContactNumberValidationMessage[
          this.transalationLanguage
        ];

      this.emailAddressValidationMessage =
        this.fieldData.personalEmailAddressValidationMessage[
          this.transalationLanguage
        ];

      this.linkedInProfileValidationMessage =
        this.fieldData.linkedInProfileValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.titlePostName =
        this.fieldData.attributes && this.fieldData.attributes.titlePostName
          ? this.fieldData.attributes.titlePostName
          : undefined),
        (this.foreNamePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.foreNamePostName
            ? this.fieldData.attributes.foreNamePostName
            : undefined),
        (this.middelNamePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.middelNamePostName
            ? this.fieldData.attributes.middelNamePostName
            : undefined),
        (this.lastNamePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.lastNamePostName
            ? this.fieldData.attributes.lastNamePostName
            : undefined),
        (this.knownAsPostName =
          this.fieldData.attributes && this.fieldData.attributes.knownAsPostName
            ? this.fieldData.attributes.knownAsPostName
            : undefined),
        (this.dateOfBirthPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.dateOfBirthPostName
            ? this.fieldData.attributes.dateOfBirthPostName
            : undefined),
        (this.maritalStatusPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.maritalStatusPostName
            ? this.fieldData.attributes.maritalStatusPostName
            : undefined),
        (this.contactNumberPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.contactNumberPostName
            ? this.fieldData.attributes.contactNumberPostName
            : undefined),
        (this.emailAddressPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.emailAddressPostName
            ? this.fieldData.attributes.emailAddressPostName
            : undefined),
        (this.linkedInProfilePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.linkedInProfilePostName
            ? this.fieldData.attributes.linkedInProfilePostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.titleDisabled =
        this.fieldData.advance && this.fieldData.advance.titleDisabled),
        (this.foreNameDisabled =
          this.fieldData.advance && this.fieldData.advance.foreNameDisabled),
        (this.middelNameDisabled =
          this.fieldData.advance && this.fieldData.advance.middelNameDisabled),
        (this.lastNameDisabled =
          this.fieldData.advance && this.fieldData.advance.lastNameDisabled),
        (this.knownAsDisabled =
          this.fieldData.advance && this.fieldData.advance.knownAsDisabled),
        (this.dateOfBirthDisabled =
          this.fieldData.advance && this.fieldData.advance.dateOfBirthDisabled),
        (this.maritalStatusDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.maritalStatusDisabled),
        (this.contactNumberDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.contactNumberDisabled),
        (this.emailAddressDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.emailAddressDisabled),
        (this.linkedInProfileDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.linkedInProfileDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireSelectedTitle =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireSelectedTitle),
        (this.isRequireForeName =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireForeName),
        (this.isRequireMiddelNames =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireMiddelNames),
        (this.isRequireLastName =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireLastName),
        (this.isRequireKnownAs =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireKnownAs),
        (this.isRequireDateOfBirth =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireDateOfBirth),
        (this.isRequireSelectedMaritalStatus =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireSelectedMaritalStatus),
        (this.isRequireMobileContactNumber =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireMobileContactNumber),
        (this.isRequirePersonalEmailAddress =
          this.fieldData.validation &&
          this.fieldData.validation.isRequirePersonalEmailAddress),
        (this.isRequireLinkedInProfile =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireLinkedInProfile),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabClickLabel(language) {
      // Label
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];

      this.title = this.fieldData.selectedTitleLabel[this.transalationLanguage];
      this.foreNameTitle =
        this.fieldData.foreNameLabel[this.transalationLanguage];
      this.middelNameTitle =
        this.fieldData.middelNamesLabel[this.transalationLanguage];

      this.lastNameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];

      this.knownAsTitle =
        this.fieldData.knownAsLabel[this.transalationLanguage];

      this.dateOfBirthTitle =
        this.fieldData.dateOfBirthLabel[this.transalationLanguage];

      this.maritalStatusTitle =
        this.fieldData.selectedMaritalStatusLabel[this.transalationLanguage];

      this.contactNumberTitle =
        this.fieldData.mobileContactNumberLabel[this.transalationLanguage];

      this.emailAddressTitle =
        this.fieldData.personalEmailAddressLabel[this.transalationLanguage];

      this.linkedInProfileTitle =
        this.fieldData.linkedInProfileLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabClickHelperText(language) {
      this.transalationLanguage = language.toLowerCase();
      // Helper Text
      this.titleHelperText =
        this.fieldData.selectedTitleHelperText[this.transalationLanguage];
      this.foreNameHelperText =
        this.fieldData.foreNameHelperText[this.transalationLanguage];
      this.middelNameHelperText =
        this.fieldData.middelNamesHelperText[this.transalationLanguage];

      this.lastNameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];

      this.knownAsHelperText =
        this.fieldData.knownAsHelperText[this.transalationLanguage];

      this.dateOfBirthHelperText =
        this.fieldData.dateOfBirthHelperText[this.transalationLanguage];

      this.maritalStatusHelperText =
        this.fieldData.selectedMaritalStatusHelperText[
          this.transalationLanguage
        ];

      this.contactNumberHelperText =
        this.fieldData.mobileContactNumberHelperText[this.transalationLanguage];

      this.emailAddressHelperText =
        this.fieldData.personalEmailAddressHelperText[
          this.transalationLanguage
        ];

      this.linkedInProfileHelperText =
        this.fieldData.linkedInProfileHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabClickPlaceHolderText(language) {
      this.transalationLanguage = language.toLowerCase();
      // PlaceHolder Text
      this.foreNamePlaceHolderText =
        this.fieldData.foreNamePlaceholder[this.transalationLanguage];
      this.middelNamePlaceHolderText =
        this.fieldData.middelNamesPlaceholder[this.transalationLanguage];

      this.lastNamePlaceHolderText =
        this.fieldData.lastNamePlaceholder[this.transalationLanguage];

      this.knownAsPlaceHolderText =
        this.fieldData.knownAsPlaceholder[this.transalationLanguage];

      this.contactNumberPlaceHolderText =
        this.fieldData.mobileContactNumberPlaceholder[
          this.transalationLanguage
        ];

      this.emailAddressPlaceHolderText =
        this.fieldData.personalEmailAddressPlaceholder[
          this.transalationLanguage
        ];

      this.linkedInProfilePlaceHolderText =
        this.fieldData.linkedInProfilePlaceholder[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholder[this.transalationLanguage];
    },

    tabClickValidationMessage(language) {
      this.transalationLanguage = language.toLowerCase();
      // Validation Message
      this.titleValidationMessage =
        this.fieldData.selectedTitleValidationMessage[
          this.transalationLanguage
        ];
      this.foreNameValidationMessage =
        this.fieldData.foreNameValidationMessage[this.transalationLanguage];
      this.middelNameValidationMessage =
        this.fieldData.middelNamesValidationMessage[this.transalationLanguage];

      this.lastNameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];

      this.knownAsValidationMessage =
        this.fieldData.knownAsValidationMessage[this.transalationLanguage];

      this.dateOfBirthValidationMessage =
        this.fieldData.dateOfBirthValidationMessage[this.transalationLanguage];

      this.maritalStatusValidationMessage =
        this.fieldData.selectedMaritalStatusValidationMessage[
          this.transalationLanguage
        ];

      this.contactNumberValidationMessage =
        this.fieldData.mobileContactNumberValidationMessage[
          this.transalationLanguage
        ];

      this.emailAddressValidationMessage =
        this.fieldData.personalEmailAddressValidationMessage[
          this.transalationLanguage
        ];

      this.linkedInProfileValidationMessage =
        this.fieldData.linkedInProfileValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;

      this.fieldData.selectedTitleLabel[tempKey] = this.title;
      this.fieldData.foreNameLabel[tempKey] = this.foreNameTitle;
      this.fieldData.middelNamesLabel[tempKey] = this.middelNameTitle;
      this.fieldData.lastNameLabel[tempKey] = this.lastNameTitle;
      this.fieldData.knownAsLabel[tempKey] = this.knownAsTitle;
      this.fieldData.dateOfBirthLabel[tempKey] = this.dateOfBirthTitle;
      this.fieldData.selectedMaritalStatusLabel[tempKey] =
        this.maritalStatusTitle;
      this.fieldData.mobileContactNumberLabel[tempKey] =
        this.contactNumberTitle;
      this.fieldData.personalEmailAddressLabel[tempKey] =
        this.emailAddressTitle;
      this.fieldData.linkedInProfileLabel[tempKey] = this.linkedInProfileTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Helper Text
      this.fieldData.selectedTitleHelperText[tempKey] = this.titleHelperText;
      this.fieldData.foreNameHelperText[tempKey] = this.foreNameHelperText;
      this.fieldData.middelNamesHelperText[tempKey] = this.middelNameHelperText;
      this.fieldData.lastNameHelperText[tempKey] = this.lastNameHelperText;
      this.fieldData.knownAsHelperText[tempKey] = this.knownAsHelperText;
      this.fieldData.dateOfBirthHelperText[tempKey] =
        this.dateOfBirthHelperText;
      this.fieldData.selectedMaritalStatusHelperText[tempKey] =
        this.maritalStatusHelperText;
      this.fieldData.mobileContactNumberHelperText[tempKey] =
        this.contactNumberHelperText;
      this.fieldData.personalEmailAddressHelperText[tempKey] =
        this.emailAddressHelperText;
      this.fieldData.linkedInProfileHelperText[tempKey] =
        this.linkedInProfileHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // PlaceHolder Text

      this.fieldData.foreNamePlaceholder[tempKey] =
        this.foreNamePlaceHolderText;
      this.fieldData.middelNamesPlaceholder[tempKey] =
        this.middelNamePlaceHolderText;
      this.fieldData.lastNamePlaceholder[tempKey] =
        this.lastNamePlaceHolderText;
      this.fieldData.knownAsPlaceholder[tempKey] = this.knownAsPlaceHolderText;
      this.fieldData.mobileContactNumberPlaceholder[tempKey] =
        this.contactNumberPlaceHolderText;
      this.fieldData.personalEmailAddressPlaceholder[tempKey] =
        this.emailAddressPlaceHolderText;
      this.fieldData.linkedInProfilePlaceholder[tempKey] =
        this.linkedInProfilePlaceHolderText;
      this.fieldData.freeFormPlaceholder[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Validation Message
      this.fieldData.selectedTitleValidationMessage[tempKey] =
        this.titleValidationMessage;
      this.fieldData.foreNameValidationMessage[tempKey] =
        this.foreNameValidationMessage;
      this.fieldData.middelNamesValidationMessage[tempKey] =
        this.middelNameValidationMessage;
      this.fieldData.lastNameValidationMessage[tempKey] =
        this.lastNameValidationMessage;
      this.fieldData.knownAsValidationMessage[tempKey] =
        this.knownAsValidationMessage;
      this.fieldData.dateOfBirthValidationMessage[tempKey] =
        this.dateOfBirthValidationMessage;
      this.fieldData.selectedMaritalStatusValidationMessage[tempKey] =
        this.maritalStatusValidationMessage;
      this.fieldData.mobileContactNumberValidationMessage[tempKey] =
        this.contactNumberValidationMessage;
      this.fieldData.personalEmailAddressValidationMessage[tempKey] =
        this.emailAddressValidationMessage;
      this.fieldData.linkedInProfileValidationMessage[tempKey] =
        this.linkedInProfileValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireSelectedTitle: this.titleDisabled
          ? false
          : this.isRequireSelectedTitle,
        isRequireForeName: this.foreNameDisabled
          ? false
          : this.isRequireForeName,
        isRequireMiddelNames: this.middelNameDisabled
          ? false
          : this.isRequireMiddelNames,
        isRequireLastName: this.lastNameDisabled
          ? false
          : this.isRequireLastName,
        isRequireKnownAs: this.knownAsDisabled ? false : this.isRequireKnownAs,
        isRequireDateOfBirth: this.dateOfBirthDisabled
          ? false
          : this.isRequireDateOfBirth,
        isRequireSelectedMaritalStatus: this.maritalStatusDisabled
          ? false
          : this.isRequireSelectedMaritalStatus,
        isRequireMobileContactNumber: this.contactNumberDisabled
          ? false
          : this.isRequireMobileContactNumber,
        isRequirePersonalEmailAddress: this.emailAddressDisabled
          ? false
          : this.isRequirePersonalEmailAddress,
        isRequireLinkedInProfile: this.linkedInProfileDisabled
          ? false
          : this.isRequireLinkedInProfile,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        titleDisabled: this.titleDisabled,
        foreNameDisabled: this.foreNameDisabled,
        middelNameDisabled: this.middelNameDisabled,
        lastNameDisabled: this.lastNameDisabled,
        knownAsDisabled: this.knownAsDisabled,
        dateOfBirthDisabled: this.dateOfBirthDisabled,
        maritalStatusDisabled: this.maritalStatusDisabled,
        contactNumberDisabled: this.contactNumberDisabled,
        emailAddressDisabled: this.emailAddressDisabled,
        linkedInProfileDisabled: this.linkedInProfileDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        titlePostName: this.titlePostName,
        foreNamePostName: this.foreNamePostName,
        middelNamePostName: this.middelNamePostName,
        lastNamePostName: this.lastNamePostName,
        knownAsPostName: this.knownAsPostName,
        dateOfBirthPostName: this.dateOfBirthPostName,
        maritalStatusPostName: this.maritalStatusPostName,
        contactNumberPostName: this.contactNumberPostName,
        emailAddressPostName: this.emailAddressPostName,
        linkedInProfilePostName: this.linkedInProfilePostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    addDateAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();
      // tomorrow.setDate(new Date().getDate() + 1);

      let advanceDate = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advanceDate });
    },

    onSetDateFormat() {
      let format = this.selectedDateFormat;
      Object.assign(this.fieldData, { format });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
