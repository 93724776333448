import { chartTypes, colors, chartStyles } from "@/constants";
import EB2ColorPallete from "@/utils/eb2ColorPallete.json";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { cloneDeep } from "lodash";

const convertListToObject = (keyName, keyValue, objectList = []) =>
  Array.isArray(objectList)
    ? objectList.reduce((acc, objectListElement) => {
        const key = objectListElement[keyName];
        acc[key] = objectListElement[keyValue];
        return acc;
      }, {})
    : {};

const makeDrillDownOptions = ({
  tableTitle = "",
  tableId = "",
  modalTitle = "",
  querySpecificData = null,
  useNewQueryParameter = false,
  uriData = null
} = {}) => ({
  tableTitle,
  tableId,
  modalTitle,
  querySpecificData,
  useNewQueryParameter,
  uriData
});

const makeObserveVisibility = (
  callBack = () => false,
  widget = {},
  delayOfVisibility = 0.9,
  preventCallbackAfterIsVisible = true
) => ({
  callback: (isVisible) => callBack(isVisible, widget),
  intersection: {
    threshold: delayOfVisibility
  },
  once: preventCallbackAfterIsVisible
});

const makeBaseOptions = (options) => ({
  title: {
    text: ""
  },
  series: [],
  credits: {
    enabled: false
  },
  subtitle: {
    text: ""
  },
  tooltip: {
    pointFormat: "<b>{point.percentage:.1f}%</b>"
  },
  accessibility: {
    point: {
      valueSuffix: "%"
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      depth: 35,
      dataLabels: {
        enabled: true,
        format: "{point.name}"
      }
    }
  },
  chart: {
    type: chartTypes.PIE,
    zooming: {
      mouseWheel: {
        enabled: false
      },
      type: ""
    }
  },
  colors: [],
  ...options
});

const makeSavedThirdPartyChartsOptions = () =>
  makeBaseOptions({
    title: {
      text: "Third Parties Over Time"
    },
    tooltip: {},
    accessibility: {},
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    },
    yAxis: {
      title: {
        text: "No. of Third Parties"
      }
    },
    xAxis: {
      categories: []
    }
  });

const makePriChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "Perceived Risk Indicator Results"
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsRedColor,
      EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor
    ]
  })
});

const makeMapChartOptions = () =>
  makeBaseOptions({
    title: {
      text: "Third Parties By Countries"
    },
    tooltip: {},
    accessibility: {},
    plotOptions: {},
    chart: {
      borderWidth: 0,
      map: worldMap,
      height: 600,
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: "bottom"
      }
    },
    series: []
  });

const makeSanctionsAlertsCountChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "Sanctions & Enforcements Results (IDD)",
      style: chartStyles.TITLE
    },
    chart: {
      type: chartTypes.PIE,
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsRedColor,
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor
    ]
  })
});

const makeIddPlusPreRemediationCountChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "IDD+ Results Pre-Remediation"
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
      EB2ColorPallete.EB2ColorPallete.chatsRedColor
    ]
  })
});

const makeIddPlusPostRemediationCountChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "Instant Due Diligence PLUS"
    },
    subtitle: {
      text: "Results Post-Remediation"
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsDarkColor,
      EB2ColorPallete.EB2ColorPallete.chatsRedColor,
      EB2ColorPallete.EB2ColorPallete.chatsAmberColor
    ]
  })
});

const makeEddResultCountChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "Enhanced Due Diligence Results"
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
      EB2ColorPallete.EB2ColorPallete.chatsRedColor
    ]
  })
});

const makeTpCountriesChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: "Third Parties by Country"
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsRedColor,
      EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
      EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
      EB2ColorPallete.EB2ColorPallete.chatsDarkColor,
      EB2ColorPallete.EB2ColorPallete.chatsBlueColor,
      EB2ColorPallete.EB2ColorPallete.chatsPurpleColor
    ]
  })
});

const makeDdqRiskScoreChartOptions = () => ({
  ...makeBaseOptions({
    title: {
      text: ""
    },
    colors: [
      EB2ColorPallete.EB2ColorPallete.chatsRedColor,
      EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
      EB2ColorPallete.EB2ColorPallete.chatsGreenColor
    ]
  })
});

const makeChartCountryObj = () => ({
  name: "Countries",
  color: "#E0E0E0",
  enableMouseTracking: false
});

const makeBarChartObj = () => ({
  chart: {
    type: "bar",
    zooming: {
      mouseWheel: {
        enabled: false
      }
    }
  },
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  colors: [],
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: false
      },
      colorByPoint: true
    }
  },
  credits: {
    enabled: false
  },
  series: []
});

const makeBubbleChartObj = () => ({
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  colors: [colors.carolinaBlue],
  plotOptions: {
    bubble: {
      dataLabels: {
        enabled: false
      }
    }
  },
  credits: {
    enabled: false
  },
  chart: {
    type: chartTypes.BUBBLE,
    zooming: {
      mouseWheel: {
        enabled: false
      },
      type: ""
    }
  },
  series: []
});

const makeScatterChartObject = () => ({
  chart: {
    type: "scatter",
    zooming: {
      mouseWheel: {
        enabled: false
      }
    }
  },
  title: {
    text: ""
  },
  legend: { enabled: false },
  xAxis: {
    type: "scatter",
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true
  },
  yAxis: {
    title: {
      text: ""
    },
    labels: {
      format: "{value}"
    }
  },
  plotOptions: {
    scatter: {
      marker: {
        radius: 2.5,
        symbol: "circle",
        states: {
          hover: {
            enabled: true,
            lineColor: "rgb(100,100,100)"
          }
        }
      },
      states: {
        hover: {
          marker: {
            enabled: false
          }
        }
      },
      jitter: {
        x: 0.005
      }
    }
  },
  tooltip: {
    pointFormat: "<b>{point.x} <br/>{point.y}</b>"
  },
  credits: {
    enabled: false
  },
  series: []
});

const sanitizeFields = (obj, invalidValues = [undefined, null, ""]) => {
  const clonedObj = cloneDeep(obj);

  Object.keys(clonedObj).forEach((key) => {
    if (invalidValues.includes(clonedObj[key])) {
      delete clonedObj[key];
    } else if (
      typeof clonedObj[key] === "object" &&
      !Array.isArray(clonedObj[key])
    ) {
      clonedObj[key] = sanitizeFields(clonedObj[key]);
    }
  });

  return clonedObj;
};

export {
  sanitizeFields,
  makeBubbleChartObj,
  makeBarChartObj,
  makeChartCountryObj,
  makeDdqRiskScoreChartOptions,
  makeTpCountriesChartOptions,
  makeEddResultCountChartOptions,
  makeIddPlusPostRemediationCountChartOptions,
  makeIddPlusPreRemediationCountChartOptions,
  makeSanctionsAlertsCountChartOptions,
  makeMapChartOptions,
  makePriChartOptions,
  makeSavedThirdPartyChartsOptions,
  makeBaseOptions,
  makeObserveVisibility,
  makeDrillDownOptions,
  convertListToObject,
  makeScatterChartObject
};
