<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Full Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="fullNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Officer role Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="officerRoleTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Date appointed label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="dateAppointedTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Free Form label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Full Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="fullNameHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Officer role</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="officerRoleHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Date appointed</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="dateAppointedHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Full Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="fullNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Officer role</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="officerRolePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationMessageClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Full Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="fullNameValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Officer role</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="officerRoleValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Date appointed</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="dateAppointedValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Full Name</label>
                <b-form-input v-model="fullNamePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Officer role</label>
                <b-form-input v-model="officerRolePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Date appointed</label>
                <b-form-input v-model="dateAppointedPostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <Checkbox
                class="text-left mb-3"
                v-model="fullNameDisabled"
                label="Disable Full Name?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="officerRoleDisabled"
                label="Disable Officer role?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="dateAppointedDisabled"
                label="Disable Date appointed?"
              />

              <Checkbox
                class="text-left mb-3"
                v-model="freeFormDisabled"
                label="Disable Free Form?"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFullName"
                label="Is required Full Name?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireOfficerRole"
                label="Is required Officer role?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireDateAppointed"
                label="Is required Date appointed?"
              />

              <Checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                label="Is required Free Form?"
              />
            </b-col>
          </div>
        </b-tab>
        <!-- ----------------Advanced Date------------------- -->
        <b-tab title="Advanced Date">
          <div class="text-left mt-4">
            <div class="col-12">
              <Checkbox
                v-model="disabledMinDateInput"
                class="col-md-12 my-1"
                @update:modelValue="onToggleDatesCheckbox('min')"
                label="Disable Past Dates"
              />
              <b-collapse :visible="!disabledMinDateInput">
                <div class="form-group col-md-6">
                  <label for>Minimum date</label>
                  <b-form-input type="date" v-model="minDate"></b-form-input>
                </div>
              </b-collapse>

              <Checkbox
                class="col-md-12 my-1"
                @update:modelValue="onToggleDatesCheckbox('max')"
                v-model="disabledMaxDateInput"
                label="Disable Future Dates"
              />
              <b-collapse :visible="!disabledMaxDateInput">
                <div class="form-group col-md-6">
                  <label for>Maximum date</label>
                  <b-form-input type="date" v-model="maxDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-group label="Select Date Format">
                <b-form-radio-group
                  @input="onSetDateFormat(selectedDateFormat)"
                  stacked
                  v-model="selectedDateFormat"
                  :options="optionsDateFormatt"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "CompanyOfficersSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: { Checkbox },

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      fullNameTitle: null,
      dateAppointedTitle: null,
      officerRoleTitle: null,
      freeFormTitle: null,

      // Helper Text
      fullNameHelperText: null,
      dateAppointedHelperText: null,
      officerRoleHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      fullNamePlaceHolderText: null,
      officerRolePlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      fullNameValidationMessage: null,
      dateAppointedValidationMessage: null,
      officerRoleValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      fullNameDisabled: false,
      dateAppointedDisabled: false,
      officerRoleDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireFullName: true,
      isRequireDateAppointed: true,
      isRequireFreeForm: false,
      isRequireOfficerRole: true,

      // attributes
      fullNamePostName: null,
      dateAppointedPostName: null,
      officerRolePostName: null,
      freeFormPostName: null,

      // advance date
      minDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
          ? this.fieldData.advanceDate.minDate
          : undefined,
      maxDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
          ? this.fieldData.advanceDate.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMinDateInput
          ? this.fieldData.advanceDate.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMaxDateInput
          ? this.fieldData.advanceDate.disabledMaxDateInput
          : false,
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ]
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      // this.addLabelLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderTextLanguageToField(this.transalationLanguage);
      // this.addValidationMessageLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
      this.addDateAdvacne();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.fullNameTitle =
        this.fieldData.fullNameLabel[this.transalationLanguage];
      this.dateAppointedTitle =
        this.fieldData.dateAppointedLabel[this.transalationLanguage];
      this.officerRoleTitle =
        this.fieldData.officerRoleLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper text

      this.fullNameHelperText =
        this.fieldData.fullNameHelperText[this.transalationLanguage];
      this.dateAppointedHelperText =
        this.fieldData.dateAppointedHelperText[this.transalationLanguage];
      this.officerRoleHelperText =
        this.fieldData.officerRoleHelperText[this.transalationLanguage];
      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      this.fullNamePlaceHolderText =
        this.fieldData.fullNamePlaceholderText[this.transalationLanguage];

      this.officerRolePlaceHolderText =
        this.fieldData.officerRolePlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message

      this.fullNameValidationMessage =
        this.fieldData.fullNameValidationMessage[this.transalationLanguage];

      this.dateAppointedValidationMessage =
        this.fieldData.dateAppointedValidationMessage[
          this.transalationLanguage
        ];

      this.officerRoleValidationMessage =
        this.fieldData.officerRoleValidationMessage[this.transalationLanguage];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.fullNamePostName =
        this.fieldData.attributes && this.fieldData.attributes.fullNamePostName
          ? this.fieldData.attributes.fullNamePostName
          : undefined),
        (this.dateAppointedPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.dateAppointedPostName
            ? this.fieldData.attributes.dateAppointedPostName
            : undefined),
        (this.officerRolePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.officerRolePostName
            ? this.fieldData.attributes.officerRolePostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.fullNameDisabled =
        this.fieldData.advance && this.fieldData.advance.fullNameDisabled),
        (this.dateAppointedDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.dateAppointedDisabled),
        (this.officerRoleDisabled =
          this.fieldData.advance && this.fieldData.advance.officerRoleDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireFullName =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireFullName),
        (this.isRequireDateAppointed =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireDateAppointed),
        (this.isRequireOfficerRole =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireOfficerRole),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.fullNameTitle =
        this.fieldData.fullNameLabel[this.transalationLanguage];
      this.dateAppointedTitle =
        this.fieldData.dateAppointedLabel[this.transalationLanguage];
      this.officerRoleTitle =
        this.fieldData.officerRoleLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabHelperTextClick(language) {
      // Helper text

      this.transalationLanguage = language.toLowerCase();
      this.fullNameHelperText =
        this.fieldData.fullNameHelperText[this.transalationLanguage];
      this.dateAppointedHelperText =
        this.fieldData.dateAppointedHelperText[this.transalationLanguage];
      this.officerRoleHelperText =
        this.fieldData.officerRoleHelperText[this.transalationLanguage];
      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderTextClick(language) {
      this.transalationLanguage = language.toLowerCase();
      this.fullNamePlaceHolderText =
        this.fieldData.fullNamePlaceholderText[this.transalationLanguage];

      this.officerRolePlaceHolderText =
        this.fieldData.officerRolePlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationMessageClick(language) {
      // Validation Message

      this.transalationLanguage = language.toLowerCase();
      this.fullNameValidationMessage =
        this.fieldData.fullNameValidationMessage[this.transalationLanguage];

      this.dateAppointedValidationMessage =
        this.fieldData.dateAppointedValidationMessage[
          this.transalationLanguage
        ];

      this.officerRoleValidationMessage =
        this.fieldData.officerRoleValidationMessage[this.transalationLanguage];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.fullNameLabel[tempKey] = this.fullNameTitle;
      this.fieldData.dateAppointedLabel[tempKey] = this.dateAppointedTitle;
      this.fieldData.officerRoleLabel[tempKey] = this.officerRoleTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      // Helper Text

      let tempKey = language.toLowerCase();
      this.fieldData.fullNameHelperText[tempKey] = this.fullNameHelperText;
      this.fieldData.dateAppointedHelperText[tempKey] =
        this.dateAppointedHelperText;
      this.fieldData.officerRoleHelperText[tempKey] =
        this.officerRoleHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderTextLanguageToField(language) {
      // Place Holder Text

      let tempKey = language.toLowerCase();
      this.fieldData.fullNamePlaceholderText[tempKey] =
        this.fullNamePlaceHolderText;
      this.fieldData.officerRolePlaceholderText[tempKey] =
        this.officerRolePlaceHolderText;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationMessageLanguageToField(language) {
      // Validation Message

      let tempKey = language.toLowerCase();
      this.fieldData.fullNameValidationMessage[tempKey] =
        this.fullNameValidationMessage;
      this.fieldData.dateAppointedValidationMessage[tempKey] =
        this.dateAppointedValidationMessage;
      this.fieldData.officerRoleValidationMessage[tempKey] =
        this.officerRoleValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireFullName: this.fullNameDisabled
          ? false
          : this.isRequireFullName,
        isRequireDateAppointed: this.dateAppointedDisabled
          ? false
          : this.isRequireDateAppointed,
        isRequireOfficerRole: this.officerRoleDisabled
          ? false
          : this.isRequireOfficerRole,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        fullNameDisabled: this.fullNameDisabled,
        dateAppointedDisabled: this.dateAppointedDisabled,
        officerRoleDisabled: this.officerRoleDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        fullNamePostName: this.fullNamePostName,
        dateAppointedPostName: this.dateAppointedPostName,
        freeFormPostName: this.freeFormPostName,
        officerRolePostName: this.officerRolePostName
      };
      Object.assign(this.fieldData, { attributes });
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    addDateAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();
      // tomorrow.setDate(new Date().getDate() + 1);

      let advanceDate = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advanceDate });
    },

    onSetDateFormat() {
      let format = this.selectedDateFormat;
      Object.assign(this.fieldData, { format });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
