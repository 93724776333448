<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="label"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- slides tabb -->
    <b-tab title="slides">
      <!-- select slides tab -->
      <b-tabs pills card vertical>
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <b-form @submit.stop.prevent class="active text-left mt-2">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th>Slide Image</th>
                  <th>Slide Caption</th>
                  <th>Slide Text</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(slide, slideIndex) in fieldData.files"
                  :key="slideIndex"
                >
                  <td class="pr-2 pt-1 pl-2">
                    <b-form-input
                      :value="slide.filename"
                      disabled
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :value="slide.caption[transalationLanguage]"
                      @input="addSlideCaption($event, slideIndex)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :value="slide.text[transalationLanguage]"
                      @input="addSlideText($event, slideIndex)"
                    ></b-form-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- ----------------Advanced------------------- -->
    <b-tab title="Advanced">
      <div @submit.stop.prevent class="text-left mt-4">
        <div class="col-12">
          <div class="form-group col-md-6">
            <label for>Slide Show Interval</label>
            <b-form-input v-model="slideInterval" type="number"></b-form-input>
          </div>
          <Checkbox v-model="slideControls" label="Show Inside Controls" />
          <Checkbox
            v-model="slideControlsOuter"
            label="Show Outside Controls"
          />
          <Checkbox v-model="slideBars" label="Show Bars" />
          <br />
        </div>
      </div>
    </b-tab>

    <!-- ----------------Dropzone------------------- -->
    <b-tab title="Upload Slides">
      <vue-dropzone
        ref="myVueDropzone"
        @vdropzone-success="vsuccess"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="sendingEvent"
        @vdropzone-upload-progress="onUploadImage"
        @vdropzone-queue-complete="onUploadFinish"
        :useCustomSlot="true"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Drag and drop to upload content!
          </h3>
          <div class="subtitle">
            ...or click to select a file from your computer
          </div>
        </div>
      </vue-dropzone>
      <b-row>
        <b-col cols="12">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th>Image</th>
                <th>Name</th>
                <th>Type</th>
                <th>Size</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <draggable v-model="files" tag="tbody" ghost-class="ghost">
              <template #item="{ element }">
                <tr class="sort">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <b-img
                      v-bind="thumbnailProps"
                      thumbnail
                      :src="element.url"
                    ></b-img>
                  </td>
                  <td>{{ element.filename }}</td>
                  <td>{{ element.type }}</td>
                  <td>{{ readableBytes(element.size) }}</td>
                  <td>
                    <font-awesome-icon
                      icon="trash"
                      @click.native="onDeleteSlide(index, element)"
                    />
                  </td>
                  <td>
                    <font-awesome-icon icon="bars" />
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import draggable from "vuedraggable";
import { EventBus } from "@/event-bus.js";
import vue2Dropzone from "./Dropzone/VueDropzone.vue";
import uid from "uid";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { makeAuthorizationHeader } from "@/utils";
import Checkbox from "@/molecules/Checkbox/Checkbox";

draggable.compatConfig = { MODE: 3 };

export default {
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
    Checkbox
  },
  name: "AdvanceTrainingSettings",
  data() {
    return {
      files: [],
      fields: ["filename", "type", "size", ""],
      isLoading: false,
      transalationLanguage: "english",
      label: undefined,
      slideText: "",
      slideCaption: "",
      slideImg: null,
      file: null,
      imgUrl: null,
      thumbnailProps: { width: 90, height: 90 },
      slideInterval: this.fieldData.interval,
      slideControls: this.fieldData.controls,
      slideControlsOuter: this.fieldData.controlsOut,
      slideBars: this.fieldData.indicators,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}v1/uploads/file/form`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        headers: makeAuthorizationHeader()
      }
    };
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },

  mounted() {
    this.label = this.fieldData.label[this.transalationLanguage];
    this.files = this.fieldData.files || [];
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.onUpdatCarouselSettings();
      Object.assign(this.fieldData, { files: this.files });
    });
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),
    readableBytes(bytes) {
      let i = Math.floor(Math.log(bytes) / Math.log(1024));
      let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
    },

    onUploadImage() {
      EventBus.emit("onUploadStart", "uploading image");
    },

    onUploadFinish() {
      EventBus.emit("onUploadCompleting", "uploading image");
    },

    vsuccess(file, response) {
      let self = this;
      let uploadedFile = {};
      if (response && response.url) {
        this.imgSrc = response.url;
        uploadedFile = {
          fileId: uid(),
          filename: file.name,
          caption: { english: this.slideCaption },
          text: { english: this.slideText },
          size: file.size,
          type: file.type,
          url: response.url
        };
        self.files.push(uploadedFile);
        // this.$refs.myVueDropzone.removeAllFiles();
        this.$refs.myVueDropzone.removeFile(file);
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("formId", this.form.formId);
      formData.append("fieldId", this.fieldData.id);
      formData.append("fieldIndex", this.fieldIndex);
      formData.append("sectionIndex", this.fieldIndex);
      formData.append("sectionId", this.fieldIndex);
    },

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.label = this.fieldData.label[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
    },

    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    // for addiing slides
    addSlideImg(value, slideIndex) {
      let imageFile = value;
      if (imageFile.type !== "image/jpeg" && imageFile.type !== "image/png") {
        alert("You can only upload JPG and PNG file!");
      } else if (imageFile.size > 3769152) {
        alert("Image must smaller than 4MB!");
      } else if (imageFile) {
        this.getBase64(imageFile, (imageUrl) => {
          this.slideImg = imageFile;
          this.imgUrl = imageUrl;
          this.fieldData.slideData[slideIndex].imgSrc = imageUrl;
          this.fieldData.slideData[slideIndex].imgName = imageFile.name;
        });
      } else {
        alert("Image Error please try later");
      }
    },

    addSlideCaption(value, slideIndex) {
      this.slideCaption = value;
      this.fieldData.files[slideIndex].caption[this.transalationLanguage] =
        value;
    },

    addSlideText(value, slideIndex) {
      this.slideText = value;
      this.fieldData.files[slideIndex].text[this.transalationLanguage] = value;
    },

    addSlide() {
      let newSlide = {
        caption: { english: this.slideCaption },
        text: { english: this.slideText },
        imgBblank: this.imgSrc,
        imgSrc: this.imgSrc
      };

      this.fieldData.slideData.push(newSlide);
    },

    onDeleteSlide(index) {
      if (
        this.files.length > 0 &&
        this.fieldData.files &&
        this.fieldData.files.length > 0
      ) {
        this.fieldData.files.splice(index, 1);
      }
      if (this.files.length > 0) {
        this.files.splice(index, 1);
      }

      // this.files = this.fieldData.files || [];
    },

    onUpdatCarouselSettings() {
      this.fieldData.interval = this.slideInterval;
      this.fieldData.controls = this.slideControls;
      this.fieldData.controlsOut = this.slideControlsOuter;
      this.fieldData.indicators = this.slideBars;
    },

    showToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionId: {
      type: String,
      required: false
    },
    sectionIndex: {
      type: Number,
      required: false
    },
    fieldIndex: {
      type: Number,
      required: false
    }
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.sort {
  cursor: move;
}
</style>
