<template id="template-input">
  <b-form-group class="mt-2">
    <label>
      {{
        attributeData.tp_alias
          ? attributeData.tp_alias
          : attributeData.tp_customatt
      }}
      <Tooltip
        class="ml-2"
        v-if="getAttribute(attributeData)"
        :text="attributeData.description"
      />
    </label>

    <!-- if custom attribute is required -->
    <div v-if="parseInt(attributeData.tp_is_required)" :ref="attributeData.id">
      <div style="widht: 550px">
        <quill-editor
          id="htmlString"
          v-model="v$.form.value.$model"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.form.value.$dirty ? !v$.form.value.$error : null"
          :style="v$.form.value.$error ? 'border: 1px solid #dc3545;' : ''"
        ></quill-editor>
      </div>
      <p
        class="text-danger"
        style="font-size: 12px"
        v-if="v$.form.value.$error"
      >
        A Required field has no value.
      </p>
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <quill-editor v-model="form.value" @blur="saveValue()"></quill-editor>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "htmlString",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip
  },
  data() {
    return {
      form: {
        value: null,
        tpRequired: false
      }
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        form: {
          value: {
            required
          }
        }
      };
    }
  },

  created() {
    // react on event htmlStringValidationError
    EventBus.on("htmlStringValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      this.v$.form?.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      this.form.value = this.values.value;
    }
    // this.checkForConditional();
  },

  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    // save custom attribute value
    saveValue() {
      if (parseInt(this.attributeData.tp_is_required)) {
        this.v$.form?.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.emit("saveValue", {
          value: this.form.value,
          id: this.attributeData.id,
          error: this.v$.form.$errors?.length,
          type: this.attributeData.tp_type
        });
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.emit("saveValue", {
          value: this.form.value,
          id: this.attributeData.id,
          error: false,
          type: this.attributeData.tp_type
        });
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
<style>
/* .form-control.is-valid{
	background-position: left calc(0.375em + 0.1875rem) center

}
.form-control.is-invalid{
	background-position :left calc(0.375em + 0.1875rem) center

} */
/* .ql-editor {
    width: 550px;
    height: 274px;
    overflow-y: scroll;
} */
.quillWrapper {
  width: 580px;
  /* height: 280px;
  overflow-y: auto; */
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 215px;
}
</style>
