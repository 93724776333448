<template id="template-input">
  <b-form-group class="mt-2">
    <label>
      {{
        attributeData.tp_alias
          ? attributeData.tp_alias
          : attributeData.tp_customatt
      }}
      <Tooltip
        class="ml-2"
        v-if="getAttribute(attributeData)"
        :text="attributeData.description"
      />
    </label>

    <!-- if custom attribute is required -->
    <div v-if="attributeData.tp_is_required == 1" :ref="attributeData.id">
      <!-- if custom attribute type is Date  -->
      <div v-if="attributeData.tp_type == 'Date'">
        <b-form-input
          v-model="v$.dateTime.date.$model"
          type="date"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.dateTime.date.$dirty ? !v$.dateTime.date.$error : null"
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.dateTime.date.$error"
          >A Required field has no value.</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is email  -->
      <div v-else-if="attributeData.tp_type == 'email'">
        <b-form-input
          v-model="v$.emailId.$model"
          size="sm"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.emailId.$dirty ? !v$.emailId.$error : null"
          type="email"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="attributeData.tp_is_required && !v$.emailId.$model"
          >A Required field has no value.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-if="v$.emailId.$model && v$.emailId.$error"
          >Please enter a valid email</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is url  -->
      <div v-else-if="attributeData.tp_type == 'url'">
        <b-form-input
          v-model="v$.url.$model"
          size="sm"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="
            invalidUrlDomain ? false : v$.url.$dirty ? !v$.url.$error : null
          "
          type="url"
          @change="is_url"
        ></b-form-input>
        <b-form-invalid-feedback v-if="invalidUrlDomain">
          {{ invalidUrlDomainValue }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="v$.url.$error"
          >A Required field has no value.</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is Text  -->
      <div v-else-if="attributeData.tp_type == 'Text'">
        <b-form-input
          v-model="v$.stringValue.$model"
          type="text"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.stringValue.$dirty ? !v$.stringValue.$error : null"
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.stringValue.$error"
          >A Required field has no value.</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is integer  -->
      <div v-else-if="attributeData.tp_type == 'integer'">
        <b-form-input
          v-model="v$.integerValue.$model"
          onkeydown="return event.keyCode !== 69"
          type="number"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.integerValue.$dirty ? !v$.integerValue.$error : null"
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.integerValue.$error"
          >A Required field has no value.</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is double  -->
      <div v-else-if="attributeData.tp_type == 'double'">
        <b-form-input
          v-model="v$.doubleValue.$model"
          onkeydown="return event.keyCode !== 69"
          type="number"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.doubleValue.$dirty ? !v$.doubleValue.$error : null"
        ></b-form-input>
      </div>

      <!-- if custom attribute type is color  -->
      <div v-else-if="attributeData.tp_type == 'color'">
        <b-form-input
          v-model="v$.colorValue.$model"
          type="color"
          @blur="saveValue()"
          placeholder="---Required---"
          :state="v$.colorValue.$dirty ? !v$.colorValue.$error : null"
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.colorValue.$error"
          >A Required field has no value.</b-form-invalid-feedback
        >
      </div>
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <!-- if custom attribute type is Date  -->
      <div v-if="attributeData.tp_type == 'Date'">
        <b-form-input
          v-model="dateTime.date"
          type="date"
          @blur="saveValue()"
        ></b-form-input>
      </div>

      <!-- if custom attribute type is email  -->
      <div v-else-if="attributeData.tp_type == 'email'">
        <b-form-input
          v-model="v$.emailId.$model"
          size="sm"
          @blur="saveValue()"
          :state="v$.emailId.$dirty ? !v$.emailId.$error : null"
          type="email"
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.emailId.$model && v$.emailId.$error"
          >Please enter a valid email</b-form-invalid-feedback
        >
      </div>

      <!-- if custom attribute type is url  -->
      <div v-else-if="attributeData.tp_type == 'url'">
        <b-form-input
          v-model="url"
          @blur="saveValue()"
          :state="invalidUrlDomain ? false : null"
          type="url"
          @change="is_url"
        ></b-form-input>
        <b-form-invalid-feedback v-if="invalidUrlDomain">
          {{ invalidUrlDomainValue }}
        </b-form-invalid-feedback>
      </div>

      <!-- if custom attribute type is Text  -->
      <div v-else-if="attributeData.tp_type == 'Text'">
        <b-form-input
          v-model="stringValue"
          type="text"
          @blur="saveValue()"
        ></b-form-input>
      </div>

      <!-- if custom attribute type is integer  -->
      <div v-else-if="attributeData.tp_type == 'integer'">
        <b-form-input
          v-model="integerValue"
          onkeydown="return event.keyCode !== 69"
          type="number"
          @blur="saveValue()"
        ></b-form-input>
      </div>

      <!-- if custom attribute type is double  -->
      <div v-else-if="attributeData.tp_type == 'double'">
        <b-form-input
          v-model="doubleValue"
          onkeydown="return event.keyCode !== 69"
          type="number"
          @blur="saveValue()"
        ></b-form-input>
      </div>

      <!-- if custom attribute type is color  -->
      <div v-else-if="attributeData.tp_type == 'color'">
        <b-form-input
          v-model="colorValue"
          type="color"
          @blur="saveValue()"
        ></b-form-input>
      </div>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "IputGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip
  },
  data() {
    return {
      dateTime: {
        date: null
        // time: null
      },
      emailId: null,
      url: null,
      stringValue: null,
      integerValue: null,
      doubleValue: null,
      colorValue: null,
      invalidUrlDomain: false,
      invalidUrlDomainValue: null,
      tpRequired: false
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        emailId: {
          required,
          email
        },
        dateTime: {
          date: {
            required
          }
        },
        url: {
          required
        },
        stringValue: {
          required
        },
        integerValue: {
          required
        },
        doubleValue: {
          required
        },
        colorValue: {
          required
        }
      };
    } else {
      return {
        emailId: {
          email
        }
      };
    }
  },

  created() {
    // react on event validationError
    EventBus.on("validationError", (value) => {
      const element = this.$refs[value.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      if (value.type == "email") {
        this.v$.emailId?.$touch();
      } else if (value.type == "url") {
        this.v$.url?.$touch();
      } else if (value.type == "Date") {
        this.v$.dateTime?.$touch();
      } else if (value.type == "Text") {
        this.v$.stringValue?.$touch();
      } else if (value.type == "integer") {
        this.v$.integerValue?.$touch();
      } else if (value.type == "double") {
        this.v$.doubleValue?.$touch();
      } else if (value.type == "color") {
        this.v$.colorValue?.$touch();
      }
    });
  },

  mounted() {
    // if(this.attributeData.tp_type == 'color'){
    //   this.colorValue = "#000000"
    // }
    if (this.values.value) {
      if (this.values.type == "Date") {
        this.dateTime.date = this.values.value;
      } else if (this.values.type == "Text") {
        this.stringValue = this.values.value;
      } else if (this.values.type == "integer") {
        this.integerValue = this.values.value;
      } else if (this.values.type == "double") {
        this.doubleValue = this.values.value;
      } else if (this.values.type == "color") {
        this.colorValue = this.values.value ? this.values.value : null;
      } else if (this.values.type == "email") {
        this.emailId = this.values.value;
      } else if (this.values.type == "url") {
        this.url = this.values.value;
      }
    }
  },

  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    // save custom attribute value
    saveValue() {
      if (parseInt(this.attributeData.tp_is_required)) {
        // emit event saveValue when custom attribute is required
        if (this.attributeData.tp_type == "Date") {
          this.v$.dateTime?.$touch();
          EventBus.emit("saveValue", {
            value: this.dateTime.date,
            id: this.attributeData.id,
            error: this.v$.dateTime?.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "email") {
          this.v$.emailId?.$touch();
          EventBus.emit("saveValue", {
            value: this.emailId,
            id: this.attributeData.id,
            error: this.v$.emailId.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "url") {
          this.v$.url.$touch();
          EventBus.emit("saveValue", {
            value: this.url,
            id: this.attributeData.id,
            error: this.v$.url.$errors?.length || this.invalidUrlDomain,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "Text") {
          this.v$.stringValue?.$touch();
          EventBus.emit("saveValue", {
            value: this.stringValue,
            id: this.attributeData.id,
            error: this.v$.stringValue.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "integer") {
          this.v$.integerValue?.$touch();
          EventBus.emit("saveValue", {
            value: this.integerValue,
            id: this.attributeData.id,
            error: this.v$.integerValue.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "double") {
          this.v$.doubleValue?.$touch();
          EventBus.emit("saveValue", {
            value: this.doubleValue,
            id: this.attributeData.id,
            error: this.v$.doubleValue.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "color") {
          this.v$.colorValue?.$touch();
          EventBus.emit("saveValue", {
            value: this.colorValue,
            id: this.attributeData.id,
            error: this.v$.colorValue.$errors?.length,
            type: this.attributeData.tp_type
          });
        }
      } else {
        // emit event saveValue when custom attribute is not required
        if (this.attributeData.tp_type == "Date") {
          EventBus.emit("saveValue", {
            value: this.dateTime.date,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "email") {
          this.v$.emailId?.$touch();
          EventBus.emit("saveValue", {
            value: this.emailId,
            id: this.attributeData.id,
            error: this.v$.emailId.$errors?.length,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "url") {
          EventBus.emit("saveValue", {
            value: this.url,
            id: this.attributeData.id,
            error: this.invalidUrlDomain,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "Text") {
          EventBus.emit("saveValue", {
            value: this.stringValue,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "integer") {
          EventBus.emit("saveValue", {
            value: this.integerValue,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "double") {
          EventBus.emit("saveValue", {
            value: this.doubleValue,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          });
        } else if (this.attributeData.tp_type == "color") {
          EventBus.emit("saveValue", {
            value: this.colorValue,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          });
        }
      }
    },

    // check valid URL
    is_url() {
      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (!regexp.test(this.url)) {
        this.invalidUrlDomain = true;
        this.invalidUrlDomainValue = "Please enter a valid URL";
      } else {
        this.invalidUrlDomain = false;
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
<style>
/* .form-control.is-valid{
	background-position: left calc(0.375em + 0.1875rem) center

}
.form-control.is-invalid{
	background-position :left calc(0.375em + 0.1875rem) center

} */
</style>
