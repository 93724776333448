const DOMEvents = {
  CLICK: "click",
  CHANGE: "change",
  INPUT: "input"
};

const genericEvents = {
  SEARCH: "search",
  FILTER: "filter",
  CLEAR: "clear",
  CLEAR_ALL: "clearAll",
  APPLY: "apply",
  REMOVE: "remove",
  ADD: "add",
  TOGGLE: "toggle"
};

const eventBusName = {
  EDIT_THIRD_PARTY_DNB_MODAL: "editThirdPartyDnbModal",
  EDIT_THIRD_PARTY_MODAL: "editThirdPartyModal"
};

const ifBlockEventType = {
  CHANGE: "change",
  ADD_SUCCESS_STEP: "addSuccessStep",
  ADD_STEP: "addStep",
  AMEND_SUCCESS_STEP: "amendSuccessStep",
  DELETE_SUCCESS_STEP: "deleteSuccessStep",
  ADD_BLOCK: "addBlock",
  DELETE_ELSE_IF_BLOCK: "deleteElseIfBlock"
};

const actionsEvents = {
  FETCH_ACTION: "fetchAction",
  RESET_ACTION: "resetAction"
};

const roleEvents = {
  ADD_ROLE: "addRole",
  EDIT_ROLE: "editRole"
};

const roundEvents = {
  ADD_ROUND: "addRound",
  REMOVE_ROUND: "removeRound",
  ROUND_DECIMAL: "roundDecimal"
};

const dataTableEvents = {
  SEARCH_FILTER_BAR: "searchFilterBar",
  PER_PAGE_PAGINATION: "perPagePagination",
  ORDER_IDD: "orderIdd",
  ORDER_IDD_PLUS: "orderIddPlus",
  GRID_READY: "gridReady",
  SORT_CHANGED: "sortChanged"
};

const drilldownEvents = {
  DRILLDOWN_CLICK: "drilldown-click"
};

const userEmailTemplateEvents = {
  FETCH_USER_EMAIL_TEMPLATE: "fetch-user-email-template",
  RESET_USER_EMAIL_TEMPLATE: "reset-user-email-template"
};

const taskTemplateEvents = {
  FETCH_TASK_TEMPLATE: "fetch-task-template",
  RESET_TASK_TEMPLATE: "reset-task-template"
};

const modalEvents = {
  CLOSE_MODAL: "close-modal"
};

const thirdPartyEvents = {
  ESG_EXPORT: "ESGExport"
};

const filterEvents = {
  VALUES_UPDATED: "valuesUpdated",
  OPTIONS_UPDATED: "optionsUpdated"
};

const automationEventType = {
  EDIT_AUTOMATION: "editAutomation",
  FETCH_AUTOMATIONS: "fetchAutomations"
};

const permissionsEvents = {
  SAVE_CLICK: "save-click"
};

const dropdownEvents = {
  MENU_ITEM_CLICK: "menu-item-click"
};

export {
  DOMEvents,
  genericEvents,
  eventBusName,
  ifBlockEventType,
  actionsEvents,
  roleEvents,
  roundEvents,
  userEmailTemplateEvents,
  dataTableEvents,
  drilldownEvents,
  modalEvents,
  automationEventType,
  permissionsEvents,
  thirdPartyEvents,
  filterEvents,
  taskTemplateEvents,
  dropdownEvents
};
