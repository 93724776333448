const clientKeysDTO = {
  ID: "id",
  COMPANY_NAME: "companyName",
  CONTACT_EMAIL: "contactEmail",
  CONTACT_NAME: "contactName",
  CREATED_DATE: "createdDate",
  TENANT_ID: "tenantId"
};

const clientKeysAPIDTO = {
  ID: "id",
  COMPANY_NAME: "company_name",
  CONTACT_EMAIL: "contact_email",
  CONTACT_NAME: "contact_name",
  CREATED_DATE: "created_date",
  TENANT_ID: "tenant_id"
};

export { clientKeysDTO, clientKeysAPIDTO };
