<template>
  <div class="date-picker-wrapper">
    <Label v-bind="labelOptions">
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot" />
        </template>
      </template>
    </Label>
    <Datepicker
      class="date-picker-wrapper--date-picker"
      v-bind="datePickerOptions"
      v-model="date"
    />
    <InputErrorMessage :error="error" />
  </div>
</template>

<script>
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Label from "@/atoms/Label/Label";
import Datepicker from "@vuepic/vue-datepicker";
import { defineComponent, ref, watch } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  name: "DatePicker",
  props: {
    labelOptions: {
      type: Object,
      default: () => ({})
    },
    datePickerOptions: {
      type: Object,
      default: () => ({})
    },
    error: {
      type: String,
      default: ""
    }
  },
  components: {
    Datepicker,
    InputErrorMessage,
    Label
  },
  compatConfig: { MODE: 3 },
  setup(props) {
    const date = ref(props.datePickerOptions.value);

    watch(
      () => props.datePickerOptions.value,
      (value) => {
        date.value = value;
      }
    );

    return {
      date
    };
  }
});
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  & :deep(.dp__input) {
    border-radius: $border-radius8;
  }
}
</style>
