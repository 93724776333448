<template>
  <ul class="component-list">
    <li v-for="(option, index) in listOptions" :key="index">
      <component v-bind="option.options" :is="option.component" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "ComponentList",
  compatConfig: {
    MODE: 3
  },
  props: {
    listOptions: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.component-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
