<template>
  <div class="sidebar-menu-list" data-test-id="sidebar-menu-list">
    <SidebarMenuItem
      v-bind="headingOptions"
      is-dropdown-variant
      :is-dropdown-open="isDropdownOpen"
      @click="handleHeadingOptionClick"
    />
    <div
      :class="[
        'sidebar-menu-list__section',
        isDropdownOpen && 'sidebar-menu-list__section--visible'
      ]"
      data-test-id="sidebar-menu-list__section"
    >
      <ComponentList :list-options="listOptions" />
    </div>
  </div>
</template>

<script>
import ComponentList from "@/molecules/ComponentList/ComponentList";
import SidebarMenuItem from "@/molecules/SidebarMenuItem/SidebarMenuItem";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "SidebarMenuList",
  components: { ComponentList, SidebarMenuItem },
  compatConfig: {
    MODE: 3
  },
  props: {
    listOptions: {
      type: Array,
      default: () => []
    },
    headingOptions: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const isDropdownOpen = ref(false);

    watch(
      () => props.headingOptions.isActive,
      (value) => {
        if (value) {
          isDropdownOpen.value = value;
        }
      },
      {
        immediate: true
      }
    );

    const handleHeadingOptionClick = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    return {
      isDropdownOpen,
      handleHeadingOptionClick
    };
  }
});
</script>

<style lang="scss" scoped>
.sidebar-menu-list {
  &__section {
    height: 0;
    overflow: hidden;

    &--visible {
      height: auto;
    }

    &:deep(.sidebar-menu-item:nth-child(n)) {
      padding-left: calc($spacing32 + $spacing8);
    }
  }
}
</style>
