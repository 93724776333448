import { clientKeysDTO, clientKeysAPIDTO } from "@/constants";

const makeClientDTO = (client = {}) => ({
  [clientKeysDTO.COMPANY_NAME]: client[clientKeysAPIDTO.COMPANY_NAME] || "",
  [clientKeysDTO.CONTACT_EMAIL]: client[clientKeysAPIDTO.CONTACT_EMAIL] || "",
  [clientKeysDTO.CONTACT_NAME]: client[clientKeysAPIDTO.CONTACT_NAME] || "",
  [clientKeysDTO.CREATED_DATE]: client[clientKeysAPIDTO.CREATED_DATE] || "",
  [clientKeysDTO.TENANT_ID]: client[clientKeysAPIDTO.TENANT_ID] || null
});

export { makeClientDTO };
