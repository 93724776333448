<template>
  <div class="tasks">
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <BaseText
        :text="operationFeedback"
        theme="success-inverse"
        data-test-id="tasks__operations-feedback"
      />
      <InputErrorMessage
        :error="errorMessages"
        data-test-id="tasks__error-message"
      />
      <template>
        <BaseInfoBox
          v-if="isListFiltered"
          text="You are viewing a filtered list"
          :icon-options="defaultIcons.INFO"
          :theme="themes.INFO"
          class="tasks__list-info"
          data-test-id="tasks__list-info"
        />
        <SearchFilterBar
          v-bind="searchFilterBarDetails"
          class="tasks__search-filter-bar"
          data-test-id="tasks__search-filter-bar"
          @click="onSearchFilterBar"
          @input="onSearchFilterBar"
          @change="onSearchFilterBar"
        />
        <StickyTable
          v-if="isDataAvailable"
          caption="Tasks"
          class="tasks__table"
          data-test-id="tasks__table"
          :table-headers="getTasksHeaders"
          :table-rows="getTasksRows"
          @table-row-click="onTableRowClick"
        />
        <BaseText
          v-else
          text="No options available"
          data-test-id="tasks__operations-feedback"
        />
        <PerPagePagination
          v-bind="paginationDetails"
          data-test-id="tasks__pagination"
          @change="onSetDisplayedNumber"
          @click="onSetCurrentPage"
        />
      </template>
    </template>
  </div>
</template>

<script>
import PerPagePagination from "@/molecules/PerPagePagination/PerPagePagination";
import SearchFilterBar from "@/organisms/SearchFilterBar/SearchFilterBar";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import {
  genericEvents,
  typographySize,
  operations,
  tasksKeysDTO,
  tasksListTableHeaders,
  getterName,
  actionName,
  destinationTypes,
  urls,
  taskFilterOptions,
  filterEvents,
  tasksFilterKeysDTO,
  emptyOption,
  themes,
  defaultIcons,
  taskStatusOptions,
  eb360DateFormat,
  customErrorMessages
} from "@/constants";
import { mapState, mapGetters, mapActions } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import {
  makeUUID,
  getAllSelectedOptionValues,
  getSelectedOption,
  makeDateToMatchFormat,
  getTextBasedOnValueFromOptions,
  makeISOStringDate
} from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import {
  makeOptionsForMultiSelect,
  makeLiteralOptionForMultiSelect
} from "@/molecules/MultiSelect/MultiSelect.dto";
import {
  isDateFilterKey,
  makeFilterRow,
  makeAvailableFilterValueComponent,
  isDynamicValueAllowedForFilterCriteria
} from "./Tasks.logic";
import {
  isFilterValid,
  isFilterCriteriaValueValid,
  isFilterCriteriaValid
} from "./Tasks.validation";

export default {
  name: "Tasks",
  components: {
    PerPagePagination,
    SearchFilterBar,
    StickyTable,
    InputErrorMessage,
    BaseLoader,
    BaseText,
    BaseInfoBox
  },
  data() {
    return {
      searchFilterBarDetails: {
        hasFilters: true,
        showFilterSection: false,
        hasSearchBar: false,
        filterOptions: []
      },
      paginationDetails: {
        currentPage: 1,
        totalItems: 10,
        itemsPerPage: 50,
        options: [10, 20, 50, 100]
      },
      errorMessages: [],
      operationFeedback: "",
      isListFiltered: false,
      typographySize,
      themes,
      defaultIcons,
      tasksListTableHeaders
    };
  },
  computed: {
    ...mapState({
      tasks: (state) => state.tasks.tasks,
      tasksFilter: (state) => state.tasks.tasksFilter,
      tasksPagination: (state) => state.tasks.tasksPagination,
      isLoadingTasks: (state) => state.tasks.isLoadingTasks,
      isLoadingTask: (state) => state.tasks.isLoadingTask
    }),
    ...mapGetters({
      getActionBasedOnId: getterName.ACTIONS.GET_ACTION_BASED_ON_ID,
      getIdToRoleNameMap: getterName.ROLES.GET_ID_TO_ROLE_NAME_MAP,
      getPlatformUserListOptions:
        getterName.PLATFORM_SETTINGS.GET_PLATFORM_USER_LIST_OPTIONS,
      getDestinationBaseOptions:
        getterName.USER_EMAIL_TEMPLATES.GET_DESTINATION_BASE_OPTIONS,
      getRolesDestinationOptions:
        getterName.USER_EMAIL_TEMPLATES.GET_ROLES_DESTINATION_OPTIONS,
      getTaskTemplatesOptions:
        getterName.TASK_TEMPLATES.GET_TASK_TEMPLATES_OPTIONS,
      isUserAdminOrSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN,
      getActionOptions: getterName.ACTIONS.GET_ACTION_OPTIONS
    }),
    isLoading() {
      return this.isLoadingTasks || this.isLoadingTask;
    },
    isDataAvailable() {
      return !!this.getTasksHeaders.length && !!this.getTasksRows.length;
    },
    getTasksHeaders() {
      return (
        Object.keys(this.tasksListTableHeaders).reduce?.((acc, key) => {
          if (tasksListTableHeaders[key]) {
            acc.push({
              value: key,
              component: BaseText,
              componentOptions: {
                tag: "span",
                text: tasksListTableHeaders[key],
                size: typographySize.BODY_TEXT_BOLD,
                styles: {
                  margin: "0"
                }
              },
              styles: {
                verticalAlign: "top",
                background: "white",
                padding: "5px"
              }
            });
          }
          return acc;
        }, []) || []
      );
    },
    getTasksRows() {
      return (
        this.tasks.map?.((task, index) =>
          Object.keys(this.tasksListTableHeaders).reduce((acc, taskKey) => {
            if (taskKey === tasksKeysDTO.ACTION) {
              acc[taskKey] = {
                component: DropDownList,
                componentOptions: {
                  dropDownList: [
                    {
                      value: JSON.stringify({
                        operation: operations.EDIT,
                        id: this.tasks[index].id
                      }),
                      text: "Edit"
                    },
                    ...(this.isUserAdminOrSuperAdmin
                      ? [
                          {
                            value: JSON.stringify({
                              operation: operations.DELETE,
                              id: this.tasks[index].id
                            }),
                            text: "Delete"
                          }
                        ]
                      : [])
                  ],
                  style: {
                    display: "inline-block"
                  }
                }
              };
            } else {
              acc[taskKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: this.getTaskTextForTableRow({
                    taskKey,
                    taskValue: task[taskKey]
                  }),
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
            }
            return acc;
          }, {})
        ) || []
      );
    },
    getActiveFilterCriteria() {
      return this.searchFilterBarDetails.filterOptions.reduce(
        (acc, { availableFilterOptions }) => {
          const { value } = this.getSelectedOption(availableFilterOptions);
          acc.push(value);
          return acc;
        },
        []
      );
    },
    queryParameters() {
      const queryParameters = {};
      for (const filterKey in this.$route?.query) {
        if (![tasksFilterKeysDTO.ASSIGNEE].includes(filterKey)) {
          queryParameters[filterKey] = this.$route?.query[filterKey];
        } else if (Array.isArray(this.$route?.query[filterKey])) {
          queryParameters[filterKey] = this.$route?.query[filterKey].map(
            (value) => (!isNaN(parseInt(value)) ? parseInt(value) : value)
          );
        } else {
          queryParameters[filterKey] = !isNaN(
            parseInt(this.$route?.query[filterKey])
          )
            ? parseInt(this.$route?.query[filterKey])
            : this.$route?.query[filterKey];
        }
      }

      return queryParameters;
    }
  },
  watch: {
    tasksPagination() {
      this.paginationDetails = { ...this.tasksPagination };
    }
  },
  async created() {
    this.setQueryParameterFilters();
    if (!this.tasks.length) {
      await this.fetchTasksWrapper({
        isResetErrorMessagesNeeded: false
      });
    }
    this.setFiltersAndPaginationOnLoad();
  },
  methods: {
    makeUUID,
    makeOptionsForSelect,
    makeOptionsForMultiSelect,
    getAllSelectedOptionValues,
    makeLiteralOptionForMultiSelect,
    getSelectedOption,
    makeDateToMatchFormat,
    getTextBasedOnValueFromOptions,
    isDateFilterKey,
    makeFilterRow,
    makeAvailableFilterValueComponent,
    isDynamicValueAllowedForFilterCriteria,
    isFilterValid,
    isFilterCriteriaValueValid,
    isFilterCriteriaValid,
    makeISOStringDate,
    ...mapActions({
      fetchTasks: actionName.TASKS.FETCH_TASKS,
      deleteTask: actionName.TASKS.DELETE_TASK
    }),
    setFiltersAndPaginationOnLoad() {
      if (Object.keys(this.tasksFilter).length) {
        this.searchFilterBarDetails.filterOptions = [...this.tasksFilter];
        this.searchFilterBarDetails.showFilterSection =
          !!this.searchFilterBarDetails.filterOptions.length;
        this.isListFiltered =
          !!this.searchFilterBarDetails.filterOptions.length;
      }

      if (Object.keys(this.tasksPagination).length) {
        this.paginationDetails = { ...this.tasksPagination };
      }
    },
    async deleteTaskWrapper(id) {
      try {
        this.operationFeedback = "";
        this.resetErrorMessages();
        await this.deleteTask(id);
        this.operationFeedback = "Task deleted successfully";
      } catch {
        this.errorMessages.push("Failed to delete the task");
      }
    },
    getTaskTextForTableRow({ taskKey, taskValue }) {
      if (taskKey === tasksKeysDTO.SELECTED_OUTCOME_ID) {
        return this.getActionBasedOnId(taskValue)?.name || "Not selected";
      }
      if (taskKey === tasksKeysDTO.ASSIGNEE) {
        return this.getAssigneeTextViaId(taskValue);
      }
      if (
        taskKey === tasksKeysDTO.CREATED_AT ||
        taskKey === tasksKeysDTO.UPDATED_AT
      ) {
        return this.makeDateToMatchFormat({
          stringifiedDate: taskValue
        });
      }
      return taskValue;
    },
    getAssigneeTextViaId({ type = "", value = {} } = {}) {
      if (type === destinationTypes.ROLE) {
        return this.getIdToRoleNameMap[value.roleId];
      }
      if (type === destinationTypes.USER) {
        return this.getPlatformUserListOptions.find(
          (platformUser) => platformUser.value === value.userId
        )?.text;
      }
    },
    async onSearchFilterBar(event) {
      if (genericEvents.TOGGLE === event?.id) {
        this.toggleFilterVisibility();
      } else if (genericEvents.FILTER === event?.id) {
        this.setFilter(event.event);
      } else if (genericEvents.CLEAR_ALL === event?.id) {
        this.resetFilter(event.event);
      }
    },
    addFilterRow() {
      const id = this.makeUUID();
      const filterRow = this.makeFilterRow({
        availableFilterOptions: this.makeOptionsForSelect("", [
          {
            ...emptyOption,
            text: "Select..."
          },
          ...taskFilterOptions
        ]),
        availableFilterError: "",
        availableFilterValueComponentOptions:
          this.makeAvailableFilterValueComponentOptions({
            options: [],
            rowId: id
          }),
        availableFilterValueOptionsComponent:
          this.makeAvailableFilterValueComponent(),
        id
      });
      this.searchFilterBarDetails.filterOptions.push(filterRow);

      return filterRow;
    },
    setQueryParameterFilters() {
      for (const filterKey in this.queryParameters) {
        const filterValue = this.queryParameters[filterKey];
        const filterRow = this.addFilterRow();
        this.setActiveFilterRow({
          id: filterRow.id,
          event: filterKey,
          filterValue: Array.isArray(filterValue) ? filterValue : [filterValue]
        });

        if (
          !this.isFilterCriteriaValid({ formRow: filterRow }) ||
          !this.isFilterCriteriaValueValid({ formRow: filterRow })
        ) {
          this.removeFilterRow(filterRow.id);
          this.errorMessages.push(
            customErrorMessages.tasks.invalidQueryFilter(filterKey)
          );
        }
      }
    },
    async setFilter(event) {
      if (genericEvents.ADD === event?.type) {
        this.isFilterValid({
          searchFilterBarDetails: this.searchFilterBarDetails
        }) && this.addFilterRow();
      } else if (genericEvents.REMOVE === event?.type) {
        this.removeFilterRow(event.id);
      } else if (filterEvents.VALUES_UPDATED === event?.type) {
        this.setActiveFilterRow(event);
      } else if (genericEvents.APPLY === event?.type) {
        this.isFilterValid({
          searchFilterBarDetails: this.searchFilterBarDetails
        }) && (await this.fetchTasksWrapper());
      } else if (genericEvents.CLEAR_ALL === event?.type) {
        this.clearAllFilters();
      }
    },
    async clearAllFilters() {
      this.searchFilterBarDetails.filterOptions = [];
      await this.fetchTasksWrapper();
    },
    getFilterRowData(filterRowId) {
      return (
        this.searchFilterBarDetails.filterOptions.find(
          ({ id }) => id === filterRowId
        ) || {}
      );
    },
    setExistingActiveFilter({
      id = "",
      selectedOptionValue = "",
      filterValue = []
    } = {}) {
      const filterRow = this.getFilterRowData(id);
      filterRow.availableFilterError = `the filter ${this.getTextBasedOnValueFromOptions(
        {
          options: filterRow.availableFilterOptions,
          value: selectedOptionValue
        }
      )} is already selected`;
      filterRow.availableFilterOptions = this.makeOptionsForSelect(
        "",
        filterRow.availableFilterOptions
      );
      filterRow.availableFilterValueComponentOptions =
        this.makeAvailableFilterValueComponentOptions({
          selectedOptionValue: "",
          options: filterRow.availableFilterValueComponentOptions.options,
          rowId: filterRow?.id,
          filterValue
        });
    },
    setActiveFilter({
      id = "",
      selectedOptionValue = "",
      filterValue = []
    } = {}) {
      const filterRow = this.getFilterRowData(id);
      filterRow.availableFilterOptions = this.makeOptionsForSelect(
        selectedOptionValue,
        filterRow.availableFilterOptions
      );
      filterRow.availableFilterError = "";
      filterRow.availableFilterValueComponentOptions =
        this.makeAvailableFilterValueComponentOptions({
          selectedOptionValue,
          options: filterRow.availableFilterValueComponentOptions.options,
          rowId: filterRow?.id,
          filterValue
        });
      filterRow.availableFilterValueOptionsComponent =
        this.makeAvailableFilterValueComponent({
          dateValue: selectedOptionValue
        });
    },
    setActiveFilterRow({
      id = "",
      event: selectedOptionValue = "",
      filterValue = []
    } = {}) {
      if (this.getActiveFilterCriteria.includes(selectedOptionValue)) {
        this.setExistingActiveFilter({
          id,
          selectedOptionValue,
          filterValue
        });
      } else {
        this.setActiveFilter({ id, selectedOptionValue, filterValue });
      }
    },
    makeBaseMultiSelectComponentOptions(rowId) {
      return {
        isLabelHidden: true,
        label: "Filter value",
        name: "set-multiselect-filter-value",
        id: `set-multiselect-values-${this.makeUUID()}`,
        "data-test-id": "set-multiselect-values",
        showAllSelectedOptions: true,
        error: "",
        options: [],
        onChange: ({ eventType, value }) =>
          this.onFilterMultiSelectChange({ eventType, value, rowId })
      };
    },
    makeDatepickerComponentOptions({ rowId = "", filterValue = [""] } = {}) {
      return {
        value: filterValue[0],
        "input-class": "tasks__search-filter-bar-datepicker",
        onInput: (value) =>
          this.onFilterDatePickerSelectChange({ value, rowId })
      };
    },
    onFilterDatePickerSelectChange({ value, rowId }) {
      const filterRow = this.getFilterRowData(rowId);
      filterRow.availableFilterValueComponentOptions.value =
        this.makeDateToMatchFormat({
          stringifiedDate: this.makeISOStringDate({ value }),
          dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
        });
      filterRow.availableFilterValueComponentOptions.error = "";
    },
    onFilterMultiSelectChangeAdd({ rowId, value }) {
      const filterRow = this.getFilterRowData(rowId);
      const { options = [] } =
        filterRow?.availableFilterValueComponentOptions || {};
      const selectedValues = this.getAllSelectedOptionValues(options);
      const extendedSelectedValues = [...selectedValues, value];
      filterRow.availableFilterValueComponentOptions.options =
        this.makeOptionsForMultiSelect(
          extendedSelectedValues,
          extendedSelectedValues,
          options
        );
      filterRow.availableFilterValueComponentOptions.error = "";
    },
    onFilterMultiSelectChangeDelete({ rowId, value }) {
      const filterRow = this.getFilterRowData(rowId);
      const { options = [] } =
        filterRow?.availableFilterValueComponentOptions || {};
      const selectedValues = this.getAllSelectedOptionValues(options);
      const decreasedSelectedValues = selectedValues.filter(
        (selectedValue) => selectedValue !== value
      );
      filterRow.availableFilterValueComponentOptions.options =
        this.makeOptionsForMultiSelect(
          decreasedSelectedValues,
          decreasedSelectedValues,
          options
        );
      filterRow.availableFilterValueComponentOptions.error = "";
    },
    onFilterMultiSelectChangeCreate({ rowId, value }) {
      const filterRow = this.getFilterRowData(rowId);
      const { options = [] } =
        filterRow?.availableFilterValueComponentOptions || {};
      const selectedValues = this.getAllSelectedOptionValues(options);
      const isValueInList = !!selectedValues.find(
        (selectedValue) => selectedValue === value
      );
      if (isValueInList) {
        filterRow.availableFilterValueComponentOptions.error = `${value} is a duplicate of a previous entry`;
      } else if (value) {
        const extendedSelectedValues = [...selectedValues, value];
        filterRow.availableFilterValueComponentOptions.options =
          this.makeOptionsForMultiSelect(
            extendedSelectedValues,
            extendedSelectedValues,
            [
              ...options,
              this.makeLiteralOptionForMultiSelect({
                text: value,
                value
              })
            ]
          );
        filterRow.availableFilterValueComponentOptions.error = "";
      }
    },
    onFilterMultiSelectChange({ eventType, value, rowId }) {
      if (eventType === operations.ADD) {
        this.onFilterMultiSelectChangeAdd({ value, rowId });
      } else if (eventType === operations.DELETE) {
        this.onFilterMultiSelectChangeDelete({ value, rowId });
      } else if (eventType === operations.CREATE) {
        const filterRow = this.getFilterRowData(rowId);
        const filterCriteria = this.getSelectedOption(
          filterRow.availableFilterOptions
        )?.value;
        if (this.isDynamicValueAllowedForFilterCriteria(filterCriteria)) {
          this.onFilterMultiSelectChangeCreate({ value, rowId });
        }
      }
    },
    makeAvailableFilterValueComponentOptions({
      selectedOptionValue = null,
      filterValue = [],
      options = [],
      rowId = ""
    } = {}) {
      if (selectedOptionValue === tasksFilterKeysDTO.SELECTED_OUTCOME_ID) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            this.getActionOptions
          )
        };
      }
      if (selectedOptionValue === tasksFilterKeysDTO.ASSIGNEE) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            this.getDestinationBaseOptions
          )
        };
      }
      if (selectedOptionValue === tasksFilterKeysDTO.EDIT_PERMISSIONS) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            this.getRolesDestinationOptions
          )
        };
      }
      if (selectedOptionValue === tasksFilterKeysDTO.TEMPLATE_ID) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            this.getTaskTemplatesOptions
          )
        };
      }
      if (selectedOptionValue === tasksFilterKeysDTO.COMPANY_TASK_ID) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            this.tasks.map(({ companyTaskId }) => ({
              text: companyTaskId,
              value: companyTaskId
            }))
          )
        };
      }
      if (selectedOptionValue === tasksFilterKeysDTO.STATUS) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            [emptyOption, ...taskStatusOptions]
          )
        };
      }
      if (this.isDynamicValueAllowedForFilterCriteria(selectedOptionValue)) {
        const selectedOptionValues = this.getAllSelectedOptionValues(options);
        return {
          ...this.makeBaseMultiSelectComponentOptions(rowId),
          options: this.makeOptionsForMultiSelect(
            [...selectedOptionValues, ...filterValue],
            [...selectedOptionValues, ...filterValue],
            []
          )
        };
      }
      if (this.isDateFilterKey({ dateValue: selectedOptionValue })) {
        return this.makeDatepickerComponentOptions({
          rowId,
          filterValue
        });
      }
      return this.makeBaseMultiSelectComponentOptions(rowId);
    },
    removeFilterRow(ID) {
      this.searchFilterBarDetails.filterOptions =
        this.searchFilterBarDetails.filterOptions.filter(({ id }) => id !== ID);
    },
    resetFilter() {
      this.searchFilterBarDetails.filterOptions = [];
      this.fetchTasksWrapper();
    },
    toggleFilterVisibility() {
      this.searchFilterBarDetails.showFilterSection =
        !this.searchFilterBarDetails.showFilterSection;
    },
    async onSetDisplayedNumber(event) {
      this.paginationDetails.itemsPerPage = event;
      await this.fetchTasksWrapper();
    },
    async onSetCurrentPage(event) {
      this.paginationDetails.currentPage = event;
      await this.fetchTasksWrapper();
    },
    onTableRowClick(event) {
      const { operation, id } = JSON.parse(event);
      if (operations.EDIT === operation) {
        this.$router.push(urls.TASK_ID(id));
      } else if (operations.DELETE === operation) {
        this.deleteTaskWrapper(id);
      }
    },
    resetErrorMessages() {
      this.errorMessages = [];
    },
    async fetchTasksWrapper({ isResetErrorMessagesNeeded = true } = {}) {
      try {
        isResetErrorMessagesNeeded && this.resetErrorMessages();
        const { data } = await this.fetchTasks({
          filter: this.searchFilterBarDetails.filterOptions,
          pagination: this.paginationDetails
        });
        this.paginationDetails.totalItems = data.meta.totalItems;
        this.isListFiltered =
          !!this.searchFilterBarDetails.filterOptions.length;
      } catch {
        this.errorMessages.push("Failed to fetch tasks");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tasks {
  text-align: left;

  &__list-info {
    margin-bottom: $spacing20;
  }

  &:deep(.tasks__search-filter-bar-datepicker) {
    width: 100%;
    border: 1px solid $metallic-silver;
    border-radius: $border-radius8;
    padding: $spacing6;
  }
}
</style>
