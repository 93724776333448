<template>
  <div>
    <b-modal
      :title="
        isDuplicateEmailTemplate
          ? 'Duplicate Email Templates'
          : isEditEmailTemplate
          ? 'Edit Email Templates'
          : 'Add Email Templates'
      "
      v-model="showAddAdminEmailSettingModal"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-tabs card justified small fill>
            <!-- Email tab -->
            <b-tab title="Email">
              <b-row>
                <b-col cols="6">
                  <b-form-group description="Please select Email Type">
                    <label
                      >Email Type<span class="required-span">*</span></label
                    >
                    <multiselect
                      placeholder=""
                      :maxHeight="220"
                      v-model="v$.form.selectedEmailType.$model"
                      :state="
                        v$.form.selectedEmailType.$dirty
                          ? !v$.form.selectedEmailType.$error
                          : null
                      "
                      :class="{
                        'form-control is-invalid p-0':
                          v$.form.selectedEmailType.$error
                      }"
                      label="title"
                      track-by="title"
                      :options="emailTypesData"
                    >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    description="Please select atleast one Email Language"
                  >
                    <label
                      >Email Language<span class="required-span">*</span></label
                    >
                    <multiselect
                      placeholder=""
                      :maxHeight="220"
                      v-model="v$.form.selectedEmailLanguage.$model"
                      :state="
                        v$.form.selectedEmailLanguage.$dirty
                          ? !v$.form.selectedEmailLanguage.$error
                          : null
                      "
                      :class="{
                        'form-control is-invalid p-0':
                          v$.form.selectedEmailLanguage.$error
                      }"
                      label="languages"
                      track-by="languages"
                      :multiple="true"
                      :options="emailLanguagesData"
                      @update:modelValue="setEmailLanguages"
                    >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    description="Please enter Email Interval (In Days)"
                    style="margin-top: 12px"
                  >
                    <label>Email Interval (In Days)</label>
                    <b-form-input
                      type="number"
                      v-model="form.emailInterval"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group description="Please enter Email Templates name">
                    <label
                      >Email Templates Name<span class="required-span"
                        >*</span
                      ></label
                    >
                    <b-form-input
                      type="text"
                      v-model="v$.form.emailTemplateName.$model"
                      :state="
                        v$.form.emailTemplateName.$dirty
                          ? !v$.form.emailTemplateName.$error
                          : null
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Client">
                    <multiselect
                      :disabled="
                        isEditEmailTemplate || isDuplicateEmailTemplate
                          ? false
                          : true
                      "
                      placeholder=""
                      :maxHeight="220"
                      v-model="form.selectedClient"
                      label="text"
                      track-by="value"
                      :options="clientsOptions"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Additional email tags"
                    :state="tagValidatorState"
                  >
                    <b-form-tags
                      input-id="tags-basic"
                      tag-variant="warning"
                      tag-pills
                      :input-attrs="{
                        'aria-describedby': 'tags-validation-help'
                      }"
                      :tag-validator="tagValidator"
                      :state="tagValidatorState"
                      v-model="form.emailTagsValue"
                      invalidTagText="Please enter a valid email"
                      @input="onInputEmailTag"
                    ></b-form-tags>
                    <template #invalid-feedback>
                      <p>Please enter a valid email</p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs card v-if="form.selectedEmailLanguage.length">
                <b-tab
                  v-for="(
                    languages, languagesIndex
                  ) in form.selectedEmailLanguage"
                  :key="languagesIndex"
                  :title="languages.languages"
                >
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Email Tags:">
                        <b-card>
                          <b-card-body
                            class="p-0"
                            style="height: 70px; overflow-y: auto"
                          >
                            <span
                              v-for="(tags, tagsIndex) in emailTagsData"
                              :key="tagsIndex"
                              class="mr-2"
                            >
                              <b-badge
                                variant="success"
                                style="cursor: pointer"
                              >
                                <p
                                  class="m-0"
                                  @click="
                                    setTagValue(
                                      languages,
                                      '[[' + tags.tags + ']]',
                                      languages.language_code +
                                        languagesIndex +
                                        'emailSubject',
                                      'text'
                                    )
                                  "
                                >
                                  {{ tags.tags }}
                                </p>
                              </b-badge>
                            </span>
                          </b-card-body>
                        </b-card>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Platform Variables:">
                        <b-card
                          v-b-tooltip.hover.top.html="
                            isActiveEmailSubject
                              ? 'To add platform variable in email subject field it\'s type must be <b>Text</b>.'
                              : ''
                          "
                        >
                          <b-card-body
                            class="p-0"
                            style="height: 70px; overflow-y: auto"
                          >
                            <span
                              v-for="(
                                platform, platformIndex
                              ) in platformVariablesData"
                              :key="platformIndex"
                              class="mr-1"
                            >
                              <b-badge
                                variant="success"
                                style="cursor: pointer"
                              >
                                <p
                                  class="m-0"
                                  @click="
                                    setTagValue(
                                      languages,
                                      '{{' +
                                        platform.platform_variable_key +
                                        '}}',
                                      languages.language_code +
                                        languagesIndex +
                                        'emailSubject',
                                      platform.variable_type
                                    )
                                  "
                                >
                                  {{ platform.platform_variable_name }}
                                </p>
                              </b-badge>
                            </span>
                          </b-card-body>
                        </b-card>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        description="Please enter Email Templates subject"
                      >
                        <label>Email Templates Subject</label>
                        <b-form-input
                          type="text"
                          size="sm"
                          :id="
                            languages.language_code +
                            languagesIndex +
                            'emailSubject'
                          "
                          @click="setEmailSubject"
                          v-model="
                            form.emailTemplates[languages.language_code].subject
                          "
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-spinner
                      v-if="renderEmailMessage"
                      label="Spinning"
                    ></b-spinner>
                    <b-col cols="12" v-else>
                      <b-form-group
                        description="Please enter Email Templates message"
                      >
                        <label>Email Templates Message</label>
                        <quill-editor
                          v-model="
                            form.emailTemplates[languages.language_code].message
                          "
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <!-- Attributes tab -->
            <b-tab title="Attributes">
              <div class="mt-3 p-4">
                <b-table-simple v-if="form.attributes">
                  <b-thead>
                    <b-tr>
                      <b-th>Key</b-th>
                      <b-th>Value</b-th>
                      <b-th></b-th>
                    </b-tr>
                  </b-thead>

                  <b-tbody>
                    <b-tr v-for="(data, index) in form.attributes" :key="index">
                      <b-td>
                        <b-form-input v-model="data.key"></b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input v-model="data.value"></b-form-input>
                      </b-td>
                      <b-td>
                        <!-- delete attribute button -->
                        <b-btn
                          v-if="index > 0"
                          size="sm"
                          variant="outline-danger"
                          @click="onDeleteData(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-btn>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <!-- add attribute button -->
                <b-btn
                  class="float-left"
                  size="sm"
                  variant="outline-info"
                  @click="addDataAttribute"
                >
                  <i class="fa fa-plus"></i>
                </b-btn>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>
              {{
                isDuplicateEmailTemplate
                  ? "Email Templates is now being duplicating......"
                  : isEditEmailTemplate
                  ? "Email Templates is now being updating......"
                  : "Email Templates is now being Saved......"
              }}
            </p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isDuplicateEmailTemplate
                ? "Email Templates has been duplicated successfully."
                : isEditEmailTemplate
                ? "Email Templates has been updated successfully."
                : "Email Templates has been saved successfully."
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" variant="outline-danger" @click="resetForm"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation"
          >{{
            isDuplicateEmailTemplate
              ? "Duplicate"
              : isEditEmailTemplate
              ? "Update"
              : "Save"
          }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { Helper } from "@/helpers";
import { mapState } from "vuex";

export default {
  name: "EmailTemplateFormModal",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      clientsOptions: [],
      showAddAdminEmailSettingModal: false,
      platformVariablesData: [],
      emailTagsData: [],
      emailLanguagesData: [],
      emailTypesData: [],
      form: {
        emailTemplateName: null,
        emailInterval: null,
        selectedEmailLanguage: [],
        selectedEmailType: null,
        emailTemplates: {},
        attributes: [{ key: null, value: null }],
        selectedClient: null,
        emailTagsValue: []
      },
      tagValidatorDirty: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      emailTemplateId: null,
      categoriesOption: [],
      submiting: false,
      responseSuccess: false,
      isLoading: false,
      renderEmailMessage: false,
      isActiveEmailSubject: false,
      setupEmails: {},
      isEditEmailTemplate: false,
      isDuplicateEmailTemplate: false,
      emaiTemplateMsg:
        "<p><center><table style='padding: 10px;width: 550px;background: white;border: 3px solid #eeeeee;'><tbody><tr><td style='width: 800px;padding:5px;'></td></tr></tbody></table></center></p>"
    };
  },
  validations: {
    form: {
      emailTemplateName: {
        required
      },
      selectedEmailLanguage: {
        required
      },
      selectedEmailType: {
        required
      }
    }
  },
  mounted() {
    this.getEmailTagList();
    this.getPlatformSettingsList();
    this.getEmailLanguagesList();
    this.getEmailTypeList();
    this.getClientsList();
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    tagValidatorState() {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let tempEmailTag =
        this.form.emailTagsValue[this.form.emailTagsValue.length - 1];
      return this.tagValidatorDirty
        ? mailformat.test(String(tempEmailTag).toLowerCase())
        : null;
    }
  },
  created() {
    EventBus.on("onShowAddModal", () => {
      this.emailTemplateId = null;
      this.showAddModal();
    });
    EventBus.on("onShowEditModal", (data) => {
      this.emailTemplateId = null;
      this.showEditModal(data);
    });
    EventBus.on("onShowDuplicateModal", (data) => {
      this.emailTemplateId = null;
      this.showDulicateModal(data);
    });
  },
  methods: {
    tagValidator(value) {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (mailformat.test(String(value).toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    onInputEmailTag() {
      if (this.form.emailTagsValue.length) {
        this.tagValidatorDirty = true;
      } else {
        this.tagValidatorDirty = false;
      }
    },
    addDataAttribute() {
      this.isLoading = true;
      this.form.attributes.push({ key: null, value: null });
      this.isLoading = false;
      // this.onSaveJsonData();
    },

    // get clients list
    async getClientsList() {
      this.isLoading = true;
      try {
        let result = await HTTP(
          "get",
          "v1/getclientslist",
          null,
          makeAuthorizationHeader()
        );
        this.clientsOptions = result.data.clients;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    onDeleteData(index) {
      this.isLoading = true;
      this.form.attributes.splice(index, 1);
      this.isLoading = false;
    },
    setEmailLanguages() {
      this.setupEmails = {};
      this.form.selectedEmailLanguage.map((languages) => {
        let languageValue = languages.language_code;
        if (this.form.emailTemplates.hasOwnProperty(languageValue)) {
          this.setupEmails[languageValue] = {
            subject: this.form.emailTemplates[languageValue].subject,
            message: this.form.emailTemplates[languageValue].message
          };
        } else {
          this.setupEmails[languageValue] = {
            subject: null,
            message: this.emaiTemplateMsg
          };
          this.form.emailTemplates = this.setupEmails;
        }
      });
    },
    setEmailSubject() {
      this.isActiveEmailSubject = true;
    },
    setEmailMessage() {
      this.isActiveEmailSubject = false;
    },
    dataInActiveField(newText, emailFormId) {
      let item = document.getElementById(emailFormId);
      const start = item.selectionStart;
      const end = item.selectionEnd;
      const text = item.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      item.value = before + newText + after;
      item.selectionStart = item.selectionEnd = start + newText.length;
      item.focus();
    },
    setTagValue(languages, selectedValue, emailSubjectId, type) {
      let tasksEmail = null;
      if (this.isActiveEmailSubject) {
        if (type == "text") {
          this.dataInActiveField(selectedValue, emailSubjectId);
        } else {
          this.addDataInEmailMessage(languages, selectedValue, tasksEmail);
        }
      } else {
        this.addDataInEmailMessage(languages, selectedValue, tasksEmail);
      }
      this.renderEmailMessage = true;
      setTimeout(() => {
        this.renderEmailMessage = false;
      }, 10);
    },
    getCaretPosition(editableDiv) {
      let caretPos = 0;
      let sel;
      let range;

      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
          if (range.commonAncestorContainer.parentNode == editableDiv) {
            caretPos = range.endOffset;
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() == editableDiv) {
          let tempEl = document.createElement("span");
          editableDiv.insertBefore(tempEl, editableDiv.firstChild);
          let tempRange = range.duplicate();
          tempRange.moveToElementText(tempEl);
          tempRange.setEndPoint("EndToEnd", range);
          caretPos = tempRange.text.length;
        }
      }
      return caretPos;
    },
    addDataInEmailMessage(languages, selectedValue, tasksEmail) {
      if (this.form.emailTemplates[languages.language_code].message == null) {
        this.form.emailTemplates[languages.language_code].message =
          selectedValue;
      } else {
        tasksEmail = `${
          this.form.emailTemplates[languages.language_code].message
        }${selectedValue}`;
        this.form.emailTemplates[languages.language_code].message = tasksEmail;
      }
    },
    showAddModal() {
      this.showAddAdminEmailSettingModal = true;
    },
    assignFieldsValue(data) {
      let openedCompanyProfile = localStorage.getItem("openedCompanyProfile");
      this.emailTemplateId = data.item.id;
      let templateData = data.item.template_data
        ? JSON.parse(data.item.template_data)
        : null;
      this.form = {
        emailInterval: data.item.email_interval,
        emailTemplateName: data.item.template_name,
        selectedEmailLanguage: templateData.languages
          ? templateData.languages
          : [],
        selectedEmailType: {
          id: data.item.email_type_id,
          title: data.item.email_type_title
        },
        emailTemplates: templateData.emails ? templateData.emails : {},
        attributes: templateData.attributes
          ? templateData.attributes
          : [{ key: null, value: null }],
        emailTagsValue: templateData.additional_emails
          ? templateData.additional_emails
          : [],
        selectedClient: {
          value: this.$route.params.id,
          text: openedCompanyProfile
        }
      };
    },
    showEditModal(data) {
      this.isEditEmailTemplate = true;
      this.assignFieldsValue(data);
      this.showAddAdminEmailSettingModal = true;
    },
    showDulicateModal(data) {
      this.isDuplicateEmailTemplate = true;
      this.assignFieldsValue(data);
      this.showAddAdminEmailSettingModal = true;
    },
    reloadTable() {
      EventBus.emit("onReloadTable", "");
    },
    resetForm() {
      this.form = {
        emailInterval: null,
        emailTemplateName: null,
        selectedEmailLanguage: [],
        selectedEmailType: null,
        emailTemplates: {},
        attributes: [{ key: null, value: null }],
        selectedClient: null,
        emailTagsValue: []
      };
      this.submiting = false;
      this.responseSuccess = false;
      this.isLoading = false;
      this.isEditEmailTemplate = false;
      this.isDuplicateEmailTemplate = false;
      this.showAddAdminEmailSettingModal = false;
      this.tagValidatorDirty = false;
      this.v$.$reset();
    },
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        if (this.isEditEmailTemplate) {
          this.editEmailTemplate();
        } else if (this.isDuplicateEmailTemplate) {
          this.addEmailTemplate(this.form.selectedClient.value);
        } else {
          this.addEmailTemplate(this.$route.params.id);
        }
      }
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetForm();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    },
    async addEmailTemplate(companyId) {
      let formData = {
        user_id: this.userId,
        email_type_id: this.form.selectedEmailType.id,
        company_id: companyId,
        email_interval: this.form.emailInterval,
        template_name: this.form.emailTemplateName,
        template_data: {
          languages: this.form.selectedEmailLanguage,
          emails: this.form.emailTemplates,
          attributes: this.form.attributes,
          additional_emails: this.form.emailTagsValue
        }
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/dynamic-templates/create",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster(
            "success",
            "Your Email Template has been added successfully."
          );
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
        this.reloadTable();
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        this.makeToster("warning", "Oops!! something went wrong!! ");
        return [err];
      }
    },
    async getEmailTagList() {
      this.emailTagsData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        "v1/tag/list",
        null,
        makeAuthorizationHeader()
      );
      try {
        this.emailTagsData = response.data.EmailTags;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getPlatformSettingsList() {
      this.platformVariablesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        `v1/platform-variable/list/${this.$route.params.id}`,
        null,
        makeAuthorizationHeader()
      );
      try {
        this.platformVariablesData = response.data.PlatformVariable;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getEmailLanguagesList() {
      this.emailLanguagesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        "v1/languages/list",
        null,
        makeAuthorizationHeader()
      );
      try {
        this.emailLanguagesData = response.data.EmailLanguages;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getEmailTypeList() {
      this.emailTypesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        "v1/types/list",
        null,
        makeAuthorizationHeader()
      );
      try {
        this.emailTypesData = response.data.EmailTypes;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async editEmailTemplate() {
      let formData = {
        email_type_id: this.form.selectedEmailType.id,
        email_interval: this.form.emailInterval,
        template_name: this.form.emailTemplateName,
        template_data: {
          languages: this.form.selectedEmailLanguage,
          emails: this.form.emailTemplates,
          attributes: this.form.attributes,
          additional_emails: this.form.emailTagsValue
        },
        company_id: this.form.selectedClient.value
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "put",
          `v1/dynamic-templates/update/${this.emailTemplateId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", result.data.message);
        } else if (result && result.data && result.data.success == 0) {
          this.makeToster("danger", result.data.message);
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
        this.reloadTable();
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        this.makeToster("warning", "Oops!! something went wrong!! ");
        return [err];
      }
    }
  }
};
</script>

<style>
.modal-body-style {
  overflow-x: hidden;
}
</style>
