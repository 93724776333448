<template>
  <div
    :class="['checkbox', disabled && 'checkbox--disabled']"
    @click="handleClick"
  >
    <input
      :id="id"
      type="checkbox"
      ref="checkbox"
      class="checkbox__input"
      :name="label"
      :checked="checked"
      :disabled="disabled"
      :aria-label="label"
    />
    <BaseIcon class="checkbox__icon" v-bind="getIconOptions" />
    <label
      :for="id"
      :class="['checkbox__label', labelHidden && 'checkbox__label--hidden']"
      >{{ getLabel() }}</label
    >
  </div>
</template>

<script>
import { makeUUID } from "@/utils";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { shapes, themes } from "@/constants";

export default {
  name: "Checkbox",
  components: { BaseIcon },
  compatConfig: {
    MODE: 3
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      default: false
    },
    checkedValue: {
      type: [String, Number, Boolean, Object, Array],
      default: true
    },
    uncheckedValue: {
      type: [String, Number, Boolean, Object, Array],
      default: false
    },
    label: {
      type: String,
      required: true
    },
    labelHidden: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 8
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shapes,
      id: `checkbox_${makeUUID()}`
    };
  },
  computed: {
    getIconOptions() {
      return {
        theme: this.checked ? themes.PRIMARY : themes.SECONDARY,
        icon: this.checked ? "check" : "",
        shape: shapes.SQUARE,
        size: this.size
      };
    },
    checked() {
      return this.modelValue === this.checkedValue;
    }
  },
  methods: {
    getLabel() {
      return this.labelHidden ? "" : this.label;
    },
    handleClick() {
      const value = this.checked ? this.uncheckedValue : this.checkedValue;

      this.$emit("update:modelValue", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  &__input {
    @include screen-reader-only;
  }

  &__icon {
    min-width: var(--icon-size);
    min-height: var(--icon-size);
  }

  &__label {
    margin-bottom: initial;
    margin-left: 10px;

    &--hidden {
      margin-left: 0;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
