<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
    v-if="isReady"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <p
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      :style="{ textAlign: 'left' }"
    >
      <span v-if="isRequired" class="required-span">*</span>
      <span class="d-inline-flex"
        >{{
          parseInt(form1.enableSectionFieldEnumeration) === 1
            ? sectionIndex + 1 + "." + (fieldIndex + 1)
            : " "
        }}
        <p class="mx-1">{{ fieldData.registeredLabel[selectedLanguage] }}</p>
      </span>
    </p>

    <!-- input content -->

    <b-collapse
      class="card"
      id="collapse-1"
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      v-model="collapse"
    >
      <b-row v-if="parseInt(fieldData.isConditional) === 1 && !isPreview">
        <b-col cols="12" class="text-right">
          <b-button
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div
        :class="
          $store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'
        "
        :id="fieldData.id"
      >
        <p>{{ fieldData.registeredDescription[selectedLanguage] }}</p>
        <Checkbox
          v-show="
            !fieldData.isNaFeature || parseInt(fieldData.isNaFeature) === 0
          "
          v-model="fieldData.isNa"
          @update:modelValue="onClilckNa"
          checked-value="1"
          unchecked-value="0"
          :label="$t('na')"
        />

        <!-- ------------------------row 1 -->
        <b-spinner v-if="isToggle" />
        <div v-else class="form-row" :id="fieldData.id">
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registeredFormCountryDisabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressCountryHelperText[selectedLanguage]
              "
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormCountry
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.registeredAddressCountryLabel[selectedLanguage] }}
              </label>

              <v-select
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                label="name"
                v-model="v$.registrationForm.country.$model"
                :state="
                  v$.registrationForm && v$.registrationForm.country.$dirty
                    ? !v$.registrationForm.country.$error
                    : null
                "
                :options="optionsCountries"
                :class="
                  v$.registrationForm.country.$error
                    ? 'border border-danger rounded'
                    : null
                "
                @update:modelValue="onSelectedRegistrationForm"
                @blur="v$.registrationForm.$touch()"
              >
                <template slot="option" slot-scope="option">
                  <country-flag
                    v-if="option.icon"
                    :country="option.icon"
                    size="small"
                  />
                  <span>{{ "&nbsp;" + option.name }}</span>
                </template>
                <template #no-options>
                  {{ $t("questionnaires.noSearchOptionText") }}
                </template>
              </v-select>
              <label
                v-if="
                  v$.registrationForm.country.$error &&
                  fieldData.validation.isRequireRegistrationFormCountry &&
                  !registrationForm.country
                "
                class="text-danger"
                style="font-size: 80%"
                >{{ $t("createProfile.requiredValidationMessage") }}</label
              >
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registeredFormCityDisabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressCityStateHelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label
                :class="
                  fieldData.validation.isRequireRegistrationFormCity
                    ? 'text-left mt-0'
                    : 'text-left mt-2'
                "
              >
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormCity
                  "
                  class="required-span"
                  >*</span
                >
                {{
                  fieldData.registeredAddressCityStateLabel[selectedLanguage]
                }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                type="text"
                v-model="v$.registrationForm.cityState.$model"
                :placeholder="
                  fieldData.registeredAddressCityStatePlaceholder[
                    selectedLanguage
                  ]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.cityState.$dirty
                    ? !v$.registrationForm.cityState.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- ------------------------row 2 -------------------------------->
        <div class="form-row" :id="fieldData.id">
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registeredFormAddress1Disabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressLine1HelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormAddress1
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.registeredAddressLine1Label[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                v-model="v$.registrationForm.address.$model"
                :placeholder="
                  fieldData.registeredAddressLine1Placeholder[selectedLanguage]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.address.$dirty
                    ? !v$.registrationForm.address.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registeredFormAddress2Disabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressLine2HelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormAddress2
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.registeredAddressLine2Label[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                type="text"
                v-model="v$.registrationForm.addressTwo.$model"
                :placeholder="
                  fieldData.registeredAddressLine2Placeholder[selectedLanguage]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.addressTwo.$dirty
                    ? !v$.registrationForm.addressTwo.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- ------------------------row 3 -------------------------------->
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registeredFormTownDisabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressTownHelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormTown
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.registeredAddressTownLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                type="text"
                v-model="v$.registrationForm.town.$model"
                :placeholder="
                  fieldData.registeredAddressTownPlaceholder[selectedLanguage]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.town.$dirty
                    ? !v$.registrationForm.town.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.registeredFormZipDisabled
            "
          >
            <b-form-group
              :description="
                fieldData.registeredAddressPostalZipCodeHelperText[
                  selectedLanguage
                ]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    fieldData.validation.isRequireRegistrationFormZip
                  "
                  class="required-span"
                  >*</span
                >
                {{
                  fieldData.registeredAddressPostalZipCodeLabel[
                    selectedLanguage
                  ]
                }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                type="text"
                v-model="v$.registrationForm.zipCode.$model"
                :placeholder="
                  fieldData.registeredAddressPostalZipCodePlaceholder[
                    selectedLanguage
                  ]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.zipCode.$dirty
                    ? !v$.registrationForm.zipCode.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
                id="inputZip"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- ------------------------row 4 -------------------------------->
        <div class="form-row" v-if="showFreeForm">
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance ||
              !fieldData.advance.registrationFormFreeFormDisabled
            "
          >
            <b-form-group
              :description="
                fieldData.registrationFormFreeFormHelperText[selectedLanguage]
              "
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireRegistrationFreeForm
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.registrationFormFreeFormLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="
                  parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
                "
                v-model="v$.registrationForm.freeForm.$model"
                :placeholder="
                  fieldData.registrationFormFreeFormPlaceholderText[
                    selectedLanguage
                  ]
                "
                :state="
                  v$.registrationForm && v$.registrationForm.freeForm.$dirty
                    ? !v$.registrationForm.freeForm.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.registrationForm.$touch()"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>

      <div
        :class="
          $store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'
        "
        :id="fieldData.id"
      >
        <Checkbox
          checked-value="1"
          unchecked-value="0"
          :disabled="
            parseInt(isDisabled) === 1 || parseInt(fieldData.isNa) === 1
          "
          v-model="tradingAndRegisteredAddressIsSame"
          @update:modelValue="onCheckSameAddress"
          :label="fieldData.checkBoxLabel[selectedLanguage]"
        />

        <!-- ----------------------------------Tradiung addresss------------------------------------------- -->
        <!-- v-if="!tradingAndRegisteredAddressIsSame" -->
        <div>
          <h5 :dir="$store.state.rightAlign ? 'rtl' : 'ltr'">
            {{ fieldData.tradingLabel[selectedLanguage] }}
          </h5>
          <p :dir="$store.state.rightAlign ? 'rtl' : 'ltr'">
            {{ fieldData.tradingDescription[selectedLanguage] }}
          </p>

          <!-- ------------------------row 1 -------------------------------->

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.tradingFormCountryDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.tradingAddressAddressCountryHelperText[
                    selectedLanguage
                  ]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormCountry
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingAddressCountryLabel[selectedLanguage] }}
                </label>
                <v-select
                  :disabled="
                    parseInt(isDisabled) === 1 ||
                    parseInt(tradingAndRegisteredAddressIsSame) !== 0
                  "
                  label="name"
                  v-model="v$.tradingForm.country.$model"
                  :state="
                    v$.tradingForm && v$.tradingForm.country.$dirty
                      ? !v$.tradingForm.country.$error
                      : null
                  "
                  :options="optionsCountries"
                  :class="
                    v$.tradingForm.country.$error
                      ? 'border border-danger rounded'
                      : null
                  "
                  @update:modelValue="onSelectedTradingForm"
                  @blur="v$.tradingForm.$touch()"
                >
                  <template slot="option" slot-scope="option">
                    <country-flag
                      v-if="option.icon"
                      :country="option.icon"
                      size="small"
                    />
                    <span>{{ "&nbsp;" + option.name }}</span>
                  </template>
                  <template #no-options>
                    {{ $t("questionnaires.noSearchOptionText") }}
                  </template>
                </v-select>
                <label
                  v-if="
                    v$.tradingForm.country.$error &&
                    fieldData.validation.isRequireTradingFormCountry &&
                    !tradingForm.country
                  "
                  class="text-danger"
                  style="font-size: 80%"
                  >{{ $t("createProfile.requiredValidationMessage") }}</label
                >
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.tradingFormCityDisabled
              "
            >
              <b-form-group
                :disabled="tradingAndRegisteredAddressIsSame != 0"
                :description="
                  fieldData.tradingAddressAddressCityStateHelperText[
                    selectedLanguage
                  ]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label
                  :class="
                    fieldData.validation.isRequireTradingFormCity
                      ? 'text-left mt-0'
                      : 'text-left mt-0'
                  "
                >
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormCity
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingAddressCityStateLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  type="text"
                  v-model="v$.tradingForm.cityState.$model"
                  :placeholder="
                    fieldData.tradingAddressCityStatePlaceholder[
                      selectedLanguage
                    ]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.cityState.$dirty
                      ? !v$.tradingForm.cityState.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- ------------------------row 2 -------------------------------->

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.tradingFormAddress1Disabled
              "
            >
              <b-form-group
                :disabled="tradingAndRegisteredAddressIsSame != 0"
                :description="
                  fieldData.tradingAddressAddressLine1HelperText[
                    selectedLanguage
                  ]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormAddress1
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingAddressLine1Label[selectedLanguage] }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  type="text"
                  v-model="v$.tradingForm.address.$model"
                  :placeholder="
                    fieldData.tradingAddressLine1Placeholder[selectedLanguage]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.address.$dirty
                      ? !v$.tradingForm.address.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.tradingFormAddress2Disabled
              "
            >
              <b-form-group
                :disabled="tradingAndRegisteredAddressIsSame != 0"
                :description="
                  fieldData.tradingAddressAddressLine2HelperText[
                    selectedLanguage
                  ]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormAddress2
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingAddressLine2Label[selectedLanguage] }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  type="text"
                  v-model="v$.tradingForm.addressTwo.$model"
                  :placeholder="
                    fieldData.tradingAddressLine2Placeholder[selectedLanguage]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.addressTwo.$dirty
                      ? !v$.tradingForm.addressTwo.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- ------------------------row 3 -------------------------------->
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.tradingFormTownDisabled
              "
            >
              <b-form-group
                :disabled="tradingAndRegisteredAddressIsSame != 0"
                :description="
                  fieldData.tradingAddressAddressTownHelperText[
                    selectedLanguage
                  ]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormTown
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingAddressTownLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  type="text"
                  v-model="v$.tradingForm.town.$model"
                  :placeholder="
                    fieldData.tradingAddressTownPlaceholder[selectedLanguage]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.town.$dirty
                      ? !v$.tradingForm.town.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.tradingFormZipDisabled
              "
            >
              <b-form-group
                :disabled="tradingAndRegisteredAddressIsSame != 0"
                :description="
                  fieldData.tradingAddressAddressPostalZipCodeHelperText[
                    selectedLanguage
                  ]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      fieldData.validation.isRequireTradingFormZip
                    "
                    class="required-span"
                    >*</span
                  >
                  {{
                    fieldData.tradingAddressPostalZipCodeLabel[selectedLanguage]
                  }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  type="text"
                  v-model="v$.tradingForm.zipCode.$model"
                  :placeholder="
                    fieldData.tradingAddressPostalZipCodePlaceholder[
                      selectedLanguage
                    ]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.zipCode.$dirty
                      ? !v$.tradingForm.zipCode.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                  id="inputZip"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- ------------------------row 4 -------------------------------->
          <div class="form-row" v-if="showFreeForm">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.tradingFormFreeFormDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.tradingFormFreeFormHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireTradingFreeForm)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.tradingFormFreeFormLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :disabled="parseInt(isDisabled) === 1"
                  v-model="v$.tradingForm.freeForm.$model"
                  :placeholder="
                    fieldData.tradingFormFreeFormPlaceholderText[
                      selectedLanguage
                    ]
                  "
                  :state="
                    v$.tradingForm && v$.tradingForm.freeForm.$dirty
                      ? !v$.tradingForm.freeForm.$error
                      : null
                  "
                  @blur="v$.tradingForm.$touch()"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "@/components/menuButtons";
import AdvanceSettings from "@/components/workflowSettings/advanceCorporateAddressSettings";
import vSelect from "vue-select";
import CountryCodes from "@/utils/countryCodesRiskScore.json";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import _ from "lodash";
import { routeKeys, routeNames } from "@/constants";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "AddressCorporateGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    AdvanceSettings,
    vSelect,
    Checkbox
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    optionsCountries() {
      return CountryCodes[this.fieldData.selectedListTypeIndex?.value || 0]
        .countries;
    }
  },
  data() {
    return {
      isReady: false,
      isRequired: false,
      isNa: this.fieldData.isNa || 0,
      isToggle: false,
      showFreeForm: false,
      usStates: [],
      isHidden: false,
      condition: {},
      title: null,
      isUsSelected: false,
      registrationForm: {
        address: null,
        addressTwo: null,
        town: null,
        cityState: null,
        usState: null,
        zipCode: null,
        country: null,
        freeForm: null
      },
      tradingForm: {
        address: null,
        addressTwo: null,
        town: null,
        cityState: null,
        usState: null,
        zipCode: null,
        country: null,
        freeForm: null
      },
      tradingAndRegisteredAddressIsSame: 0,
      label: null,
      showSettings: false,
      collapse: true,
      postData: []
    };
  },

  validations() {
    return {
      registrationForm: {
        address: {
          [this.address]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormAddress1 &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormAddress1Disabled
              ? required
              : !required
        },
        addressTwo: {
          [this.addressTwo]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormAddress2 &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormAddress2Disabled
              ? required
              : !required
        },
        town: {
          [this.town]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormTown &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormTownDisabled
              ? required
              : !required
        },
        cityState: {
          [this.cityState]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormCity &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormCityDisabled
              ? required
              : !required
        },
        zipCode: {
          [this.zipCode]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormZip &&
            this.fieldData.advance &&
            !this.fieldData.advance.isRequireRegistrationFormZip
              ? required
              : !required
        },
        country: {
          [this.country]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormCountry &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormCountryDisabled
              ? required
              : !required
        },
        usState: {
          [this.usState]:
            this.isUsSelected &&
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormUsState &&
            this.fieldData.advance &&
            !this.fieldData.advance.registeredFormCityDisabled
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFreeForm &&
            this.fieldData.advance &&
            !this.fieldData.advance.registrationFormFreeFormDisabled
              ? required
              : !required
        }
      },

      tradingForm: {
        address: {
          [this.address]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormAddress1
              ? required
              : !required
        },
        addressTwo: {
          [this.addressTwo]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormAddress2
              ? required
              : !required
        },
        town: {
          [this.town]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormTown
              ? required
              : !required
        },
        cityState: {
          [this.cityState]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormCity
              ? required
              : !required
        },
        zipCode: {
          [this.zipCode]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormZip
              ? required
              : !required
        },
        country: {
          [this.country]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFormCountry
              ? required
              : !required
        },
        usState: {
          [this.usState]:
            this.isUsSelected &&
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationFormUsState
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireTradingFreeForm
              ? required
              : !required
        }
      }
    };
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value === "progress" || parseInt(this.fieldData.isNa) === 1) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
        this.setTempDataRegistration();
      } else if (this.fieldData.isHidden) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        this.v$.registrationForm.$touch();
        if (
          this.v$ &&
          this.v$.registrationForm.$errors?.length &&
          this.v$.registrationForm.$invalid
        ) {
          this.$store.state.validFields = false;
          this.fieldData.isInValid = true;
        } else {
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
          this.setTempDataRegistration();
        }
      }
    });

    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value === "progress" || parseInt(this.fieldData.isNa) === 1) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
        this.setTempDataTrade();
      } else if (this.fieldData.isHidden) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        this.v$.tradingForm.$touch();
        this.v$.registrationForm.$touch();
        if (
          (this.v$ &&
            this.v$.tradingForm.$errors?.length &&
            this.v$.tradingForm.$invalid) ||
          (this.v$.registrationForm.$errors?.length &&
            this.v$.registrationForm.$invalid)
        ) {
          this.$store.state.validFields = false;
          this.fieldData.isInValid = true;
        } else {
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
          this.setTempDataTrade();
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    this.setFreeFormFields();
    this.setNewConditions();
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === routeNames[routeKeys.USER_FORM]
    ) {
      let validations = Object.values(this.fieldData.validation);
      const found = validations.find((value) => value);
      if (found) {
        this.isRequired = true;
      }
      setTimeout(() => {
        this.getDataForUser();
        this.isReady = true;
      }, 1500);
    }
    if (this.$route.name === "builder") {
      this.isReady = true;
    }
  },

  methods: {
    onClilckNa(e) {
      this.isToggle = true;
      this.fieldData.isNa = e;
      this.isToggle = false;
      this.tradingAndRegisteredAddressIsSame = e;
      this.onCheckSameAddress();
      this.v$.$reset();
      this.resetData();
    },
    resetData() {
      this.registrationForm.address = null;
      this.registrationForm.addressTwo = null;
      this.registrationForm.town = null;
      this.registrationForm.cityState = null;
      this.registrationForm.usState = null;
      this.registrationForm.zipCode = null;
      this.registrationForm.country = null;
      this.registrationForm.freeForm = null;

      this.tradingForm.address = null;
      this.tradingForm.addressTwo = null;
      this.tradingForm.town = null;
      this.tradingForm.cityState = null;
      this.tradingForm.usState = null;
      this.tradingForm.zipCode = null;
      this.tradingForm.country = null;
      this.tradingForm.freeForm = null;
    },
    setFreeFormFields() {
      Object.assign(this.fieldData, {
        registrationFormFreeFormLabel: {
          english: "Free Form"
        },
        registrationFormFreeFormPlaceholderText: {
          english: ""
        },
        registrationFormFreeFormHelperText: {
          english: ""
        },
        registrationFormFreeFormValidationMessage: {
          english: "This field is required"
        },

        tradingFormFreeFormLabel: {
          english: "Free Form"
        },
        tradingFormFreeFormPlaceholderText: {
          english: ""
        },
        tradingFormFreeFormHelperText: {
          english: ""
        },
        tradingFormFreeFormValidationMessage: {
          english: "This field is required"
        }
      });
      let validation = {
        ...this.fieldData.validation,
        isRequireRegistrationFreeForm:
          this.fieldData.validation.isRequireRegistrationFreeForm || false,
        isRequireTradingFreeForm:
          this.fieldData.validation.isRequireTradingFreeForm || false
      };
      Object.assign(this.fieldData, { validation });
      let advance = {
        ...this.fieldData.advance,
        registrationFormFreeFormDisabled:
          this.fieldData.advance.registrationFormFreeFormDisabled || true,
        tradingFormFreeFormDisabled:
          this.fieldData.advance.tradingFormFreeFormDisabled || true
      };
      Object.assign(this.fieldData, { advance });
      this.showFreeForm = true;
    },

    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },

    onSelectRegistrationFormUsState(value) {
      if (value) {
        this.registrationForm.usState = value.name;
        this.checkFormProgress();
      }
    },

    onSelectTradingFormUsState(value) {
      if (value) {
        this.tradingForm.usState = value.name;
      }
    },

    checkForConditional() {
      if (parseInt(this.fieldData.isConditional) === 1) {
        this.fieldData.isHidden = false;
      }
    },

    onSelectedRegistrationForm(value) {
      if (value) {
        this.registrationForm.country = value.name;
      }
    },

    onSelectedTradingForm(value) {
      if (value) {
        this.tradingForm.country = value.name;
      }
    },

    onCheckSameAddress() {
      if (this.tradingAndRegisteredAddressIsSame != 0) {
        (this.tradingForm.address = this.registrationForm.address),
          (this.tradingForm.addressTwo = this.registrationForm.addressTwo),
          (this.tradingForm.town = this.registrationForm.town),
          (this.tradingForm.cityState = this.registrationForm.cityState),
          (this.tradingForm.usState = this.registrationForm.usState),
          (this.tradingForm.zipCode = this.registrationForm.zipCode),
          (this.tradingForm.country = this.registrationForm.country),
          (this.tradingForm.freeForm = this.registrationForm.freeForm);
      } else {
        (this.tradingForm.address = null),
          (this.tradingForm.addressTwo = null),
          (this.tradingForm.town = null),
          (this.tradingForm.cityState = null),
          (this.tradingForm.usState = null),
          (this.tradingForm.zipCode = null),
          (this.tradingForm.country = null),
          (this.tradingForm.freeForm = null);
      }
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elementId === this.fieldData.id) {
          this.fieldData.isNa = data.isWorkFlowNa;
          this.fieldData.isHidden = data.isHiddenElement === 1;
        }
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              if (element[0].reg) {
                (this.registrationForm.address =
                  element[
                    element.findIndex((x) => x.key === "regAddress")
                  ].postValue),
                  (this.registrationForm.addressTwo =
                    element[
                      element.findIndex((x) => x.key === "regAddressTwo")
                    ].postValue),
                  (this.registrationForm.town =
                    element[
                      element.findIndex((x) => x.key === "regTown")
                    ].postValue);
                this.registrationForm.cityState =
                  element[
                    element.findIndex((x) => x.key === "regCityState")
                  ].postValue;
                this.registrationForm.usState =
                  element[
                    element.findIndex((x) => x.key === "regUsState")
                  ].postValue;
                this.registrationForm.zipCode =
                  element[
                    element.findIndex((x) => x.key === "regZipCode")
                  ].postValue;
                this.registrationForm.country =
                  element[
                    element.findIndex((x) => x.key === "regCountry")
                  ].postValue;
                this.registrationForm.freeForm =
                  element[
                    element.findIndex((x) => x.key === "regFreeForm")
                  ].postValue;

                if (parseInt(data.isSameAsRegistration) === 1) {
                  let cloneAddress = _.cloneWith(this.registrationForm);
                  this.tradingAndRegisteredAddressIsSame = 1;
                  this.tradingForm = cloneAddress;
                }
              } else if (!element[1].reg) {
                (this.tradingForm.address =
                  element[
                    element.findIndex((x) => x.key === "address")
                  ].postValue),
                  (this.tradingForm.addressTwo =
                    element[
                      element.findIndex((x) => x.key === "addressTwo")
                    ].postValue),
                  (this.tradingForm.town =
                    element[
                      element.findIndex((x) => x.key === "town")
                    ].postValue);
                this.tradingForm.cityState =
                  element[
                    element.findIndex((x) => x.key === "cityState")
                  ].postValue;
                this.tradingForm.usState =
                  element[
                    element.findIndex((x) => x.key === "usState")
                  ].postValue;
                this.tradingForm.zipCode =
                  element[
                    element.findIndex((x) => x.key === "zipCode")
                  ].postValue;
                this.tradingForm.country =
                  element[
                    element.findIndex((x) => x.key === "country")
                  ].postValue;
                this.tradingForm.freeForm =
                  element[
                    element.findIndex((x) => x.key === "freeForm")
                  ].postValue;
              }
            }
          });
        }
      });
    },

    setDataToUser() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        this.$store.state.userFormSubmitArray.map((ele) => {
          if (ele.elementId === this.fieldData.id) {
            ele.isWorkFlowNa = this.fieldData.isNa;
            ele.isHiddenElement = this.fieldData.isHidden ? 1 : 0;
            ele.elements = this.postData;
            ele.isSameAsRegistration = this.tradingAndRegisteredAddressIsSame;
            ele.registeredLabel =
              this.fieldData.registeredLabel[this.selectedLanguage];
            ele.tradingLabel =
              this.fieldData.tradingLabel[this.selectedLanguage];
            ele.registeredLabelForPDF = this.fieldData.registeredLabel;
            ele.tradingLabelForPDF = this.fieldData.tradingLabel;
          }
        });
      }
    },

    setTempDataRegistration() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        let aa = [
          {
            reg: true,
            postValue: this.registrationForm.address,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.address1PostName) ||
              "addressPostName",
            key: "regAddress",
            label:
              this.fieldData.registeredAddressLine1Label[this.selectedLanguage],
            labelForPDF: this.fieldData.registeredAddressLine1Label
          },
          {
            reg: true,
            postValue: this.registrationForm.addressTwo,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.address2PostName) ||
              "regAddressTwoPostName",
            key: "regAddressTwo",
            label:
              this.fieldData.registeredAddressLine2Label[this.selectedLanguage],
            labelForPDF: this.fieldData.registeredAddressLine2Label
          },
          {
            reg: true,
            postValue: this.registrationForm.town,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.townPostName) ||
              "regTownPostName",
            key: "regTown",
            label:
              this.fieldData.registeredAddressTownLabel[this.selectedLanguage],
            labelForPDF: this.fieldData.registeredAddressTownLabel
          },
          {
            reg: true,
            postValue: this.registrationForm.usState,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.usStatePostName) ||
              "regUsStatePostName",
            key: "regUsState",
            label:
              this.fieldData.registeredAddressUsStateLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.registeredAddressUsStateLabel
          },
          {
            reg: true,
            postValue: this.registrationForm.cityState,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.cityPostName) ||
              "regCityStatePostName",
            key: "regCityState",
            label:
              this.fieldData.registeredAddressCityStateLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.registeredAddressCityStateLabel
          },
          {
            reg: true,
            postValue: this.registrationForm.zipCode,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.zipPostName) ||
              "regZipCodePostName",
            key: "regZipCode",
            label:
              this.fieldData.registeredAddressPostalZipCodeLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.registeredAddressPostalZipCodeLabel
          },
          {
            reg: true,
            postValue: this.registrationForm.country,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.countryPostName) ||
              "regCountryPostName",
            key: "regCountry",
            label:
              this.fieldData.registeredAddressCountryLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.registeredAddressCountryLabel
          },
          {
            reg: true,
            postValue: this.registrationForm.freeForm,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "regFreeFormPostName",
            key: "regFreeForm",
            label:
              this.fieldData.registrationFormFreeFormLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.registrationFormFreeFormLabel
          }
        ];
        this.postData[0] = aa;
        this.setDataToUser();
      }
    },

    setTempDataTrade() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        let aa = [
          {
            postValue: this.tradingForm.address,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.addressPostName) ||
              "addressPostName",
            key: "address",
            label:
              this.fieldData.tradingAddressLine1Label[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingAddressLine1Label
          },
          {
            postValue: this.tradingForm.addressTwo,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.addressTwoPostName) ||
              "addressTwoPostName",
            key: "addressTwo",
            label:
              this.fieldData.tradingAddressLine2Label[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingAddressLine2Label
          },
          {
            postValue: this.tradingForm.town,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.townPostName) ||
              "townPostName",
            key: "town",
            label:
              this.fieldData.tradingAddressTownLabel[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingAddressTownLabel
          },
          {
            postValue: this.tradingForm.usState,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.cityStatePostName) ||
              "usStatePostName",
            key: "usState",
            label:
              this.fieldData.tradingAddressUsStateLabel[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingAddressUsStateLabel
          },
          {
            postValue: this.tradingForm.cityState,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.cityStatePostName) ||
              "cityStatePostName",
            key: "cityState",
            label:
              this.fieldData.tradingAddressCityStateLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.tradingAddressCityStateLabel
          },
          {
            postValue: this.tradingForm.zipCode,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.zipCodePostName) ||
              "zipCodePostName",
            key: "zipCode",
            label:
              this.fieldData.tradingAddressPostalZipCodeLabel[
                this.selectedLanguage
              ],
            labelForPDF: this.fieldData.tradingAddressPostalZipCodeLabel
          },
          {
            postValue: this.tradingForm.country,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.countryPostName) ||
              "countryPostName",
            key: "country",
            label:
              this.fieldData.tradingAddressCountryLabel[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingAddressCountryLabel
          },
          {
            postValue: this.tradingForm.freeForm,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "freeForm",
            label:
              this.fieldData.tradingFormFreeFormLabel[this.selectedLanguage],
            labelForPDF: this.fieldData.tradingFormFreeFormLabel
          }
        ];
        this.postData[1] = aa;
        this.setDataToUser();
      }
    },

    checkFormProgress() {
      const registerData = _.clone(this.$store.state.registerData);

      if (
        this.v$.registrationForm.$errors?.length &&
        this.v$.registrationForm.$invalid
      ) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;
      } else {
        registerData[this.fieldData.id] = "address-corporate";
        this.$store.state.registerData = registerData;
      }
      const numkeys = Object.keys(registerData).length;
      this.$store.state.progressValue = numkeys;
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    },

    setValidation() {
      let validation = {
        isRequireRegistrationFormCountry: true,
        isRequireRegistrationFormCity: true,
        isRequireRegistrationFormUsState: true,
        isRequireRegistrationFormAddress1: true,
        isRequireRegistrationFormAddress2: true,
        isRequireRegistrationFormTown: true,
        isRequireRegistrationFormZip: true,

        isRequireTradingFormCountry: false,
        isRequireTradingFormCity: false,
        isRequireTradingFormUsState: false,
        isRequireTradingFormAddress1: false,
        isRequireTradingFormAddress2: false,
        isRequireTradingFormTown: false,
        isRequireTradingFormZip: false
      };
      Object.assign(this.fieldData, { validation });
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style scoped>
.checkbox {
  justify-content: flex-start;
}
</style>
