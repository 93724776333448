<template>
  <div class="checkbox-group">
    <Checkbox
      v-bind="option"
      v-for="(option, index) in modelValue"
      :key="index"
      @update:modelValue="handleUpdateEvent($event, index)"
      :disabled="isDisabled(option)"
    />
  </div>
</template>
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-undef -->
<script setup>
import Checkbox from "@/molecules/Checkbox/Checkbox";

const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const handleUpdateEvent = (value, index) => {
  props.modelValue[index].modelValue = value;

  emit("update:modelValue", props.modelValue);
};

const isDisabled = (option) => option?.disabled || props.disabled;
</script>

<script>
export default {
  compatConfig: {
    MODE: 3
  }
};
</script>
