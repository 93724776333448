<template>
  <div>
    <b-card class="mt-3" header="Configure Form">
      <div class="row" style="height: calc(100vh - 330px); overflow: auto">
        <div class="col-6 text-left">
          <b-form-group label="Translation Type">
            <b-form-radio
              v-model="formTranslationType"
              @input="updateTranslationType"
              name="translationtype"
              value="custom"
              >Custom</b-form-radio
            >
            <b-form-radio
              v-model="formTranslationType"
              @input="updateTranslationType"
              name="translationtype"
              value="googletranslate"
              >Google Translate</b-form-radio
            >
          </b-form-group>

          <b-form-group
            label="Form Languages"
            v-if="formTranslationType == 'custom'"
          >
            <CheckboxGroup
              class="form-configuration__translation-list"
              v-model="formTranslationsList"
            />
          </b-form-group>
        </div>
        <div class="col-6 text-left">
          <b-form-group label="Version">
            <b-form-input
              @input="updateFormVersion"
              v-model="formVersion"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Type" stacked>
                <b-form-radio-group
                  :disabled="isFormPublished"
                  stacked
                  v-model="formType"
                  :options="formTypeOptions"
                  name="form-type"
                  @input="updateFormType"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- check for form intro show -->
          <Checkbox
            v-if="formType != 4"
            v-model="showFormIntro"
            @update:modelValue="onShowFormClick"
            class="mb-3"
            label="Show Form Intro"
          />

          <b-form-group
            label="Layout"
            stacked
            v-if="formType != 4 && formType != 3"
          >
            <b-form-radio-group
              stacked
              v-model="formLayout"
              :options="formLayoutOptions"
              name="form-layout"
              @change="updateFormLayout"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Enumerated Fields"
            v-if="formType != 4 && formType != 3"
          >
            <Checkbox
              @update:modelValue="updateFormEnumeration"
              v-model="enableSectionFieldEnumeration"
              checked-value="1"
              unchecked-value="0"
              label="Enable enumerated fields"
            />
          </b-form-group>

          <b-form-group label="Password">
            <Checkbox
              @update:modelValue="updateFormPassword"
              v-model="formRequirePassword"
              checked-value="1"
              unchecked-value="0"
              label="Enable password access"
            />
          </b-form-group>

          <b-form-group label="Scoring">
            <Checkbox
              v-model="form.suppressScoreReporting"
              label="Suppress score reporting"
            />
          </b-form-group>
        </div>
      </div>
    </b-card>
    <b-navbar toggleable="sm" type="dark" variant="dark">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0"
              type="button"
              to="/form/details"
              >Next</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { HTTP } from "@/utils";
import formConfigLanguages from "@/utils/formConfigLanguages.json";
import { formStatus, localStorageItem } from "@/constants";
import { Helper } from "@/helpers";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import CheckboxGroup from "@/molecules/CheckboxGroup/CheckboxGroup";

export default {
  name: "FormConfigurationContainer",
  components: {
    Checkbox,
    CheckboxGroup
  },
  data() {
    return {
      formConfigLanguages,
      formTranslationType: "custom",
      formVersion: undefined,
      formType: null,
      formRequirePassword: 0,
      showFormIntro: false,
      isFormPublished: false,
      formLayout: "vertical",
      formTypeOptions: [
        {
          text: "External Questionaire",
          value: 1
        },
        {
          text: "Survey",
          value: 2
        },
        {
          text: "Training",
          value: 3
        },
        {
          text: "Exam",
          value: 4
        },
        {
          text: "Executive Summary",
          value: 5
        },
        {
          text: "PDF Uploads",
          value: 6
        },
        {
          text: "Internal Questionaire",
          value: 7
        },
        {
          text: "Signature Form",
          value: 8
        },
        {
          text: "Internal Company Form",
          value: 9
        },
        {
          text: "Certification Form",
          value: 10
        }
      ],
      formLayoutOptions: [
        {
          text: "Vertical",
          value: "vertical"
        },
        {
          text: "Tabbed",
          value: "tabbed"
        }
      ],
      enableSectionFieldEnumeration: 0,
      maxExamAttempts: 5,
      examTimeLimit: 0,
      examSequentialAnswer: 0,
      examRandomiseQuestions: 0,
      examUniqueAnswersPerAttempt: 0,
      examPassMark: 0,
      isUnlimitedExam: false,
      formExams: [],
      formTrainings: [],
      selectedExamForTraining: null,
      selectedTraining: null,
      isDisabledTranslation: false
    };
  },
  computed: {
    ...mapGetters({
      selectedLanguage: "selectedLanguage"
    }),
    ...mapState({
      form: "form1"
    }),
    formTranslationsList: {
      get() {
        return formConfigLanguages.languages.map(
          ({ value, text, disabled }) => ({
            modelValue: this.form?.formTranslations?.includes(value)
              ? value
              : "",
            checkedValue: value,
            uncheckedValue: "",
            label: text,
            disabled
          })
        );
      },
      set(languages) {
        const formTranslations = languages
          .filter((value) => value.modelValue)
          .map((value) => value.modelValue);

        this.form.formTranslations = formTranslations;
      }
    }
  },
  watch: {
    form() {
      this.updateFormLocals();
    }
  },
  async mounted() {
    this.isFormPublished =
      parseInt(localStorage.getItem(localStorageItem.FORM_STATUS)) ===
      formStatus.PUBLISHED;
    await this.updateFormLocals();
  },
  async beforeDestroy() {
    await EventBus.emit("onAutoSaveJsonForm", true);
  },
  methods: {
    async setSubTask(e) {
      let formId = this.$store.state.form1.formId;
      let relatedTaskIds = [];
      relatedTaskIds.push({
        formId: e.id,
        type: 8,
        is_active: 1
      });
      try {
        await HTTP("post", "v1/set/onboardingforms/sub/tasks", {
          relatedTaskIds,
          formId
        });
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },

    updateFormLocals() {
      this.formTranslationType = this.form.formTranslationType
        ? this.form.formTranslationType
        : "custom";
      this.formVersion = this.form.formVersion;
      this.formType = this.form.formType;
      this.showFormIntro = this.form.isShowFormIntro || false;
      this.formLayout = this.form.formLayout;

      this.enableSectionFieldEnumeration = parseInt(
        this.form.enableSectionFieldEnumeration
      );
    },
    updateExamUniqueAnswersPerAttempt() {
      this.form.formConfiguration.exam.examUniqueAnswersPerAttempt =
        this.examUniqueAnswersPerAttempt;
    },
    onShowFormClick(e) {
      this.showFormIntro = e;
      Object.assign(this.form, (this.form.isShowFormIntro = e));
    },
    updateExamPassMark() {
      this.form.formConfiguration.exam.examPassMark = parseInt(
        this.examPassMark
      );
    },
    updateExamRandomiseQuestions() {
      if (this.examRandomiseQuestions != 1) {
        this.form.formConfiguration.exam.examUniqueAnswersPerAttempt = 0;
        this.examUniqueAnswersPerAttempt = 0;
      }
      this.form.formConfiguration.exam.examRandomiseQuestions = parseInt(
        this.examRandomiseQuestions
      );
    },
    updateSequentialAnswers() {
      this.form.formConfiguration.exam.examSequentialAnswer = parseInt(
        this.examSequentialAnswer
      );
    },
    updateMaxExamAttempts() {
      this.form.formConfiguration.exam.examMaxAttempts = parseInt(
        this.maxExamAttempts
      );
    },
    updateExamTimeLimit() {
      this.form.formConfiguration.exam.examTimeLimit = parseInt(
        this.examTimeLimit
      );
    },
    updateFormEnumeration(e) {
      this.form.enableSectionFieldEnumeration = e;
    },
    updateFormType() {
      this.form.formType = this.formType;
    },
    updateFormLayout(e) {
      this.form.formLayout = e;
    },
    updateFormPassword() {
      this.form.formRequirePassword = this.formRequirePassword;
    },
    updateTranslationType() {
      this.form.formTranslationType = this.formTranslationType;
    },
    updateFormVersion() {
      this.form.formVersion = this.formVersion;
    },
    onSelectExam(e) {
      this.selectedExamForTraining = e.title;
      let linkedExam = {
        id: e.id || null,
        formId: this.form.formId,
        company_id: e.company_id || null,
        title: e.title || null,
        createdBy: e.created_by_id || null
      };
      Object.assign(this.form, { linkedExam });
    },
    onSelectTraining(e) {
      this.setSubTask(e);
      this.selectedTraining = e.title;
      let linkedTraining = {
        id: e.id || null,
        formId: this.form.formId,
        company_id: e.company_id || null,
        title: e.title || null,
        createdBy: e.created_by_id || null
      };
      Object.assign(this.form, { linkedTraining });
    },
    onExamUnlitedClick(e) {
      if (e) {
        this.maxExamAttempts = 0;
      } else {
        this.maxExamAttempts = 5;
      }
      this.updateMaxExamAttempts();
      Object.assign(this.form.formConfiguration.exam, {
        isUnlimitedAttempts: e
      });
    },
    onDisabledTranslation(e) {
      Object.assign(this.form.formConfiguration, {
        isDisabledTranslation: e
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-configuration {
  &__translation-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.checkbox {
  justify-content: flex-start;
}
</style>
