<template>
  <div class="sample-excel">
    <BaseLoader v-if="isLoading" />
    <div v-else>
      <BaseText
        text="List of available sample excel files"
        :size="typographySize.HEADING_FOUR"
        tag="h2"
      />
      <ul class="sample-excel--list">
        <li
          v-for="(excelFileObject, index) in excelDownloadList"
          :key="index"
          class="sample-excel--list-item"
        >
          <Link v-bind="makeLinkProps(excelFileObject)" />
          <InputErrorMessage :error="excelFileObject.error" />
          <hr />
        </li>
      </ul>
      <Modal
        v-if="isModalVisible"
        title="Operation feedback"
        data-test-id="sample-excel--modal"
        @close-modal="setModalVisibility"
      >
        <div class="sample-excel--modal-body">
          <BaseText :text="modalText" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import {
  actionName,
  typographySize,
  themes,
  iconSizes,
  infoMessages
} from "@/constants";
import { mapActions, mapState } from "vuex";
import { makeUUID } from "@/utils";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import Modal from "@/molecules/Modal/Modal";
import Link from "@/molecules/Link/Link";

export default {
  name: "DownloadableList",
  components: {
    InputErrorMessage,
    BaseText,
    BaseLoader,
    Modal,
    Link
  },
  data() {
    return {
      typographySize,
      themes,
      iconSizes,
      isModalVisible: false,
      modalText: "",
      excelDownloadList: [
        this.makeExcelDownloadObject({
          title: "Sample Excel File For ThirdParty Bulk Upload",
          fileName: "Data_Template.xlsx",
          filePath: "public/platform"
        }),
        this.makeExcelDownloadObject({
          title: "Sample Excel File For ThirdParty Bulk Update",
          fileName: "Update_Template.xlsx",
          filePath: "public/platform",
          isSentViaEmail: true
        })
      ]
    };
  },
  computed: {
    ...mapState({
      isLoadingSendEmailForThirdPartyUpdateExcelSheet: (state) =>
        state.thirdParty.isLoadingSendEmailForThirdPartyUpdateExcelSheet,
      isLoadingThirdPartyBulkUploadSampleFile: (state) =>
        state.thirdParty.isLoadingThirdPartyBulkUploadSampleFile
    }),
    isLoading() {
      return (
        this.isLoadingSendEmailForThirdPartyUpdateExcelSheet ||
        this.isLoadingThirdPartyBulkUploadSampleFile
      );
    }
  },
  methods: {
    makeUUID,
    ...mapActions({
      fetchThirdPartyBulkUploadSampleFile:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE,
      sendEmailForThirdPartyUpdateExcelSheet:
        actionName.THIRD_PARTY.SEND_EMAIL_FOR_THIRD_PARTY_UPDATE_EXCEL_SHEET
    }),
    makeLinkProps(excelFileObject) {
      return {
        textOptions: { text: excelFileObject.title, theme: themes.SECONDARY },
        iconOptions: {
          icon: "file-excel",
          size: iconSizes.LARGE,
          theme: themes.ETHIXBASE_PRIMARY_INVERSE
        },
        anchorOptions: {
          href: "#",
          onClick: (event) => {
            this.downloadSampleExcelFile(excelFileObject);
            event.preventDefault();
          }
        }
      };
    },
    resetErrorMessage(id) {
      this.setErrorMessage({ id });
    },
    setErrorMessage({ errorMessage = "", id } = {}) {
      const excelFileObject =
        this.excelDownloadList.find(({ id: ID }) => ID === id) || {};
      excelFileObject.error = errorMessage;
    },
    makeExcelDownloadObject({
      title = "",
      fileName = "",
      filePath = "",
      isSentViaEmail = false,
      error = ""
    } = {}) {
      return {
        id: this.makeUUID(),
        title,
        fileName,
        filePath,
        isSentViaEmail,
        error
      };
    },
    downloadSampleExcelFile(excelFileObject) {
      if (excelFileObject.isSentViaEmail) {
        this.sendEmailForThirdPartyUpdateExcelSheetWrapper(excelFileObject);
      } else {
        this.fetchThirdPartyBulkUploadSampleFileWrapper(excelFileObject);
      }
    },
    setModalVisibility(value = false) {
      this.isModalVisible = value;
    },
    sendEmailForThirdPartyUpdateExcelSheetSuccess({ numberOfEmails = 0 } = {}) {
      this.setModalText(numberOfEmails);
      this.setModalVisibility(true);
    },
    setModalText(numberOfEmails = 0) {
      this.modalText = infoMessages.downloadableList.numberOfEmails({
        numberOfEmails
      });
    },
    async sendEmailForThirdPartyUpdateExcelSheetWrapper({ title, id }) {
      try {
        this.resetErrorMessage(id);
        const { data } = await this.sendEmailForThirdPartyUpdateExcelSheet();
        this.sendEmailForThirdPartyUpdateExcelSheetSuccess(data);
      } catch {
        this.setErrorMessage({
          errorMessage: `Failed to initiate the process for ${title}`,
          id
        });
      }
    },
    async fetchThirdPartyBulkUploadSampleFileWrapper({
      fileName,
      filePath,
      title,
      id
    }) {
      try {
        this.resetErrorMessage(id);
        const { data } = await this.fetchThirdPartyBulkUploadSampleFile({
          fileName,
          filePath
        });
        this.downloadExcel({
          data,
          fileName
        });
      } catch {
        this.setErrorMessage({
          errorMessage: `Failed to download ${title}`,
          id
        });
      }
    },
    downloadExcel({ data, fileName }) {
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style scoped lang="scss">
.sample-excel {
  text-align: left;

  &--modal-body {
    padding: $spacing16;
  }

  &--list {
    list-style-type: none;
    margin: 0;
    padding: $spacing20 0 0 0;

    &-item {
      &-cta,
      &-link {
        vertical-align: middle;
        margin-right: $spacing8;
      }
    }
  }
}
</style>
