<template>
  <div>
    <!-- Modal for adding new item -->
    <!-- Currently not in use -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add New Item"
      v-model="showAddModal"
      scrollable
      size="md"
    >
      <b-overlay :show="isLoadingData">
        <div>
          <b-form-group label="Name of EDD Report Type :">
            <b-form-input
              placeholder="Enter Name of EDD Report Type"
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Turn around time :">
            <b-form-input
              type="number"
              v-model="form.tat"
              placeholder="Enter Turn around time"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Turn around time MAX :">
            <b-form-input
              type="number"
              v-model="form.tatMax"
              placeholder="Enter Tat max"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Cost (USD) :">
            <b-form-input
              type="number"
              v-model="form.cost"
              placeholder="Enter Cost (USD)"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="resetModal">Close</b-button>
        <b-button size="md" variant="outline-success" @click="addItem"
          >Add
        </b-button>
      </template>
    </b-modal>

    <div>
      <div class="drop-down">
        <div class="ml-auto my-2">
          <!-- Submit btn for saving configuration -->
          <b-btn variant="outline-success" @click="sumbitElements">
            Submit
          </b-btn>
        </div>
      </div>

      <b-overlay :show="isSubmiting">
        <b-row>
          <!-- EDD Report Type: -->
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            cols="3"
          >
            <h5 class="pt-1">EDD Report Type:</h5>
            <hr style="margin: 0px" />
            <draggable
              :list="listOfEDD"
              :v-model="listOfEDD"
              style="height: calc(100vh - 300px); overflow-y: auto"
            >
              <template #item="{ element }">
                <b-list-group>
                  <div style="display: flex; flex-direction: column">
                    <b-list-group-item
                      :style="{
                        backgroundColor:
                          selecteditem == element.name ? '#42dc98' : ''
                      }"
                    >
                      <Checkbox
                        class="float-left"
                        :modelValue="parseInt(element.client_packages_id) > 0"
                        :disabled="!element.client_packages_id"
                        @update:modelValue="deleteElements($event, element)"
                      />
                      <div @click="setSelectedItem(element)">
                        {{ element.name }}
                      </div>
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </template>
            </draggable>
          </b-col>
          <b-col cols="1"></b-col>

          <!-- All Scope of Research Options: -->
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            cols="4"
          >
            <h5 class="pt-1">All Scope of Research Options:</h5>
            <hr style="margin: 0px" />
            <draggable
              :list="allActions"
              :v-model="allActions"
              group="actions"
              style="height: calc(100vh - 300px); overflow-y: auto"
            >
              <template #item="{ element }">
                <b-list-group>
                  <b-list-group-item class="mb-1">{{
                    element.product_name
                  }}</b-list-group-item>
                </b-list-group>
              </template>
            </draggable>
          </b-col>

          <!-- Selected Scope of Research for Report Type: -->
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            cols="4"
          >
            <h5 class="pt-1">Selected Scope of Research for Report Type:</h5>
            <hr style="margin: 0px" />
            <draggable
              style="height: calc(100vh - 300px); overflow-y: auto"
              :list="actionsOccur"
              :v-model="actionsOccur"
              group="actions"
            >
              <template #item="{ element }">
                <b-list-group>
                  <b-list-group-item class="mb-1">{{
                    element.product_name
                  }}</b-list-group-item>
                </b-list-group>
              </template>
            </draggable>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Checkbox from "@/molecules/Checkbox/Checkbox";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "ProductsTab",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    draggable,
    Checkbox
  },
  data() {
    return {
      isSubmiting: false,
      isLoading: false,
      isLoadingData: false,
      allActions: [],
      actionsOccur: [],
      showAddModal: false,
      form: {
        name: null,
        tat: null,
        tatMax: null,
        cost: null
      },
      selecteditem: "",
      selectedItemArray: [],
      staticAllActions: [],
      companyId: null
    };
  },
  computed: {},
  mounted() {
    this.companyId = this.$route.params.id;
    this.getElements();
  },
  validations: {
    selectedItemArray: {
      client_packages_tat: {
        required
      },
      client_packages_tat_max: {
        required
      },
      client_packages_cost: {
        required
      }
    }
  },
  methods: {
    // get products
    async getElements() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/types",
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.staticAllActions = result.data;
          this.allActions = result.data;
          this.isSubmiting = false;
        }
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    },
    openAddModal() {
      this.showAddModal = true;
    },
    resetModal() {
      this.showAddModal = false;
      this.form = {};
    },

    // add product
    async addItem() {
      this.isLoadingData = true;
      let formData = {
        name: this.form.name,
        tat: `${this.form.tat}-${this.form.tatMax}`,
        cost: this.form.cost
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/packages/create",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          this.$emit("onAddNewItem", this.form);
          this.resetModal();
          this.isLoadingData = false;
        } else if (result.data.status == 0) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isLoadingData = false;
        }
      } catch (error) {
        //
        this.isLoadingData = false;
        return [error];
      }
    },

    // save products configuration
    async sumbitElements() {
      this.isSubmiting = true;
      let idArray = [];
      this.actionsOccur.map((data) => {
        idArray.push({
          id: data.id
        });
      });
      let formData = {
        client_id: this.companyId,
        package_id: this.selectedItemArray.id,
        tat: this.selectedItemArray.client_packages_tat,
        product_ids: idArray,
        tat_max: this.selectedItemArray.client_packages_tat_max,
        cost: this.selectedItemArray.client_packages_cost
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/packages/add/products",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          this.$bvToast.toast("Package has been added successfully", {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          // this.$emit( "onGetDropDownItems" );
          this.sendOrderedEddReportType();
          // this.isSubmiting = false;
        } else {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.sendOrderedEddReportType();
          this.isSubmiting = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        return [error];
      }
    },
    async sendOrderedEddReportType() {
      let idArray = [];
      this.listOfEDD.map((data) => {
        if (parseInt(data.client_packages_id) > 0) {
          idArray.push({
            id: data.id
          });
        }
      });
      let formData = {
        client_id: this.companyId,
        package_ids: idArray
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/set/order/packages",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          this.$emit("onGetDropDownItems");
          this.isSubmiting = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        return [error];
      }
    },

    // set selected product
    setSelectedItem(val) {
      this.selecteditem = val.name;
      this.selectedItemArray = val;
      this.getElementsByType();
    },

    // delete product
    async deleteElements(event, item) {
      if (!event) {
        this.isLoading = true;
        let formData = {
          client_packages_id: item.client_packages_id,
          client_id: this.companyId,
          package_id: item.id
        };
        try {
          let result = await HTTP(
            "post",
            "thirdparty/edd/client/packages/delete",
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200) {
            this.$bvToast.toast(result.data.message, {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.$emit("onGetDropDownItems");
            this.isLoading = false;
          }
        } catch (error) {
          //
          this.isLoading = false;
          return [error];
        }
      }
    },

    // get products by type
    async getElementsByType() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/client/packages/products/${this.companyId}/${this.selectedItemArray.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.actionsOccur = result.data;
          this.filterElements();
          this.isSubmiting = false;
        }
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    },
    filterElements() {
      let customFields = [];
      this.staticAllActions.map((data) => {
        let filterIndex = this.actionsOccur.findIndex((x) => x.id == data.id);
        if (filterIndex == -1) {
          customFields.push(data);
        }
      });
      this.allActions = customFields;
    }
  },
  props: {
    listOfEDD: Array
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
.drop-down {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
}
.dropdownFieldsContainer {
  display: flex;
}
</style>
