export const getters = {
  getUniqueAvailableProducts: (state) =>
    state.platformProductList.length
      ? state.availableProductList.filter(
          (availableProduct) =>
            !state.platformProductList.some(
              ({ courseId = "" } = {}) => courseId === availableProduct.courseId
            )
        )
      : state.availableProductList,

  getUniqueAvailableProductsOptions: (state, getters) =>
    getters.getUniqueAvailableProducts?.map(({ name, courseId, id }) => ({
      text: name,
      value: courseId,
      id
    }))
};
