import { actions } from "./product.actions.store";
import { mutations } from "./product.mutations.store";
import { getters } from "./product.getters.store";
import { state } from "./product.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
