<template>
  <div class="ml-5">
    <b-card no-body class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#" class="text-dark">
          <strong style="color: #3b3e66"
            >Action Settings for {{ companyName }}</strong
          >
        </b-navbar-brand>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <!-- Reload button -->
            <b-button
              variant="outline-info"
              size="sm"
              class="mr-4"
              @click="reloadTable"
            >
              <font-awesome-icon icon="sync" />
            </b-button>
            <!-- Edit button -->
            <b-btn
              v-if="showEditBtn"
              @click="onEditTable"
              :disabled="isLoading"
              size="sm"
              variant="outline-warning"
              class="ml-1"
              >Edit</b-btn
            >
            <div v-else>
              <b-btn
                @click="onCancelActionSettings"
                :disabled="isLoading"
                size="sm"
                variant="outline-danger"
                class="ml-1"
                >Cancel</b-btn
              >
              <b-btn
                @click="onShowSubmitModal"
                :disabled="isLoading"
                size="sm"
                variant="outline-success"
                class="ml-1"
                >Submit</b-btn
              >
            </div>
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <b-overlay :show="isLoading">
        <!-- Action setting table data -->
        <div v-if="isTableLoading">
          <vue-good-table
            :columns="tablecolumns"
            :rows="tablerows"
            :group-options="{
              enabled: true
            }"
            max-height="500px"
            :fixed-header="true"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action_max_volume'">
                <div class="d-flex" v-if="!isLoadingDisabled">
                  <div>
                    <b-form-group
                      v-if="props.row.actionKeyType == 'actionsOccur'"
                    >
                      <b-form-input
                        :disabled="disableAnticipatedVolume"
                        v-model="
                          actionArray.actionsOccur[props.index]
                            .action_max_volume
                        "
                        size="sm"
                        onkeydown="return event.keyCode !== 69"
                        type="number"
                        placeholder="Anticipated volume"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group v-else>
                      <b-form-input
                        :disabled="disableAnticipatedVolume"
                        v-model="
                          actionArray.extraActions[props.index]
                            .action_max_volume
                        "
                        size="sm"
                        onkeydown="return event.keyCode !== 69"
                        type="number"
                        placeholder="Anticipated volume"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div></div>
                </div>
              </span>
              <span
                v-else-if="props.column.field == 'enable_action_notifications'"
              >
                <Checkbox
                  v-model="
                    actionArray[props.row.actionKeyType][props.index]
                      .enable_action_notifications
                  "
                  label="Enable action notifications"
                  labelHidden
                  class="text-center"
                />
              </span>
              <span
                v-else-if="
                  props.column.field == 'action_notification_trigger_count'
                "
              >
                <div class="d-flex" v-if="!isLoadingDisabled">
                  <div>
                    <b-form-group
                      v-if="props.row.actionKeyType == 'actionsOccur'"
                    >
                      <b-form-input
                        :disabled="disableNotificationCount"
                        v-model="
                          actionArray.actionsOccur[props.index]
                            .action_notification_trigger_count
                        "
                        size="sm"
                        onkeydown="return event.keyCode !== 69"
                        type="number"
                        placeholder="Notification percentage"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group v-else>
                      <b-form-input
                        :disabled="disableNotificationCount"
                        v-model="
                          actionArray.extraActions[props.index]
                            .action_notification_trigger_count
                        "
                        size="sm"
                        onkeydown="return event.keyCode !== 69"
                        type="number"
                        placeholder="Notification percentage"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div></div>
                </div>
              </span>
            </template>
          </vue-good-table>
        </div>
      </b-overlay>
    </b-card>
    <b-modal
      v-model="showSubmitModal"
      no-close-on-backdrop
      no-close-on-esc
      @ok="onSubmitActionsData"
      @cancel="onCloseModal"
      title="Submit Settings"
    >
      <p>Are you sure you want to submit these settings</p>
    </b-modal>
  </div>
</template>

<script>
import { allActions } from "@/constants";
import { EventBus } from "@/event-bus.js";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import defaultActionJson from "@/utils/defaultAction.json";
import { Helper } from "@/helpers";
import { mapState } from "vuex";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "ActionSettings",
  components: {
    VueGoodTable,
    Checkbox
  },
  data() {
    return {
      showSubmitModal: false,
      showEditBtn: true,
      disableAnticipatedVolume: true,
      disableNotificationCount: true,
      tablecolumns: [
        { field: "title", label: "Action", sortable: false },
        { field: "count", label: "Count", sortable: false },
        {
          field: "action_max_volume",
          label: "Anticipated volume",
          sortable: false
        },
        {
          field: "enable_action_notifications",
          label: "Enable Notifications",
          sortable: false
        },
        {
          field: "action_notification_trigger_count",
          label: "Notification trigger Percentage",
          sortable: false
        }
      ],
      anticipatedVolumeDisabaled: [],
      anticipatedVolume: [],
      editVolume: [],
      submitVolume: [],
      allActions,
      companyName: null,
      isLoading: true,
      isTableLoading: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "title", label: "Action", sortable: true },
        { key: "volume", label: "Anticipated volume", sortable: true },
        {
          key: "enable_notifications",
          label: "Enable Notifications",
          sortable: true
        },
        {
          key: "notification_trigger_count",
          label: "Notification trigger percentage (%)",
          sortable: true
        }
      ],
      items: [],
      isLoadingDisabled: false,
      allActionsLength: null,
      actionsData: null,
      actionOccurData: null,
      extraActionsData: null,
      tempActionOccurData: null,
      tempExtraActionsData: null,
      tablerows: [],
      actionArray: {}
    };
  },
  computed: {
    ...mapState({
      companyDetails: (state) => state.company.companyDetails,
      userId: (state) => state.user.userId
    })
  },
  created() {
    EventBus.on("callgetAdditionalVouchers", () => {
      this.getAdditionalVouchers();
    });
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
  },
  methods: {
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "action-details-table");
    },
    getAdditionalVouchers() {
      this.isTableLoading = false;
      this.actionsData = this.companyDetails.additionalData;
      this.actionOccurData =
        this.actionsData?.actionsOccur || defaultActionJson.actionsOccur;
      this.extraActionsData =
        this.actionsData?.extraActions || defaultActionJson.extraActions;
      this.actionArray = {};
      this.actionArray.actionsOccur =
        this.actionsData?.actionsOccur || this.actionOccurData;
      this.actionArray.extraActions =
        this.actionsData?.extraActions || this.extraActionsData;
      if (this.actionArray.actionsOccur) {
        this.actionArray.actionsOccur.map((key) => {
          key.action_max_volume = key?.action_max_volume || null;
          key.enable_action_notifications =
            key?.enable_action_notifications || null;
          key.action_notification_trigger_count =
            key?.action_notification_trigger_count || null;
          key.actionKeyType = "actionsOccur";
        });
      }
      if (this.actionArray.extraActions) {
        this.actionArray.extraActions.map((key) => {
          key.action_max_volume = key?.action_max_volume || null;
          key.enable_action_notifications =
            key?.enable_action_notifications || null;
          key.action_notification_trigger_count =
            key?.action_notification_trigger_count || null;
          key.actionKeyType = "extraActions";
        });
      }
      this.getAccountDetailsData();
    },
    async getAccountDetailsData() {
      try {
        let formData = {
          company_id: this.$route.params.id,
          userId: this.userId
        };
        let result = await HTTP(
          "post",
          "thirdparty/account/actions/count",
          formData,
          makeAuthorizationHeader()
        );

        Object.keys(this.actionArray).forEach((key) => {
          if (this.actionArray && this.actionArray.actionsOccur) {
            for (let action of result.data) {
              for (let actionOccur of this.actionArray.actionsOccur) {
                if (action.btnText == actionOccur.btnText) {
                  actionOccur.count = action.count;
                }
              }
            }
          }
          if (this.actionArray && this.actionArray.extraActions) {
            for (let action of result.data) {
              for (let actionExtra of this.actionArray.extraActions) {
                if (action.btnText == actionExtra.btnText) {
                  actionExtra.count = action.count;
                }
              }
            }
          }

          this.tablerows.push({
            mode: "span",
            label: this.$t(key),
            html: false,
            children: this.actionArray[key]
          });
          this.isLoading = false;
          this.isTableLoading = true;
        });
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    toggleDisableVolume(props, clickType) {
      this.isLoadingDisabled = true;
      if (clickType == "volumeType") {
        this.actionArray[props.row.actionKeyType][
          props.index
        ].disabaledAnticipatedVolume =
          !this.actionArray[props.row.actionKeyType][props.index]
            .disabaledAnticipatedVolume;
      }
      if (clickType == "notificationType") {
        this.actionArray[props.row.actionKeyType][
          props.index
        ].disabaleNotificationCount =
          !this.actionArray[props.row.actionKeyType][props.index]
            .disabaleNotificationCount;
      }
      setTimeout(() => {
        this.isLoadingDisabled = false;
      }, 20);

      // if ( clickType == "volumeType" ) {
      //   this.allActions[data.index].disabaledAnticipatedVolume = !this
      //     .allActions[data.index].disabaledAnticipatedVolume;
      // }
      // if ( clickType == "notificationType" ) {
      //   this.allActions[data.index].disabaleNotificationCount = !this
      //     .allActions[data.index].disabaleNotificationCount;
      // }
      // setTimeout( () => {
      //   this.isLoadingDisabled = false;
      // }, 20 );
    },
    onShowSubmitModal() {
      this.showSubmitModal = true;
    },
    onCloseModal() {
      this.showSubmitModal = false;
    },
    onEditTable() {
      this.showEditBtn = false;
      this.disableAnticipatedVolume = false;
      this.disableNotificationCount = false;
    },
    onCancelActionSettings() {
      this.disableAnticipatedVolume = true;
      this.disableNotificationCount = true;
      this.showEditBtn = true;
    },
    async onSubmitActionsData() {
      this.isLoading = true;
      try {
        let formData = {
          company_id: this.$route.params.id,
          actionsOccur: this.actionArray.actionsOccur
            ? this.actionArray.actionsOccur
            : [],
          extraActions: this.actionArray.extraActions
            ? this.actionArray.extraActions
            : []
        };
        await HTTP(
          "post",
          "thirdparty/update-tp/dynamic-actions",
          formData,
          makeAuthorizationHeader()
        );
        this.disableAnticipatedVolume = true;
        this.disableNotificationCount = true;
        this.showEditBtn = true;
        this.isLoading = false;
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    getTableData() {
      this.allActions.map((key) => {
        key.disabaledAnticipatedVolume = true;
        key.anticipatedVolumeValue = null;
        //
        key.disabaleNotificationCount = true;
        key.notificationCountValue = null;
      });
      return this.allActions;
    },
    onClickVolumeBtn(data, index) {
      this.anticipatedVolumeDisabaled[index] = false;

      this.editVolume[index] = false;
      this.submitVolume[index] = true;
    }
  }
};
</script>

<style></style>
