<template>
  <div>
    <b-container fluid class="mt-3 pl-0 pr-0">
      <AppLoader v-if="isLoading" />
      <b-container fluid class="pl-0 pr-0" v-else>
        <b-row>
          <div :class="showNavigator ? 'slide-after' : 'slide'">
            <ComponentListView
              v-if="showNavigator"
              :isFormPublished="isFormPublished"
            />
          </div>

          <b-col
            :cols="
              showNavigator && !isFormPublished
                ? 6
                : showNavigator && isFormPublished
                ? 9
                : !showNavigator && !isFormPublished
                ? 9
                : 12
            "
            id="builder"
          >
            <div class="float-right"></div>

            <div class="clearfix" />
            <div ref="dropTab" v-on:scroll="matchHeight">
              <b-button
                class="float-left mr-3 btn-sm"
                variant="outline-primary"
                @click="showNavigator = !showNavigator"
                v-if="form1.formType == 1 || form1.formType == 5"
              >
                <font-awesome-icon
                  :icon="
                    showNavigator ? 'caret-square-left' : 'caret-square-right'
                  "
                />
              </b-button>
              <b-button class="float-right mr-3 btn-sm" @click="onCollapseAll">
                <font-awesome-icon :icon="expand ? 'plus' : 'minus'" />
                {{ expand ? "Expand All" : "Collapse All" }}
              </b-button>
              <div class="clearfix"></div>
              <br />

              <b-col
                v-if="
                  form1.formType == 1 ||
                  form1.formType == 6 ||
                  form1.formType == 5 ||
                  form1.formType == 10
                "
              >
                <b-button
                  v-if="!isFormPublished"
                  variant="outline-primary"
                  class="float-right ml-2"
                  @click="addSection(form1.formSection.length, 'section')"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>

                <b-dropdown
                  v-if="
                    form1.formType == 1 ||
                    form1.formType == 5 ||
                    form1.formType == 10
                  "
                  variant="outline-warning"
                  class="float-right ml-2"
                >
                  <b-dropdown-item-button
                    v-for="(sectionData, index) in form1.formSection"
                    :key="sectionData.id"
                    @click="setTab(index, form1.formSection.length, $event)"
                  >
                    {{ sectionData.sectionTitle[selectedLanguage] }}
                    <b-badge variant="warning">{{
                      sectionData.formElements.length
                    }}</b-badge>
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-col>

              <b-tabs
                ref="navTabs"
                id="navTabs"
                v-model="tabIndex"
                nav-class="nav-tabs-drop"
                content-class="mt-3"
              >
                <b-tab
                  v-for="(sectionData, sIndex) in form1.formSection"
                  :key="sectionData.id"
                  class="pl-3 pr-3 scrollHeight"
                >
                  <template v-slot:title>
                    <font-awesome-icon
                      class="mr-2 cogs"
                      icon="cog"
                      @click.native="openSettings(sectionData)"
                    />
                    <font-awesome-icon
                      class="mr-2"
                      icon="copy"
                      @click.native="onCloneSection(sectionData)"
                      v-if="
                        form1.formType == 1 ||
                        (form1.formType == 5 && !isFormPublished)
                      "
                    />
                    <strong>{{
                      sectionData.sectionTitle[selectedLanguage]
                    }}</strong>
                    <font-awesome-icon
                      class="ml-2 trash"
                      icon="trash"
                      @click.native="$bvModal.show(sectionData.id)"
                      v-if="
                        form1.formType == 1 ||
                        form1.formType == 5 ||
                        form1.formType == 6 ||
                        (form1.formType == 10 && !isFormPublished)
                      "
                    />
                  </template>

                  <p v-if="!sectionData.formElements.length">
                    Drag and drop input controls from the right panel
                  </p>

                  <draggable
                    v-model="sectionData.formElements"
                    group="sections"
                    class="list-group"
                    ghost-class="ghost"
                    :itemKey="getSectionId"
                  >
                    <template #item="{ element, index }">
                      <ElementsContainer
                        :fieldData="element"
                        :isPreview="isPreview"
                        :sectionIndex="sIndex"
                        :fieldIndex="index"
                        :inputType="element.inputType"
                        class="orderable"
                      />
                    </template>
                  </draggable>

                  <!--delete confirmation modal -->
                  <b-modal
                    :id="sectionData.id"
                    title="Alert!"
                    @ok="onDeleteSection(sIndex)"
                  >
                    <p class="my-4">Confirm delete?</p>
                  </b-modal>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>

          <b-col v-if="!isFormPublished">
            <b-tabs content-class="mt-3" pills>
              <b-tab title="Inputs" active>
                <InputComponents />
              </b-tab>

              <b-tab title="Html">
                <HtmlFormComponents />
              </b-tab>
              <b-tab title="Workflow">
                <WorkflowComponents />
              </b-tab>
              <b-tab title="Charts">
                <ChartsComponents />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
      <!-- show advance field options -->
      <b-modal
        ok-only
        ok-title="Close"
        size="lg"
        v-model="showSettings"
        scrollable
        title="Section Settings"
      >
        <SectionSettings v-if="showSettings" :sectionData="selectedSection" />
      </b-modal>
      <b-navbar toggleable="lg" type="dark" variant="dark" class="m-0">
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button
                size="sm"
                variant="light"
                class="my-2 my-sm-0 mr-2"
                type="button"
                to="/form/intro"
                >Back</b-button
              >
              <b-button
                size="sm"
                variant="light"
                class="my-2 my-sm-0"
                type="button"
                to="/preview"
                >Preview</b-button
              >
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import uid from "uid";
import draggable from "vuedraggable";
import ElementsContainer from "@/components/templatesConstants/elementIndex";
import InputComponents from "@/components/InputComponets";
import WorkflowComponents from "@/components/WorkflowComponents";
import HtmlFormComponents from "@/components/HtmlFormComponents";
import ChartsComponents from "@/components/chartsComponent";
import ComponentListView from "@/components/componentsListView";
import AppLoader from "@/components/Loader/Loader";
import SectionSettings from "@/components/sectionSettingsCompenent";
import { Helper } from "@/helpers";
import { EventBus } from "@/event-bus";
import { localStorageItem, formStatus } from "@/constants";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "FormBuilderContainer",
  components: {
    InputComponents,
    WorkflowComponents,
    HtmlFormComponents,
    ChartsComponents,
    SectionSettings,
    ElementsContainer,
    ComponentListView,
    draggable,
    AppLoader
  },
  data() {
    return {
      tabIndex: 1,
      showNavigator: false,
      expand: false,
      formId: null,
      showSettings: false,
      selectedSection: null,
      collapseAll: false,
      sectionLength: 0,
      tabHeight: 0,
      isFormPublished: false,
      styleObject: {
        height: "calc(100vh - 150px)",
        "overflow-y": "auto"
      }
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage", "isLoading"])
  },
  mounted() {
    this.isFormPublished =
      parseInt(localStorage.getItem(localStorageItem.FORM_STATUS)) ===
      formStatus.PUBLISHED;
  },
  async beforeUnmount() {
    await EventBus.emit("onAutoSaveJsonForm", true);
  },
  methods: {
    ...mapMutations(["ADD_SECTION", "TOGGLE_ALL_COLLAPSE"]),
    ...mapActions(["removeSection", "cloneSection"]),
    setTab(index) {
      this.tabIndex = index;
      let content = document.querySelector(".nav-tabs-drop");
      let tabWidth = 150;
      let left = tabWidth * index;
      content.scrollTo({
        left,
        behavior: "smooth"
      });
    },
    matchHeight() {
      this.tabHeight = this.$refs.dropTab.scrollTop;
    },
    openSettings(sectionData) {
      this.selectedSection = sectionData;
      this.showSettings = !this.showSettings;
    },
    onCollapseAll() {
      this.expand = !this.expand;
      this.collapseAll = !this.collapseAll;
      this.TOGGLE_ALL_COLLAPSE(this.collapseAll);
    },
    onDeleteSection(sIndex) {
      this.removeSection(sIndex);
      this.$bvModal.hide("modal-1");
      Helper.makeToast(this.$bvToast, "success", "Section deleted");
    },
    addSection(length, type) {
      if (this.form1.formType == 6 && length > 0) {
        alert("you cannot add section in this form");
        return false;
      }
      let id = uid();
      let newSection;
      let title;
      this.sectionLength = length;
      switch (type) {
        case "section":
        default:
          title = `Section ${length + 1} Title`;
          newSection = {
            type: "section",
            id,
            index: length,
            name: "",
            isInValid: null,
            sectionStyle: {
              backgroundColor: null,
              backgroundImage: null,
              backgroundSize: null,
              backgroundRepeat: null,
              backgroundPosition: null,
              height: null,
              width: null,
              opacity: 1,
              padding: {
                top: null,
                right: null,
                bottom: null,
                left: null
              },
              margin: {
                top: null,
                right: null,
                bottom: null,
                left: null
              },
              border: {
                top: null,
                right: null,
                bottom: null,
                left: null
              }
            },
            advance: { hideSectionTitle: false },
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: []
          };
          break;
      }
      this.ADD_SECTION(newSection);
      this.setTab(
        this.form1.formSection.length - 1,
        this.form1.formSection.length
      );
    },
    onCloneSection(section) {
      this.cloneSection(section);
    },
    getSectionId(section) {
      return section?.id;
    }
  },
  props: {
    isPreview: Boolean
  }
};
</script>

<style lang="scss">
.main-container {
  height: 100vh;
}
.ghost {
  opacity: 0.5;
  background: $lavender-blue;
  cursor: move;
}
.orderable {
  cursor: move;
}
.selected-choosen {
  cursor: move;
}
.cogs:hover {
  color: $blue-violet;
}
.trash:hover {
  color: $spanish-red;
}
.nav-tabs-drop {
  max-width: calc(100% - 100px);
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
}
@keyframes shake {
  0% {
    transform: rotate(50deg);
  }

  50% {
    transform: rotate(-50deg);
  }

  100% {
    transform: rotate(50deg);
  }
}

@keyframes spin {
  to {
    transform: rotateZ(360deg);
  }
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}
.scrollHeight {
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.slide {
  width: 0;
  transition: width 0.1s;
}
.slide-after {
  width: 22%;
  transition: width 0.5s;
}
</style>
