<template>
  <div class="principals">
    <BaseInfoBox
      v-if="isDnbDisabled"
      :text="infoMessages.thirdParty.enablePremiumCorporateData"
      data-test-id="principals__disable-text--dnb-disabled"
      :icon-options="defaultIcons.INFO"
      :theme="themes.INFO"
    />
    <div v-else>
      <BaseInfoBox
        data-test-id="principals__disable-text--dnb-not-verified"
        v-if="!isDnbVerified"
        :text="infoMessages.thirdParty.addPremiumCorporateData"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <div class="principals__row" data-test-id="principals__row">
        <div class="principals__table">
          <StickyTable
            caption="Principals"
            :table-headers="makeSeniorPrincipalsHeaders"
            :table-rows="makeSeniorPrincipalsRows"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  typographySize,
  seniorPrincipalsKeys,
  seniorPrincipalsTableHeaders,
  defaultIcons,
  themes,
  infoMessages
} from "@/constants";

export default {
  name: "Principals",
  components: {
    StickyTable,
    BaseInfoBox
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    },
    thirdPartyData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      typographySize,
      defaultIcons,
      themes,
      infoMessages
    };
  },
  computed: {
    makeSeniorPrincipalsListForTable() {
      let principalsData = [
        ...(this.premiumCorporateData?.mostSeniorPrincipals || []),
        ...(this.premiumCorporateData?.currentPrincipals || [])
      ];
      return principalsData?.length
        ? principalsData.map(
            ({ jobTitles, namePrefix, fullName, nameSuffix, gender }) => ({
              [seniorPrincipalsKeys.TITLE]: jobTitles?.[0]?.title,
              [seniorPrincipalsKeys.NAME_PREFIX]: namePrefix,
              [seniorPrincipalsKeys.FULL_NAME]: fullName,
              [seniorPrincipalsKeys.NAME_SUFFIX]: nameSuffix,
              [seniorPrincipalsKeys.GENDER]: gender,
              [seniorPrincipalsKeys.SELECTED]: ""
            })
          )
        : [];
    },
    makeSeniorPrincipalsHeaders() {
      return Object.keys(seniorPrincipalsTableHeaders).length
        ? Object.keys(seniorPrincipalsTableHeaders).map((key) => ({
            value: key,
            component: BaseText,
            componentOptions: {
              tag: "span",
              text: seniorPrincipalsTableHeaders[key],
              size: typographySize.BODY_TEXT_BOLD,
              styles: {
                margin: "0"
              }
            },
            styles: {
              padding: "5px"
            }
          }))
        : [];
    },
    makeSeniorPrincipalsRows() {
      return this.makeSeniorPrincipalsListForTable.length
        ? this.makeSeniorPrincipalsListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              if (actionKey === seniorPrincipalsKeys.SELECTED) {
                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: this.checkAssociateExist(action.fullName)
                      ? "Yes"
                      : "No",
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              } else {
                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: action[actionKey],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }

              return acc;
            }, {})
          )
        : [];
    }
  },
  methods: {
    checkAssociateExist(fullName) {
      return this.thirdPartyData.srch_tpi_assos.find(
        (associate) => associate.asso_name === fullName
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.principals {
  &__disable-text {
    text-align: center;
  }

  &__table {
    margin-top: $spacing8;
  }
}
</style>
