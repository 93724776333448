<template>
  <b-container fluid class="p-2 layout-container">
    <AppLoader v-if="$store.state.isLoading" small />
    <b-row v-else>
      <b-col cols="6">
        <h2>
          {{
            form.formTitle
              ? form.formTitle.english
              : "Form title has not been set"
          }}
        </h2>
      </b-col>
      <b-col cols="2"> </b-col>
      <b-col cols="2" class="bg-red">
        <p style="font-size: 12px; color: grey" v-if="lastUpdatedTime">
          Last Updated: {{ lastUpdatedTime }}
        </p>
      </b-col>
      <b-col cols="2">
        <b-button
          :disabled="isLoading || isLoadingLocal"
          @click="onSaveJsonData()"
          variant="outline-success"
          class="mr-1"
          size="sm"
        >
          <span v-if="isLoading || isLoadingLocal">
            <b-spinner small type="grow"></b-spinner>Saving...
          </span>
          <span v-else>Save Form</span>
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          v-if="isPublished != 1"
          @click="showModalFormPublish = true"
          >Publish</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          v-else
          @click="onShowUnpublishFormModal"
          >Unpublish</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link
              to="/form/configuration"
              :class="
                currentRoute === 'config' ? 'nav-link active' : 'nav-link'
              "
              >Configuration</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/form/details"
              :class="
                currentRoute === 'details' ? 'nav-link active' : 'nav-link'
              "
              >Details</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/form/intro"
              :class="currentRoute === 'intro' ? 'nav-link active' : 'nav-link'"
              >Intro</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/form/builder"
              :class="
                currentRoute === 'builder' ? 'nav-link active' : 'nav-link'
              "
              >Builder</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/form/json"
              :class="currentRoute === 'json' ? 'nav-link active' : 'nav-link'"
              >JSON</router-link
            >
          </li>
        </ul>
      </b-col>
    </b-row>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <b-modal
      id="modal-2"
      v-model="showModalFormPublish"
      modal-header-close="No"
      ok-title="Publish"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      title="Alert.!"
      @ok="publishForm"
      @hidden="showModalFormPublish = false"
    >
      <p>Are you sure you want to publish this form?</p>
      <strong>Note:</strong>Once this form published, you may not be able to
      add, edit or delete any field or section. Only settings can be updated.
      <p></p>
    </b-modal>
    <!-- UNPUBLISH FORM MODAL -->
    <b-modal
      id="bv-modal-unpublishFormModal"
      size="md"
      title="Unpublish Form"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      v-model="showUnpublishModal"
    >
      Are you sure you want to unpublish this form?
      <template v-slot:modal-footer>
        <b-button
          :disabled="isSubmitting"
          @click="onCloseUnpublishModal"
          class="mx-1"
          size="sm"
          variant="outline-danger"
          >Close</b-button
        >
        <b-button
          :disabled="isSubmitting"
          class="mx-1"
          @click="onSubmitUnpublishModal"
          size="sm"
          variant="outline-success"
          ><span v-if="isSubmitting"><b-spinner small></b-spinner></span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
    <AppLoaderModal
      isMulti
      content="Processing your request..."
      content2="Building your form..."
      content3="Publishing your form..."
    />
  </b-container>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import _ from "lodash";
import { Helper } from "@/helpers";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { EventBus } from "@/event-bus.js";
import AppLoaderModal from "@/components/Loader/LoaderModal";
import AppLoader from "@/components/Loader/Loader";
import uid from "uid";
import moment from "moment";
import { urls } from "@/constants";

export default {
  name: "FormPage",
  components: {
    AppLoaderModal,
    AppLoader
  },
  data() {
    return {
      showModalFormPublish: false,
      isPublished: 0,
      isAutoUpdate: 0,
      activeTab: null,
      isBuilder: false,
      pushsing: null,
      lastUpdateInterval: null,
      isLoadingLocal: false,
      lastUpdatedTime: null,
      currentRoute: this.$route.name,
      showUnpublishModal: false,
      isSubmitting: false,
      reRenderForm: true
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      isLoading: "isLoading"
    }),
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  created() {
    this.pushUpdate();
  },
  async beforeCreate() {
    let formId = await localStorage.getItem("activeFormId");
    if (!this.form.formId) {
      this.getForm(formId);
    }

    await EventBus.on("onAutoSaveJsonForm", () => {});
  },
  beforeRouteLeave(_, __, next) {
    this.onResetLocalData();
    this.lockUnlockForm(0);
    next();
  },
  watch: {
    currentRoute() {},
    $route() {
      this.currentRoute = this.$route.name;
    }
  },
  methods: {
    ...mapMutations(["ADD_SECTION", "UPDATE_FORM", "ADD_TEMP_FORM"]),
    ...mapActions(["removeSection", "cloneSection"]),
    onResetLocalData() {
      localStorage.removeItem("taskId");
      localStorage.removeItem("examAttemptsToDate");
      localStorage.removeItem("activeTaskStatus");
      localStorage.removeItem("activeFormSchema");
      localStorage.removeItem("examAttemptsToDate");
      localStorage.removeItem("examAttemptId");
      localStorage.removeItem("tempForm");
      localStorage.removeItem("activeFormId");
      localStorage.removeItem("userConsent");
      localStorage.removeItem("clientId");
    },
    onShowUnpublishFormModal() {
      this.showUnpublishModal = true;
    },
    onCloseUnpublishModal() {
      this.showUnpublishModal = false;
    },
    async onSubmitUnpublishModal() {
      this.isSubmitting = true;
      let activeFormId = localStorage.getItem("activeFormId");
      try {
        await HTTP(
          "get",
          `v1/formsunlock/${activeFormId}`,
          null,
          makeAuthorizationHeader()
        );
        this.isSubmitting = false;
        this.$bvToast.toast("Form Unlock Successfully", {
          variant: "success",
          autoHideDelay: 2000,
          appendToast: false
        });
        this.onCloseUnpublishModal();
        this.$router.replace("/templates");
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    async lockUnlockForm(status) {
      let activeFormId = localStorage.getItem("activeFormId")
        ? localStorage.getItem("activeFormId")
        : this.$store.state.form1.formId;
      try {
        await HTTP(
          "put",
          `v1/form/lockstatus/${activeFormId}`,
          {
            userId: this.userId,
            lockStatus: status
          },
          makeAuthorizationHeader(),
          null,
          true
        );
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    clearFields() {
      this.$store.state.form1.formSection.map((sec) => {
        sec.formElements.map((ee) => {
          if (ee.inputType == "signature") {
            ee.data = {
              signatureValue: null,
              nameValue: null,
              dateValue: null
            };
          }
          ee.value = null;
          ee.selected = null;
        });
      });
    },
    async publishForm() {
      this.onSaveJsonData(true);
      this.$store.state.isLoadingMulti = true;
      let formId = await localStorage.getItem("activeFormId");
      if (formId) {
        let result = await HTTP(
          "put",
          `v1/form/publish/${formId}`,
          null,
          makeAuthorizationHeader()
        );
        let self = this;
        setTimeout(() => {
          self.$store.state.isLoadingMulti = false;
          if (result.data.success == 1) {
            Helper.makeToast(self.$bvToast, "success", "Form Publish Success");
            self.$router.replace(urls.ONBOARDING_TEMPLATES);
          } else {
            Helper.makeToast(
              self.$bvToast,
              "danger",
              "Form Publish Fail, please try again.."
            );
          }
        }, 9000);
      }
    },
    pushUpdate() {
      if (this.isAutoUpdate == 1) {
        let formId = localStorage.getItem("activeFormId");
        if (this.$route.name != "templates" && formId) {
          this.pushsing = setInterval(() => {
            let compareForms = _.isEqual(this.form, this.$store.state.tempForm);
            if (!compareForms) {
              this.onSaveJsonData();
            }
          }, 180000);
        }
      }
    },
    async getForm(formId) {
      this.$store.state.isLoading = true;
      let result = await HTTP(
        "get",
        `v1/form/${formId}`,
        null,
        makeAuthorizationHeader(),
        null,
        true
      );
      if (result.data.form && result.data.form.schema) {
        this.isPublished = JSON.parse(result.data.form.is_active);
        this.isAutoUpdate = result.data.form.is_auto_update
          ? JSON.parse(result.data.form.is_auto_update)
          : 1;
        let form = JSON.parse(result.data.form.schema);
        let cloneForm = _.cloneDeep(form);
        this.ADD_TEMP_FORM(cloneForm);
        this.updateFormToStore(form);
        this.$store.state.isLoading = false;
        this.grtPdfForms(form.clientId);
      }
    },
    async grtPdfForms(clientId) {
      let company_id = clientId;
      let form_type = 6;
      let url = `v1/getforms/${company_id}/${form_type}`;
      let result = await HTTP(
        "get",
        url,
        null,
        makeAuthorizationHeader(),
        null,
        false
      );
      this.$store.state.pdfForms = result.data.forms;
    },
    updateFormToStore(form) {
      this.UPDATE_FORM(form);
      EventBus.emit("getPdsForms", "e");
      this.$store.state.isLoading = false;
    },
    addSection(length, type) {
      let id = uid();
      let newSection;
      let title;
      this.sectionLength = length;
      switch (type) {
        case "directors-section":
          title = `Directors section ${length + 1} Title`;
          newSection = {
            type: "directors",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "address",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelAddress1: {
                      english: "Address1"
                    },
                    labelAddress2: {
                      english: "Address2"
                    },
                    labelCity: {
                      english: "City"
                    },
                    labelState: {
                      english: "State"
                    },
                    labelZip: {
                      english: "Zip"
                    },
                    selectedState: null,
                    optionsState: [
                      { value: "", text: "State 1" },
                      { value: "", text: "State 2" }
                    ],
                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "address-section":
          title = `Address section ${length + 1} Title`;
          newSection = {
            type: "address",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "address",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelAddress1: {
                      english: "Address1"
                    },
                    labelAddress2: {
                      english: "Address2"
                    },
                    labelCity: {
                      english: "City"
                    },
                    labelState: {
                      english: "State"
                    },
                    labelZip: {
                      english: "Zip"
                    },
                    selectedState: null,
                    optionsState: [
                      { value: "", text: "State 1" },
                      { value: "", text: "State 2" }
                    ],
                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "personal-details-section":
          title = `Personal Details section ${length + 1} Title`;
          newSection = {
            type: "personal-details",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "personal-details",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelTitle: {
                      english: "Title"
                    },
                    selectedTitle: null,
                    optionsTitle: [
                      { value: "1", text: "Mr" },
                      { value: "2", text: "Mrs" },
                      { value: "3", text: "Miss" },
                      { value: "4", text: "Ms" },
                      { value: "5", text: "Dr" },
                      { value: "6", text: "Sir" },
                      { value: "7", text: "Madam" },
                      { value: "8", text: "Lord" },
                      { value: "9", text: "Dame" }
                    ],
                    labelForeName: {
                      english: "Forename"
                    },
                    labelMiddelNames: {
                      english: "Middle name(s)"
                    },
                    labelLastName: {
                      english: "Last Name"
                    },
                    labelKnownAs: {
                      english: "Known As"
                    },
                    labelDateOfBirth: {
                      english: "Date of birth"
                    },
                    labelMaritalStatus: {
                      english: "Marital Status"
                    },
                    selectedMaritalStatus: null,
                    optionsMaritalStatus: [
                      { value: "1", text: "Single" },
                      { value: "2", text: "Married" },
                      { value: "3", text: "Separated" },
                      { value: "4", text: "Divorced" },
                      { value: "5", text: "Other" }
                    ],
                    labelDescribeBelow: {
                      english: "If other please describe below"
                    },
                    labelMobileContactNumber: {
                      english: "Mobile contact number"
                    },
                    labelPersonalEmailAddress: {
                      english: "Personal email address"
                    },
                    labelLinkedInProfile: {
                      english: "LinkedIn Profile"
                    },

                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "references-section":
          title = `References section ${length + 1} Title`;
          newSection = {
            type: "references",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "references",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelRefereeName: {
                      english: "Referee name"
                    },
                    labelRefereePositionRole: {
                      english: "Referee Position/Role"
                    },
                    labelRefereeCompany: {
                      english: "Referee Company"
                    },
                    labelRefereeEmail: {
                      english: "Referee Email"
                    },
                    labelRefereePhone: {
                      english: "Referee Phone"
                    },
                    labelRefereeWebsite: {
                      english: "Referee website"
                    },
                    labelNatureOfRelationship: {
                      english: "Nature of relationship"
                    },
                    labelLengthOfRelation: {
                      english: "Length of relation"
                    },

                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "beneficial-owners-section":
          title = `Beneficial Owners section ${length + 1} Title`;
          newSection = {
            type: "beneficial-owners",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "beneficial-owners",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelEntityName: {
                      english: "Entity name"
                    },
                    labelCompanyRegistration: {
                      english: "Company registration"
                    },
                    labelPercentageOwned: {
                      english: "Percentage owned (number)"
                    },
                    labelCountryOfRegistration: {
                      english: "Country of registration (country select)"
                    },

                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "share-holder-beneficial-owners":
          title = `Share holder Beneficial Owners section ${length + 1} Title`;
          newSection = {
            type: "share-holder-beneficial-owners",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: [
              {
                sectionIndex: length.toString(),
                inputType: "share-holder-beneficial-owners",
                id: uid(),
                name: "",
                label: {
                  english: "Select english Title"
                },
                fields: [
                  {
                    _id: uid(),
                    name: "",
                    value: "",
                    size: "",
                    labelIndividualName: {
                      english: "Individual name"
                    },
                    labelName: {
                      english: "Name (enter as per passport or ID card)"
                    },
                    labelPercentageOwned: {
                      english: "Percentage Owned"
                    },

                    validationMessage: {
                      english: "This field is required"
                    }
                  }
                ]
              }
            ]
          };
          break;

        case "section":
        default:
          title = `Section ${length + 1} Title`;
          newSection = {
            type: "section",
            id,
            index: length,
            name: "",
            isRepeatable: false,
            advance: { hideSectionTitle: false },
            sectionTitle: {
              english: title
            },
            formElements: []
          };

          break;
      }
      this.ADD_SECTION(newSection);
    },
    async onSaveJsonData(noToast) {
      let formId = localStorage.getItem("activeFormId");
      if (formId && this.form.formId) {
        this.isLoadingLocal = true;
        let payload = this.form;
        try {
          let result = await HTTP(
            "put",
            `v1/form/edit/${formId}`,
            payload,
            makeAuthorizationHeader()
          );
          this.isLoadingLocal = false;
          if (result && result.data) {
            localStorage.setItem("activeFormSchema", JSON.stringify(this.form));
            let toLocal = moment
              .utc(result.data.updated_at.date)
              .local()
              .format();
            let toFrom = moment(toLocal).calendar();
            this.lastUpdatedTime = toFrom;
            if (!noToast) {
              Helper.makeToast(this.$bvToast, "success", "Form Save Success");
            }
          }
        } catch (error) {
          this.isLoadingLocal = false;

          if (!noToast) {
            Helper.makeToast(
              this.$bvToast,
              "danger",
              "Form not save please try again"
            );
          }
        } finally {
          this.isLoadingLocal = false;
        }
      }
    },
    tabClick(tab) {
      this.activeTab = tab;
      if (tab == "BUILDER") {
        this.isBuilder = true;
      } else {
        this.isBuilder = false;
      }
    }
  },
  beforeDestroy() {
    this.clearFields();
    clearInterval(this.pushsing);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.nav-tabs .nav-link {
  margin-bottom: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
