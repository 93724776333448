<template>
  <div>
    <BaseText :text="$t('dashboardTitle')" :size="typographySize.HEADING_TWO" />
    <AppLoader v-if="isLoading" class="text-center" small />
    <b-row class="dashboard-row">
      <b-col cols="12">
        <b-row class="ml-4 mr-4 mt-3"> </b-row>
        <b-row class="ml-2 mr-2 justify-content-md-center">
          <b-col cols="4">
            <BaseText
              :text="$t('questionnaires.title')"
              :size="typographySize.HEADING_FOUR"
              :theme="themes.GREY"
            />
            <b-card
              @click="$router.push('/my-questionnaires')"
              bg-variant="default"
              class="mb-2 click-cursor card-wrapper"
              style="height: 350px"
            >
              <b-card-text class="my-5">
                <font-awesome-icon
                  icon="file-signature"
                  size="6x"
                  class="supplier__icon"
                />
              </b-card-text>
              <b-card-text>
                <BaseText
                  :text="$t('questionnaires.cardText')"
                  :size="typographySize.BODY_TEXT_BOLD"
                />
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="4" v-if="hasUserTrainingList">
            <BaseText
              :text="$t('training.title')"
              :size="typographySize.HEADING_FOUR"
              :theme="themes.GREY"
            />
            <b-card
              @click="$router.push({ name: routeNames[routeKeys.TRAINING] })"
              bg-variant="default"
              class="mb-2 click-cursor card-wrapper"
              style="height: 350px"
            >
              <b-card-text class="my-5">
                <font-awesome-icon
                  icon="graduation-cap"
                  size="6x"
                  class="supplier__icon"
                />
              </b-card-text>
              <b-card-text>
                <BaseText
                  :text="$t('training.cardText')"
                  :size="typographySize.BODY_TEXT_BOLD"
                />
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppLoader from "@/components/Loader/Loader";
import { mapGetters, mapState, mapActions } from "vuex";
import {
  actionName,
  themes,
  typographySize,
  routeKeys,
  routeNames
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "mytpp",
  components: {
    AppLoader,
    BaseText
  },
  data() {
    return {
      themes,
      typographySize,
      routeKeys,
      routeNames
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading"
    }),
    ...mapState({
      userTrainingList: (state) => state.userTraining.userTrainingList
    }),
    hasUserTrainingList() {
      return !!this.userTrainingList.length;
    }
  },
  async created() {
    await this.fetchUserTrainingList();
  },
  methods: {
    ...mapActions({
      fetchUserTrainingList: actionName.USER_TRAINING.FETCH_USER_TRAINING_LIST
    })
  }
};
</script>

<style lang="scss" scoped>
.dashboard-row {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}
.supplier__icon {
  color: $bright-gray;
}
</style>
