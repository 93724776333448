import {
  trainingLearnersKeysDTO,
  trainingLearnersKeysAPIDTO,
  trainingsKeysDTO,
  trainingsKeysAPIDTO,
  eb360DateFormat
} from "@/constants";
import { makeDateToMatchFormat } from "@/utils";

const makeLearnerAPIDTO = (learner = {}) => ({
  [trainingLearnersKeysAPIDTO.EMAIL_ADDRESS]:
    learner[trainingLearnersKeysDTO.EMAIL] || "",
  [trainingLearnersKeysAPIDTO.FIRST_NAME]:
    learner[trainingLearnersKeysDTO.FIRST_NAME] || "",
  [trainingLearnersKeysAPIDTO.LAST_NAME]:
    learner[trainingLearnersKeysDTO.LAST_NAME] || "",
  [trainingLearnersKeysAPIDTO.PREFERRED_LANGUAGE]:
    learner[trainingLearnersKeysDTO.LANGUAGE] || "",
  [trainingLearnersKeysAPIDTO.JOB_TITLE]:
    learner[trainingLearnersKeysDTO.JOB_TITLE] || "",
  [trainingLearnersKeysAPIDTO.THIRD_PARTY_COURSE_ID]:
    learner[trainingLearnersKeysDTO.ID] || "",
  [trainingLearnersKeysAPIDTO.THIRD_PARTY_ID]:
    learner[trainingLearnersKeysDTO.THIRD_PARTY_ID] || ""
});

const makeUserTrainingsDTO = ({
  trainings = [],
  _makeDateToMatchFormat = makeDateToMatchFormat
} = {}) =>
  trainings?.map((training) => ({
    [trainingsKeysDTO.ID]: training[trainingsKeysAPIDTO.ID],
    [trainingsKeysDTO.TITLE]: training[trainingsKeysAPIDTO.NAME],
    [trainingsKeysDTO.DATE_ASSIGNED]: training?.[
      trainingsKeysAPIDTO.ASSIGNED_AT
    ]
      ? _makeDateToMatchFormat({
          stringifiedDate: training[trainingsKeysAPIDTO.ASSIGNED_AT],
          dateFormat: eb360DateFormat.WORDED_LONG_FORM_DATE
        })
      : "",
    [trainingsKeysDTO.DATE_COMPLETED]: training?.[
      trainingsKeysAPIDTO.DATE_COMPLETED
    ]
      ? _makeDateToMatchFormat({
          stringifiedDate: training[trainingsKeysAPIDTO.DATE_COMPLETED],
          dateFormat: eb360DateFormat.WORDED_LONG_FORM_DATE
        })
      : "",
    [trainingsKeysDTO.STATUS]:
      training?.[trainingsKeysAPIDTO.STATUS].toUpperCase?.() ||
      training[trainingsKeysAPIDTO.STATUS],
    [trainingsKeysDTO.REMINDER_SENT]:
      training[trainingsKeysAPIDTO.REMINDER_SENT],
    [trainingsKeysDTO.LEARNERS_REGISTERED]:
      training[trainingsKeysAPIDTO.LEARNERS_REGISTERED],
    [trainingsKeysDTO.LEARNERS_COMPLETED]:
      training[trainingsKeysAPIDTO.LEARNERS_COMPLETED]
  })) || [];

export { makeLearnerAPIDTO, makeUserTrainingsDTO };
