<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="label"
                :placeholder="fieldData.label[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Abbreviation Label">
              <b-form-input
                v-model="fieldData.abbreviationLabel"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Text for table">
              <b-form-input
                v-model="fieldData.textForTable"
                maxlength="18"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Helper Text">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="helperText"
                :placeholder="fieldData.helperText[transalationLanguage]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Validation Message">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="validationMessage"
                :placeholder="fieldData.validationMessage[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>
            <Checkbox
              v-if="fieldData.inputType != 'checkbox' || 'radio'"
              v-model="isStacked"
              label="Is Stacked?"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>
    <!-- options tabb -->
    <b-tab title="Options" v-if="fieldData.inputType == 'radioMulti'">
      <!-- select options tab -->
      <b-tabs pills card vertical>
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <b-form @submit.stop.prevent class="active text-left mt-2">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th>Option text</th>
                  <th>Value</th>
                  <th>Score</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, optionIndex) in fieldData.options"
                  :key="optionIndex"
                >
                  <td>
                    <b-form-input
                      :value="option.text[transalationLanguage]"
                      @input="setFieldOptionText($event, optionIndex)"
                      @focus="$event.target.select()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :disabled="transalationLanguage !== 'english'"
                      :value="option.value"
                      @input="setFieldOptionValue($event, optionIndex)"
                      @focus="$event.target.select()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :placeholder="JSON.stringify(option.score)"
                      @input="optionValue = $event"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-button
                      variant="outline-danger"
                      v-if="optionIndex > 1"
                      class="btn btn-outline-danger btn-sm"
                      @click="onDeleteOption(optionIndex)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-button variant="primary" @click="addOption">
              <i class="fa fa-plus"></i>
            </b-button>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- attribute tab -->
    <b-tab title="Attributes">
      <b-form @submit.stop.prevent class="text-left mt-2">
        <div class="form-group col-md-6">
          <label for>ID</label>
          <b-form-input v-model="selectId"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Class</label>
          <b-form-input v-model="selectClass"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Title</label>
          <b-form-input v-model="selectTitle"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post Name</label>
          <b-form-input v-model="selectedPostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>style</label>
          <b-form-textarea
            id="textarea"
            v-model="selectStyle"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="form-check">
          <Checkbox
            id="checkbox-1"
            name="checkbox-1"
            v-model="selectReadOnly"
            label="Readonly"
          />
        </div>
        <div class="form-check">
          <Checkbox
            id="checkbox-4"
            checked-value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForThirdPartyProfile"
            name="checkbox-4"
            label="Show Self Reporting Data in Third Party Profile"
          />
          <Checkbox
            id="checkbox-5"
            checked-value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForManageThirdParty"
            name="checkbox-5"
            label="Show Self Reporting Data in Manage Third Party"
          />
        </div>
        <div class="form-check">
          <Checkbox
            id="checkbox-6"
            checked-value="1"
            unchecked-value="0"
            v-model="fieldData.enableFilterForManageThirdParty"
            name="checkbox-5"
            label="Filter in Manage Third Party"
          />
        </div>
      </b-form>
    </b-tab>

    <!-- data validation -->
    <b-tab title="Validation">
      <div class="text-left">
        <b-col>
          <Checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireCheck"
            checked-value="1"
            unchecked-value="0"
            label="Is required?"
          />
        </b-col>
      </div>
    </b-tab>

    <!-- --------------Conditional------------------ -->
    <b-tab title="Conditional">
      <b-tabs>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab title="Simple" v-if="fieldData.inputType == 'radioMulti'">
          <b-col>
            <!-- enabledConditionalField -->
            <Checkbox
              class="text-left"
              checked-value="1"
              unchecked-value="0"
              @update:modelValue="onToggleConditionalCheck"
              v-model="enabledConditionalField"
              label="Enable Conditional Field"
            />

            <b-collapse v-model="showCollapseRadios" class="mt-2">
              <b-form-group class="my-3">
                <b-form-radio
                  @change="onShowHideSelect"
                  v-model="selectedShowHide"
                  value="1"
                  >Show when selected option value is:</b-form-radio
                >
              </b-form-group>
              <b-form-select
                v-show="selectedShowHide == 1"
                v-model="selectedConditionalFieldValue"
                text-field="value"
                @change="onSelectConditionOption"
                :options="fieldData.options"
              ></b-form-select>

              <b-tabs pills card vertical>
                <b-tab
                  @click="tabClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-tabs small @input="onCilckProgressiveTab">
                    <b-tab
                      v-for="(option, i) in fieldData.progressiveOptions"
                      :key="'dyn-tab-' + i"
                    >
                      <template v-slot:title>
                        <font-awesome-icon
                          class="mr-1"
                          icon="trash"
                          @click.native="closeTab(i)"
                        />
                        <i>condition</i>
                        <strong>{{ i + 1 }}</strong>
                      </template>

                      <div class="my-2">
                        <b-form-group label="Select Field Type">
                          <b-form-radio v-model="option.type" value="radio"
                            >Radio</b-form-radio
                          >
                          <b-form-radio v-model="option.type" value="text"
                            >Text</b-form-radio
                          >
                          <b-form-radio v-model="option.type" value="date"
                            >Date</b-form-radio
                          >
                        </b-form-group>

                        <b-form-group label="Label">
                          <b-form-input
                            v-model="option.label[transalationLanguage]"
                            type="text"
                          ></b-form-input>
                        </b-form-group>

                        <b-form v-if="option.type == 'radio'">
                          <b-form-group label="Yes Label">
                            <b-form-input
                              @blur="addLanguageToProgressiveField(language, i)"
                              v-model="conditionalFieldYesLabel"
                              :placeholder="
                                option.yesLabel[transalationLanguage]
                              "
                              @input="
                                setYesProgressiveFieldOptionText($event, i)
                              "
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group label="No Label">
                            <b-form-input
                              @blur="addLanguageToProgressiveField(language, i)"
                              v-model="conditionalFieldNoLabel"
                              :placeholder="
                                option.noLabel[transalationLanguage]
                              "
                              @input="
                                setNoProgressiveFieldOptionText($event, i)
                              "
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </b-form>

                        <!-- for third conditions -->
                        <Checkbox
                          class="text-left"
                          v-model="option.isConditional"
                          label="Enable Conditional for this field"
                        />
                        <b-form-select
                          v-show="option.isConditional"
                          v-model="option.selectedConditionalFieldValue"
                          text-field="value"
                          :options="option.options"
                        ></b-form-select>
                        <b-collapse v-model="option.isConditional">
                          <b-form-group label="Conditional Field">
                            <b-form-input
                              @blur="addLanguageToProgressiveField(language, i)"
                              v-model="conditionalFieldLabel"
                              :placeholder="
                                option.conditionalFieldLabel[
                                  transalationLanguage
                                ]
                              "
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="Conditional Field Validation Message"
                          >
                            <b-form-input
                              @blur="addLanguageToProgressiveField(language, i)"
                              v-model="conditionalFieldValidationMessage"
                              :placeholder="
                                option.conditionalFieldValidationMessage[
                                  transalationLanguage
                                ]
                              "
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </b-collapse>
                      </div>
                    </b-tab>

                    <!-- New Tab Button (Using tabs-end slot) -->
                    <template v-slot:tabs-end>
                      <b-nav-item @click.prevent="newProgressiveTab" href="#">
                        <b>+</b>
                      </b-nav-item>
                    </template>
                  </b-tabs>
                </b-tab>
              </b-tabs>
            </b-collapse>
          </b-col>
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
import uid from "uid";
import { Helper } from "@/helpers";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "AdvanceRadioSettings",
  components: {
    Checkbox
  },
  data() {
    return {
      conditionalFieldLabel: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldHelperText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldFirstLabel: null,
      conditionalFieldYesLabel: null,
      conditionalFieldNoLabel: null,
      isStacked: true,
      fieldCSS: null,
      fieldJS: null,
      // multi progressive
      optionsMutiInputs: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 }
      ],
      selectedMutiInputs: 1,
      // advance condition
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      condition: {},
      isConditional: 0,
      noOfConditions: [1],

      isLoading: false,
      transalationLanguage: "english",
      label: undefined,
      placeholder: undefined,
      validationMessage: undefined,
      helperText: undefined,
      optiponName: undefined,
      optionValue: undefined,
      dataAttributeValue: undefined,
      dataAttributeKey: undefined,
      isRequireCheck:
        this.fieldData.validation && this.fieldData.validation.required,
      validityMessage:
        this.fieldData.validation && this.fieldData.validation.message
          ? this.fieldData.validation.message
          : "This field is required",
      selectId:
        this.fieldData.attributes && this.fieldData.attributes.newId
          ? this.fieldData.attributes.newId
          : undefined,
      selectClass:
        this.fieldData.attributes && this.fieldData.attributes.Class
          ? this.fieldData.attributes.Class
          : undefined,
      selectTitle:
        this.fieldData.attributes && this.fieldData.attributes.Title
          ? this.fieldData.attributes.Title
          : undefined,
      selectedPostName:
        this.fieldData.attributes && this.fieldData.attributes.postName
          ? this.fieldData.attributes.postName
          : undefined,

      selectStyle:
        this.fieldData.attributes && this.fieldData.attributes.Style
          ? this.fieldData.attributes.Style
          : undefined,
      selectReadOnly: !!this.fieldData?.attributes?.readonly,
      showTab: "text",

      // advance tab settings for select only

      isMultipleSelect:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect) ||
        false,
      isClearable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isClearable) ||
        false,
      isLiveSearchable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isLiveSearchable) ||
        false,
      minSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.minSelection) ||
        1,
      maxSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxSelection) ||
        2,
      renderOptionsLinmit:
        (this.fieldData.configuration &&
          this.fieldData.configuration.renderOptionsLinmit) ||
        5,

      // advance tab settings for checkbox
      minSelect: this.fieldData.validation.minSelect,
      maxSelect: this.fieldData.validation.maxSelect,

      // for conditional filedsss
      enabledConditionalField: 0,
      showCollapseRadios: false,
      selectedShowHide: null,
      selectedConditionalFieldValue: null,
      progressiveTabs: [],
      tabCounter: 0
    };
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.addAttributes();
      this.setValidation();
      this.setAdvanceSettingsForSelect();
    });
  },

  mounted() {
    (this.isStacked = this.fieldData.configuration.isStacked),
      (this.label = this.fieldData.label[this.transalationLanguage]),
      (this.validationMessage =
        this.fieldData.validationMessage[this.transalationLanguage]),
      (this.helperText = this.fieldData.helperText[this.transalationLanguage]);

    // Conditional Field
    if (this.fieldData.inputType == "radioMulti") {
      this.enabledConditionalField = this.fieldData.enabledConditionalField;
      this.selectedShowHide = this.fieldData.conditionalShowHiddenOption;
      this.showCollapseRadios = this.enabledConditionalField == 1;
      this.selectedConditionalFieldValue =
        this.fieldData.selectedConditionalFieldValue;
      this.selectedMutiInputs = this.fieldData.selectedMutiInputs;
      this.conditionalFieldLabel =
        this.fieldData.conditionalFieldLabel[this.transalationLanguage];
      this.conditionalFieldPlaceHolderText =
        this.fieldData.conditionalFieldPlaceHolderText[
          this.transalationLanguage
        ];

      this.conditionalFieldValidationMessage =
        this.fieldData.conditionalFieldValidationMessage[
          this.transalationLanguage
        ];
      this.conditionalFieldHelperText =
        this.fieldData.conditionalFieldHelperText[this.transalationLanguage];
    }
    this.getCurrentSectionList();
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.label = this.fieldData.label[this.transalationLanguage];

      this.validationMessage =
        this.fieldData.validationMessage[this.transalationLanguage];
      this.helperText = this.fieldData.helperText[this.transalationLanguage];

      // Conditional Field

      if (
        this.fieldData.inputType == "radio" ||
        this.fieldData.inputType == "select" ||
        this.fieldData.inputType == "checkbox"
      ) {
        this.conditionalFieldLabel =
          this.fieldData.conditionalFieldLabel[this.transalationLanguage];
        this.conditionalFieldPlaceHolderText =
          this.fieldData.conditionalFieldPlaceHolderText[
            this.transalationLanguage
          ];

        this.conditionalFieldValidationMessage =
          this.fieldData.conditionalFieldValidationMessage[
            this.transalationLanguage
          ];
        this.conditionalFieldHelperText =
          this.fieldData.conditionalFieldHelperText[this.transalationLanguage];
        this.getValuesForProgressive();
      }
    },

    getValuesForProgressive() {
      this.conditionalFieldLabel =
        this.fieldData.progressiveOptions[
          this.tabCounter
        ].conditionalFieldLabel[this.transalationLanguage];
      this.conditionalFieldValidationMessage =
        this.fieldData.progressiveOptions[
          this.tabCounter
        ].conditionalFieldValidationMessage[this.transalationLanguage];
      this.conditionalFieldFirstLabel =
        this.fieldData.progressiveOptions[this.tabCounter].label[
          this.transalationLanguage
        ];
      this.conditionalFieldYesLabel =
        this.fieldData.progressiveOptions[this.tabCounter].yesLabel[
          this.transalationLanguage
        ];
      this.conditionalFieldNoLabel =
        this.fieldData.progressiveOptions[this.tabCounter].noLabel[
          this.transalationLanguage
        ];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.helperText[tempKey] = this.helperText;
      this.fieldData.validationMessage[tempKey] = this.validationMessage;

      // Conditional Field
      if (
        this.fieldData.inputType == "radio" ||
        this.fieldData.inputType == "select" ||
        this.fieldData.inputType == "checkbox"
      ) {
        this.fieldData.conditionalFieldLabel[tempKey] =
          this.conditionalFieldLabel;
        this.fieldData.conditionalFieldHelperText[tempKey] =
          this.conditionalFieldHelperText;
        this.fieldData.conditionalFieldValidationMessage[tempKey] =
          this.conditionalFieldValidationMessage;
        this.fieldData.conditionalFieldPlaceHolderText[tempKey] =
          this.conditionalFieldPlaceHolderText;
      }
    },

    addLanguageToProgressiveField(language, index) {
      let tempKey = language.toLowerCase();
      this.fieldData.progressiveOptions[index].conditionalFieldLabel[tempKey] =
        this.conditionalFieldLabel;
      this.fieldData.progressiveOptions[
        index
      ].conditionalFieldValidationMessage[tempKey] =
        this.conditionalFieldValidationMessage;
      this.fieldData.progressiveOptions[index].label[tempKey] =
        this.conditionalFieldFirstLabel;
      this.fieldData.progressiveOptions[index].yesLabel[tempKey] =
        this.conditionalFieldYesLabel;
      this.fieldData.progressiveOptions[index].noLabel[tempKey] =
        this.conditionalFieldNoLabel;
      this.conditionalFieldFirstLabel = null;
    },

    addAttributes() {
      let attributes = {
        newId: this.selectId ? this.selectId : undefined,
        Class: this.selectClass ? this.selectClass : undefined,
        Title: this.selectTitle ? this.selectTitle : undefined,
        Style: this.selectStyle ? this.selectStyle : undefined,
        readonly: this.selectReadOnly,
        postName: this.selectedPostName ? this.selectedPostName : undefined
      };
      Object.assign(this.fieldData, { attributes });
    },

    addOption() {
      let newOptiopn = {
        value: this.optionValue
          ? this.optionValue
          : `${this.fieldData.inputType} value${
              this.fieldData.options.length
            }${1}`,
        text: {
          english: this.optiponName
            ? this.optionName
            : `${this.fieldData.inputType} text ${
                this.fieldData.options.length
              }${1}`
        },
        score: 0
      };

      this.fieldData.options.push(newOptiopn);
    },

    addDataAttribute() {
      let newDataAttribute = {
        value: this.dataAttributeValue ? this.dataAttributeValue : undefined,
        key: this.dataAttributeKey ? `data-${this.dataAttributeKey}` : undefined
      };
      this.fieldData.dataAttributes.push(newDataAttribute);
    },

    onDeleteOption(index) {
      if (this.fieldData.options.length > 2) {
        this.fieldData.options.splice(this.fieldData.options.indexOf(index), 1);
      }
    },

    // methods for radio options settings
    setFieldOptionValue(value, optionIndex) {
      this.fieldData.options[optionIndex].value;
      this.optionValue = value;
      this.fieldData.options[optionIndex].value = value;
    },

    setFieldOptionText(value, optionIndex) {
      this.optiponName = value;
      this.fieldData.options[optionIndex].text[this.transalationLanguage] =
        value;
    },

    setYesProgressiveFieldOptionText(value, progressiveIndex) {
      this.fieldData.progressiveOptions[progressiveIndex].yesLabel[
        this.transalationLanguage
      ] = value;
      this.fieldData.progressiveOptions[progressiveIndex].options[1].text[
        this.transalationLanguage
      ] = value;
    },
    setNoProgressiveFieldOptionText() {},

    /**
     * Required for validation on
     */
    setValidation() {
      let validation = {
        required: this.isRequireCheck,
        minSelect: this.minSelect,
        maxSelect: this.maxSelect
      };
      Object.assign(this.fieldData, { validation });
    },

    setAdvanceSettingsForSelect() {
      this.fieldData.configuration = {
        isStacked: this.isStacked,
        isMultipleSelect: this.isMultipleSelect,
        isClearable: this.isClearable,
        isLiveSearchable: this.isLiveSearchable,
        minSelection: this.minSelection,
        maxSelection: this.maxSelection,
        renderOptionsLinmit: this.renderOptionsLinmit
      };
    },

    // for conditional
    onToggleConditionalCheck(e) {
      if (e == 0) {
        this.fieldData.selectedConditionalFieldValue = null;
        this.selectedConditionalFieldValue = null;
        this.selectedShowHide = null;
        this.selectedMutiInputs = 1;
      }
      this.showCollapseRadios = e == 1;
      this.enabledConditionalField = parseInt(e);
      this.fieldData.enabledConditionalField = parseInt(e);
    },

    onShowHideSelect(e) {
      this.fieldData.conditionalShowHiddenOption = parseInt(e); // 0 -> hide, 1 - > show
    },

    onSelectConditionOption() {
      this.fieldData.selectedConditionalFieldValue =
        this.selectedConditionalFieldValue;
      this.fieldData.selectedMutiInputs = this.selectedMutiInputs;
    },
    // for advance conditional
    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };

        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
        Helper.makeToast(this.$bvToast, "success", "Conditional  Updated");
      } else {
        Helper.makeToast(this.$bvToast, "danger", "No Condition selected");
      }
    },

    closeTab(x) {
      for (let i = 0; i < this.fieldData.progressiveOptions.length; i++) {
        if (i == x) {
          this.fieldData.progressiveOptions.splice(i, 1);
        }
      }
    },

    newProgressiveTab() {
      let progressiveOptions = {
        id: uid(),
        label: {
          english: "Multi Fileds Label"
        },
        labelValue: null,
        yesLabel: {
          english: "Yes"
        },
        yesValue: null,
        noLabel: {
          english: "No"
        },
        noValue: null,
        conditionalFieldLabel: {
          english: "Conditional Field Label"
        },
        conditionalFieldValidationMessage: {
          english: "This field is required"
        },
        options: [
          {
            value: "No",
            text: {
              english: "No"
            },
            score: 0
          },
          {
            value: "Yes",
            text: {
              english: "Yes"
            },
            score: 1
          }
        ],
        type: "radio",
        isConditional: false,
        isRequired: true,
        fieldInputValue: null,
        fieldRadioValue: null,
        conditionalFieldInputValue: null,
        selectedConditionalFieldValue: null
      };
      this.fieldData.progressiveOptions.push(progressiveOptions);
    },

    onCilckProgressiveTab(tab) {
      this.tabCounter = tab;
      this.getValuesForProgressive();
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
