import {
  actionKeys,
  automationsKeys,
  companyOrganizationsKeys,
  ESGReportsKeys,
  greenliteThirdPartyKeys,
  numberOfEmployeesKeys,
  productVersionsKeysDTO,
  registrationNumbersKeys,
  seniorPrincipalsKeys,
  userRolesKeysDTO,
  userEmailTemplateKeysDTO,
  taskTemplateKeysDTO,
  trainingsKeysDTO,
  trainingLearnersKeysDTO,
  tasksKeysDTO,
  productKeysDTO
} from "@/constants";

const greenliteThirdPartiesTableHeaders = {
  [greenliteThirdPartyKeys.NAME]: "Third party",
  [greenliteThirdPartyKeys.GREENLITE_EXPLORE_RATING]: "ESG Explore Rating",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RATING]: "Environmental Rating",
  [greenliteThirdPartyKeys.SOCIAL_RATING]: "Social Rating",
  [greenliteThirdPartyKeys.GOVERNANCE_RATING]: "Governance Overall Rating",
  [greenliteThirdPartyKeys.OVERALL_RISK_SEVERITY]: "Risk Severity",
  [greenliteThirdPartyKeys.RESILIENCE_STRENGTH]: "Resilience Strength",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RISK_SEVERITY]:
    "Environmental Risk Severity",
  [greenliteThirdPartyKeys.SOCIAL_RISK_SEVERITY]: "Social Risk Severity",
  [greenliteThirdPartyKeys.GOVERNANCE_RISK_SEVERITY]:
    "Governance Risk Severity",
  [greenliteThirdPartyKeys.ENVIRONMENT_RESILIENCE_STRENGTH]:
    "Environment Resilience Strength",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_PRACTICES_STRENGTH]:
    "Environmental Practices Strength",
  [greenliteThirdPartyKeys.CARBON_NEUTRAL_STRENGTH]: "Carbon Neutral Strength",
  [greenliteThirdPartyKeys.SOCIAL_RESILIENCE_STRENGTH]:
    "Social Resilience Strength",
  [greenliteThirdPartyKeys.HUMAN_RIGHTS_AND_MODERN_SLAVERY_STRENGTH]:
    "Human Rights & Modern Slavery Strength",
  [greenliteThirdPartyKeys.LABOUR_STRENGTH]: "Labour Strength",
  [greenliteThirdPartyKeys.DIVERSITY_AND_INCLUSION_STRENGTH]:
    "Diversity & Inclusion Strength",
  [greenliteThirdPartyKeys.HEALTH_AND_SAFETY_STRENGTH]:
    "Health & Safety Strength",
  [greenliteThirdPartyKeys.GOVERNANCE_RESILIENCE_STRENGTH]:
    "Governance Resilience Strength",
  [greenliteThirdPartyKeys.ANTI_BRIBERY_CORRUPTION_STRENGTH]:
    "Anti Bribery & Corruption Strength",
  [greenliteThirdPartyKeys.WHISTLEBLOWING_STRENGTH]: "Whistleblowing Strength",
  [greenliteThirdPartyKeys.SUPPLY_CHAIN_STRENGTH]: "Supply Chain Strength"
};

const actionsListTableHeaders = {
  [actionKeys.NAME]: "Name",
  [actionKeys.DESCRIPTION]: "Description",
  [actionKeys.IS_ACTIVE]: "Status",
  [actionKeys.IS_SYSTEM]: "Created by",
  [actionKeys.ACTION]: "Action"
};

const registrationNumbersTableHeaders = {
  [registrationNumbersKeys.TYPE]: "Registration Type",
  [registrationNumbersKeys.NUMBER]: "Registration Number"
};

const numberOfEmployeesTableHeaders = {
  [numberOfEmployeesKeys.DESCRIPTION]: "Type",
  [numberOfEmployeesKeys.VALUE]: "No. of Employee"
};
const seniorPrincipalsTableHeaders = {
  [seniorPrincipalsKeys.TITLE]: "Job Title",
  [seniorPrincipalsKeys.NAME_PREFIX]: "Prefix",
  [seniorPrincipalsKeys.FULL_NAME]: "Senior Principal",
  [seniorPrincipalsKeys.NAME_SUFFIX]: "Suffix",
  [seniorPrincipalsKeys.GENDER]: "Gender",
  [seniorPrincipalsKeys.SELECTED]: "In Associates"
};

const companyOrganizationsTableHeaders = {
  [companyOrganizationsKeys.SELECT]: "Select",
  [companyOrganizationsKeys.COMPANY_NAME]: "Company name",
  [companyOrganizationsKeys.OPERATING_STATUS]: "Operating status",
  [companyOrganizationsKeys.ADDRESS]: "Address",
  [companyOrganizationsKeys.CORPORATE_LINKAGE]: "Corporate linkage",
  [companyOrganizationsKeys.MATCH_CONFIDENCE]: "Match confidence",
  [companyOrganizationsKeys.MORE_INFO]: "More info"
};

const automationsListTableHeaders = {
  [automationsKeys.NAME]: "Name",
  [automationsKeys.DESCRIPTION]: "Description",
  [automationsKeys.CREATED_BY]: "Created by",
  [automationsKeys.IS_ACTIVE]: "Status",
  [automationsKeys.ACTIONS]: "Actions"
};

const automationConditionTableHeaders = {
  [automationsKeys.NAME]: "Name",
  [automationsKeys.TYPE]: "Data type"
};

const ESGReportsTableHeaders = {
  [ESGReportsKeys.VERSION]: "Version",
  [ESGReportsKeys.TRIGGER_DATE]: "Trigger Date",
  [ESGReportsKeys.REPORT_ID]: "Reports"
};

const productVersionTableHeaders = {
  [productVersionsKeysDTO.SERVICE]: "Service",
  [productVersionsKeysDTO.VERSION]: "Version"
};

const roleTableHeaders = {
  [userRolesKeysDTO.NAME]: "Role name",
  [userRolesKeysDTO.DESCRIPTION]: "Description",
  [userRolesKeysDTO.ACTIONS]: "Actions"
};

const ultimateBeneficialOwnersHeaders = {
  SELECT: "Select",
  BENEFICIARY_NAME: "Beneficiary name",
  COUNTRY: "Country",
  BENEFICIARY_TYPE: "Beneficiary type",
  OWNED_ENTITY: "Owned entity",
  ENTITY_OWNERSHIP_PERCENTAGE: "Entity ownership percentage (%)",
  RISK_STATUS_IDD: "IDD",
  RISK_STATUS_IDD_PLUS: "IDD+",
  DEGREE_OF_SEPARATION: "Degree of separation",
  DIRECT_OWNERSHIP_PERCENTAGE: "Direct ownership percentage (%)",
  INDIRECT_OWNERSHIP_PERCENTAGE: "Indirect ownership percentage (%)",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "Beneficial ownership percentage (%)",
  PERSON_OF_SIGNIFICANT_CONTROL: "Person of significant control",
  BUSINESS_OWNERSHIP_TYPE: "Business ownership type",
  VIEW_PROFILE: "View profile"
};

const userEmailTemplatesListTableHeaders = {
  [userEmailTemplateKeysDTO.NAME]: "Name",
  [userEmailTemplateKeysDTO.DESCRIPTION]: "Description",
  [userEmailTemplateKeysDTO.ACTION]: "Action"
};

const taskTemplatesListTableHeaders = {
  [taskTemplateKeysDTO.TEMPLATE_NAME]: "Name",
  [taskTemplateKeysDTO.TEMPLATE_DESCRIPTION]: "Description",
  [taskTemplateKeysDTO.ACTION]: "Action"
};

const UBOProfileDueDiligenceHeaders = {
  CREATE_DATE: "Create date",
  TYPE: "Type",
  RISK_STATUS: "Risk status",
  REMEDIATED_DATE: "Remediated date",
  REMEDIATED_STATUS: "Remediated status",
  ACTION: "Action"
};

const tableOptions = {
  ACCORDION_OPTIONS: "accordionOptions",
  IS_ACCORDION_VISIBLE: "isAccordionVisible"
};

const entityOperatingStatuses = {
  ACTIVE: "Active",
  OUT_OF_BUSINESS: "Out of business"
};

const trainingsTableHeaders = {
  [trainingsKeysDTO.TITLE]: "Training title",
  [trainingsKeysDTO.DATE_ASSIGNED]: "Date assigned",
  [trainingsKeysDTO.DATE_COMPLETED]: "Date completed",
  [trainingsKeysDTO.LEARNERS_REGISTERED]: "Learners registered",
  [trainingsKeysDTO.LEARNERS_COMPLETED]: "Learners completed",
  [trainingsKeysDTO.STATUS]: "Status",
  [trainingsKeysDTO.REMINDER_SENT]: "Reminder sent",
  [trainingsKeysDTO.DETAILS]: "Details"
};

const trainingLearnersTableHeaders = {
  [trainingLearnersKeysDTO.NAME]: "Learner name",
  [trainingLearnersKeysDTO.EMAIL]: "Learner email",
  [trainingLearnersKeysDTO.JOB_TITLE]: "Job title",
  [trainingLearnersKeysDTO.DATE_ASSIGNED]: "Date assigned",
  [trainingLearnersKeysDTO.DATE_COMPLETED]: "Date completed",
  [trainingLearnersKeysDTO.STATUS]: "Status",
  [trainingLearnersKeysDTO.REMINDER_SENT]: "Reminder sent"
};

const trainingLearnersSupplierTableHeaders = {
  [trainingLearnersKeysDTO.SELECT]: "Select",
  [trainingLearnersKeysDTO.NAME]: "Learner name",
  [trainingLearnersKeysDTO.EMAIL]: "Learner email",
  [trainingLearnersKeysDTO.JOB_TITLE]: "Job title",
  [trainingLearnersKeysDTO.DATE_ASSIGNED]: "Date assigned",
  [trainingLearnersKeysDTO.DATE_COMPLETED]: "Date completed",
  [trainingLearnersKeysDTO.STATUS]: "Status",
  [trainingLearnersKeysDTO.REMINDER_SENT]: "Reminder sent"
};

const tasksListTableHeaders = {
  [tasksKeysDTO.COMPANY_TASK_ID]: "Id",
  [tasksKeysDTO.CREATED_AT]: "Created at",
  [tasksKeysDTO.SUBJECT]: "Subject",
  [tasksKeysDTO.ASSIGNEE]: "Assignee",
  [tasksKeysDTO.STATUS]: "Status",
  [tasksKeysDTO.UPDATED_AT]: "Updated at",
  [tasksKeysDTO.SELECTED_OUTCOME_ID]: "Outcome",
  [tasksKeysDTO.ACTION]: "Action"
};

const productListTableHeaders = {
  [productKeysDTO.ID]: "Id",
  [productKeysDTO.NAME]: "Name",
  [productKeysDTO.DAYS_TO_COMPLETE]: "Days to complete",
  [productKeysDTO.CREATED_AT]: "Created at"
};

export {
  greenliteThirdPartiesTableHeaders,
  actionsListTableHeaders,
  registrationNumbersTableHeaders,
  numberOfEmployeesTableHeaders,
  seniorPrincipalsTableHeaders,
  companyOrganizationsTableHeaders,
  automationsListTableHeaders,
  automationConditionTableHeaders,
  roleTableHeaders,
  ESGReportsTableHeaders,
  productVersionTableHeaders,
  ultimateBeneficialOwnersHeaders,
  userEmailTemplatesListTableHeaders,
  UBOProfileDueDiligenceHeaders,
  taskTemplatesListTableHeaders,
  tableOptions,
  entityOperatingStatuses,
  trainingsTableHeaders,
  trainingLearnersTableHeaders,
  tasksListTableHeaders,
  trainingLearnersSupplierTableHeaders,
  productListTableHeaders
};
