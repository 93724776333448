<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <ErrorPageTemplate v-if="isErrorPresent" v-bind="errorPageOptions" />
      <router-view v-else v-slot="{ Component }">
        <component
          :is="Component"
          @fetchAction="fetchActionWrapper"
          @resetAction="resetAction"
          :action="action"
        />
      </router-view>
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  actionName,
  mutationName,
  taskTemplateKeysAPIDTO,
  routeNames
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "ActionsTemplate",
  data() {
    return {
      errorMessages: []
    };
  },
  components: {
    BaseLoader,
    ErrorPageTemplate
  },
  computed: {
    ...mapState({
      action: (state) => state.actions.action,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      isLoadingActions: (state) => state.actions.isLoadingActions,
      isLoadingAction: (state) => state.actions.isLoadingAction,
      isLoadingExpectedData: (state) =>
        state.expectedData.isLoadingExpectedData,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      isLoadingUserEmailTemplates: (state) =>
        state.userEmailTemplates.isLoadingUserEmailTemplates
    }),
    isLoading() {
      return (
        this.isLoadingActions ||
        this.isLoadingAction ||
        this.isLoadingExpectedData ||
        this.isLoadingRoles ||
        this.isLoadingUserEmailTemplates
      );
    },
    isErrorPresent() {
      return !!this.errorMessages.length;
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.errorMessages
        },
        CTAOptions: {
          value: "Please contact support"
        },
        linkComponent: "a",
        linkOptions: {
          href: this.platformRequestSupportLink,
          target: "_blank"
        }
      };
    }
  },
  methods: {
    ...mapActions({
      fetchActions: actionName.ACTIONS.FETCH_ACTIONS,
      fetchAction: actionName.ACTIONS.FETCH_ACTION,
      fetchExpectedData: actionName.EXPECTED_DATA.FETCH_EXPECTED_DATA,
      fetchRoles: actionName.ROLES.FETCH_ROLES,
      fetchUserEmailTemplates:
        actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      fetchTaskTemplates: actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES
    }),
    ...mapMutations({
      resetAction: mutationName.ACTIONS.RESET_ACTION,
      resetActions: mutationName.ACTIONS.RESET_ACTIONS,
      resetRoles: mutationName.ROLES.RESET_ROLES,
      resetUserEmailTemplates:
        mutationName.USER_EMAIL_TEMPLATES.RESET_USER_EMAIL_TEMPLATES,
      resetExpectedData: mutationName.EXPECTED_DATA.RESET_EXPECTED_DATA,
      resetTaskTemplates: mutationName.TASK_TEMPLATES.RESET_TASK_TEMPLATES
    }),
    async fetchActionsWrapper() {
      try {
        await this.fetchActions();
      } catch {
        this.errorMessages.push("Failed to fetch the actions.");
      }
    },
    async fetchActionWrapper(id) {
      try {
        await this.fetchAction(id);
      } catch {
        this.errorMessages.push(`Failed to fetch the action with id: ${id}.`);
      }
    },
    async fetchExpectedDataWrapper() {
      try {
        await this.fetchExpectedData();
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available expected data."
        );
      }
    },
    async fetchUserEmailTemplatesWrapper() {
      try {
        await this.fetchUserEmailTemplates();
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available user email templates."
        );
      }
    },
    async fetchTaskTemplatesWrapper() {
      try {
        await this.fetchTaskTemplates({
          excluded_fields: [taskTemplateKeysAPIDTO.OUTCOME_ACTIONS]
        });
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available task templates."
        );
      }
    },
    async fetchRolesWrapper() {
      try {
        await this.fetchRoles();
      } catch {
        this.errorMessages.push("Failed to retrieve the available roles.");
      }
    }
  },
  async created() {
    await this.fetchExpectedDataWrapper();

    if (
      this.$route.name === routeNames.EDIT_ACTION_ID &&
      this.$route?.params?.id
    ) {
      await this.fetchActionWrapper(this.$route?.params?.id);
    }

    await Promise.allSettled([
      this.fetchActionsWrapper(),
      this.fetchUserEmailTemplatesWrapper(),
      this.fetchRolesWrapper(),
      this.fetchTaskTemplatesWrapper()
    ]);
  },
  beforeRouteLeave(_, __, next) {
    this.resetActions();
    this.resetRoles();
    this.resetUserEmailTemplates();
    this.resetExpectedData();
    this.resetTaskTemplates();
    next();
  }
};
</script>
