<template>
  <div
    class="tab-pane fade show active scrollHeight"
    id="htmlinputs"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <draggable
      :sort="false"
      v-model="fieldData"
      :group="{ name: 'sections', pull: 'clone', put: false }"
      :clone="onClone"
      tag="ul"
      :itemKey="getFieldId"
    >
      <template #item="{ element }">
        <li
          class="list-group-item text-left pointer"
          :data-elementtype="element.inputType"
        >
          <font-awesome-icon
            :icon="element.icon"
            class="mr-2 text-primary"
            v-if="element.icon"
          />
          {{ element.name }}
        </li>
      </template>
    </draggable>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { FormData } from "@/helpers";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "InputComponents",
  components: {
    draggable
  },
  data() {
    return {
      fieldData: [
        // input
        {
          name: "Input",
          inputType: "input",
          icon: "keyboard"
        },
        //
        {
          name: "Number",
          inputType: "number",
          icon: "hashtag"
        },
        {
          name: "Email",
          inputType: "email",
          icon: "envelope"
        },
        {
          name: "Phone",
          inputType: "phone",
          icon: "phone-alt"
        },
        {
          name: "Website",
          inputType: "website",
          icon: "globe"
        },
        // Text Area
        {
          name: "Text Area",
          inputType: "textarea",
          icon: "align-left"
        },
        // What you see is what you get editor
        {
          name: "Html Editor",
          inputType: "wyswg-editor",
          icon: "align-left"
        },
        // Select picker
        {
          name: "Combo Select",
          inputType: "comboselect",
          icon: "check-double"
        },
        {
          name: "Combo Select Progressive",
          inputType: "comboSelectProgressive",
          icon: "check-double"
        },
        {
          name: "Select",
          inputType: "select",
          icon: "check"
        },
        {
          name: "Countries",
          inputType: "countries",
          icon: "flag"
        },
        {
          name: "Cpi Countries",
          inputType: "cpi-countries",
          icon: "flag"
        },
        {
          name: "Currency",
          inputType: "currency",
          icon: "money-bill-wave"
        },
        {
          name: "Name Titles",
          inputType: "titles",
          icon: "flag"
        },
        {
          name: "Advanced Table",
          inputType: "summary",
          icon: "flag"
        },

        // Radio button
        {
          name: "Radio",
          inputType: "radio",
          icon: "dot-circle"
        },
        {
          name: "Radio Progressive",
          inputType: "radioMulti",
          icon: "dot-circle"
        },
        // disabled as its not fully complete
        // {
        //   name: "Question",
        //   inputType: "question",
        //   icon: "question-circle"
        // },
        {
          name: "Exam Question",
          inputType: "exam-question",
          icon: "question-circle"
        },

        // Checkbox
        {
          name: "Checkbox",
          inputType: "checkbox",
          icon: "check-square"
        },
        // Date
        {
          name: "Date",
          inputType: "date",
          icon: "calendar-alt"
        },
        // Upload
        {
          name: "Upload",
          inputType: "file",
          icon: "file-upload"
        },
        {
          name: "Upload PDFs",
          inputType: "pdf",
          icon: "file-upload"
        },
        {
          name: "Download PDF",
          inputType: "downloadPDF",
          icon: "file-download"
        },
        // Rating
        // { name: "Rating", inputType: "rating", icon: "star" },
        // Button
        // { name: "Button", inputType: "button" , icon:"" },
        // Signature
        {
          name: "Signature",
          inputType: "signature",
          icon: "signature"
        },
        {
          name: "Carousel",
          inputType: "carousel",
          icon: "image"
        },
        {
          name: "Auto Sum",
          inputType: "sum",
          icon: "plus"
        },
        {
          name: "Range",
          inputType: "range",
          icon: "signature"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["isLoading"])
  },
  methods: {
    onClone(e) {
      if (this.$store.state.form1.formType == 6 && e.inputType !== "pdf") {
        alert("This type cannot be added");
      } else if (
        this.$store.state.form1.formType == 1 &&
        e.inputType == "pdf"
      ) {
        alert("This type cannot be added");
      } else {
        let data = _.cloneDeep(FormData.inputData(e.inputType));
        return {
          ...data
        };
      }
    },
    getFieldId(fieldData) {
      return fieldData?.id;
    }
  },
  props: {}
};
</script>
<style scoped>
.pointer {
  cursor: grabbing;
}

.scrollHeight {
  height: calc(100vh - 240px);
  overflow-y: auto;
}
</style>
