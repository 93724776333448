<template>
  <div
    class="ultimate-beneficial-owners"
    data-test-id="ultimate-beneficial-owners"
  >
    <div
      class="ultimate-beneficial-owners__information"
      data-test-id="ultimate-beneficial-owners__information"
    >
      <BaseIcon
        icon="info"
        :size="12"
        :shape="shapes.CIRCLE"
        :theme="themes.VERY_STRONG_INVERSE"
        class="ultimate-beneficial-owners__information-icon"
      />
      <BaseText
        text="The data in this table has been sorted. Numbers in the column headers indicate which columns were used to sort the data, and the order in which the columns were sorted. You can select which column is used for the primary sort by clicking on a column header."
        :size="typographySize.BODY_TEXT_SMALL"
        has-default-spacing-removed
        class="ultimate-beneficial-owners__information-text"
      />
    </div>
    <DataTable
      v-if="hasTableRows"
      :ag-grid-options="agGridOptions"
      :search-filter-bar-options="searchFilterOptions"
      grid-height="calc(100vh - 350px)"
      ref="data-table"
      @grid-ready="onGridReady"
      @click="onDataTableClick"
      @sort-changed="setSortOrder"
    />
  </div>
</template>

<script>
import {
  actionName,
  dataTableEvents,
  mutationName,
  shapes,
  themes,
  ultimateBeneficialOwnersHeaders,
  ultimateBeneficialOwnersKeysDTO,
  ultimateBeneficialOwnersTableColumnKeys,
  timers,
  thirdPartyActionIds,
  sortOrder,
  typographySize,
  sortingSequence,
  riskAlertText
} from "@/constants";
import DataTable from "@/organisms/DataTable/DataTable";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import {
  getRiskIconBasedOnStatus,
  makeUUID,
  sizeColumnsToGridWidth,
  compareStringAlphabeticallyIgnoringCase,
  compareNumber
} from "@/utils";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { mapActions, mapMutations, mapState } from "vuex";
import { isEqual } from "lodash";
import {
  navigateToUBOProfile,
  orderDueDiligence,
  updateAllRowSelections,
  updateRowSelection
} from "@/organisms/UltimateBeneficialOwnersList/UltimateBeneficialOwnersList.logic";
import IconWithText from "@/molecules/IconWithText/IconWithText";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "UltimateBeneficialOwnersList",
  components: {
    BaseIcon,
    BaseText,
    DataTable
  },
  data() {
    return {
      typographySize,
      shapes,
      themes,
      gridAPI: null,
      agGridOptions: {
        gridOptions: {
          enableBrowserTooltips: true,
          suppressRowClickSelection: true,
          rowSelection: "multiple",
          suppressAutoSizing: true,
          defaultColDef: {
            sortable: true
          }
        },
        rowData: [],
        columnDefs: [],
        getRowId: (params) => `${params.data.id}`,
        rowHeight: 90
      },
      searchFilterOptions: {
        actionButtons: [
          {
            id: dataTableEvents.ORDER_IDD,
            value: "Order IDD",
            isLoading: false,
            isSuccess: false,
            isError: false,
            isDisabled: true
          },
          {
            id: dataTableEvents.ORDER_IDD_PLUS,
            value: "Order IDD+",
            isLoading: false,
            isSuccess: false,
            isError: false,
            isDisabled: true
          }
        ]
      },
      showCreateThirdPartyError: false,
      columnInitialWidthMap: {
        [ultimateBeneficialOwnersTableColumnKeys.SELECT]: 75,
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_TYPE]: 155,
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE]: 210,
        [ultimateBeneficialOwnersTableColumnKeys.DIRECT_OWNERSHIP_PERCENTAGE]: 195,
        [ultimateBeneficialOwnersTableColumnKeys.INDIRECT_OWNERSHIP_PERCENTAGE]: 195,
        [ultimateBeneficialOwnersTableColumnKeys.PERSON_OF_SIGNIFICANT_CONTROL]: 195,
        [ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE]: 90,
        [ultimateBeneficialOwnersTableColumnKeys.DEGREE_OF_SEPARATION]: 150,
        [ultimateBeneficialOwnersTableColumnKeys.COUNTRY]: 220,
        [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD]: 160,
        [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD_PLUS]: 160
      },
      numberColumnHeaders: [
        ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.DIRECT_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.INDIRECT_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.ENTITY_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.DEGREE_OF_SEPARATION
      ],
      columnSortingMap: {
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_TYPE]: [
          ...sortingSequence.ASCENDING_DEFAULT
        ],
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE]:
          [...sortingSequence.DESCENDING_DEFAULT],
        [ultimateBeneficialOwnersTableColumnKeys.DIRECT_OWNERSHIP_PERCENTAGE]: [
          ...sortingSequence.DESCENDING_DEFAULT
        ],
        [ultimateBeneficialOwnersTableColumnKeys.INDIRECT_OWNERSHIP_PERCENTAGE]:
          [...sortingSequence.DESCENDING_DEFAULT],
        [ultimateBeneficialOwnersTableColumnKeys.PERSON_OF_SIGNIFICANT_CONTROL]:
          [...sortingSequence.DESCENDING_DEFAULT],
        [ultimateBeneficialOwnersTableColumnKeys.DEGREE_OF_SEPARATION]: [
          ...sortingSequence.DESCENDING_DEFAULT
        ],
        [ultimateBeneficialOwnersTableColumnKeys.COUNTRY]: [
          ...sortingSequence.ASCENDING_DEFAULT
        ],
        [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD]: [
          ...sortingSequence.DESCENDING_DEFAULT
        ],
        [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD_PLUS]: [
          ...sortingSequence.DESCENDING_DEFAULT
        ]
      },
      dueDiligenceOrderType: ""
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    beneficialOwnersList: {
      type: Array,
      default: () => []
    },
    relationships: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      createThirdPartyError: (state) => state.thirdParty.createThirdPartyError,
      isCreatingThirdPartySuccessful: (state) =>
        state.thirdParty.isCreatingThirdPartySuccessful,
      isCreatingThirdParty: (state) => state.thirdParty.isCreatingThirdParty,
      userId: (state) => state.user.userId
    }),
    hasTableRows() {
      return !!this.agGridOptions.rowData.length;
    },
    orderDueDiligenceButtonIndex() {
      const index = this.searchFilterOptions?.actionButtons?.findIndex(
        (action) => action?.id === this.dueDiligenceOrderType
      );

      return index >= 0 ? index : 0;
    }
  },
  watch: {
    beneficialOwnersList: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.makeUBOTableRows(newValue);
        }
      }
    },
    createThirdPartyError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.setCreateThirdPartyError(false);
        }, timers.MODERATE);

        this.showCreateThirdPartyError = true;
        setTimeout(() => {
          this.showCreateThirdPartyError = false;
        }, timers.MODERATE_SLOW);
      }

      this.searchFilterOptions.actionButtons[
        this.orderDueDiligenceButtonIndex
      ].isError = newValue;
    },
    isCreatingThirdPartySuccessful(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.setIsCreatingThirdPartySuccessful(false);
        }, timers.MODERATE);
      }

      this.searchFilterOptions.actionButtons[
        this.orderDueDiligenceButtonIndex
      ].isSuccess = newValue;
    },
    isCreatingThirdParty(newValue) {
      this.searchFilterOptions.actionButtons[0].isDisabled = newValue;
      this.searchFilterOptions.actionButtons[1].isDisabled = newValue;

      this.searchFilterOptions.actionButtons[
        this.orderDueDiligenceButtonIndex
      ].isLoading = newValue;
    }
  },
  created() {
    this.makeUBOTableHeaders();
    if (this.beneficialOwnersList?.length) {
      this.makeUBOTableRows(this.beneficialOwnersList);
    }
  },
  methods: {
    orderDueDiligence,
    ...mapActions({
      createThirdPartyFromUBO:
        actionName.THIRD_PARTY.CREATE_THIRD_PARTY_FROM_UBO
    }),
    ...mapMutations({
      setCreateThirdPartyError:
        mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR,
      setIsCreatingThirdPartySuccessful:
        mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY_SUCCESSFUL
    }),
    onGridReady({ api }) {
      this.gridAPI = api;
      this.sizeColumns();
      sizeColumnsToGridWidth({
        gridAPI: api,
        sizingElement: this.$refs?.["data-table"]?.$el,
        makeColumnLimits: this.makeColumnLimits
      });
      this.setSortOrder({ gridAPI: this.gridAPI });
    },
    makeColumnLimits() {
      return Object.keys(this.columnInitialWidthMap).map((key) => ({
        key,
        maxWidth: this.columnInitialWidthMap[key]
      }));
    },
    isNumberColumn(UBOKey) {
      return !!this.numberColumnHeaders.includes(
        ultimateBeneficialOwnersTableColumnKeys[UBOKey]
      );
    },
    sizeColumns() {
      this.gridAPI.autoSizeColumns([
        ultimateBeneficialOwnersKeysDTO.BENEFICIARY_NAME,
        ultimateBeneficialOwnersKeysDTO.BUSINESS_OWNERSHIP_TYPE
      ]);
    },
    makeUBOSelectHeaderColumn() {
      return {
        field: ultimateBeneficialOwnersTableColumnKeys.SELECT,
        pinned: "left",
        cellRenderer: "BaseGridComponentWrapper",
        headerComponent: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        headerComponentParams: {
          value: {
            component: Checkbox,
            componentOptions: {
              label: "Select all",
              labelHidden: true,
              modelValue: false,
              size: 16,
              "onUpdate:modelValue": () => {
                updateAllRowSelections({
                  gridAPI: this.gridAPI,
                  agGridOptions: this.agGridOptions,
                  setActionButtonDisabledState:
                    this.setActionButtonDisabledState
                });
              }
            }
          }
        },
        valueFormatter: (params) => params.value.componentOptions.modelValue,
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys.SELECT
          ],
        sortable: false
      };
    },
    makeUBOViewProfileHeaderColumn() {
      return {
        field: ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE,
        headerName: ultimateBeneficialOwnersHeaders.VIEW_PROFILE,
        pinned: "right",
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: () => null,
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE
          ],
        sortable: false
      };
    },
    getValueTextIndex(text) {
      const iconSortOrder = [
        riskAlertText.UNDEFINED,
        riskAlertText.NOT_AVAILABLE,
        riskAlertText.NOT_RUN,
        riskAlertText.IN_PROGRESS,
        riskAlertText.NO_RISK_ALERT,
        riskAlertText.NO_RISK_ALERT_REMEDIATED,
        riskAlertText.RISK_ALERT,
        riskAlertText.RISK_ALERT_REMEDIATED
      ];
      const valueText = Array.isArray(text) ? text.join(" ") : text;
      return iconSortOrder.indexOf(valueText);
    },
    makeUBORiskStatusHeaderColumn(UBOKey) {
      return {
        headerName: ultimateBeneficialOwnersHeaders[UBOKey],
        field: ultimateBeneficialOwnersTableColumnKeys[UBOKey],
        headerTooltip: ultimateBeneficialOwnersHeaders[UBOKey],
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys[UBOKey]
          ],
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: (params) => params.value,
        unSortIcon: true,
        sortingOrder:
          this.columnSortingMap[
            ultimateBeneficialOwnersTableColumnKeys[UBOKey]
          ],
        comparator: (valueA, valueB) => {
          const valueAIndex = this.getValueTextIndex(
            valueA?.componentOptions?.textOptions?.text
          );
          const valueBIndex = this.getValueTextIndex(
            valueB?.componentOptions?.textOptions?.text
          );

          if (valueAIndex === null && valueBIndex === null) {
            return 0;
          }
          if (valueAIndex === null) {
            return -1;
          }
          if (valueBIndex === null) {
            return 1;
          }
          return valueAIndex - valueBIndex;
        }
      };
    },
    makeUBOHeaderColumn(UBOKey) {
      return {
        ...(ultimateBeneficialOwnersKeysDTO[UBOKey] ===
          ultimateBeneficialOwnersKeysDTO.BENEFICIARY_NAME && {
          pinned: "left"
        }),
        headerName: ultimateBeneficialOwnersHeaders[UBOKey],
        field: ultimateBeneficialOwnersTableColumnKeys[UBOKey],
        headerTooltip: ultimateBeneficialOwnersHeaders[UBOKey],
        ...(this.columnInitialWidthMap[
          ultimateBeneficialOwnersTableColumnKeys[UBOKey]
        ] && {
          width:
            this.columnInitialWidthMap[
              ultimateBeneficialOwnersTableColumnKeys[UBOKey]
            ]
        }),
        ...(this.isNumberColumn(UBOKey) && {
          type: "numericColumn"
        }),
        ...(ultimateBeneficialOwnersTableColumnKeys[UBOKey] ===
          ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE && {
          sort: sortOrder.DESCENDING
        }),
        unSortIcon: true,
        sortingOrder:
          this.columnSortingMap[
            ultimateBeneficialOwnersTableColumnKeys[UBOKey]
          ],
        ...(this.isNumberColumn(UBOKey)
          ? {
              comparator: (valueA, valueB) =>
                compareNumber({ numberA: valueA, numberB: valueB })
            }
          : {
              comparator: (valueA, valueB) =>
                compareStringAlphabeticallyIgnoringCase({ valueA, valueB })
            })
      };
    },
    makeUBOTableHeaders() {
      this.agGridOptions.columnDefs = Object.keys(
        ultimateBeneficialOwnersHeaders
      ).map((UBOKey) => {
        if (UBOKey === "SELECT") {
          return this.makeUBOSelectHeaderColumn();
        } else if (UBOKey === "VIEW_PROFILE") {
          return this.makeUBOViewProfileHeaderColumn(UBOKey);
        } else if (
          UBOKey === "RISK_STATUS_IDD" ||
          UBOKey === "RISK_STATUS_IDD_PLUS"
        ) {
          return this.makeUBORiskStatusHeaderColumn(UBOKey);
        } else {
          return this.makeUBOHeaderColumn(UBOKey);
        }
      });
    },
    makeUBOSelectRow(rowId) {
      return {
        component: Checkbox,
        componentOptions: {
          label: "Select UBO",
          labelHidden: true,
          size: 16,
          modelValue: false,
          "onUpdate:modelValue": () => {
            updateRowSelection({
              rowId,
              gridAPI: this.gridAPI,
              agGridOptions: this.agGridOptions,
              setActionButtonDisabledState: this.setActionButtonDisabledState
            });
          },
          onChange: () => {
            updateRowSelection({
              rowId,
              gridAPI: this.gridAPI,
              agGridOptions: this.agGridOptions,
              setActionButtonDisabledState: this.setActionButtonDisabledState
            });
          }
        },
        valueFormatter: (params) => params.value.componentOptions.modelValue
      };
    },
    makeUBOViewProfileRow(UBOData) {
      return {
        component: CallToAction,
        componentOptions: {
          icon: "file",
          size: 24,
          theme: themes.SECONDARY,
          shape: shapes.NONE,
          onClick: () =>
            navigateToUBOProfile({
              router: this.$router,
              memberId: UBOData.memberId,
              thirdPartyId: this.thirdPartyId
            })
        },
        valueFormatter: () => null
      };
    },
    makeUBORiskStatusRow(riskStatus) {
      return {
        component: IconWithText,
        componentOptions: {
          ...getRiskIconBasedOnStatus(riskStatus)
        }
      };
    },
    setSharePercentageOnUBO(uboData) {
      return uboData.map((beneficialOwner) => {
        const relationship =
          this.relationships.find(
            (relationship) =>
              beneficialOwner.memberId === relationship.targetMemberId
          ) || {};
        beneficialOwner.relationshipSharePercentage =
          relationship?.sharePercentage ?? null;

        return beneficialOwner;
      });
    },
    makeUBOTableRows(thirdPartyUBOData) {
      let filteredUBOData = thirdPartyUBOData.filter(
        ({ isRootThirdParty }) => !isRootThirdParty
      );
      filteredUBOData = this.setSharePercentageOnUBO(filteredUBOData);
      this.agGridOptions.rowData = filteredUBOData.map((UBOData) => {
        const rowId = makeUUID();
        const keys = Object.keys(UBOData);
        const rowData = keys.reduce((accumulation, key) => {
          accumulation[key] =
            UBOData[key] || UBOData[key] === false || UBOData[key] === 0
              ? `${UBOData[key]}`
              : "-";

          return accumulation;
        }, {});

        return {
          ...rowData,
          [ultimateBeneficialOwnersTableColumnKeys.SELECT]:
            this.makeUBOSelectRow(rowId),
          [ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE]:
            this.makeUBOViewProfileRow(UBOData),
          [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD]:
            this.makeUBORiskStatusRow(
              UBOData[ultimateBeneficialOwnersKeysDTO.RISK_STATUS_IDD]
            ),
          [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS_IDD_PLUS]:
            this.makeUBORiskStatusRow(
              UBOData[ultimateBeneficialOwnersKeysDTO.RISK_STATUS_IDD_PLUS]
            ),
          [ultimateBeneficialOwnersTableColumnKeys.ID]: rowId
        };
      });
    },
    setActionButtonDisabledState(selectedRowsLength = 0) {
      const currentValue =
        this.searchFilterOptions.actionButtons[
          this.orderDueDiligenceButtonIndex
        ].isDisabled;
      const newValue = !selectedRowsLength;

      if (newValue !== currentValue) {
        this.searchFilterOptions.actionButtons[0].isDisabled = newValue;
        this.searchFilterOptions.actionButtons[1].isDisabled = newValue;
      }
    },
    onDataTableClick({ type = "", event = {} }) {
      if (type === dataTableEvents.SEARCH_FILTER_BAR) {
        if (event.id === dataTableEvents.ORDER_IDD) {
          this.showCreateThirdPartyError = false;
          this.dueDiligenceOrderType =
            this.searchFilterOptions.actionButtons[0].id;

          orderDueDiligence({
            gridAPI: this.gridAPI,
            thirdPartyId: this.thirdPartyId,
            thirdPartyActionIds: [thirdPartyActionIds.IDD],
            createThirdPartyFromUBO: this.createThirdPartyFromUBO
          });
        }

        if (event.id === dataTableEvents.ORDER_IDD_PLUS) {
          this.showCreateThirdPartyError = false;
          this.dueDiligenceOrderType =
            this.searchFilterOptions.actionButtons[1].id;

          orderDueDiligence({
            gridAPI: this.gridAPI,
            thirdPartyId: this.thirdPartyId,
            thirdPartyActionIds: [thirdPartyActionIds.IDD_PLUS],
            createThirdPartyFromUBO: this.createThirdPartyFromUBO
          });
        }
      }
    },
    setSortOrder({ gridAPI }) {
      const sortedColumns =
        gridAPI?.getColumnState().filter(({ sort }) => !!sort) || [];

      if (sortedColumns?.length === 1) {
        const { colId, sort } = sortedColumns[0];
        const state = [
          {
            colId,
            sort
          },
          {
            colId:
              ultimateBeneficialOwnersTableColumnKeys.PERSON_OF_SIGNIFICANT_CONTROL,
            sort: sortOrder.DESCENDING
          },
          {
            colId: ultimateBeneficialOwnersTableColumnKeys.OWNED_ENTITY,
            sort: sortOrder.ASCENDING
          }
        ];

        if (
          colId ===
          ultimateBeneficialOwnersTableColumnKeys.PERSON_OF_SIGNIFICANT_CONTROL
        ) {
          state.splice(1, 1);
        } else if (
          colId === ultimateBeneficialOwnersTableColumnKeys.OWNED_ENTITY
        ) {
          state.splice(2, 1);
        }

        const stateWithSortIndex = state.map((column, index) => {
          column.sortIndex = index;
          return column;
        });

        gridAPI.applyColumnState({
          state: stateWithSortIndex
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ultimate-beneficial-owners {
  overflow: hidden;

  &__information {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 40px 0;
    max-width: 1000px;
    margin: 0 auto;

    &-text {
      flex: 1;
    }
  }
}
</style>
