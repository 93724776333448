<template>
  <div class="if-block-wrapper">
    <div class="if-block-wrapper__logic-wrapper">
      <div
        v-for="({ condition, successSteps }, blockIndex) in blocks.ifBlock"
        :key="`if-step--${stepIndex}-${blockIndex}`"
      >
        <div class="if-block-wrapper__logic if-block-wrapper__logic--if">
          <div class="if-block-wrapper__logic-text">
            <BaseText text="If" :size="typographySize.LARGE_TEXT" />
          </div>
          <BracketSection
            :bracket-section="condition"
            data-test-id="if-block-wrapper__logic--bracket"
            @change="
              $emit('change', {
                eventType: 'change',
                property: actionKeys.IF_BLOCK,
                event: $event,
                index: blockIndex
              })
            "
            class="if-block-wrapper__logic-bracket"
          />
        </div>
        <template v-if="showIfSuccessSteps">
          <div
            v-for="(successStep, index) in successSteps"
            :key="successStep.componentOptions.id"
            class="if-block-wrapper__success-blocks if-block-wrapper__success-blocks--if"
          >
            <div class="if-block-wrapper__success-block-column--text">
              <BaseText
                :text="getSuccessStepText(index)"
                :size="typographySize.LARGE_TEXT"
              />
            </div>
            <div
              class="if-block-wrapper__success-block if-block-wrapper__success-block--if"
            >
              <div class="if-block-wrapper__success-step">
                <!-- eslint-disable-next-line vue/require-component-is -->
                <component
                  v-if="successStep.component"
                  v-bind="successStep.componentOptions"
                  :is="successStep.component"
                  :data-test-id="`if-block-wrapper__success-step--${index}`"
                  @change="
                    $emit('change', {
                      eventType: ifBlockEventType.AMEND_SUCCESS_STEP,
                      stepType: successStep.stepType,
                      property: actionKeys.IF_BLOCK,
                      event: $event,
                      index: blockIndex,
                      stepIndex: index
                    })
                  "
                  @create="
                    $emit('create', {
                      eventType: ifBlockEventType.AMEND_SUCCESS_STEP,
                      stepType: successStep.stepType,
                      property: actionKeys.IF_BLOCK,
                      event: $event,
                      index: blockIndex,
                      stepIndex: index
                    })
                  "
                />
              </div>
              <CallToAction
                icon="trash-alt"
                :size="20"
                class="if-block-wrapper__delete-success-step"
                :theme="themes.GREY"
                data-test-id="if-block-wrapper__delete-success-step"
                @click="
                  $emit('change', {
                    eventType: ifBlockEventType.DELETE_SUCCESS_STEP,
                    stepType: successStep.stepType,
                    property: actionKeys.IF_BLOCK,
                    id: successStep.componentOptions.id,
                    index: blockIndex
                  })
                "
              />
            </div>
          </div>
        </template>
        <CallToAction
          class="if-block-wrapper__success-blocks-action--add"
          value="Add success block"
          :theme="themes.SECONDARY"
          data-test-id="if-block-wrapper__success-blocks-action--add"
          @click="addSuccessStep(actionKeys.IF_BLOCK, blockIndex)"
        />
      </div>
      <template v-if="hasElseIF">
        <div
          v-for="(
            { condition, successSteps }, blockIndex
          ) in blocks.elseIfBlocks"
          :key="`else-if-step--${stepIndex}-${blockIndex}`"
        >
          <div class="if-block-wrapper__logic if-block-wrapper__logic--else-if">
            <div class="if-block-wrapper__logic-text">
              <BaseText text="Else if" :size="typographySize.LARGE_TEXT" />
            </div>
            <BracketSection
              :bracket-section="condition"
              data-test-id="if-block-wrapper__logic--else-if-bracket"
              @change="
                $emit('change', {
                  eventType: 'change',
                  property: actionKeys.ELSE_IF_BLOCKS,
                  event: $event,
                  index: blockIndex
                })
              "
              class="if-block-wrapper__logic-bracket"
            />
            <CallToAction
              icon="trash-alt"
              :size="20"
              class="if-block-wrapper__delete-success-step"
              data-test-id="if-block-wrapper__delete-success-step--else-if-block"
              :theme="themes.GREY"
              @click="
                $emit('change', {
                  eventType: ifBlockEventType.DELETE_ELSE_IF_BLOCK,
                  property: actionKeys.ELSE_IF_BLOCKS,
                  index: blockIndex,
                  block: blocks.elseIfBlocks[blockIndex]
                })
              "
            />
          </div>
          <template v-if="showElseIfSuccessSteps(blockIndex)">
            <div
              v-for="(successStep, successStepIndex) in successSteps"
              :key="successStep.componentOptions.id"
              class="if-block-wrapper__success-blocks if-block-wrapper__success-blocks--else-if"
            >
              <div class="if-block-wrapper__success-block-column--text">
                <BaseText
                  :text="getSuccessStepText(successStepIndex)"
                  :size="typographySize.LARGE_TEXT"
                />
              </div>
              <div
                class="if-block-wrapper__success-block if-block-wrapper__success-block--else-if"
              >
                <div class="if-block-wrapper__success-step">
                  <!-- eslint-disable-next-line vue/require-component-is -->
                  <component
                    v-if="successStep.component"
                    v-bind="{
                      ...successStep.componentOptions
                    }"
                    :is="successStep.component"
                    :data-test-id="`if-block-wrapper__success-block--else-if-${successStepIndex}`"
                    @change="
                      $emit('change', {
                        eventType: ifBlockEventType.AMEND_SUCCESS_STEP,
                        property: actionKeys.ELSE_IF_BLOCKS,
                        stepType: successStep.stepType,
                        event: $event,
                        index: blockIndex
                      })
                    "
                  />
                </div>
                <CallToAction
                  icon="trash-alt"
                  :size="20"
                  class="if-block-wrapper__delete-success-step"
                  data-test-id="if-block-wrapper__delete-success-step--else-if-component"
                  :theme="themes.GREY"
                  @click="
                    $emit('change', {
                      eventType: ifBlockEventType.DELETE_SUCCESS_STEP,
                      stepType: successStep.stepType,
                      property: actionKeys.ELSE_IF_BLOCKS,
                      id: successStep.componentOptions.id,
                      index: blockIndex
                    })
                  "
                />
              </div>
            </div>
          </template>
          <CallToAction
            class="if-block-wrapper__success-blocks-action--add"
            data-test-id="if-block-wrapper__success-blocks-action--add-else-if"
            value="Add success block"
            :theme="themes.SECONDARY"
            @click="addSuccessStep(actionKeys.ELSE_IF_BLOCKS, blockIndex)"
          />
        </div>
      </template>

      <div v-if="hasElseStep" class="if-block-wrapper__else-wrapper">
        <div
          v-for="(successStep, successStepIndex) in blocks.elseBlock"
          :key="successStep.componentOptions.id"
          class="if-block-wrapper__success-blocks if-block-wrapper__success-blocks--else"
        >
          <div class="if-block-wrapper__success-block-column--text">
            <BaseText
              :text="getSuccessStepText(successStepIndex, 'else')"
              :size="typographySize.LARGE_TEXT"
            />
          </div>
          <div
            class="if-block-wrapper__success-block if-block-wrapper__success-block--else"
          >
            <div class="if-block-wrapper__success-step">
              <!-- eslint-disable-next-line vue/require-component-is -->
              <component
                v-if="successStep.component"
                v-bind="{
                  ...successStep.componentOptions
                }"
                :is="successStep.component"
                :data-test-id="`if-block-wrapper__success-block--else-${successStepIndex}`"
                @change="
                  $emit('change', {
                    eventType: ifBlockEventType.AMEND_SUCCESS_STEP,
                    property: actionKeys.ELSE_BLOCK,
                    stepType: successStep.stepType,
                    event: $event
                  })
                "
              />
            </div>
            <CallToAction
              icon="trash-alt"
              :size="20"
              class="if-block-wrapper__delete-success-step"
              data-test-id="if-block-wrapper__delete-success-step--else"
              :theme="themes.GREY"
              @click="
                $emit('change', {
                  eventType: ifBlockEventType.DELETE_SUCCESS_STEP,
                  stepType: successStep.stepType,
                  property: actionKeys.ELSE_BLOCK,
                  id: successStep.componentOptions.id
                })
              "
            />
          </div>
        </div>
        <CallToAction
          class="if-block-wrapper__success-blocks-action--add"
          data-test-id="if-block-wrapper__success-blocks-action--add-else"
          value="Add success block"
          :theme="themes.SECONDARY"
          @click="addSuccessStep(actionKeys.ELSE_BLOCK)"
        />
      </div>
    </div>
    <BaseFooter class="if-block-wrapper__footer">
      <template #left>
        <div>
          <CallToAction
            value="Add else if"
            :theme="themes.SECONDARY"
            @click="addElseIfBlock"
            class="if-block-wrapper__add-blocks--else-if"
            data-test-id="if-block-wrapper__add-blocks--else-if"
          />
          <CallToAction
            v-if="!hasElseStep"
            value="Add else"
            :theme="themes.SECONDARY"
            @click="addElseBlock"
            class="if-block-wrapper__add-blocks--else"
            data-test-id="if-block-wrapper__add-blocks--else"
          />
        </div>
      </template>
    </BaseFooter>
  </div>
</template>

<script>
import {
  actionKeys,
  ifBlockEventType,
  stepAccordionOptions,
  themes,
  typographySize
} from "@/constants";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import BracketSection from "@/molecules/BracketSection/BracketSection";

export default {
  name: "IfBlockWrapper",
  components: {
    BracketSection,
    Select,
    BaseText,
    CallToAction,
    BaseFooter
  },
  props: {
    blocks: {
      type: Object,
      default: () => ({})
    },
    stepIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      stepAccordionOptions,
      themes,
      isAccordionCollapsed: false,
      ifBlockEventType,
      actionKeys,
      typographySize
    };
  },
  computed: {
    hasElseIF() {
      return !!this.blocks?.elseIfBlocks?.length;
    },
    hasElseStep() {
      return !!this.blocks?.elseBlock?.length;
    },
    showIfSuccessSteps() {
      return !!this.blocks.ifBlock?.[0]?.successSteps?.length;
    }
  },
  methods: {
    showElseIfSuccessSteps(index) {
      return !!this.blocks.elseIfBlocks?.[index]?.successSteps?.length;
    },
    getSuccessStepText(index, blockType) {
      const firstWord = blockType === "else" ? "Else" : "Then";

      return index === 0 ? firstWord : "And";
    },
    addSuccessStep(property, index) {
      this.$emit("change", {
        eventType: ifBlockEventType.ADD_SUCCESS_STEP,
        property,
        index
      });
    },
    addElseIfBlock() {
      this.$emit("change", {
        eventType: ifBlockEventType.ADD_BLOCK,
        property: actionKeys.ELSE_IF_BLOCKS
      });
    },
    addElseBlock() {
      this.$emit("change", {
        eventType: ifBlockEventType.ADD_BLOCK,
        property: actionKeys.ELSE_BLOCK
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.if-block-wrapper {
  &__logic {
    display: flex;
    flex-direction: row;

    &--else-if {
      border-top: 2px solid $davy-grey;
      padding-top: 15px;
      margin-top: 15px;
    }

    &-bracket {
      width: calc(100% - 120px);
      margin-left: 15px;
    }

    &-text {
      min-width: 75px;
    }
  }

  &__else-wrapper {
    border-top: 2px solid $davy-grey;
    padding-top: 15px;
    margin-top: 15px;
  }

  &__success-blocks {
    margin-bottom: 15px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    border-top: 1px dashed $chinese-silver;

    &--else:first-child {
      border: none;
      padding-top: 0;
    }

    &-action--add {
      margin-left: 90px;
    }
  }

  &__success-block {
    display: flex;
    flex: 1;
    margin-left: 90px;

    &-column--text {
      margin-right: 15px;
    }
  }

  &__success-step {
    width: calc(100% - 30px);
  }

  &__add-blocks--else-if {
    margin-right: 15px;
  }

  &__footer {
    margin-top: 15px;
  }
}
</style>
