<template>
  <div class="idd-result">
    <b-card style="border: none">
      <b-card-title>
        <div class="text-right">
          <Tooltip
            class="idd-result__tooltip"
            text="The figures associated with this chart include screened beneficial owners"
          />
          <b-dropdown size="sm" variant="outline-info" class="text-capitalize">
            <template v-slot:button-content>
              <font-awesome-icon icon="cogs" />
            </template>
            <b-dropdown-item @click="onSelectSanctionsAlertsChart('Pie')">{{
              $t("eb360.analyticsTabChart.selectChart.pie")
            }}</b-dropdown-item>
            <b-dropdown-item @click="onSelectSanctionsAlertsChart('Pyramid')">{{
              $t("eb360.analyticsTabChart.selectChart.pyramid")
            }}</b-dropdown-item>
            <b-dropdown-item @click="onSelectSanctionsAlertsChart('Funnel')">{{
              $t("eb360.analyticsTabChart.selectChart.funnel")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-title>
      <highcharts :options="sanctionsAlertsCountChartOptions" />
    </b-card>
  </div>
</template>

<script>
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "IddResultsComponent",
  components: {
    Tooltip
  },
  props: {
    sanctionsAlertsCountChartOptions: { type: Object, default: () => ({}) }
  },
  methods: {
    // change chart type functions
    onSelectSanctionsAlertsChart(type) {
      this.sanctionsAlertsCountChartOptions.chart.type = type.toLowerCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.idd-result {
  border: $element-border;

  &__tooltip {
    margin-right: 5px;
  }
}
</style>
